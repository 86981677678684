<template>
  <b-modal
    visible
    scrollable
    centered
    :title="title"
    size="xl"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <template #modal-header>
      <div class="h5">
        {{ title }}
      </div>

      <div>
        <b-button
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          :disabled="isLoading || isSaving || ishideLoading"
          title="Закрыть"
          variant="secondary"
          class="mr-2"
          @click="onClickHide"
        >
          <b-icon icon="dash" />
        </b-button>

        <b-button
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          title="Закрыть"
          variant="danger"
          @click="onClose"
        >
          <b-icon icon="x" />
        </b-button>
      </div>
    </template>
    <div
      v-if="!isLoading"
      class="appeal-content d-flex"
    >
      <AppealStages
        v-if="!isMidAdmin"
        :stages="stages"
        class="appeal-stages mr-4"
      />
      <div
        style="max-width: calc(100% - 154px - 1.5rem)"
        class="w-100"
      >
        <div class="d-flex mb-2">
          <div style="min-width: 270px;">
            <b-form-group
              label="Дата и время"
            >
              <b-form-input
                :value="getFormatDate(form.date, 'dd.MM.yyyy HH:mm')"
                type="text"
                size="sm"
                disabled
              />
            </b-form-group>
            <b-form-checkbox
              v-if="show.urgency"
              v-model="$v.form.urgency.$model"
              :disabled="disabledFields.urgency"
              name="check-button"
              switch
            >
              Срочно
            </b-form-checkbox>
            <b-form-checkbox
              v-if="show.transportation"
              v-model="$v.form.transportation.$model"
              :disabled="disabledFields.transportation"
              name="check-button"
              switch
            >
              Нужна ли транспортировка
            </b-form-checkbox>
          </div>

          <b-form-group
            label="Цель обращения"
            class="ml-5"
          >
            <div
              v-if="!isMidAdmin"
              class="d-flex flex-wrap"
            >
              <div
                v-for="purpose in APPEALS_PURPOSES_TEXTS"
                :key="purpose.id"
                class="w-50"
              >
                <b-form-radio
                  v-model="$v.form.newPurpose.$model"
                  name="purpose-radios"
                  :value="purpose.id"
                  :disabled="disabledFields.purpose"
                >
                  {{ purpose.title }}
                </b-form-radio>
              </div>
            </div>
            <div
              v-else
              class="d-flex flex-wrap"
            >
              <div
                :key="APPEALS_PURPOSES_TEXTS[2].id"
                class="w-50"
              >
                <b-form-radio
                  v-model="$v.form.newPurpose.$model"
                  name="purpose-radios"
                  :value="APPEALS_PURPOSES_TEXTS[2].id"
                >
                  {{ APPEALS_PURPOSES_TEXTS[2].title }}
                </b-form-radio>
              </div>
            </div>
            <!-- <b-form-radio-group
              :options="APPEALS_PURPOSES_TEXTS"
              size="sm"
              value-field="id"
              text-field="title"
              stacked
            /> -->
          </b-form-group>
        </div>

        <!-- categoryId, categoryAnotherText -->
        <AppealCategory
          v-if="show.category"
          v-model="$v"
          :v="$v"
          :errors-validation="errorsValidation"
          :categories="categories"
          :disabled-fields="disabledFields"
          :category-another-show="show.categoryAnotherText"
          class="mb-2"
        />

        <!-- type, subtype -->
        <AppealType
          v-if="show.type"
          v-model="$v"
          :v="$v"
          :errors-validation="errorsValidation"
          :showss="show"
          :types="selectedCategory.types"
          :subtypes="subtypes"
          :disabled-fields="disabledFields"
          :type-another-text-show="show.typeAnotherText"
          class="mb-2"
        />

        <AppealPatientInfo
          :person="person"
          class="mb-2"
        />

        <b-form-group
          v-if="conditionForChoosePolicy"
          label="Выберите полис"
          :invalid-feedback="errorsValidation.policyId[0]"
          :state="$v.$anyError ? !$v.form.policyId.$error : null"
        >
          <AppealPoliciesList
            :error="$v.$anyError ? $v.form.policyId.$error : false"
            :policies="choosePolicy"
            :person="person"
            :selected-policy="selectedPolicy"
            :disabled="disabledFields.policyId"
            class="mb-2"
            @select-policy="selectPolicy"
          />
        </b-form-group>

        <!-- techComment -->
        <b-form-group
          v-if="show.techComment"
          v-model="$v.form.techComment.$model"
          :disabled="disabledFields.techComment"
          :state="$v.$anyError ? !$v.form.techComment.$error : null"
          label="Комментакий технической поддержки"
          class="mb-2"
        >
          <b-form-textarea
            placeholder="Введите текст"
            rows="3"
            size="sm"
          />
        </b-form-group>

        <b-form-group
          v-if="show.techsupportPurposeType"
          label="Техподдержка, куда передать обращение"
          class="mb-2"
        >
          <b-form-select
            v-model="$v.form.techsupportPurposeType.$model"
            :disabled="disabledFields.techsupportPurposeType"
            :state="$v.$anyError ? !$v.form.techsupportPurposeType.$error : null"
            :options="APPEALS_TECHSUPPORT_WHERE_TO_SEND_TEXTS"
            size="sm"
            value-field="id"
            text-field="title"
          />
        </b-form-group>

        <!-- YandexLink -->
        <b-form-group
          v-if="show.yandexLink"
          label="Ссылка на Яндекс трекер"
        >
          <b-input
            v-model="form.yandexLink"
            :disabled="disabledFields.yandexLink"
            placeholder="Введите текст"
            size="sm"
          />
        </b-form-group>
        <!-- PolicyNumber -->
        <b-form-group
          v-if="show.policyNumber && !selectedCategoryClinicChoicePolicy"
          label="Номер полиса"
        >
          <b-input
            v-model="$v.form.policyNumber.$model"
            :disabled="disabledFields.policyNumber"
            :state="$v.$anyError ? !$v.form.policyNumber.$error : null"
            placeholder="Введите номер полиса"
            size="sm"
          />
        </b-form-group>
        <!-- PatientInfo -->
        <b-form-group
          v-if="show.patientInfo"
          label="Информация о пациенте"
        >
          <b-form-textarea
            v-model="$v.form.patientInfo.$model"
            :disabled="disabledFields.patientInfo"
            :state="$v.$anyError ? !$v.form.patientInfo.$error : null"
            placeholder="Введите текст"
            rows="3"
            size="sm"
          />
        </b-form-group>
        <b-row
          v-if="(show.policyBuyDate || show.isActivePolicy) && !selectedCategoryClinicChoicePolicy"
          class="d-flex"
        >
          <!-- <b-row
          v-if="show.policyBuyDate || show.isActivePolicy"
          class="d-flex"
        > -->
          <!-- PolicyBuyDate -->
          <b-col v-if="show.policyBuyDate">
            <b-form-group
              label="Примерная дата приобретения полиса"
              style="width: 200px;"
            >
              <base-date-picker
                v-model="$v.form.policyBuyDate.$model"
                :disabled="disabledFields.policyBuyDate"
                :error="$v.form.policyBuyDate.$error"
                :position-fixed="true"
              />
            </b-form-group>
          </b-col>

          <!-- isActivePolicy -->
          <b-col v-if="show.isActivePolicy">
            <b-form-group
              label="Активация полиса на сайте страховой компании"
            >
              <b-form-checkbox
                v-model="$v.form.isActivePolicy.$model"
                :disabled="disabledFields.isActivePolicy"
                :state="$v.$anyError ? !$v.form.isActivePolicy.$error : null"
                name="check-button"
                switch
                class="mb-2"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- service -->
        <b-form-group
          v-if="show.service"
          label="Сервис"
          class="mb-2"
        >
          <b-form-textarea
            v-model="$v.form.service.$model"
            :disabled="disabledFields.service"
            :state="$v.$anyError ? !$v.form.service.$error : null"
            placeholder="Введите текст"
          />
        </b-form-group>

        <!-- !! Город и инициатор в одну строчку  -->
        <!-- gemotestAppealId -->
        <!-- <b-form-group
          label="Гемотест обращение id"
        >
          <b-input
            v-model="form.gemotestAppealId"
            placeholder="Введите текст"
          />
        </b-form-group> -->

        <!-- consultationId -->
        <b-form-group
          v-if="show.consultationId"
          label="Id консультации"
          :invalid-feedback="errorsValidation.consultationId[0]"
        >
          <b-input
            v-model="$v.form.consultationId.$model"
            :disabled="disabledFields.consultationId"
            :state="$v.$anyError ? !$v.form.consultationId.$error : null"
            placeholder="Введите текст"
          />
        </b-form-group>

        <!-- cityId -->
        <b-form-group
          v-if="show.city && !selectedCategoryAdjustmentExtensionGP"
          label="Город"
          :state="$v.$anyError ? !$v.form.cityId.$error : null"
          :invalid-feedback="errorsValidation.cityId[0]"
        >
          <v-select
            v-model="$v.form.cityId.$model"
            :reduce="city => city.id"
            :options="cities"
            placeholder="Выберите город"
            label="name"
            :clearable="true"
            :disabled="disabledFields.cityId"
            class="mw-100"
            @input="onCityChange"
          />
        </b-form-group>

        <!-- clinicId -->
        <b-form-group
          v-if="show.clinic"
          label="Клиника"
          :state="$v.$anyError ? !$v.form.clinicId.$error : null"
          :invalid-feedback="errorsValidation.clinicId[0]"
        >
          <v-select
            v-model="$v.form.clinicId.$model"
            :reduce="clinic => clinic.id"
            :options="clinicsForSelectedCity"
            placeholder="Выберите клинику"
            :disabled="disabledFields.clinicId"
            label="title"
            :clearable="true"
            class="mw-100"
          />
          <!-- <b-form-select
            v-model="$v.form.clinicId.$model"
            :disabled="disabledFields.clinicId"
            :options="clinicsForSelectedCity"
            :state="$v.$anyError ? !$v.form.clinicId.$error : null"
            text-field="title"
            value-field="id"
            size="sm"
          >
            <template #first>
              <b-form-select-option
                :value="null"
              >
                Выберите клинику
              </b-form-select-option>
            </template>
          </b-form-select> -->
        </b-form-group>

        <!-- rejectionReason -->
        <div
          v-if="show.rejectionReason && clinic?.clinicNetworkId !== 1"
          class="d-flex align-items-center"
        >
          <b-form-group
            label="Причина отказа от МИД"
            class="w-100"
          >
            <b-form-textarea
              v-model="form.rejectionReason"
              :disabled="disabledFields.rejectionReason"
              :state="$v.$anyError ? !$v.form.rejectionReason.$error : null"
              placeholder="Введите текст"
              rows="2"
              max-rows="15"
              size="sm"
            />
          </b-form-group>
          <div class="ml-3 mt-3">
            <b-button
              variant="outline-success"
              :type="$const.PRIMARY_BUTTON"
              squared
              size="md"
              class="border custom-btn"
              title="Выбрать шаблон"
              @click="openAppealTemplateModalForReject"
            >
              <b-icon icon="list-ul" />
            </b-button>
          </div>
        </div>

        <b-form-group
          v-if="show.administratorName"
          label="ФИО Администратора"
          :invalid-feedback="errorsValidation.administratorName[0]"
        >
          <b-form-input
            v-model="$v.form.administratorName.$model"
            placeholder="Введите текст"
            :disabled="disabledFields.administratorName"
            :state="$v.$anyError ? !$v.form.administratorName.$error : null"
            size="sm"
          />
        </b-form-group>

        <!-- OrderNumber -->
        <b-form-group
          v-if="show.orderNumber"
          label="Номер заказа"
          :invalid-feedback="errorsValidation.orderNumber[0]"
        >
          <b-input
            v-model="$v.form.orderNumber.$model"
            :disabled="disabledFields.orderNumber"
            :state="$v.$anyError ? !$v.form.orderNumber.$error : null"
            placeholder="Введите номер заказа"
            size="sm"
          />
        </b-form-group>

        <!-- consultationReason -->
        <b-form-group
          v-if="show.consultationReason"
          label="Причина консультации"
          class="mb-2"
        >
          <b-form-textarea
            v-model="$v.form.consultationReason.$model"
            :disabled="disabledFields.consultationReason"
            :state="$v.$anyError ? !$v.form.consultationReason.$error : null"
            placeholder="Введите текст"
            rows="3"
            size="sm"
          />
        </b-form-group>
        <!-- consultationComment -->
        <b-form-group
          v-if="show.consultationComment"
          label="Комментарий консультации"
          class="mb-2"
        >
          <b-form-textarea
            v-model="$v.form.consultationComment.$model"
            :disabled="disabledFields.consultationComment"
            :state="$v.$anyError ? !$v.form.consultationComment.$error : null"
            placeholder="Введите текст"
            rows="3"
            size="sm"
          />
        </b-form-group>

        <!-- checkupDate, checkupProfileId -->
        <AppealCheckout
          v-if="show.checkout"
          :v="$v"
          :disabled-fields="disabledFields"
          :show-checkup-another-text="show.checkupAnotherText"
          :errors-validation="errorsValidation"
          :checkup-profiles-list="checkupProfilesList"
          class="mb-2"
        />

        <!-- insuranseCompanyId -->
        <b-form-group
          v-if="show.insuranceCompany && !selectedCategoryClinicChoicePolicy"
          label="Страховая компания"
          class="mb-2"
          :invalid-feedback="errorsValidation.insuranseCompanyId[0]"
        >
          <b-form-select
            v-model="$v.form.insuranseCompanyId.$model"
            :disabled="disabledFields.insuranseCompanyId"
            :state="$v.$anyError ? !$v.form.insuranseCompanyId.$error : null"
            :options="insuranceCompanies"
            text-field="title"
            value-field="id"
          >
            <template #first>
              <b-form-select-option
                :value="null"
              >
                Выберите компанию
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>

        <!-- regionId -->
        <b-form-group
          v-if="show.region"
          label="Регион"
          :invalid-feedback="errorsValidation.regionId[0]"
        >
          <b-form-select
            v-model="$v.form.regionId.$model"
            :disabled="disabledFields.regionId"
            :options="regions"
            :state="$v.$anyError ? !$v.form.regionId.$error : null"
            size="sm"
            text-field="name"
            value-field="id"
          >
            <template #first>
              <b-form-select-option
                :value="null"
              >
                Выберите регион
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>

        <b-form-group
          v-if="show.files"
          label="Файлы"
        >
          <div
            v-if="documents.length"
            class="mb-3 cursor-pointer"
          >
            <!-- disabled при нажатии на удаление -->
            <base-file
              v-for="document in documents"
              :key="document.id"
              :file="document"
              file-api="File/"
              edit
              :disabled="disabledFields.fileIds"
              class="mb-2"
              @delete="onClickDeleteFiles(document)"
            />
          </div>

          <b-button
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            :disabled="disabledFields.fileIds"
            size="sm"
            @click="openAddDocumentsModal"
          >
            Добавить файлы
          </b-button>
        </b-form-group>
        <!-- label-class="required" -->

        <b-form-group
          v-if="show.comment"
          label="Комментарий"
          :invalid-feedback="errorsValidation.comment[0]"
        >
          <b-form-textarea
            v-model="$v.form.comment.$model"
            :disabled="disabledFields.comment"
            :state="$v.$anyError ? !$v.form.comment.$error : null"
            placeholder="Введите текст"
            rows="3"
            size="sm"
          />
        </b-form-group>

        <b-form-group
          v-if="show.reconciliationComment"
          label="Комментарий ответственного"
        >
          <b-form-textarea
            v-model="$v.form.reconciliationComment.$model"
            :disabled="disabledFields.reconciliationsComment"
            :state="$v.$anyError ? !$v.form.reconciliationComment.$error : null"
            placeholder="Введите текст"
            rows="3"
            size="sm"
          />
        </b-form-group>

        <!-- OperatorComment -->
        <b-form-group
          v-if="show.operatorComment"
          label="Комментарий оператора"
        >
          <b-form-textarea
            v-model="$v.form.operatorComment.$model"
            :disabled="disabledFields.operatorComment"
            :state="$v.$anyError ? !$v.form.operatorComment.$error : null"
            placeholder="Введите текст"
            rows="3"
            size="sm"
          />
        </b-form-group>

        <b-form-group
          v-if="show.status"
          label="Статус обращения"
          :invalid-feedback="errorsValidation.status[0]"
        >
          <b-form-select
            v-model="$v.form.status.$model"
            :options="appealStatusOptions"
            :disabled="disabledFields.status"
            :state="$v.$anyError ? !$v.form.status.$error : null"
            text-field="title"
            value-field="id"
            size="sm"
          />
        </b-form-group>

        <!-- qualityControlPurposeType -->
        <b-form-group
          v-if="show.qualityControl"
          label="Контроль качества, куда передать обращение"
        >
          <b-form-radio-group
            v-model="$v.form.qualityControlPurposeType.$model"
            :disabled="disabledFields.qualityControlPurposeType"
            :state="$v.$anyError ? !$v.form.qualityControlPurposeType.$error : null"
            :options="APPEALS_QUALITY_CONTROL_WHERE_TO_SEND_TEXTS"
            size="sm"
            value-field="id"
            text-field="title"
          />
        </b-form-group>

        <b-form-group
          v-if="show.responsible"
          label="Ответственный"
        >
          <b-form-input
            :value="appealResponsible"
            type="text"
            disabled
            size="sm"
          />
        </b-form-group>

        <!-- returnType -->
        <b-form-group
          v-if="show.returnType"
          label="Тип возврата"
          invalid-feedback="Выберите один из вариантов"
        >
          <b-form-select
            v-model="$v.form.returnType.$model"
            :disabled="disabledFields.returnType"
            :state="$v.$anyError ? !$v.form.returnType.$error : null"
            size="sm"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                Выберите тип возврата
              </b-form-select-option>
            </template>
            <template>
              <b-form-select-option
                :value="0"
              >
                Гемотест
              </b-form-select-option>
            </template>
            <template>
              <b-form-select-option
                :value="1"
              >
                Консультация
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>

        <!-- returnComment -->
        <b-form-group
          v-if="show.returnComment"
          invalid-feedback="Обязательное поле"
          label="Комментарий к возврату оплаты"
        >
          <b-form-textarea
            v-model="$v.form.returnComment.$model"
            :disabled="disabledFields.returnComment"
            :state="$v.$anyError ? !$v.form.returnComment.$error : null"
            placeholder="Введите комментарий"
            rows="3"
            size="sm"
          />
        </b-form-group>

        <div
          v-if="actualStage === 2 && selectedPurposeServiceCoordination"
          class="my-divider"
        />
        <div
          v-if="showCoordinationServices"
          class="reconciliations mb-2 mt-4"
        >
          <div
            v-for="({id, reconciliation, $anyError, showContent, isChanged}, reconciliationIndex) in $v.reconciliations.$each.$iter"
            :key="id.$model"
            class="reconciliation-item"
          >
            <div class="d-flex mb-2 bg-white">
              <div
                class="d-flex justify-content-between align-items-center bg-primary rounded p-2 w-100"
                :class="{
                  'border border-danger bg-white': $anyError,
                }"
                @click="showContent.$model = !showContent.$model"
              >
                <div
                  :class="[
                    $anyError ? 'text-dark' : 'text-white'
                  ]"
                >
                  Согласование {{ Number(reconciliationIndex) + 1 }}
                </div>

                <b-icon
                  icon="caret-down-fill"
                  :variant="$anyError ? 'danger' : 'light'"
                  :rotate="showContent.$model ? 180 : 0"
                />
              </div>

              <b-button
                variant="success"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                class="ml-2 text-nowrap"
                :disabled="disabledFields.applyTemplate"
                @click="openAppealTemplateModal(reconciliation)"
              >
                Выбрать шаблон
              </b-button>

              <b-button
                v-if="show.removeReconciliation"
                variant="danger"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                class="ml-2"
                :disabled="disabledFields.removeReconciliation"
                @click="agreeRemoveReconciliation(id.$model)"
              >
                <b-icon icon="x" />
              </b-button>
            </div>

            <div
              v-if="showContent.$model"
              class="px-3"
            >
              <b-row
                v-if="show.reconciliationsInitiator
                  || show.reconciliationsCreateDate"
              >
                <!-- reconciliations / initiator -->
                <b-col v-if="show.reconciliationsInitiator">
                  <b-form-group
                    label="Инициатор согласования"
                  >
                    <b-form-select
                      v-model="reconciliation.initiator.$model"
                      :options="initiators"
                      :disabled="disabledFields.reconciliationsInitiator"
                      :state="$v.$anyError ? !reconciliation.initiator.$error : null"
                      text-field="title"
                      value-field="id"
                      size="sm"
                      @select="isChanged = true"
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                        >
                          Выберите
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col v-if="show.reconciliationsCreateDate">
                  <b-form-group
                    v-if="reconciliation.createDate.$model"
                    label="Дата и время согласования"
                    style="width: 235px;"
                  >
                    <b-form-input
                      :value="getFormatDate(reconciliation.createDate.$model, 'dd.MM.yyyy HH:mm')"
                      type="text"
                      size="sm"
                      disabled
                      @input="isChanged.$model = true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- reconciliations / diagnosis -->
              <b-form-group
                v-if="show.reconciliationsDiagnosis"
                label="Диагноз"
                invalid-feedback="Обязательное поле"
              >
                <b-form-textarea
                  v-model="reconciliation.diagnosis.$model"
                  :disabled="disabledFields.reconciliationsDiagnosis"
                  :state="$v.$anyError ? !reconciliation.diagnosis.$error : null"
                  placeholder="Введите текст"
                  rows="3"
                  size="sm"
                  @input="isChanged.$model = true"
                />
              </b-form-group>

              <!-- reconciliations / doctorName -->
              <b-form-group
                v-if="show.reconciliationsDoctorName"
                label="ФИО врача"
                invalid-feedback="Обязательное поле"
              >
                <b-form-textarea
                  v-model="reconciliation.doctorName.$model"
                  :disabled="disabledFields.reconciliationsDoctorName"
                  :state="$v.$anyError ? !reconciliation.doctorName.$error : null"
                  placeholder="Введите текст"
                  rows="2"
                  size="sm"
                  @input="isChanged.$model = true"
                />
              </b-form-group>

              <!-- reconciliations / agreedServices -->
              <b-form-group
                v-if="show.reconciliationsAgreedServices"
                label="Согласованные услуги"
              >
                <b-form-textarea
                  v-model="reconciliation.agreedServices.$model"
                  placeholder="Введите текст"
                  :disabled="disabledFields.reconciliationsAgreedServices"
                  :state="$v.$anyError ? !reconciliation.agreedServices.$error : null"
                  rows="3"
                  size="sm"
                  @input="isChanged.$model = true"
                />
              </b-form-group>

              <!-- reconciliations / notAgreedServices -->
              <b-form-group
                v-if="show.reconciliationsNotAgreedServices"
                label="Несогласованные услуги"
              >
                <b-form-textarea
                  v-model="reconciliation.notAgreedServices.$model"
                  placeholder="Введите текст"
                  :disabled="disabledFields.reconciliationsNotAgreedServices"
                  :state="$v.$anyError ? !reconciliation.notAgreedServices.$error : null"
                  rows="3"
                  size="sm"
                  @input="isChanged.$model = true"
                />
              </b-form-group>

              <!-- regectionReason -->
              <b-form-group
                v-if="show.reconciliationsRegectionReason"
                label="Причина отказа"
              >
                <b-form-textarea
                  v-model="reconciliation.regectionReason.$model"
                  :disabled="disabledFields.reconciliationsRegectionReason"
                  :state="$v.$anyError ? !reconciliation.regectionReason.$error : null"
                  placeholder="Введите текст"
                  rows="3"
                  size="sm"
                  @input="isChanged.$model = true"
                />
              </b-form-group>

              <!-- reconciliations / doctorComment -->
              <b-form-group
                v-if="show.reconciliationsDoctorComment"
                label="Комментарий врача"
              >
                <b-form-textarea
                  v-model="reconciliation.doctorComment.$model"
                  placeholder="Введите текст"
                  :disabled="disabledFields.reconciliationsDoctorComment"
                  :state="$v.$anyError ? !reconciliation.doctorComment.$error : null"
                  rows="3"
                  size="sm"
                  @input="isChanged.$model = true"
                />
              </b-form-group>

              <!-- reconciliations / insuranseDiagnosis -->
              <b-form-group
                v-if="show.reconciliationsInsuranseDiagnosis"
                label="Страховой диагноз"
              >
                <b-form-textarea
                  v-model="reconciliation.insuranseDiagnosis.$model"
                  placeholder="Введите текст"
                  :disabled="disabledFields.reconciliationsInsuranseDiagnosis"
                  :state="$v.$anyError ? !reconciliation.insuranseDiagnosis.$error : null"
                  rows="3"
                  size="sm"
                  @input="isChanged.$model = true"
                />
              </b-form-group>

              <!-- reconciliations / insuranseDiagnosis -->
              <b-form-group
                v-if="show.reconciliationsFiles"
                label="Файлы"
              >
                <div
                  v-if="reconciliation.documents.$model && reconciliation.documents.$model.length"
                  class="mb-3 cursor-pointer"
                >
                  <!-- disabled при нажатии на удаление -->
                  <base-file
                    v-for="document in reconciliation.documents.$model"
                    :key="document.id"
                    :file="document"
                    file-api="File/"
                    edit
                    :disabled="disabledFields.fileIds"
                    class="mb-2"
                    @delete="onClickDeleteReconciliationFiles(document, Number(reconciliationIndex))"
                  />
                </div>

                <b-button
                  variant="primary"
                  :type="$const.PRIMARY_BUTTON"
                  :disabled="disabledFields.fileIds"
                  size="sm"
                  @click="openAddReconciliationDocumentsModal(Number(reconciliationIndex))"
                >
                  Добавить файлы к согласованию
                </b-button>
              </b-form-group>

              <b-form-group
                v-if="reconciliation.responsible && reconciliation.responsible.$model"
                label="Ответственный"
              >
                <b-form-input
                  :value="reconciliation.responsible.$model.displayName"
                  type="text"
                  disabled
                  size="sm"
                />
              </b-form-group>
            </div>
          </div>

          <b-button
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            :disabled="disabledFields.addNewReconciliation"
            class="mt-2"
            @click="addNewReconciliation"
          >
            Добавить согласование
          </b-button>
        </div>

        <div
          v-if="actualStage === 2 && selectedPurposeServiceCoordination"
          class="my-divider"
        />

        <b-form-group
          v-if="show.reconciliationsCommentForOperator"
          label="Комментарий для оператора"
        >
          <b-form-textarea
            v-model="$v.reconciliationCommentForOperator.$model"
            placeholder="Введите текст"
            :disabled="disabledFields.reconciliationsCommentForOperator"
            :state="$v.$anyError ? !$v.reconciliationCommentForOperator.$error : null"
            rows="3"
            size="sm"
          />
        </b-form-group>

        <b-form-group
          v-if="show.reconciliationsStatus"
          label="Статус согласования"
        >
          <b-form-select
            v-model="$v.reconciliationStatus.$model"
            :options="reconciliationStatusOptions"
            :state="$v.$anyError ? !$v.reconciliationStatus.$error : null"
            :disabled="disabledFields.reconciliationsStatus"
            text-field="title"
            value-field="id"
            size="sm"
          >
            <template #first>
              <b-form-select-option
                :value="null"
              >
                Выберите статус
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </div>
    </div>
    <div
      v-else
      class="d-flex"
    >
      <div>
        <b-skeleton-img
          no-aspect
          width="154px"
          height="124px"
          class="rounded"
        />
        <b-skeleton-img
          no-aspect
          width="154px"
          height="124px"
          class="mt-2 rounded"
        />
        <b-skeleton-img
          no-aspect
          width="154px"
          height="124px"
          class="mt-2 rounded"
        />
      </div>
      <div class="w-100 d-flex justify-content-center align-items-center">
        <b-spinner variant="primary" />
      </div>
    </div>
    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClickSave"
      >
        Сохранить
        <b-spinner
          v-if="isSaving"
          variant="light"
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
// как убрать stageOne stageTwoo и тп - вывести условия появления при каждом этапе в компьютед вероятнее всего.
import { v4 as uuidv4 } from 'uuid';
import { format, formatISO } from '@evd3v/date-fns';
import { validationMixin } from 'vuelidate';
import { mixinRoles } from '@/mixins';
import { required } from 'vuelidate/lib/validators';
import { dateWithoutTime, utc0ToDateWithUserTimezone } from '@/helpers/utils';
import {
  showValidationErrorMessage,
  showSuccessMessage,
  showDefaultErrorMessage,
  showErrorCustomMessage,
} from '@/helpers/messages';

import {
  APPEAL_RECONCILIATION_STATUSES,
  APPEAL_RECONCILIATION_STATUSES_TEXTS,
  APPEAL_STATUSES,
  APPEALS_PURPOSES,
  APPEALS_PURPOSES_TEXTS,
  APPEALS_CATEGORIES,
  APPEALS_TYPES,
  APPEALS_STATUSES_TEXTS,
  APPEALS_QUALITY_CONTROL_WHERE_TO_SEND_TEXTS,
  APPEALS_TECHSUPPORT_WHERE_TO_SEND_TEXTS,
} from '@/services/appeals/appeals.const';
import {
  appealsService,
  specializationsService,
  clinicService,
  locationService,
} from '@/services';

import { FEATURES_FOR_CHAT } from '@/helpers/consts';

import { ROLES } from '@/helpers/roles';
import { BaseDatePicker, BaseFile } from '@/components/base';

import AppealStages from '@/components/domains/appeals/AppealEditorModalNew/AppealsStages';
import AppealPoliciesList from '@/components/domains/appeals/AppealEditorModalNew/AppealPoliciesList';
import AppealPatientInfo from '@/components/domains/appeals/AppealEditorModalNew/AppealPatientInfo';
import AppealCategory from '@/components/domains/appeals/AppealEditorModalNew/AppealCategory';
import AppealType from '@/components/domains/appeals/AppealEditorModalNew/AppealType';
import AppealCheckout from '@/components/domains/appeals/AppealEditorModalNew/AppealCheckout';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

export default {
  name: 'AppealEditorModalNew',
  components: {
    BaseDatePicker,
    BaseFile,
    AppealStages,
    AppealPoliciesList,
    AppealPatientInfo,
    AppealCategory,
    AppealType,
    AppealCheckout,
  },
  mixins: [validationMixin, mixinRoles],

  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Создание обращения',
    },
    personId: {
      type: [String, Number],
      default: null,
    },
    appealId: {
      type: [Number, String],
      default: null,
    },
    panelTabId: {
      type: [String, Number],
      default: null,
    },
    panelTabAppealData: {
      type: Object,
      default: () => ({}),
    },
    chatAppealId: {
      type: [String, Number],
      default: null,
    },
    creatingFromPatient: {
      type: Boolean,
      default: null,
    },
    isEditAppeal: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    isOpen: true,
    initProcess: false,
    isLoading: false,
    ishideLoading: false,
    categoriesAndTypesLoading: false,
    isSaving: false,
    actualStage: 0,
    APPEALS_TECHSUPPORT_WHERE_TO_SEND_TEXTS,
    APPEALS_QUALITY_CONTROL_WHERE_TO_SEND_TEXTS,
    APPEALS_PURPOSES_TEXTS,
    stageOne: [
      'status',
      'categoryId',
      'typeNewId',
      'categoryAnotherText',
      'typeAnotherText',
      'comment',
      'fileIds',
      'responsible',
      'responsibleOperatorId',
      'cityId',
      'clinicId',
      'rejectionReason',
      'personId',
      'policyId',
      'checkupDate',
      'checkupProfileId',
      'checkupAnotherText',
      'type',
      'subtype',
      'documents',
      'orderNumber',
      'insuranseCompanyId',
      'policyNumber',
      'isActivePolicy',
      'regionId',
      'yandexLink',
      'consultationId',
      'operatorComment',
      'policyBuyDate',
      'urgency',
      'transportation',
      'qualityControlPurposeType',
    ],
    // Этап согласования. появляется при "согласовании услуг"
    stageTwoo: [
      'status',
      'rejectionReason',
      'categoryId',
      'typeNewId',
      'categoryAnotherText',
      'typeAnotherText',
      'fileIds',
      'responsible',
      'responsibleOperatorId',
      'cityId',
      'reconciliationComment',
      'comment',
      'clinicId',
      'personId',
      'policyId',
      'checkupDate',
      'checkupProfileId',
      'checkupAnotherText',
      'type',
      'subtype',
      'documents',
      'orderNumber',
      'insuranseCompanyId',
      'policyNumber',
      'isActivePolicy',
      'regionId',
      'yandexLink',
      'consultationId',
      'operatorComment',
      'policyBuyDate',
      'urgency',
      'transportation',
      'reconciliations/diagnosis',
      'reconciliations/doctorName',
      'reconciliations',
      'reconciliations/status',
      'reconciliations/createDate',
      // 'reconciliations/initiator',
      'reconciliations/doctorComment',
      'reconciliations/notAgreedServices',
      'reconciliations/regectionReason',
      'reconciliations/agreedServices',
      'reconciliations/fileIds',
      // 'reconciliations/insuranseDiagnosis',
      // 'qualityControlPurposeType',
    ],
    stageThree: [
      'status',
      'operatorComment',
      'typeNewId',
      'categoryId',
      'fileIds',
      'responsible',
      'responsibleOperatorId',
      'clinicId',
      'personId',
      'policyId',
      'reconciliationComment',
      'comment',
      'checkupDate',
      'rejectionReason',
      'checkupProfileId',
      'checkupAnotherText',
      'urgency',
      'transportation',
      'techComment',
      'consultationId',
      // 'consultationReason',
      // 'consultationComment',
      // 'returnType',
      // 'returnComment',
      'orderNumber',
      'operatorComment',
      'yandexLink',
      'regionId',
      'techsupportPurposeType', /// ?!!!
      // 'qualityControlPurposeType',
      'gemotestAppealId',
      'cityId',
      'service',
      'insuranseCompanyId',
      'policyNumber',
      // 'patientInfo',
      'policyBuyDate',
      'isActivePolicy',
      'categoryAnotherText',
      'typeAnotherText',
      'reconciliations',
      // 'reconciliationComment',
      'reconciliations/status',
      'reconciliations/diagnosis',
      'reconciliations/doctorName',
      'reconciliations/createDate',
      // 'reconciliations/initiator',
      'reconciliations/doctorComment',
      'reconciliations/notAgreedServices',
      'reconciliations/regectionReason',
      'reconciliations/agreedServices',
      // 'reconciliations/insuranseDiagnosis',
      'reconciliations/commentForOperator',
      'reconciliations/fileIds',
      'type',
      'subtype',
      'documents',
    ],
    disabledStageOne: [],
    disabledStageTwoo: [
      'newPurpose',
      'urgency',
      'transportation',
      'categoryId',
      'categoryAnotherText',
      'typeNewId',
      'orderNumber',
      'consultationId',
      'policyId',
      'techComment',
      'techsupportPurposeType',
      'yandexLink',
      'policyNumber',
      'patientInfo',
      'policyBuyDate',
      'isActivePolicy',
      'service',
      'consultationId',
      'cityId',
      'clinicId',
      'orderNumber',
      'consultationReason',
      'consultationComment',
      'checkupDate',
      'checkupProfileId',
      'checkupAnotherText',
      'insuranseCompanyId',
      'regionId',
      'returnType',
      'returnComment',
      'fileIds',
      'operatorComment',
    ],
    disabledStageThree: [
      'newPurpose',
      'urgency',
      'transportation',
      'categoryId',
      'categoryAnotherText',
      'typeNewId',
      'orderNumber',
      'consultationId',
      'policyId',
      'techComment',
      'techsupportPurposeType',
      'yandexLink',
      'policyNumber',
      'patientInfo',
      'policyBuyDate',
      'isActivePolicy',
      'service',
      'consultationId',
      'cityId',
      'clinicId',
      'orderNumber',
      'consultationReason',
      'consultationComment',
      'checkupDate',
      'checkupProfileId',
      'checkupAnotherText',
      'insuranseCompanyId',
      // 'reconciliationComment',
      'regionId',
      'returnType',
      'returnComment',
      'fileIds',
      'operatorComment',
      'returnType',
      'status',
      'reconciliations',
      'reconciliations/status',
      'reconciliations/createDate',
      // 'reconciliations/initiator',
      'reconciliations/doctorComment',
      'reconciliations/notAgreedServices',
      'reconciliations/regectionReason',
      'reconciliations/agreedServices',
      'reconciliations/insuranseDiagnosis',
    ],
    documents: [],
    appealTemplates: [],
    initAppealData: null,
    person: null,
    form: {
      isFinalStage: false,
      newPurpose: 0,
      administratorName: null,
      categoryId: null,
      status: null,
      typeNewId: null,
      comment: null,
      fileIds: [],
      responsibleOperatorId: null,
      cityId: null,
      clinicId: null,
      policyId: null,
      checkupDate: null,
      rejectionReason: null,
      checkupProfileId: null,
      checkupAnotherText: null,
      urgency: false,
      transportation: false,
      techComment: null,
      consultationId: null,
      consultationReason: null,
      consultationComment: null,
      returnType: null,
      returnComment: null,
      orderNumber: null,
      operatorComment: null,
      reconciliationComment: null,
      yandexLink: null,
      regionId: null,
      techsupportPurposeType: null,
      qualityControlPurposeType: 0,
      gemotestAppealId: null,
      service: null,
      insuranseCompanyId: null,
      policyNumber: null,
      patientInfo: null,
      policyBuyDate: null,
      isActivePolicy: false,
      categoryAnotherText: null,
      typeAnotherText: null,
      reconciliations: [],
      date: new Date().toISOString(),
    },
    reconciliations: [
      {
        id: 1,
        showContent: true,
        isChanged: false,
        reconciliation: {
          createDate: null,
          initiator: null,
          clinicId: null,
          diagnosis: null,
          doctorName: null,
          agreedServices: null,
          notAgreedServices: null,
          regectionReason: null,
          doctorComment: null,
          responsibleId: null,
          insuranseDiagnosis: null,
          responsible: null,
          documents: [],
          fileIds: [],
        },
      },
    ],
    reconciliationCommentForOperator: null,
    reconciliationStatus: null,
    reconciliationForDeletionId: null,
    initiators: [
      {
        id: 0,
        title: 'Клиника',
      },
      {
        id: 1,
        title: 'Пациент',
      },
      {
        id: 2,
        title: 'Другое',
      },
    ],
    insuranceCompanies: [],
    cities: [],
    clinics: [],
    categories: [],
    subtypes: [],
    regions: [],
    clinic: null,
    checkupProfilesList: [],
    preparedFieldsRejectionReason: [],
  }),
  validations() {
    return {
      form: {
        newPurpose: { required },
        categoryId: this.categoryIdValidation,
        categoryAnotherText: this.categoryAnotherTextValidation,
        typeNewId: this.typeNewIdValidation,
        typeAnotherText: this.typeAnotherTextValidation,
        comment: { required },
        status: { required },
        policyId: this.policyIdValidation,
        checkupDate: this.checkupDateValidation,
        checkupProfileId: this.checkupProfileIdValidation,
        checkupAnotherText: this.checkupAnotherTextValidation,
        cityId: this.cityIdValidation,
        administratorName: this.administratorNameValidation,
        clinicId: this.clinicIdValidation,
        rejectionReason: this.rejectionReasonValidation,
        regionId: this.regionIdValidation,
        techComment: this.techCommentValidation,
        techsupportPurposeType: this.techsupportPurposeTypeValidation,
        qualityControlPurposeType: this.qualityControlPurposeTypeValidation,
        reconciliationComment: this.reconciliationCommentValidation,
        policyNumber: this.policyNumberValidation,
        patientInfo: this.patientInfoValidation,
        policyBuyDate: this.policyBuyDateValidation,
        service: this.serviceValidation,
        consultationId: this.consultationIdValidation,
        orderNumber: this.orderNumberValidation,
        consultationReason: this.consultationReasonValidation,
        consultationComment: this.consultationCommentValidation,
        urgency: this.urgencyValidation,
        transportation: this.transportationValidation,
        insuranseCompanyId: this.insuranseCompanyIdValidation,
        returnType: this.returnTypeValidation,
        returnComment: this.returnCommentValidation,
        isActivePolicy: this.isActivePolicyValidation,
        operatorComment: this.operatorCommentValidation,
      },
      reconciliations: {
        $each: {
          id: {},
          showContent: {},
          isChanged: {},
          reconciliation: {
            createDate: {},
            initiator: this.initiatorValidation,
            diagnosis: this.diagnosisValidation,
            doctorName: this.doctorNameValidation,
            agreedServices: this.agreedServicesValidation,
            notAgreedServices: this.notAgreedServicesValidation,
            regectionReason: this.regectionReasonValidation,
            doctorComment: this.doctorCommentValidation,
            insuranseDiagnosis: this.insuranseDiagnosisValidation,
            responsible: {},
            documents: {},
            fileIds: {},
          },
        },
      },
      reconciliationCommentForOperator: this.commentForOperatorValidation,
      reconciliationStatus: this.reconciliationsStatusValidation,
    };
  },
  computed: {

    actualChatAppealId() {
      return this.initAppealData?.chatAppealId || this.chatAppealId;
    },
    isEdit() {
      return this.appealId !== null;
    },
    isClosedAppeal() {
      return this.initAppealData?.status === APPEAL_STATUSES.Closed;
    },
    isReturnedAppeal() {
      return this.initAppealData?.status === APPEAL_STATUSES.Returned;
    },
    isPostponedByReturnedAppeal() {
      return this.initAppealData?.status === APPEAL_STATUSES.Postponed
        && this.selectedPurposeContractDepartament
        && this.initAppealData?.operatorComment;
    },
    isApprovalAvailableWhenCreating() {
      if (this.selectedCategory?.name === APPEALS_CATEGORIES.ServiceCoordinationSignUpForTreatment) {
        return false;
      }
      return this.actualStage === 0
        && this.actualChatAppealId
        && this.selectedPurposeServiceCoordination
        && this.checkFeatureAccess(FEATURES_FOR_CHAT.twoStagesForApprovalOfServices);
    },
    isAdministrator() {
      return this.checkRoleAccess([ROLES.ADMINISTRATOR]);
    },
    isQualitiesOfDoctors() {
      return this.checkRoleAccess([ROLES.QUALITIES_OF_DOCTORS]);
    },
    isQualitySpecialist() {
      return this.checkRoleAccess([ROLES.QUALITY_SPECIALIST]);
    },
    isMedicalCurator() {
      return this.checkRoleAccess([ROLES.MEDICAL_CURATOR]);
    },
    isOperator() {
      return this.checkRoleAccess([ROLES.OPERATOR]);
    },
    isTechOperator() {
      return this.checkRoleAccess([ROLES.TECH_OPERATOR]);
    },
    isPolisOperator() {
      return this.checkRoleAccess([ROLES.POLIS_OPERATOR]);
    },
    isPolisOperatorDop() {
      return this.checkRoleAccess([ROLES.POLIS_OPERATOR_DOP]);
    },
    isReconciliationsPresent() {
      return !!this.initAppealData?.reconciliations?.length;
    },
    isMidAdmin() {
      return this.checkRoleAccess([ROLES.CLINIC_ADMINISTRATOR_MID]);
    },
    allowedEditDoctorFullName() {
      return this.checkFeatureAccess({ name: 'Показывать ФИО врача в согласовании', url: '/patients' });
    },
    appealResponsible() {
      if (this.initAppealData && this.initAppealData.responsible?.displayName) {
        return this.initAppealData.responsible.displayName;
      }
      return this.$store.state.Auth.user.displayName;
    },
    onlyOneStage() {
      return (this.selectedPurposeInformation && !this.selectedCategoryDeviceRental)
        || this.selectedPurposeCheckout;
    },
    onlyTwooStages() {
      return this.selectedPurposeTechnicalSupport
        || this.selectedPurposeGostelemed
        || this.selectedPurposeComplaint
        || this.selectedPurposeContractDepartament
        || this.selectedPurposeQualityControlDoctors
        || this.selectedCategoryDeviceRental;
    },
    stages() {
      try {
        const stages = [
          {
            id: 1,
            description: '',
            active: false,
            permissions:
              this.isAdministrator
                || this.isMedicalCurator
                || this.isOperator
                || this.isTechOperator
                || this.isPolisOperator
                || this.isPolisOperatorDop
                || this.isQualitiesOfDoctors
                || this.isQualitySpecialist,
            load: true,
          },
          {
            id: 2,
            description: 'Согласование',
            permissions:
              this.isAdministrator
                || this.isMedicalCurator
                || this.isTechOperator
                || this.isPolisOperator
                || this.isPolisOperatorDop,
            active: false,
            load: true,
          },
          {
            id: 3,
            description: '',
            permissions:
              this.isAdministrator
              || this.isOperator
              || this.isMedicalCurator
              || this.isQualitiesOfDoctors
              || this.isQualitySpecialist,
            active: false,
            load: true,
          },
        ];

        const stageOne = stages.find((item) => item.id === 1);
        const stageTwoo = stages.find((item) => item.id === 2);
        const stageThree = stages.find((item) => item.id === 3);

        if (this.actualStage > 0) {
          stageOne.active = true;
          stageOne.load = true;
        }
        if (this.actualStage > 1) {
          if (stageOne) {
            stageOne.load = false;
          }
          stageTwoo.active = true;
          stageTwoo.load = true;
          if (this.onlyTwooStages) stageThree.active = true;
        }
        if (this.actualStage > 2) {
          if (stageOne) {
            stageOne.load = false;
          }
          if (stageTwoo) {
            stageTwoo.load = false;
          }
          stageThree.active = true;
          stageThree.load = false;
        }

        if (this.onlyOneStage) stages.splice(1, 2);

        if (this.onlyTwooStages) stages.splice(1, 1);

        if (this.selectedPurposeServiceCoordination) {
          stages[2].permissions = this.isAdministrator || this.isMedicalCurator;
        }

        if (this.isReturnedAppeal || this.isPostponedByReturnedAppeal) {
          stages.push({
            id: 4,
            description: 'Возвращено',
            permissions:
              this.isAdministrator
                || this.isMedicalCurator
                || this.isTechOperator
                || this.isPolisOperator
                || this.isPolisOperatorDop,
            active: true,
            load: true,
          });
        }

        if (
          this.isEdit
          && this.initAppealData
          && Array.isArray(this.initAppealData.reconciliations)
          && this.initAppealData.reconciliations.length
          && this.selectedPurposeServiceCoordination
        ) {
          if (
            this.initAppealData?.reconciliations[0]?.status === 3
            || this.initAppealData?.reconciliations[0]?.status === 4
          ) {
            stages[2].load = true;
          }
        }

        return stages;
      } catch (e) {
        console.error(e);
      }
      return [];
    },
    // stagesActiveStatus() {
    //   const result = {};
    //   Object.entries(this.stages).forEach(([key, value]) => {
    //     console.log(key);
    //     result[value.id] = value.active;
    //   });

    //   return result;
    // },
    appealStatusOptions() {
      const {
        InProgress, Closed, Returned, Postponed,
      } = APPEAL_STATUSES;
      let options = JSON.parse(JSON.stringify(APPEALS_STATUSES_TEXTS));
      const copyOptions = JSON.parse(JSON.stringify(APPEALS_STATUSES_TEXTS));

      if (this.selectedCategory?.name === APPEALS_CATEGORIES.ServiceCoordinationSignUpForTreatment) {
        options = copyOptions.filter((item) => item.id !== InProgress && item.id !== Returned);
        return options;
      }

      if (this.selectedCategory?.name === APPEALS_CATEGORIES.ServiceCoordinationAdjustmentExtensionGP) {
        options = copyOptions.filter((item) => item.id !== InProgress && item.id !== Returned && item.id !== Postponed);
        return options;
      }

      if (this.selectedPurposeComplaint) {
        options = copyOptions.filter((item) => item.id !== Postponed && item.id !== Returned);
        return options;
      }

      if (this.onlyOneStage) {
        options = options.filter((item) => item.id !== InProgress);
      }

      if (this.selectedPurposeContractDepartament) {
        options = options.map((item) => {
          if (item.id === InProgress) item.title = 'Передано в договорной';

          return item;
        });
        if (this.actualStage < 2) options = options.filter((item) => item.id !== Returned);
      } else {
        options = options.filter((item) => item.id !== Returned);
      }

      if (this.selectedPurposeServiceCoordination) {
        options = options.filter((item) => item.id !== Closed);
      }

      if (this.actualStage < 2) {
        if (this.selectedPurposeContractDepartament) {
          options = options.filter((item) => item.id !== Closed);
        }
      }
      if (this.actualStage === 2) {
        if (
          this.selectedPurposeContractDepartament
          || this.selectedPurposeComplaint
        ) {
          options = options.filter((item) => item.id !== InProgress);
        }
        if (this.isPolisOperatorDop) {
          options = options.filter((item) => item.id !== Closed);
        }
      }
      if (this.actualStage === 4) {
        options = options.filter((item) => item.id !== Returned);
      }
      return options;
    },
    reconciliationStatusOptions() {
      let statuses = JSON.parse(JSON.stringify(APPEAL_RECONCILIATION_STATUSES_TEXTS));

      if (this.actualStage === 3) {
        statuses = statuses.filter((item) => item.id !== APPEAL_RECONCILIATION_STATUSES.InProgress);
      }

      return statuses;
    },
    errorsValidation() {
      const errors = {};

      errors.categoryId = [];
      if (!this.$v.form.categoryId.required) {
        errors.categoryId.push('Поле "категория" обязательно для заполнения');
      }

      errors.categoryAnotherText = [];
      if (!this.$v.form.categoryAnotherText.required) {
        errors.categoryAnotherText.push('Поле "другая категория обращения" обязательно для заполнения');
      }

      errors.typeNewId = [];
      if (!this.$v.form.typeNewId.required) {
        errors.typeNewId.push('Поле "тип" обязательно для заполнения');
      }

      errors.comment = [];
      if (!this.$v.form.comment.required) {
        errors.comment.push('Поле "комментарий" обязательно для заполнения');
      }

      errors.status = [];
      if (!this.$v.form.status.required) {
        errors.status.push('Поле "статус" обязательно для заполнения');
      }

      errors.policyId = [];
      if (!this.$v.form.policyId.required) {
        errors.policyId.push('Поле "полис" обязательно для заполнения');
      }

      errors.checkupDate = [];
      if (!this.$v.form.checkupDate.required) {
        errors.checkupDate.push('Поле "дата чекапа" обязательно для заполнения');
      }

      errors.checkupProfileId = [];
      if (!this.$v.form.checkupProfileId.required) {
        errors.checkupProfileId.push('Поле "профиль для чекапа" обязательно для заполнения');
      }

      errors.checkupAnotherText = [];
      if (!this.$v.form.checkupAnotherText.required) {
        errors.checkupAnotherText.push('Поле "другой профиль для чекапа" обязательно для заполнения');
      }

      errors.cityId = [];
      if (!this.$v.form.cityId.required) {
        errors.cityId.push('Поле "город" обязательно для заполнения');
      }

      errors.clinicId = [];
      if (!this.$v.form.clinicId.required) {
        errors.clinicId.push('Поле "клиника" обязательно для заполнения');
      }

      errors.administratorName = [];
      if (!this.$v.form.administratorName.required) {
        errors.administratorName.push('Поле "ФИО Администратора" обязательно для заполнения');
      }

      errors.regionId = [];
      if (!this.$v.form.regionId.required) {
        errors.regionId.push('Поле "регион" обязательно для заполнения');
      }

      errors.orderNumber = [];
      if (!this.$v.form.orderNumber.required) {
        errors.orderNumber.push('Поле "номер заказа" обязательно для заполнения');
      }

      errors.consultationId = [];
      if (!this.$v.form.consultationId.required) {
        errors.consultationId.push('Поле "id консультации" обязательно для заполнения');
      }

      errors.insuranseCompanyId = [];
      if (!this.$v.form.insuranseCompanyId.required) {
        errors.insuranseCompanyId.push('Поле "страховая компания" консультации" обязательно для заполнения');
      }

      return errors;
    },
    errors() {
      return Object.entries(this.$v.form).reduce((acc, [key, value]) => {
        if (typeof value === 'object' && value !== null) {
          if (value.$anyError) {
            acc.push(key);
          }
        }

        return acc;
      }, []);
    },
    cityIdValidation() {
      const validation = {
        required: () => true,
      };

      if (this.selectedCategoryAdjustmentExtensionGP) {
        validation.required = false;
      }

      if (this.show.city && !this.selectedCategoryAdjustmentExtensionGP) {
        validation.required = required;
      }

      return validation;
    },
    administratorNameValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.administratorName) {
        validation.required = required;
      }

      return validation;
    },
    clinicIdValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.clinic && this.clinicsForSelectedCity?.length) {
        validation.required = required;
      }

      return validation;
    },
    rejectionReasonValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.rejectionReason && this.clinic?.clinicNetworkId !== 1) {
        validation.required = required;
      }

      return validation;
    },
    categoryIdValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.category) {
        validation.required = required;
      }

      return validation;
    },
    categoryAnotherTextValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.categoryAnotherText) {
        validation.required = required;
      }

      return validation;
    },
    typeNewIdValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.type) {
        validation.required = required;
      }

      return validation;
    },
    typeAnotherTextValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.typeAnotherText) {
        validation.required = required;
      }

      return validation;
    },
    policyIdValidation() {
      const validation = {
        required: () => true,
      };

      if (this.activatedPolicies?.length && !this.selectedCategoryNoPolicyInCRM) {
        validation.required = required;
      }

      return validation;
    },
    checkupDateValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.checkout) {
        validation.required = required;
      }

      return validation;
    },
    checkupProfileIdValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.checkout) {
        validation.required = required;
      }

      return validation;
    },
    checkupAnotherTextValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.checkupAnotherText) {
        validation.required = required;
      }

      return validation;
    },
    regionIdValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.region) {
        validation.required = required;
      }

      return validation;
    },
    techCommentValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.techComment) {
        validation.required = required;
      }

      return validation;
    },
    techsupportPurposeTypeValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.techsupportPurposeType) {
        validation.required = required;
      }

      return validation;
    },
    qualityControlPurposeTypeValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.qualityControl) {
        validation.required = required;
      }

      return validation;
    },
    reconciliationCommentValidation() {
      // if (this.show.reconciliationComment) {
      //   validation.required = required;
      // }

      return {};
    },
    policyNumberValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.policyNumber && !this.selectedCategoryClinicChoicePolicy) {
        validation.required = required;
      }

      return validation;
    },
    patientInfoValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.patientInfo) {
        validation.required = required;
      }

      return validation;
    },
    policyBuyDateValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.policyBuyDate && !this.selectedCategoryClinicChoicePolicy) {
        validation.required = required;
      }

      return validation;
    },
    serviceValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.service) {
        validation.required = required;
      }

      return validation;
    },
    consultationIdValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.consultationId) {
        validation.required = required;
      }

      return validation;
    },
    orderNumberValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.orderNumber) {
        validation.required = required;
      }

      return validation;
    },
    consultationReasonValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.consultationReason) {
        validation.required = required;
      }

      return validation;
    },
    consultationCommentValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.consultationComment) {
        validation.required = required;
      }

      return validation;
    },
    urgencyValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.urgency) {
        validation.required = required;
      }

      return validation;
    },
    transportationValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.transportation) {
        validation.required = required;
      }

      return validation;
    },
    insuranseCompanyIdValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.insuranceCompany && !this.selectedCategoryClinicChoicePolicy) {
        validation.required = required;
      }

      return validation;
    },
    returnTypeValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.returnType) {
        validation.required = required;
      }

      return validation;
    },
    returnCommentValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.returnComment) {
        validation.required = required;
      }

      return validation;
    },
    initiatorValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.reconciliationsInitiator) {
        // validation.required = required;
      }

      return validation;
    },
    isActivePolicyValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.isActivePolicy && !this.selectedCategoryClinicChoicePolicy) {
        validation.required = required;
      }

      return validation;
    },
    operatorCommentValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.operatorComment) {
        validation.required = required;
      }

      return validation;
    },
    diagnosisValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.diagnosis) {
        // validation.required = required;
      }

      return validation;
    },
    doctorNameValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.reconciliationsDoctorName) {
        // validation.required = required;
      }

      return validation;
    },
    reconciliationsStatusValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.reconciliationsStatus) {
        validation.required = required;
      }

      return validation;
    },
    agreedServicesValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.reconciliationsAgreedServices) {
        // validation.required = required;
      }

      return validation;
    },
    notAgreedServicesValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.reconciliationsNotAgreedServices) {
        // validation.required = required;
      }

      return validation;
    },
    regectionReasonValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.reconciliationsRegectionReason) {
        // validation.required = required;
      }

      return validation;
    },
    doctorCommentValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.reconciliationsDoctorComment) {
        // validation.required = required;
      }

      return validation;
    },
    commentForOperatorValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.reconciliationsCommentForOperator) {
        validation.required = required;
      }

      return validation;
    },
    insuranseDiagnosisValidation() {
      const validation = {
        required: () => true,
      };

      if (this.show.reconciliationsInsuranseDiagnosis) {
        // validation.required = required;
      }

      return validation;
    },
    purposeId() {
      return this.form.newPurpose;
    },
    policies() {
      return this.person?.policies;
    },
    activatedPolicies() {
      return this.policies?.filter((item) => item.isActivated);
    },
    inactivatedPolicies() {
      return this.policies?.filter((item) => !item.isActivated);
    },
    choosePolicy() {
      if (!this.activatedPolicies?.length && this.selectedCategoryChangePolicy && this.form.newPurpose === 6) {
        return this.inactivatedPolicies;
      }
      return this.activatedPolicies;
    },
    conditionForChoosePolicy() {
      if (!this.selectedCategoryNoPolicyInCRM && this.activatedPolicies?.length) {
        return true;
      }
      if (this.selectedCategoryChangePolicy && this.form.newPurpose === 6) {
        return true;
      }
      return false;
    },
    selectedPurpose() {
      return APPEALS_PURPOSES_TEXTS.find((item) => item.id === this.purposeId);
    },
    selectedPurposeInformation() {
      // Информационное
      return this.selectedPurpose?.id === APPEALS_PURPOSES.Information;
    },
    selectedPurposeCheckout() {
      // Чекап
      return this.selectedPurpose?.id === APPEALS_PURPOSES.Checkup;
    },
    selectedPurposeServiceCoordination() {
      // Согласование услуг
      return this.selectedPurpose?.id === APPEALS_PURPOSES.ServiceCoordination;
    },
    selectedPurposeTechnicalSupport() {
      // Тех. поддержка
      return this.selectedPurpose?.id === APPEALS_PURPOSES.TechnicalSupport;
    },
    selectedPurposeGostelemed() {
      // Гостелемед
      return this.selectedPurpose?.id === APPEALS_PURPOSES.Gostelemed;
    },
    selectedPurposeQualityControlDoctors() {
      // Контроль качества врачей
      return this.selectedPurpose?.id === APPEALS_PURPOSES.QualityControlDoctors;
    },
    selectedPurposeContractDepartament() {
      // Договорной отдел
      return this.selectedPurpose?.id === APPEALS_PURPOSES.ContractDepartament;
    },
    selectedPurposeComplaint() {
      // Жалобы
      return this.selectedPurpose?.id === APPEALS_PURPOSES.Complaint;
    },
    selectClinic() {
      if (this.form.clinicId === null) return;
      return this.clinics?.find((item) => item.id === this.form.clinicId);
    },
    isSelectedClinicMotherAndChild() {
      return this.selectClinic?.title.toLowerCase().includes('Мать и Дитя'.toLowerCase());
    },
    selectedCategory() {
      return this.categories.find((item) => item.id === this.form.categoryId);
    },
    selectedCategoryAnalyzes() {
      return this.selectedCategory?.name === APPEALS_CATEGORIES.InformationAnalyzes;
    },
    selectedCategoryDeviceRental() {
      return this.selectedCategory?.name === APPEALS_CATEGORIES.InformationRentDevice;
    },
    selectedCategoryRefundOfFunds() {
      return this.selectedCategory?.name === APPEALS_CATEGORIES.QualityControlDoctorsRefund;
    },
    selectedCategoryNoClient() {
      return this.selectedCategory?.name === APPEALS_CATEGORIES.ContractDepartamentNoCustomerInCRM;
    },
    selectedCategoryGostelemed() {
      return this.selectedCategory?.name === 'Гостелемед'; // нет такого
    },
    selectedCategoryFMBA() {
      return this.selectedCategory?.name === 'ФМБА'; // нет такого
    },
    selectedCategoryСancellationСonsultation() {
      return this.selectedCategory?.name === APPEALS_CATEGORIES.QualityControlDoctorsCancelRescheduleConsultation;
    },
    selectedCategorySearchClinic() {
      return this.selectedCategory?.name === APPEALS_CATEGORIES.ContractDepartamentSearchClinicServices;
    },
    selectedCategoryChangePolicy() {
      return this.selectedCategory?.name === APPEALS_CATEGORIES.ContractDepartamentUpdatePolicyDataInCRM;
    },
    selectedCategorySearchClinicInCity() {
      return this.selectedCategory?.name === APPEALS_CATEGORIES.ContractDepartamentFindClinicInCity;
    },
    selectedCategoryChangeClinic() {
      return this.selectedCategory?.name === APPEALS_CATEGORIES.ContractDepartamentUpdateClinicData;
    },
    selectedCategoryClinicChoicePolicy() {
      return this.selectedCategory?.name === APPEALS_CATEGORIES.ContractDepartamentPolicyNotVisibleInClinic;
    },
    selectedCategoryProtocolLPU() {
      return this.selectedCategory?.name === APPEALS_CATEGORIES.ContractDepartamentPatientProtocolRequestLPU;
    },
    selectedCategoryInsuranceCompanyRequest() {
      return this.selectedCategory?.name === APPEALS_CATEGORIES.ContractDepartamentInsuranceCompanyRequest;
    },
    selectedCategoryNoPolicyInCRM() {
      return this.selectedCategory?.name === APPEALS_CATEGORIES.ContractDepartamentNoPolicyInCRM;
    },
    selectedCategoryAdjustmentExtensionGP() {
      // категория обращения - Корректировка/Продление ГП
      return this.selectedCategory?.name === APPEALS_CATEGORIES.ServiceCoordinationAdjustmentExtensionGP;
    },
    selectedType() {
      return this.selectedCategory?.types?.find((item) => item.id === this.form.typeNewId);
    },
    selectedTypeRefundByTelemed() {
      return this.selectedType?.name === APPEALS_TYPES.TelemedicineConsultation;
    },
    selectedTypeRefundByAnalysis() {
      return this.selectedType?.name === APPEALS_TYPES.Analysis;
    },
    selectedPolicy() {
      return this.policies?.find((item) => item.id === this.form.policyId);
    },
    selectedCheckupProfile() {
      if (this.form.checkupProfileId === null) return;

      return this.checkupProfilesList?.find((item) => {
        const id = this.form.checkupProfileId;
        return Number(item.id) === Number(id);
      });
    },
    selectedStatusTransmitted() {
      return this.form.status === APPEAL_STATUSES.InProgress;
    },
    clinicsForSelectedCity() {
      if (!this.form.cityId) return [];

      return this.clinics?.filter((item) => item.cityId === this.form.cityId);
    },
    showTypeAnotherText() {
      return this.showByStage(['typeAnotherText'])
        && this.selectedType?.name === APPEALS_TYPES.Other;
    },
    showCategory() {
      return this.showByStage(['categoryId'])
        && !this.selectedPurposeCheckout;
    },
    showCategoryAnotherText() {
      return this.selectedCategory?.name === APPEALS_CATEGORIES.Other;
    },
    showType() {
      return this.showByStage(['type', 'subtype'])
        && !this.selectedPurposeCheckout
        && this.selectedCategory?.types?.length;
    },
    showSubtype() {
      return this.form.typeNewId
        && this.selectedType?.subtypes?.length;
    },
    showComment() {
      return this.showByStage(['comment']);
    },
    showStatus() {
      return this.showByStage(['status']);
    },
    showResponsible() {
      return this.showByStage(['responsible']);
    },
    showOrderNumber() {
      return this.showByStage(['orderNumber'])
        && (
          this.selectedCategoryAnalyzes
          || this.selectedTypeRefundByAnalysis
        );
    },
    showQualityControl() {
      return this.showByStage(['qualityControlPurposeType'])
        && this.selectedPurposeQualityControlDoctors
        && (this.selectedStatusTransmitted || (this.actualStage > 0 && this.initAppealData?.qualityControlPurposeType !== null));
    },
    showCity() {
      return this.showByStage(['cityId'])
        && (
          this.selectedPurposeServiceCoordination
          || this.selectedPurposeCheckout
          || this.selectedCategorySearchClinicInCity // тут без клиники
          || this.selectedCategoryChangeClinic
          || this.selectedCategoryClinicChoicePolicy
          || this.selectedCategoryProtocolLPU
          || this.selectedCategorySearchClinic
        );
    },
    showClinic() {
      return this.showByStage(['clinicId'])
        && this.form.cityId
        && (
          this.selectedPurposeServiceCoordination
          || this.selectedPurposeCheckout
          || this.selectedCategoryChangeClinic
          || this.selectedCategoryClinicChoicePolicy
          || this.selectedCategoryProtocolLPU
        );
    },
    showAdministratorName() {
      return this.selectedPurposeContractDepartament && this.selectedCategoryClinicChoicePolicy;
    },
    showCheckout() {
      return this.showByStage(['checkupDate', 'checkupProfileId'])
        && this.selectedPurposeCheckout;
    },
    showCheckupAnotherText() {
      return this.showByStage(['checkupAnotherText'])
        && this.selectedPurposeCheckout
        && this.selectedCheckupProfile?.title === 'Другое';
    },
    showFiles() {
      return this.showByStage(['fileIds']);
      // && (
      //   (this.selectedPurposeInformation
      //     && this.selectedCategoryAnalyzes)
      //   || this.selectedPurposeServiceCoordination
      // );
    },
    showInsuranceCompany() {
      return this.showByStage(['insuranseCompanyId'])
        && (
          this.selectedCategoryNoClient
          || this.selectedCategoryNoPolicyInCRM
          || this.selectedCategoryInsuranceCompanyRequest
          || this.selectedCategoryClinicChoicePolicy
        );
    },
    showPolicyNumber() {
      return this.showByStage(['policyNumber'])
        && (
          this.selectedCategoryNoClient
          || this.selectedCategoryNoPolicyInCRM
          || this.selectedCategoryInsuranceCompanyRequest
          || this.selectedCategoryClinicChoicePolicy
        );
    },
    showIsActivePolicy() {
      return this.showByStage(['isActivePolicy'])
        && (
          this.selectedCategoryNoClient
          || this.selectedCategoryNoPolicyInCRM
          || this.selectedCategoryInsuranceCompanyRequest
          || this.selectedCategoryClinicChoicePolicy
        );
    },
    showPolicyBuyDate() {
      return this.showByStage(['policyBuyDate'])
        && (
          this.selectedCategoryNoClient
          || this.selectedCategoryNoPolicyInCRM
          || this.selectedCategoryInsuranceCompanyRequest
          || this.selectedCategoryClinicChoicePolicy
        );
    },
    showPatientInfo() {
      return this.showByStage(['patientInfo'])
        && !this.selectedPurposeInformation;
    },
    showRegion() {
      return this.showByStage(['regionId'])
        && (this.selectedCategoryGostelemed || this.selectedCategoryFMBA || this.selectedPurposeGostelemed);
    },
    showYandexLink() {
      return this.showByStage(['yandexLink'])
        && this.selectedCategoryGostelemed;
    },
    showTechComment() {
      return this.showByStage(['techComment'])
        && this.selectedCategoryGostelemed;
    },
    showTechsupportPurposeType() {
      return this.showByStage(['techsupportPurposeType'])
        && this.selectedCategoryGostelemed;
    },
    showReconciliationComment() {
      if (this.initAppealData?.reconciliationComment) return true;
      if (
        this.selectedPurposeContractDepartament
        && this.selectedCategorySearchClinic
      ) {
        return this.actualStage === 2;
      }
      return this.showByStage(['reconciliationComment'])
        && (this.selectedCategoryDeviceRental
        || this.selectedPurposeQualityControlDoctors
        || this.selectedPurposeContractDepartament
        || this.selectedPurposeComplaint);
    },
    showOperatorComment() {
      if (this.initAppealData?.operatorComment) return true;
      if (
        this.selectedPurposeContractDepartament
        && this.selectedCategorySearchClinic
      ) {
        return this.actualStage === 4;
      }
      return this.showByStage(['operatorComment'])
        && (this.selectedCategorySearchClinic
        || (this.selectedPurposeContractDepartament
          && (
            this.isReturnedAppeal
            || this.isPostponedByReturnedAppeal
          )));
    },
    showConsultationId() {
      return this.showByStage(['consultationId'])
        && (
          this.selectedCategoryСancellationСonsultation
          || this.selectedTypeRefundByTelemed
        );
    },
    showUrgency() {
      if (this.onlyOneStage) return;

      return this.showByStage(['urgency']);
    },
    showTransportation() {
      return this.showByStage(['transportation'])
        && this.selectedPurposeServiceCoordination;
    },
    showRejectionReason() {
      return this.showByStage(['rejectionReason'])
        && this.selectedPurposeCheckout
        && this.selectClinic
        && !this.isSelectedClinicMotherAndChild;
    },
    showReturnType() {
      return this.showByStage(['returnType'])
        && !this.selectedPurposeInformation;
    },
    showReturnComment() {
      return this.showByStage(['returnComment']);
    },
    showRemoveReconciliation() {
      return this.reconciliations.length
        && this.reconciliations.length > 1;
    },
    showConsultationComment() {
      return this.showByStage(['consultationComment'])
        && !this.selectedPurposeInformation;
    },
    showConsultationReason() {
      return this.showByStage(['consultationReason']);
    },
    showReconciliationsDiagnosis() {
      return (this.showByStage(['reconciliations/diagnosis'])
        || this.isApprovalAvailableWhenCreating
        || this.isReconciliationsPresent)
        && this.selectedPurposeServiceCoordination;
    },
    showReconciliationsDoctorName() {
      return (this.showByStage(['reconciliations/doctorName'])
        || this.isApprovalAvailableWhenCreating
        || this.isReconciliationsPresent)
        && this.selectedPurposeServiceCoordination
        && this.allowedEditDoctorFullName;
    },
    showReconciliationsStatus() {
      return (this.showByStage(['reconciliations', 'reconciliations/status'])
        || this.isApprovalAvailableWhenCreating
        || this.isReconciliationsPresent)
        && this.selectedPurposeServiceCoordination
        && !this.selectedCategoryAdjustmentExtensionGP;
    },
    showReconciliationsCreateDate() {
      return (this.showByStage(['reconciliations/createDate'])
        || this.isApprovalAvailableWhenCreating
        || this.isReconciliationsPresent)
        && this.selectedPurposeServiceCoordination;
    },
    showReconciliationsDoctorComment() {
      return this.showByStage(['reconciliations/doctorComment'])
        && this.selectedPurposeServiceCoordination;
    },
    showReconciliationsNotAgreedServices() {
      return this.showByStage(['reconciliations/notAgreedServices'])
        && this.selectedPurposeServiceCoordination;
    },
    showReconciliationsRegectionReason() {
      return this.showByStage(['reconciliations/regectionReason'])
        && this.selectedPurposeServiceCoordination;
    },
    showReconciliationsAgreedServices() {
      if (this.isApprovalAvailableWhenCreating || this.isReconciliationsPresent) return true;
      return this.showByStage(['reconciliations/agreedServices'])
        && this.selectedPurposeServiceCoordination;
    },
    showReconciliationsCommentForOperator() {
      return this.showByStage(['reconciliations/commentForOperator'])
        && this.selectedPurposeServiceCoordination && !this.selectedCategoryAdjustmentExtensionGP;
    },
    showReconciliationsFiles() {
      return this.showByStage(['reconciliations/fileIds'])
        && this.selectedPurposeServiceCoordination;
    },
    showReconciliationsInitiator() {
      return this.showByStage(['reconciliations/initiator'])
        && this.selectedPurposeServiceCoordination;
    },
    showCommentAndStatus() {
      return this.showByStage(['status', 'category']);
    },
    showReconciliationsInsuranseDiagnosis() {
      return this.showByStage(['reconciliations/insuranseDiagnosis'])
        && this.selectedPurposeServiceCoordination;
    },
    show() {
      return {
        city: this.showCity,
        clinic: this.showClinic,
        category: this.showCategory,
        categoryAnotherText: this.showCategoryAnotherText,
        type: this.showType,
        typeAnotherText: this.showTypeAnotherText,
        subtype: this.showSubtype,
        comment: this.showComment,
        administratorName: this.showAdministratorName,
        status: this.showStatus,
        responsible: this.showResponsible,
        qualityControl: this.showQualityControl,
        checkout: this.showCheckout,
        checkupAnotherText: this.showCheckupAnotherText,
        files: this.showFiles,
        insuranceCompany: this.showInsuranceCompany,
        policyNumber: this.showPolicyNumber,
        isActivePolicy: this.showIsActivePolicy,
        policyBuyDate: this.showPolicyBuyDate,
        patientInfo: this.showPatientInfo,
        orderNumber: this.showOrderNumber,
        region: this.showRegion,
        yandexLink: this.showYandexLink,
        operatorComment: this.showOperatorComment,
        techComment: this.showTechComment,
        techsupportPurposeType: this.showTechsupportPurposeType,
        reconciliationComment: this.showReconciliationComment,
        consultationId: this.showConsultationId,
        urgency: this.showUrgency,
        transportation: this.showTransportation,
        rejectionReason: this.showRejectionReason,
        returnType: this.showReturnType,
        returnComment: this.showReturnComment,
        removeReconciliation: this.showRemoveReconciliation,
        consultationReason: this.showConsultationReason,
        consultationComment: this.showConsultationComment,
        reconciliationsStatus: this.showReconciliationsStatus,
        reconciliationsDiagnosis: this.showReconciliationsDiagnosis,
        reconciliationsDoctorName: this.showReconciliationsDoctorName,
        reconciliationsCreateDate: this.showReconciliationsCreateDate,
        reconciliationsInitiator: this.showReconciliationsInitiator,
        reconciliationsDoctorComment: this.showReconciliationsDoctorComment,
        reconciliationsNotAgreedServices: this.showReconciliationsNotAgreedServices,
        reconciliationsRegectionReason: this.showReconciliationsRegectionReason,
        reconciliationsAgreedServices: this.showReconciliationsAgreedServices,
        reconciliationsCommentForOperator: this.showReconciliationsCommentForOperator,
        reconciliationsInsuranseDiagnosis: this.showReconciliationsInsuranseDiagnosis,
        reconciliationsFiles: this.showReconciliationsFiles,
      };
    },
    rolePermission() {
      if (this.actualStage === 0) {
        return this.isAdministrator || this.isOperator || this.isMedicalCurator;
      }

      if (this.stages.length === 2 && this.actualStage > 0) {
        return this.isAdministrator || this.isOperator || this.isMedicalCurator || this.isQualitiesOfDoctors;
      }

      if (this.actualStage === 3 && this.onlyOneStage) {
        return this.isAdministrator || this.isMedicalCurator;
      }

      return this.stages.find((item) => item.id === this.actualStage)?.permissions;
    },
    addNewReconciliationDisabled() {
      return !(this.isMedicalCurator || this.isAdministrator);
    },
    removeReconciliationDisabled() {
      return !(this.isMedicalCurator || this.isAdministrator);
    },
    applyTemplateDisabled() {
      return !(this.isMedicalCurator || this.isAdministrator);
    },
    purposeDisabled() {
      if (this.isClosedAppeal) return true;
      if (this.isEditAppeal) return true;
      return this.disabledByStage(['newPurpose'])
        || this.categoriesAndTypesLoading;
    },
    categoryDisabled() {
      if (this.isClosedAppeal) return true;
      return this.disabledByStage(['categoryId'])
        || this.categoriesAndTypesLoading;
    },
    categoryAnotherTextDisabled() {
      if (this.isClosedAppeal) return true;
      return this.disabledByStage(['categoryAnotherText'])
        || this.categoriesAndTypesLoading;
    },
    typeNewIdDisabled() {
      if (this.isClosedAppeal) return true;
      return this.disabledByStage(['typeNewId'])
        || this.categoriesAndTypesLoading;
    },
    typeAnotherTextDisabled() {
      if (this.isClosedAppeal) return true;
      return this.disabledByStage(['typeAnotherText'])
        || this.categoriesAndTypesLoading;
    },
    policyIdDisabled() {
      if (this.isClosedAppeal) return true;
      if (this.rolePermission) return false;
      return this.disabledByStage(['policyId']);
    },
    urgencyDisabled() {
      if (this.isClosedAppeal) return true;
      // if (this.rolePermission) return false;
      return this.disabledByStage(['urgency']);
    },
    transportationDisabled() {
      if (this.isClosedAppeal) return true;
      // if (this.rolePermission) return false;
      return this.disabledByStage(['transportation']);
    },
    techCommentDisabled() {
      if (this.isClosedAppeal) return true;
      if (this.rolePermission) return false;
      return this.disabledByStage(['techComment']);
    },
    qualityControlPurposeType() {
      if (this.isClosedAppeal) return true;
      if (this.rolePermission) return false;
      return this.disabledByStage(['qualityControlPurposeType']);
    },
    techsupportPurposeTypeDisabled() {
      if (this.isClosedAppeal) return true;
      if (this.rolePermission) return false;
      return this.disabledByStage(['techsupportPurposeType']);
    },
    reconciliationCommentDisabled() {
      if (this.isClosedAppeal) return true;
      if (
        this.selectedPurposeContractDepartament
        && this.selectedCategorySearchClinic
      ) {
        return !(this.isAdministrator || this.isPolisOperator || this.isPolisOperatorDop);
      }
      return this.disabledByStage(['reconciliationComment']);
    },
    yandexLinkDisabled() {
      if (this.isClosedAppeal) return true;
      if (this.rolePermission) return false;
      return this.disabledByStage(['yandexLink']);
    },
    policyNumberDisabled() {
      if (this.isClosedAppeal) return true;
      if (this.isAdministrator) return false;
      return this.disabledByStage(['policyNumber']);
    },
    patientInfoDisabled() {
      if (this.isClosedAppeal) return true;
      if (this.rolePermission) return false;
      return this.disabledByStage(['patientInfo']);
    },
    policyBuyDateDisabled() {
      if (this.isClosedAppeal) return true;
      if (this.isAdministrator) return false;
      return this.disabledByStage(['policyBuyDate']);
    },
    isActivePolicyDisabled() {
      if (this.isClosedAppeal) return true;
      if (this.isAdministrator) return false;
      return this.disabledByStage(['isActivePolicy']);
    },
    serviceDisabled() {
      if (this.isClosedAppeal) return true;
      if (this.rolePermission) return false;
      return this.disabledByStage(['service']);
    },
    consultationIdDisabled() {
      if (this.isClosedAppeal) return true;
      if (this.rolePermission) return false;
      return this.disabledByStage(['consultationId']);
    },
    cityIdDisabled() {
      if (this.isClosedAppeal) return true;
      if (this.rolePermission) return false;
      return this.disabledByStage(['cityId']);
    },
    clinicIdDisabled() {
      if (this.isClosedAppeal) return true;
      if (this.rolePermission) return false;
      return this.disabledByStage(['clinicId']);
    },
    administratorNameDisabled() {
      return this.isClosedAppeal;
    },
    orderNumberDisabled() {
      if (this.isClosedAppeal) return true;
      if (this.rolePermission) return false;
      return this.disabledByStage(['orderNumber']);
    },
    consultationReasonDisabled() {
      if (this.isClosedAppeal) return true;
      if (this.rolePermission) return false;
      return this.disabledByStage(['consultationReason']);
    },
    consultationCommentDisabled() {
      if (this.isClosedAppeal) return true;
      if (this.rolePermission) return false;
      return this.disabledByStage(['consultationComment']);
    },
    checkupDateDisabled() {
      if (this.isClosedAppeal) return true;
      if (this.rolePermission) return false;
      return this.disabledByStage(['checkupDate']);
    },
    checkupProfileIdDisabled() {
      if (this.isClosedAppeal) return true;
      if (this.rolePermission) return false;
      return this.disabledByStage(['checkupProfileId']);
    },
    checkupAnotherTextDisabled() {
      if (this.isClosedAppeal) return true;
      if (this.rolePermission) return false;
      return this.disabledByStage(['checkupAnotherText']);
    },
    insuranseCompanyIdDisabled() {
      if (this.isClosedAppeal) return true;
      if (this.isAdministrator) return false;
      return this.disabledByStage(['insuranseCompanyId']);
    },
    fileIdsDisabled() {
      if (this.isClosedAppeal) return true;
      return false;
    },
    operatorCommentDisabled() {
      if (this.isClosedAppeal) return true;
      if (this.selectedPurposeContractDepartament) return !(this.isOperator || this.isAdministrator || this.isQualitySpecialist);
      if (this.rolePermission) return false;
      return this.disabledByStage(['operatorComment']);
    },
    commentDisabled() {
      if (this.selectedPurposeContractDepartament) {
        if (this.selectedCategorySearchClinic) {
          return !(this.isAdministrator || this.isOperator || this.isQualitySpecialist);
        }

        if (this.isClosedAppeal) return true;
        if (this.actualStage > 1) return !(this.isAdministrator || this.isQualitySpecialist); // если второй этап
        if (this.initAppealData?.operatorComment) return !this.isAdministrator; // если было в "возвращено"
        return !(this.isOperator || this.isAdministrator || this.isQualitySpecialist);
      }

      if (this.rolePermission) return false;
      return this.disabledByStage(['comment']);
    },
    statusDisabled() {
      if (this.isQualitySpecialist && this.selectedPurposeComplaint) return false;
      if (this.isClosedAppeal) return true;
      if (this.selectedPurposeServiceCoordination && this.actualStage > 1) return true;
      if (this.rolePermission || this.selectedPurposeContractDepartament) return false;
      return this.disabledByStage(['status']);
    },
    reconciliationsInitiatorDisabled() {
      if (this.rolePermission) return false;
      return this.disabledByStage(['reconciliations/initiator'])
        || !this.isMedicalCurator;
    },
    reconciliationsDiagnosisDisabled() {
      if (this.rolePermission) return false;
      return !this.isMedicalCurator;
    },
    reconciliationsDoctorNameDisabled() {
      if (this.rolePermission) return false;
      return !this.isMedicalCurator;
    },
    reconciliationsAgreedServicesDisabled() {
      if (this.rolePermission) return false;
      return !this.isMedicalCurator;
    },
    reconciliationsNotAgreedServicesDisabled() {
      if (this.rolePermission) return false;
      return !this.isMedicalCurator;
    },
    reconciliationsRegectionReasonDisabled() {
      if (this.rolePermission) return false;
      return !this.isMedicalCurator;
    },
    reconciliationsDoctorCommentDisabled() {
      if (this.rolePermission) return false;
      return !this.isMedicalCurator;
    },
    reconciliationsCommentForOperatorDisabled() {
      if (this.isMedicalCurator || this.isAdministrator) return false;
      if (this.isClosedAppeal) return true;
      if (this.rolePermission) return false;
      return false;
    },
    reconciliationsInsuranseDiagnosisDisabled() {
      if (this.rolePermission) return false;
      return !this.isMedicalCurator;
    },
    reconciliationsCommentDisabled() {
      if (this.selectedPurposeContractDepartament) {
        if (this.isClosedAppeal) return true;

        return !(this.isPolisOperator || this.isPolisOperatorDop || this.isAdministrator);
      }
      if (this.selectedPurposeComplaint) {
        if (this.isClosedAppeal) return true;

        return !(this.rolePermission || this.isQualitySpecialist);
      }
      if (this.selectedPurposeQualityControlDoctors) {
        return !(this.isAdministrator || this.isQualitiesOfDoctors);
      }
      if (this.isReturnedAppeal || this.isPostponedByReturnedAppeal) return true;
      if (this.rolePermission) return false;
      return !this.isMedicalCurator;
    },
    reconciliationsStatusDisabled() {
      // if (this.isClosedAppeal) return true;
      if (this.rolePermission) return false;
      if (this.actualStage === 3) {
        return false;
      }
      return !this.isMedicalCurator;
    },
    rejectionReasonDisabled() {
      return this.isClosedAppeal;
    },
    reconciliationsFilesDisabled() {
      if (this.isClosedAppeal) return true;
      if (this.rolePermission) return false;
      if (this.actualStage === 3) {
        return false;
      }
      return !this.isMedicalCurator;
    },
    disabledFields() {
      return {
        purpose: this.purposeDisabled,
        urgency: this.urgencyDisabled,
        transportation: this.transportationDisabled,
        category: this.categoryDisabled,
        categoryAnotherText: this.categoryAnotherTextDisabled,
        typeNewId: this.typeNewIdDisabled,
        typeAnotherText: this.typeAnotherTextDisabled,
        policyId: this.policyIdDisabled,
        techComment: this.techCommentDisabled,
        qualityControlPurposeType: this.qualityControlPurposeType,
        techsupportPurposeType: this.techsupportPurposeTypeDisabled,
        reconciliationComment: this.reconciliationCommentDisabled,
        yandexLink: this.yandexLinkDisabled,
        policyNumber: this.policyNumberDisabled,
        patientInfo: this.patientInfoDisabled,
        policyBuyDate: this.policyBuyDateDisabled,
        isActivePolicy: this.isActivePolicyDisabled,
        service: this.serviceDisabled,
        consultationId: this.consultationIdDisabled,
        cityId: this.cityIdDisabled,
        clinicId: this.clinicIdDisabled,
        administratorName: this.administratorNameDisabled,
        orderNumber: this.orderNumberDisabled,
        consultationReason: this.consultationReasonDisabled,
        consultationComment: this.consultationCommentDisabled,
        checkupDate: this.checkupDateDisabled,
        checkupProfileId: this.checkupProfileIdDisabled,
        checkupAnotherText: this.checkupAnotherTextDisabled,
        insuranseCompanyId: this.insuranseCompanyIdDisabled,
        regionId: this.regionIdDisabled,
        returnType: this.returnTypeDisabled,
        returnComment: this.returnCommentDisabled,
        fileIds: this.fileIdsDisabled,
        operatorComment: this.operatorCommentDisabled,
        comment: this.commentDisabled,
        status: this.statusDisabled,
        reconciliationsInitiator: this.reconciliationsInitiatorDisabled,
        addNewReconciliation: this.addNewReconciliationDisabled,
        removeReconciliation: this.removeReconciliationDisabled,
        applyTemplate: this.applyTemplateDisabled,
        reconciliationsDiagnosis: this.reconciliationsDiagnosisDisabled,
        reconciliationsDoctorName: this.reconciliationsDoctorNameDisabled,
        reconciliationsAgreedServices: this.reconciliationsAgreedServicesDisabled,
        reconciliationsNotAgreedServices: this.reconciliationsNotAgreedServicesDisabled,
        reconciliationsRegectionReason: this.reconciliationsRegectionReasonDisabled,
        reconciliationsDoctorComment: this.reconciliationsDoctorCommentDisabled,
        reconciliationsInsuranseDiagnosis: this.reconciliationsInsuranseDiagnosisDisabled,
        reconciliationsComment: this.reconciliationsCommentDisabled,
        reconciliationsCommentForOperator: this.reconciliationsCommentForOperatorDisabled,
        reconciliationsStatus: this.reconciliationsStatusDisabled,
        reconciliationsFiles: this.reconciliationsFilesDisabled,
        rejectionReason: this.rejectionReasonDisabled,
      };
    },
    showCoordinationServices() {
      if (this.isEditAppeal && this.form.newPurpose === 2 && (this.form.status === 0 || this.form.status === 2) && this.actualStage >= 2) {
        return true;
      }
      if (this.creatingFromPatient) {
        return false;
      }
      if (this.form.newPurpose !== 2 || !this.actualChatAppealId) {
        return false;
      }
      if (this.selectedCategory?.name === APPEALS_CATEGORIES.ServiceCoordinationSignUpForTreatment) {
        return false;
      }
      if (this.selectedCategory?.name === APPEALS_CATEGORIES.ServiceCoordinationAdjustmentExtensionGP) {
        return false;
      }
      return true;
    },
  },
  watch: {
    async purposeId(value) {
      await this.fetchCategoriesAndTypes();
      if (!this.isEditAppeal) this.onChangePurpose(value);
      // this.clearFieldsOnChangePurpose();
    },
    'form.categoryId': {
      handler() {
        this.clearFieldsOnChangeCategory();
      },
    },
    'form.clinicId': {
      async handler(id) {
        if (id) {
          this.clinic = await this.fetchClinicById(id);
        }
      },
    },
    selectedCategory(value) {
      if (value?.name === APPEALS_CATEGORIES.ServiceCoordinationSignUpForTreatment) {
        this.stages.splice(1, 2);
        this.show.reconciliationsStatus = false;
      }
      if (value?.name === APPEALS_CATEGORIES.ServiceCoordinationAdjustmentExtensionGP) {
        this.stages.splice(1, 2);
        this.show.city = false;
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.isMidAdmin) this.form.newPurpose = 2;

    try {
      await Promise.allSettled([
        this.fetchPerson(),
        this.fetchCategoriesAndTypes(),
        this.fetchCities(),
        this.fetchClinics(),
        // this.fetchRegions(),
        this.fetchCheckupProfiles(),
        this.fetchInsuranceCompanies(),
      ]);

      if (this.appealId || this.panelTabId) {
        this.initProcess = true;

        if (this.appealId) {
          await this.initAppeal(this.appealId);
        }

        if (this.panelTabId) {
          this.initAppealByTab();
        }

        this.initStage();
        this.changesAfterInitializtion();
        this.initAppeal = false;
      }

      if (!this.isEdit) {
        if (this.activatedPolicies?.length === 1) {
          this.$v.form.policyId.$model = this.activatedPolicies[0].id;
        }
        if (this.inactivatedPolicies?.length === 1) {
          this.$v.form.policyId.$model = this.inactivatedPolicies[0].id;
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }

    if (!this.form.policyId) {
      if (this.activatedPolicies.length === 1) {
        const { id } = this.activatedPolicies[0];
        this.selectPolicy(id);
      } else if (this.inactivatedPolicies.length === 1) {
        const { id } = this.inactivatedPolicies[0];
        this.selectPolicy(id);
      }
    }
  },
  methods: {
    onClickHide() {
      if (this.ishideLoading) return;
      this.ishideLoading = true;

      try {
        this.$store.commit(this.$types.TAB_PANEL_ITEMS_FETCH_FROM_LOCALSTORAGE);
        const mutationName = 'TAB_PANEL_ITEM_ADD';

        const tabData = {
          id: this.panelTabId || uuidv4(),
          title: this.appealId ? `Обращение ${this.appealId}` : 'Новое обращение',
          type: 2, // appeal type
          data: {
            formData: this.form,
            reconciliations: this.reconciliations,
            reconciliationCommentForOperator: this.reconciliationCommentForOperator,
            reconciliationStatus: this.reconciliationStatus,
            personId: this.personId,
            appealId: this.appealId,
            documents: this.documents,
            chatAppealId: this.actualChatAppealId,
          },
        };
        this.$store.commit(this.$types[mutationName], tabData);
      } catch (e) {
        console.error(e);
        this.ishideLoading = false;
      } finally {
        // this.ishideLoading = false; // да, после закрытия, лучше уж так) там дубляж можно создать если несколько раз нажимать
        this.onClose();
      }
    },
    onClose() {
      this.$emit('input', false);
    },
    openAppealTemplateModalForReject() {
      uiService.showModal(MODALS.APPEAL_TEMPLATE_MODAL, {
        name: 'AppealTemplatesModal',
        props: {
          title: 'Выберите шаблон отказа от МиД',
          type: 'RejectionReason',
        },
        actions: {
          onSelectAppealTemplateForReject: (data) => {
            this.form.rejectionReason = data.rejectionReasonComment;
          },
        },
      });
    },
    onSelectAppealRejectTemplate(newFields) {
      this.form.rejectionReason = newFields.rejectionReasonComment;
    },
    onChangePurpose(value) {
      if (this.isEdit) return;
      if (this.selectedPurposeServiceCoordination) return;
      this.$v.form.categoryId.$model = null;
      this.$v.form.typeNewId.$model = null;

      if (!this.isEdit && value === APPEALS_PURPOSES.Complaint) {
        this.$v.form.categoryId.$model = this.takeCategoryByName(APPEALS_CATEGORIES.ComplaintDMS)?.id;
      }
    },
    takeCategoryByName(categoryName) {
      return this.categories.find((item) => item.name === categoryName);
    },
    getFormatDate(date, formatter) {
      return date && formatter ? format(new Date(date), formatter) : null;
    },
    onSelectAppealTemplate(newFields, reconciliation) {
      reconciliation.agreedServices.$model = newFields.reconciliationsAgreedServices;
      reconciliation.notAgreedServices.$model = newFields.reconciliationsNotAgreedServices;
      reconciliation.regectionReason.$model = newFields.reconciliationsRegectionReason;
      reconciliation.diagnosis.$model = newFields.reconciliationsDiagnosis;
      reconciliation.doctorName.$model = newFields.reconciliationsDoctorName;
      reconciliation.doctorComment.$model = newFields.reconciliationsDoctorComment;
    },
    openAppealTemplateModal(reconciliation) {
      uiService.showModal(MODALS.APPEAL_TEMPLATE_MODAL, {
        name: 'AppealTemplatesModal',
        props: {
          reconciliation,
        },
        actions: {
          onSelectAppealTemplate: this.onSelectAppealTemplate,
        },
      });
    },
    addNewReconciliation() {
      const reconciliation = {
        id: Math.floor(Math.random() * 10000),
        showContent: false,
        isChanged: false,
        reconciliation: {
          createDate: null,
          initiator: null,
          clinicId: null,
          diagnosis: null,
          doctorName: null,
          agreedServices: null,
          notAgreedServices: null,
          regectionReason: null,
          doctorComment: null,
          responsibleId: null,
          insuranseDiagnosis: null,
          responsible: null,
          documents: [],
        },
      };

      this.reconciliations.push(reconciliation);
    },
    agreeRemoveReconciliation(id) {
      uiService.showModal(MODALS.AGREE_MODAL, {
        name: 'AgreeModal',
        props: {
          title: 'Удаление согласования',
        },
        actions: {
          delete: async () => {
            try {
              this.removeReconciliation(id);
            } catch (err) {
              console.log(err);
            }
          },
        },
      });
    },
    removeReconciliation(id) {
      this.reconciliations = this.reconciliations.filter((item) => item.id !== id);
    },
    async initAppeal(appealId) {
      // console.log('appealId', appealId);
      const data = await appealsService.getById(appealId);
      // console.log('data in index - policy', data.policy);
      this.initAppealData = JSON.parse(JSON.stringify(data));
      this.form.isFinalStage = data.isFinalStage;
      this.form.categoryId = data.category?.id;
      this.form.typeNewId = data.type?.id;
      this.form.policyId = data.policy?.id;
      this.form.cityId = data.city?.id;
      this.form.checkupDate = new Date(data.checkupDate);
      this.form.checkupProfileId = data.checkupProfile?.id;
      this.form.checkupAnotherText = data.checkupAnotherText;
      this.form.clinicId = data.clinic?.id;
      this.form.consultationReason = data.consultationReason;
      this.form.consultationId = data.consultationId;
      this.form.consultationComment = data.consultationComment;
      this.form.comment = data.comment;
      this.form.categoryAnotherText = data.categoryAnotherText;
      this.form.typeAnotherText = data.typeAnotherText;
      this.form.newPurpose = data.purpose;
      this.form.techComment = data.techComment;
      this.form.yandexLink = data.yandexLink;
      this.form.urgency = data.urgency;
      this.form.transportation = data.transportation;
      this.form.status = data.status;
      this.form.gemotestAppealId = data.gemotestAppealId;
      this.form.isActivePolicy = data.isActivePolicy;
      this.form.operatorComment = data.operatorComment;
      this.form.orderNumber = data.orderNumber;
      this.form.patientInfo = data.patientInfo;
      this.form.administratorName = data.administratorName;
      this.form.reconciliations = data.reconciliations;
      this.form.reconciliationComment = data.reconciliationComment;
      this.form.rejectionReason = data.rejectionReason;
      this.form.responsible = data.responsible?.id;
      this.form.regionId = data.region?.id;
      this.form.policyNumber = data.policyNumber;
      this.form.policyBuyDate = new Date(data.policyBuyDate);
      this.form.date = new Date(utc0ToDateWithUserTimezone(data.dateTime));
      this.form.insuranseCompanyId = data.insuranseCompany?.id;
      this.form.qualityControlPurposeType = data.qualityControlPurposeType || 0;

      if (this.selectedPurposeContractDepartament && this.initAppealData.status !== APPEAL_STATUSES.Closed) {
        this.form.status = null;
      }
      if (data.files) {
        this.documents = data.files.map((item) => ({ title: item.name, guid: item.id }));
      }

      // Согласование
      if (
        data.reconciliations
        && Array.isArray(data.reconciliations)
        && data.reconciliations.length
      ) {
        this.reconciliations = data.reconciliations.map((item) => {
          const newReconciliation = {
            id: item.id,
            showContent: true,
            isChanged: false,
            reconciliation: {
              ...item,
              responsibleId: item.responsible.id,
              documents: [],
            },
          };

          if (Array.isArray(item.files) && item.files.length) {
            newReconciliation.reconciliation.documents = item.files.map((fileItem) => ({ title: fileItem.name, guid: fileItem.id }));
          }
          return newReconciliation;
        });
        this.reconciliationCommentForOperator = data.reconciliations[0].commentForOperator;

        // Статус ставлю только если "Готово", что бы не сохраняли случайно со старым статусом
        if (data.isFinalStage && data.reconciliations[0].status === 2) {
          this.reconciliationStatus = data.reconciliations[0].status;
        }
      }
    },
    initAppealByTab() {
      const {
        formData,
        reconciliationCommentForOperator,
        reconciliationStatus,
        reconciliations,
        documents,
      } = this.panelTabAppealData;
      this.form = formData;
      this.reconciliationCommentForOperator = reconciliationCommentForOperator;
      this.reconciliationStatus = reconciliationStatus;
      this.reconciliations = reconciliations;
      this.documents = documents || [];
    },
    initStage() {
      // Да, Это сложно
      try {
        if (this.isEdit) {
          if (this.initAppealData.status === APPEAL_STATUSES.InProgress) {
            this.actualStage = 1;
            if (this.stages.length >= 2) {
              this.actualStage = 2;
            }

            const { reconciliations } = this.initAppealData;

            if (reconciliations && reconciliations.length) {
              const firstReconciliation = this.initAppealData.reconciliations[0];
              if (firstReconciliation.status === 2 || firstReconciliation.status === 3) {
                this.actualStage = 3;
              }
              if (
                firstReconciliation.status === 4
                && this.initAppealData.isFinalStage
              ) {
                this.actualStage = 3;
              }
            }
          }
          if (this.selectedPurposeContractDepartament) {
            if (this.isReturnedAppeal || this.isPostponedByReturnedAppeal) {
              this.actualStage = 4;
            }
          }
          if (this.isClosedAppeal && !!this.selectedCategory
            && this.selectedCategory.name !== APPEALS_CATEGORIES.ServiceCoordinationSignUpForTreatment) this.actualStage = 3;
        }
      } catch (e) {
        console.error(e);
      }
    },
    changesAfterInitializtion() {
      if (this.actualStage === 2 && this.selectedPurposeComplaint) {
        this.form.status = null;
      }
    },
    showByStage(formKeyses) {
      try {
        const stages = {
          0: this.stageOne,
          1: this.stageOne,
          2: this.stageTwoo,
          3: this.stageThree,
          4: this.stageThree,
        };

        if (this.stages.length === 2) {
          delete stages[2];
        }

        let stage = this.actualStage;

        if (this.actualStage === 2 && this.stages.length === 2) {
          stage += 1;
        }
        if (this.isApprovalAvailableWhenCreating) {
          stage = 2;
        }
        let show = false;
        formKeyses.forEach((formKey) => {
          const status = stages[stage]?.some((item) => item === formKey);
          if (status) show = true;
        });

        return show;
      } catch (e) {
        console.error(e);
      }

      return false;
    },
    disabledByStage(formKeyses) {
      const stages = {
        0: this.disabledStageOne,
        1: this.disabledStageOne,
        2: this.disabledStageTwoo,
        3: this.disabledStageThree,
        4: this.disabledStageThree,
      };

      if (this.stages.length === 2) {
        delete stages[2];
      }

      let stage = this.actualStage;

      if (this.actualStage === 2 && this.stages.length === 2) {
        stage += 1;
      }

      let disabled = false;
      formKeyses.forEach((formKey) => {
        const status = stages[stage]?.some((item) => item === formKey);
        if (status) disabled = true;
      });

      return disabled;
    },
    selectPolicy(id) {
      this.$v.form.policyId.$model = id;
    },
    async fetchCities() {
      this.cities = (await locationService.getAllCities({ query: '' })).sort((a, b) => a.name.localeCompare(b.name));
    },
    async fetchClinics() {
      this.clinics = (await clinicService.getListNames()).map(({
        id,
        name,
        cityId,
        clinicAddress,
      }) => ({ id, title: `${name}, (${clinicAddress})`, cityId }));
    },
    async fetchClinicById(id) {
      try {
        return clinicService.getOne(id);
      } catch (err) {
        console.warn(err);
        showErrorCustomMessage('Не удалось получить информацию о клинике');
      }
    },
    async fetchPerson() {
      this.person = await this.$store.dispatch(this.$types.PATIENT_FETCH, this.personId);
    },
    async fetchCategoriesAndTypes() {
      this.categoriesAndTypesLoading = true;

      try {
        const data = await appealsService.getTypes(this.form.newPurpose);
        this.categories = data;
        return data;
      } catch (err) {
        console.log(err);
      } finally {
        this.categoriesAndTypesLoading = false;
      }
    },
    async fetchRegions() {
      this.regions = await locationService.getRegions({ isActive: true });
    },
    async fetchCheckupProfiles() {
      this.checkupProfilesList = await specializationsService.getCheckupProfilesOld();
    },
    async fetchInsuranceCompanies() {
      const companies = await this.$store.dispatch(this.$types.INSURANCE_COMPANIES_FETCH);
      this.insuranceCompanies = companies.map((item) => item.company);
    },
    saveFilesCallback(newFilesArray) {
      newFilesArray.map((fileObject) => {
        this.documents.push({
          title: fileObject.name,
          guid: fileObject.responseData?.guid,
        });
        return null;
      });
    },
    onClickDeleteFiles(file) {
      if (this.disabledFields.fileIds) return;
      this.documents = this.documents.filter((doc) => doc.guid !== file.guid);
    },
    openAddDocumentsModal() {
      uiService.showModal(MODALS.FILES_UPLOAD_MODAL, {
        name: 'FilesUploadModal',
        props: {
          multiple: true,
          title: 'Загрузка файлов',
          saveFileCallback: this.saveFilesCallback,
        },
      });
    },
    saveReconciliationFilesCallback(newFilesArray, index) {
      newFilesArray.map((fileObject) => {
        this.reconciliations[index].reconciliation.documents.push({
          title: fileObject.name,
          guid: fileObject.responseData?.guid,
        });
        return null;
      });

      this.$v.reconciliations.$model[index].isChanged = true;
    },
    openAddReconciliationDocumentsModal(index) {
      uiService.showModal(MODALS.FILES_UPLOAD_MODAL, {
        name: 'FilesUploadModal',
        props: {
          multiple: true,
          title: 'Загрузка файлов',
          saveFileCallback: this.saveReconciliationFilesCallback,
          cbArgs: [index],
        },
      });
    },
    onClickDeleteReconciliationFiles(file, index) {
      if (this.disabledFields.reconciliationsFiles) return;
      const { documents } = this.$v.reconciliations.$model[index].reconciliation;
      this.$v.reconciliations.$model[index].reconciliation.documents = documents.filter((doc) => doc.guid !== file.guid);
      this.$v.reconciliations.$model[index].isChanged = true;
    },
    onCityChange() {
      if (this.selectedCategory?.name === APPEALS_CATEGORIES.ServiceCoordinationSignUpForTreatment) {
        this.show.reconciliationsStatus = false;
      }
      this.$v.form.clinicId.$model = null;
    },
    clearFieldsByShow() {
      if (!this.show.city) this.form.cityId = null;
      if (!this.show.clinic) this.form.clinicId = null;
      if (!this.show.category) {
        this.form.categoryId = null;
        this.form.categoryAnotherText = null;
      }
      if (!this.show.categoryAnotherText) this.form.categoryAnotherText = null;
      if (!this.show.type) {
        this.form.typeNewId = null;
        this.form.typeAnotherText = null;
      }
      if (!this.show.typeAnotherText) this.form.typeAnotherText = null;
      if (!this.show.subtype) this.form.subtype = null;
      if (!this.show.qualityControl) {
        if (!this.initAppealData || this.initAppealData?.qualityControl === null) {
          this.form.qualityControlPurposeType = null;
        }
      }
      if (!this.selectedPurposeQualityControlDoctors) this.form.qualityControlPurposeType = null;
      if (!this.show.reconciliationsStatus) {
        this.form.reconciliations = [];
        // this.form.reconciliationComment = null; не относится к согласованиям
      }
      if (!this.show.checkout) {
        this.form.checkupDate = null;
        this.form.checkupProfileId = null;
      }
      // if (!this.show.files) this.form.fileIds = null; // нафиг надо)))
      if (!this.show.insuranceCompany) this.form.insuranseCompanyId = null;
      // if (!this.show.policyNumber) this.form.policyId = null;
      if (!this.show.isActivePolicy) this.form.isActivePolicy = false;
      if (!this.show.policyBuyDate) this.form.policyBuyDate = null;
      if (!this.show.orderNumber) this.form.orderNumber = null;
      if (!this.show.region) this.form.regionId = null;
      if (!this.show.yandexLink) this.form.yandexLink = null;
      if (!this.show.operatorComment) this.form.operatorComment = null;
      if (!this.show.techComment) this.form.techComment = null;
      if (!this.show.returnComment) this.form.returnComment = null;
      if (!this.show.returnType) this.form.returnType = null;
      if (!this.show.rejectionReason) this.form.rejectionReason = null;
      if (!this.show.rejectionReason) this.form.rejectionReason = null;
      if (!this.show.region) this.form.regionId = null;
      if (!this.show.techComment) this.form.techComment = null;
      if (!this.show.techsupportPurposeType) this.form.techsupportPurposeType = null;
      if (!this.show.policyNumber) this.form.policyNumber = null;
      if (!this.show.consultationId) this.form.consultationId = null;
    },
    clearFieldsOnChangePurpose() {
      this.form.categoryId = null;
      this.form.categoryAnotherText = null;
      this.form.typeNewId = null;
      this.form.typeAnotherText = null;
      this.form.status = null;
    },
    clearFieldsOnChangeCategory() {
      if (!this.initProcess) {
        this.form.typeNewId = null;
        this.form.typeAnotherText = null;
      }
    },
    removeAppealTab() {
      if (this.panelTabId) {
        this.$store.commit(this.$types.TAB_PANEL_ITEMS_FETCH_FROM_LOCALSTORAGE);
        this.$store.commit(this.$types.TAB_PANEL_ITEM_REMOVE_BY_ID, this.panelTabId);
      }
    },
    async onClickSave() {
      this.$v.$touch();
      if (this.$v.$error) {
        showValidationErrorMessage();
        return;
      }

      this.isSaving = true;

      try {
        // очищаем лишнее
        this.clearFieldsByShow();
        let appealResponsibleId = this.form.responsible;

        if (!appealResponsibleId) {
          appealResponsibleId = this.$store.state.Auth.user.userId;
        }

        const data = {
          personId: this.person.id,
          responsible: appealResponsibleId,
          chatAppealId: this.actualChatAppealId,
          ...this.form,
        };

        if (data.checkupDate) {
          data.checkupDate = dateWithoutTime(formatISO(data.checkupDate));
        }

        if (data.policyBuyDate) {
          data.policyBuyDate = dateWithoutTime(formatISO(data.policyBuyDate));
        }

        if (this.show.files) {
          data.fileIds = this.documents.map((item) => item.guid);
        }

        if (this.selectedCategoryNoPolicyInCRM || !this.conditionForChoosePolicy) {
          data.policyId = null;
        }

        if (this.initAppealData) {
          const appealData = {
            ...data,
            id: this.initAppealData.id,
          };

          if (
            this.selectedPurposeServiceCoordination
            && (this.actualStage > 1 || this.isApprovalAvailableWhenCreating)
            && !this.selectedCategoryAdjustmentExtensionGP
          ) {
            const reconciliations = this.reconciliations.map((item) => {
              let responsible = item.reconciliation.responsibleId;

              if (item.isChanged || !responsible) {
                responsible = this.$store.state.Auth.user.userId;
              }

              if (this.show.reconciliationsFiles) {
                if (
                  Array.isArray(item.reconciliation.documents)
                  && item.reconciliation.documents.length
                ) {
                  item.reconciliation.fileIds = item.reconciliation.documents.map((documentItem) => documentItem.guid);
                } else {
                  item.reconciliation.fileIds = [];
                }
              }

              return {
                ...item.reconciliation,
                appealId: this.initAppealData.id,
                clinicId: appealData.clinicId,
                responsibleId: responsible,
                status: this.reconciliationStatus,
                commentForOperator: this.reconciliationCommentForOperator,
              };
            });
            // reconciliations[reconciliations.length - 1].commentForOperator = this.reconciliationCommentForOperator;
            // reconciliations[reconciliations.length - 1].status = this.reconciliationStatus;
            reconciliations.forEach((item) => {
              if (!item.createDate) {
                delete item.createDate;
              }

              delete item.documents;
              delete item.responsible;
            });
            // appealData.reconciliations = [{
            //   appealId: this.initAppealData.id,
            //   ...this.reconciliation,
            //   clinicId: appealData.clinicId,
            //   responsibleId: this.$store.state.Auth.user.userId,
            // }];
            appealData.reconciliations = reconciliations;
          }

          // переход на последний этап
          if (
            this.selectedPurposeServiceCoordination
            && (this.actualStage === 2 || this.isApprovalAvailableWhenCreating)
            && appealData.reconciliations.length
            && appealData.reconciliations[0].status !== 4
          ) {
            appealData.isFinalStage = true;
          }

          // Закрытие Согласования услуг
          if (
            appealData.reconciliations.length
            && appealData.reconciliations[0].status === 2
          ) {
            appealData.status = 2;
          }

          const response = await this.editNewAppeal(appealData);
          console.log(response);
          this.removeAppealTab();
        } else {
          const responseData = await this.createNewAppeals(data);

          if (this.isApprovalAvailableWhenCreating) {
            await this.initAppeal(responseData.id);

            await this.onClickSave();
          }

          this.removeAppealTab();
        }
        this.isSaving = false;
        this.$store.commit('Appeal/TOGGLE_APPEAL_CREATED_TRIGGER'); // сделает тогл триггера и обновит обращения
        this.onClose();
      } catch (e) {
        console.error(e);
      } finally {
        this.isSaving = false;
      }
    },
    async createNewAppeals(data) {
      try {
        const response = await appealsService.create(data);
        showSuccessMessage(response.id, 'создано');
        return response;
      } catch (err) {
        console.log(err);
        showDefaultErrorMessage('создании');
      }
    },
    async editNewAppeal(obj) {
      try {
        const response = await appealsService.update(obj);
        showSuccessMessage(response.id, 'отредактировано');
        return response;
      } catch (err) {
        console.log(err);
        showDefaultErrorMessage('редактровании');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.appeal-stages {
  position: sticky;
  top: 0px;
  height: 100%;
}

.my-divider {
  display: block;
  height: 0.5px;
  width: 100%;
  margin: 25px 0;
  background-color: #d8d8d8;
}

.reconciliation-item {
  position: relative;

  &:not(:first-child) {
    margin-top: 2rem;
  }

  &__title {
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 10;

    &::before {
      position: absolute;
      content: '';
      transform: translate(0, -100%);
      left: 0;
      top: 0;
      height: 17px;
      width: 100%;
      background-color: #fff;
      z-index: -1;
    }
  }
}

.cursor-pointer{
  cursor: pointer;
}
</style>
