// Main

// actions

// mutations

// getters
// Auth

// actions
export const LOGIN = 'Auth/LOGIN';
export const LOGOUT = 'Auth/userLogOut';
export const USER_FETCH = 'Auth/USER_FETCH';
export const LOGIN_ESIA = 'Auth/LOGIN_ESIA';

// mutations
export const TOKEN_SET = 'Auth/TOKEN_SET';
export const USER_LOADING_SET = 'Auth/USER_LOADING_SET';
export const USER_SET = 'Auth/USER_SET';
export const ROLE_SET = 'Auth/ROLE_SET';
export const LOGIN_SET = 'Auth/LOGIN_SET';
//  getters
export const USER_INFO_GET = 'Auth/USER_INFO_GET';
// Laboratories
// actions
export const GET_LABORATORY_PARTNERS = 'Laboratories/GET_LABORATORY_PARTNERS';
export const GET_LABORATORY_TAGS = 'Laboratories/GET_LABORATORY_TAGS';
export const LABORATORY_TAG_CREATE = 'Laboratories/LABORATORY_TAG_CREATE';
export const LABORATORY_TAG_UPDATE = 'Laboratories/LABORATORY_TAG_UPDATE';
export const LABORATORY_TAG_ACTIVATE = 'Laboratories/LABORATORY_TAG_ACTIVATE';
export const LABORATORY_TAG_DEACTIVATE = 'Laboratories/LABORATORY_TAG_DEACTIVATE';
export const GET_LABORATORY_TAG_FOR_ID = 'Laboratories/GET_LABORATORY_TAG_FOR_ID';
export const GET_LABORATORY_PARTNER_FOR_ID = 'Laboratories/GET_LABORATORY_PARTNER_FOR_ID';
export const GET_LABORATORY_LEGAL_PARTNERS = 'Laboratories/GET_LABORATORY_LEGAL_PARTNERS';
export const GET_LABORATORY_LEGAL_PARTNER_FOR_ID = 'Laboratories/GET_LABORATORY_LEGAL_PARTNER_FOR_ID';
export const GET_LABORATORY_SERVICE_FOR_ID = 'Laboratories/GET_LABORATORY_SERVICE_FOR_ID';
export const GET_LABORATORY_CITY_FOR_ID = 'Laboratories/GET_LABORATORY_CITY_FOR_ID';
export const GET_LABORATORY_ORDERS = 'Laboratories/GET_LABORATORY_ORDERS';
export const GET_LABORATORY_PARTNER_SERVICES = 'Laboratories/GET_LABORATORY_PARTNER_SERVICES';
export const GET_LABORATORY_PARTNER_SERVICES_FOR_ID = 'Laboratories/GET_LABORATORY_PARTNER_SERVICES_FOR_ID';
export const GET_LABORATORY_ORDER_FOR_ID = 'Laboratories/GET_LABORATORY_ORDER_FOR_ID';
export const GET_LABORATORY_SERVICES = 'Laboratories/GET_LABORATORY_SERVICES';
export const GET_LABORATORY_BRANCHES = 'Laboratories/GET_LABORATORY_BRANCHES';
export const LABORATORY_PARTNER_ACTIVATE = 'Laboratories/LABORATORY_PARTNER_ACTIVATE';
export const LABORATORY_PARTNER_DEACTIVATE = 'Laboratories/LABORATORY_PARTNER_DEACTIVATE';
export const LABORATORY_PARTNER_CREATE = 'Laboratories/LABORATORY_PARTNER_CREATE';
export const LABORATORY_PARTNER_UPDATE = 'Laboratories/LABORATORY_PARTNER_UPDATE';
export const LABORATORY_PARTNER_UPLOAD_LOGO = 'Laboratories/LABORATORY_PARTNER_UPLOAD_LOGO';
export const LABORATORY_PARTNER_DELETE_LOGO = 'Laboratories/LABORATORY_PARTNER_DELETE_LOGO';
export const GET_LABORATORY_ORDER_DOWNLOAD_ANALYSIS = 'Laboratories/GET_LABORATORY_ORDER_DOWNLOAD_ANALYSIS';
export const GET_LABORATORY_DOWNLOAD_BRANCHES = 'Laboratories/GET_LABORATORY_DOWNLOAD_BRANCHES';
export const GET_LABORATORY_PARTNER_PRICES = 'Laboratories/GET_LABORATORY_PARTNER_PRICES';
export const LABORATORY_IMPORT_BRANCHES = 'Laboratories/LABORATORY_IMPORT_BRANCHES';
export const LABORATORY_DOWNLOAD_PARTNER_PRICES = 'Laboratories/LABORATORY_DOWNLOAD_PARTNER_PRICES';
export const LABORATORY_ORDER_UPLOAD_ANALYSIS = 'Laboratories/LABORATORY_ORDER_UPLOAD_ANALYSIS';
export const LABORATORY_ORDER_STATUS_UPDATE = 'Laboratories/LABORATORY_ORDER_STATUS_UPDATE';
export const LABORATORY_DOWNLOAD_GUARANTEE = 'Laboratories/LABORATORY_DOWNLOAD_GUARANTEE';
export const LABORATORY_ORDER_CANCEL = 'Laboratories/LABORATORY_ORDER_CANCEL';
export const LABORATORY_ORDER_COMPLETE = 'Laboratories/LABORATORY_ORDER_COMPLETE';
// export const LABORATORY_ORDER_HISTORY_FETCH = 'Laboratories/LABORATORY_ORDER_HISTORY_FETCH';
export const LABORATORY_LEGAL_PARTNER_ACTIVATE = 'Laboratories/LABORATORY_LEGAL_PARTNER_ACTIVATE';
export const LABORATORY_LEGAL_PARTNER_DEACTIVATE = 'Laboratories/LABORATORY_LEGAL_PARTNER_DEACTIVATE';
export const LABORATORY_LEGAL_PARTNER_CREATE = 'Laboratories/LABORATORY_LEGAL_PARTNER_CREATE';
export const LABORATORY_LEGAL_PARTNER_UPDATE = 'Laboratories/LABORATORY_LEGAL_PARTNER_UPDATE';
export const LABORATORY_PARTNER_SERVICE_IMPORT = 'Laboratories/LABORATORY_PARTNER_SERVICE_IMPORT';
export const LABORATORY_SERVICE_IMPORT = 'Laboratories/LABORATORY_SERVICE_IMPORT';
export const LABORATORY_PARTNER_SERVICE_ACTIVATE = 'Laboratories/LABORATORY_PARTNER_SERVICE_ACTIVATE';
export const LABORATORY_PARTNER_SERVICE_DEACTIVATE = 'Laboratories/LABORATORY_PARTNER_SERVICE_DEACTIVATE';
export const LABORATORY_PARTNER_SERVICE_SET_MAPPABLE = 'Laboratories/LABORATORY_PARTNER_SERVICE_SET_MAPPABLE';
export const LABORATORY_PARTNER_SERVICE_MAP = 'Laboratories/LABORATORY_PARTNER_SERVICE_MAP';
export const LABORATORY_PARTNER_SERVICE_UNMAP = 'Laboratories/LABORATORY_PARTNER_SERVICE_UNMAP';
export const LABORATORY_PARTNER_SERVICE_GET_SIMILAR_SERVICE = 'Laboratories/LABORATORY_PARTNER_SERVICE_GET_SIMILAR_SERVICE';
export const LABORATORY_BRANCH_CREATE = 'Laboratories/LABORATORY_BRANCH_CREATE';
export const GET_METRO_FOR_CITY = 'Laboratories/GET_METRO_FOR_CITY';
export const GET_LABORATORY_BRANCH_FOR_ID = 'Laboratories/GET_LABORATORY_BRANCH_FOR_ID';
export const LABORATORY_BRANCH_ACTIVATE = 'Laboratories/LABORATORY_BRANCH_ACTIVATE';
export const LABORATORY_BRANCH_DEACTIVATE = 'Laboratories/LABORATORY_BRANCH_DEACTIVATE';
export const LABORATORY_BRANCH_IMPORT = 'Laboratories/LABORATORY_BRANCH_IMPORT';
export const LABORATORY_BRANCH_UPDATE = 'Laboratories/LABORATORY_BRANCH_UPDATE';
export const LABORATORY_SERVICE_CREATE = 'Laboratories/LABORATORY_SERVICE_CREATE';
export const LABORATORY_SERVICE_ACTIVATE = 'Laboratories/LABORATORY_SERVICE_ACTIVATE';
export const LABORATORY_SERVICE_DEACTIVATE = 'Laboratories/LABORATORY_SERVICE_DEACTIVATE';
export const LABORATORY_SERVICE_UPDATE = 'Laboratories/LABORATORY_SERVICE_UPDATE';
export const CITY_UPDATE = 'Laboratories/CITY_UPDATE';
export const CITY_DELETE = 'Laboratories/CITY_DELETE';
export const LABORATORY_ORDER_UPLOAD_RESULT_FILE = 'Laboratories/LABORATORY_ORDER_UPLOAD_RESULT_FILE';
export const LABORATORY_ORDER_DELETE_RESULT_FILE = 'Laboratories/LABORATORY_ORDER_DELETE_RESULT_FILE';
export const LABORATORY_ORDER_DOWNLOAD_RESULT_FILE = 'Laboratories/LABORATORY_ORDER_DOWNLOAD_RESULT_FILE';

// mutations
export const PARTNERS_SET = 'Laboratories/PARTNERS_SET';
export const LEGAL_PARTNERS_SET = 'Laboratories/LEGAL_PARTNERS_SET';
export const BRANCHES_SET = 'Laboratories/BRANCHES_SET';
export const PARTNER_SERVICES_SET = 'Laboratories/PARTNER_SERVICES_SET';
export const CURRENT_PARTNER_SERVICE_SET = 'Laboratories/CURRENT_PARTNER_SERVICE_SET';
export const NEXT_PARTNER_SERVICE_SET = 'Laboratories/NEXT_PARTNER_SERVICE_SET';
export const BIND_SERVICES_OFFSET_SET = 'Laboratories/BIND_SERVICES_OFFSET_SET';
export const ORDER_ITEMS_SET = 'Laboratories/ORDER_ITEMS_SET';
export const CURRENT_ORDER_SET = 'Laboratories/CURRENT_ORDER_SET';
export const SERVICES_SET = 'Laboratories/SERVICES_SET';
export const SIMILAR_SERVICES_SET = 'Laboratories/SIMILAR_SERVICES_SET';
export const PARTNERS_IS_LOADING = 'Laboratories/PARTNERS_IS_LOADING';
export const TOGGLE_PARTNERS_LABORATORY_SERVICES_TRIGGER = 'Laboratories/TOGGLE_PARTNERS_LABORATORY_SERVICES_TRIGGER';
export const TOGGLE_OUR_LABORATORY_SERVICES_TRIGGER = 'Laboratories/TOGGLE_OUR_LABORATORY_SERVICES_TRIGGER';
export const LABORATORY_REMOVE_ATTACHED_FILE = 'Laboratories/LABORATORY_REMOVE_ATTACHED_FILE';
export const LABORATORY_INITIALIZE_ATTACHED_FILES = 'Laboratories/LABORATORY_INITIALIZE_ATTACHED_FILES';
export const LABORATORY_DESTORY_ATTACHED_FILES = 'Laboratories/LABORATORY_DESTORY_ATTACHED_FILES';
export const LABORATORY_ADD_ATTACHED_FILE = 'Laboratories/LABORATORY_ADD_ATTACHED_FILE';

// getters
export const PARTNER_IS_LOADING_GET = 'Laboratories/PARTNER_IS_LOADING_GET';
export const PARTNER_SERVICES_LIST_GET = 'Laboratories/PARTNER_SERVICES_LIST_GET';
export const BIND_SERVICES_OFFSET_GET = 'Laboratories/BIND_SERVICES_OFFSET_GET';
export const ORDERS_LIST_GET = 'Laboratories/ORDERS_LIST_GET';
export const PARTNERS_LABORATORY_SERVICES_TRIGGER = 'Laboratories/PARTNERS_LABORATORY_SERVICES_TRIGGER';
export const OUR_LABORATORY_SERVICES_TRIGGER = 'Laboratories/OUR_LABORATORY_SERVICES_TRIGGER';
export const LABORATORY_ATTACHED_FILES = 'Laboratories/LABORATORY_ATTACHED_FILES';

// LaboratoryPromocodes

// actions
export const LABORATORY_PROMOCODE_CREATE = 'Laboratories/LABORATORY_PROMOCODE_CREATE';
export const LABORATORY_PROMOCODE_UPDATE = 'Laboratories/LABORATORY_PROMOCODE_UPDATE';
export const LABORATORY_PROMOCODE_FETCH = 'Laboratories/LABORATORY_PROMOCODE_FETCH';
export const LABORATORY_PROMOCODES_FETCH = 'Laboratories/LABORATORY_PROMOCODES_FETCH';
export const LABORATORY_PROMOCODE_DELETE = 'Laboratories/LABORATORY_PROMOCODE_DELETE';

// mutations
export const LABORATORY_PROMOCODES_FILTER_SET = 'Laboratories/LABORATORY_PROMOCODES_FILTER_SET';
export const LABORATORY_PROMOCODES_SET = 'Laboratories/LABORATORY_PROMOCODES_SET';
export const LABORATORY_PROMOCODES_COUNT_SET = 'Laboratories/LABORATORY_PROMOCODES_COUNT_SET';
export const LABORATORY_PROMOCODE_REMOVE_BY_ID = 'Laboratories/LABORATORY_PROMOCODE_REMOVE_BY_ID';
export const LABORATORY_PROMOCODES_IS_LOADING_SET = 'Laboratories/LABORATORY_PROMOCODES_IS_LOADING_SET';
export const LABORATORY_PROMOCODES_IS_ALL_ITEMS_LOADED_SET = 'Laboratories/LABORATORY_PROMOCODES_IS_ALL_ITEMS_LOADED_SET';

// getters

export const LABORATORY_PROMOCODES_GET = 'Laboratories/LABORATORY_PROMOCODES_GET';
export const LABORATORY_PROMOCODES_COUNT_GET = 'Laboratories/LABORATORY_PROMOCODES_COUNT_GET';

// BannerManagement

// actions

export const FETCH_BANNERS = 'BannerManagement/FETCH_BANNERS';
export const GET_BANNER = 'BannerManagement/GET_BANNER';
export const UPDATE_BANNER = 'BannerManagement/UPDATE_BANNER';
export const CREATE_BANNER = 'BannerManagement/CREATE_BANNER';
export const DELETE_BANNER = 'BannerManagement/DELETE_BANNER';
export const REOPEN_BANNER = 'BannerManagement/REOPEN_BANNER';

// Chat

// actions
export const CHAT_APPEAL_UNASSIGNED = 'Chat/CHAT_APPEAL_UNASSIGNED';
export const CHAT_APPEAL_UNASSIGN = 'Chat/CHAT_APPEAL_UNASSIGN';
export const CHAT_APPEAL_FOR_ROLE = 'Chat/CHAT_APPEAL_FOR_ROLE';
export const CHAT_APPEAL_IN_WORK = 'Chat/CHAT_APPEAL_IN_WORK';
export const CHAT_APPEAL_TAKEON = 'Chat/CHAT_APPEAL_TAKEON';
export const CHAT_APPEAL_CLOSE = 'Chat/CHAT_APPEAL_CLOSE';
export const CHAT_APPEAL_MOVE = 'Chat/CHAT_APPEAL_MOVE';
export const CHAT_APPEAL_SMS_SENDING = 'Chat/CHAT_APPEAL_SMS_SENDING';
export const CHAT_APPEAL_FILE_PUT = 'Chat/CHAT_APPEAL_FILE_PUT';

// getters
export const CHAT_APPEAL_MESSAGE_FETCH = 'Chat/CHAT_APPEAL_MESSAGE_FETCH';
export const CHAT_APPEALS_HISTORY_FETCH = 'Chat/CHAT_APPEALS_HISTORY_FETCH';
export const CHAT_MESSAGES_HISTORY_ONE_APPEAL_FETCH = 'Chat/CHAT_MESSAGES_HISTORY_ONE_APPEAL_FETCH';
export const CHAT_APPEAL_FILE_FETCH = 'Chat/CHAT_APPEAL_FILE_FETCH';
export const CHAT_APPEAL_FILE_PREVIEW = 'Chat/CHAT_APPEAL_FILE_PREVIEW';
export const GET_CHAT_DATA = 'Chat/GET_CHAT_DATA';

// mutations
// export const CHAT_DATA_SET = 'Chat/CHAT_DATA_SET';

//  Clinic

// actions
export const CLINICS_FETCH = 'Clinic/CLINICS_FETCH';
export const CLINICS_FETCH_FOR_MAP = 'Clinic/CLINICS_FETCH_FOR_MAP';
export const LABS_FETCH_FOR_MAP = 'Clinic/LABS_FETCH_FOR_MAP';
// export const CLINIC_RECOVERY = 'Clinic/CLINIC_RECOVERY';
export const CITY_CREATE = 'Clinic/CITY_CREATE';
export const CLINIC_HISTORY_FETCH = 'Clinic/CLINIC_HISTORY_FETCH';
export const CLINIC_PRICES_IMPORT = 'Clinic/CLINIC_PRICES_IMPORT';
export const FETCH_PRICES_LIST = 'Clinic/FETCH_PRICES_LIST';
export const CLINIC_PRICES_FETCH = 'Clinic/CLINIC_PRICES_FETCH';
export const CLINIC_PRICES_HISTORY_FETCH = 'Clinic/CLINIC_PRICES_HISTORY_FETCH';
export const CLINIC_PRICES_LIST_FETCH = 'Clinic/CLINIC_PRICES_LIST_FETCH';

export const LEGAL_PERSONS_FETCH = 'Clinic/LEGAL_PERSONS_FETCH';
export const CITIES_FETCH = 'Clinic/CITIES_FETCH';
export const CLINICS_PINNED_DOCTORS_TOGGLE = 'Clinic/CLINICS_PINNED_DOCTORS_TOGGLE';
// mutations
export const CLINICS_SET = 'Clinic/CLINICS_SET';
export const CLINICS_ADD = 'Clinic/CLINICS_ADD';
export const CLINIC_LIST_NAMES_SET = 'Clinic/CLINIC_LIST_NAMES_SET';
// export const CLINIC_REMOVE_BY_ID = 'Clinic/CLINIC_REMOVE_BY_ID';
export const CLINICS_PINNED_DOCTORS_SET = 'Clinic/CLINICS_PINNED_DOCTORS_SET';
export const CLINICS_SET_IS_CHANGED_CLINIC_INFO = 'Clinic/CLINICS_SET_IS_CHANGED_CLINIC_INFO';
export const CLINICS_SET_IS_CHANGED_CLINIC_DOCTORS_INFO = 'Clinic/CLINICS_SET_IS_CHANGED_CLINIC_DOCTORS_INFO';
export const CLINIC_SET = 'Clinic/CLINIC_SET';
export const CLINIC_INFO_IS_EDIT_SET = 'Clinic/CLINIC_INFO_IS_EDIT_SET';
// export const CLINIC_DOCTORS_IS_EDIT_SET = 'Clinic/SET_CLINIC_DOCTORS_IS_EDIT';
export const CLINIC_IS_LOADING_SET = 'Clinic/CLINIC_IS_LOADING_SET';
export const SET_LIST_PRICES = 'Clinic/SET_LIST_PRICES';
export const SET_LIST_PRICES_PAGINATION_COUNT = 'Clinic/SET_LIST_PRICES_PAGINATION_COUNT';
export const SET_LIST_PRICES_LOADING = 'Clinic/SET_LIST_PRICES_LOADING';

// getters
export const GET_DOCTORS_PINNED = 'Clinic/GET_DOCTORS_PINNED';
export const GET_IS_CHANGED_DOCTORS_INFO = 'Clinic/GET_IS_CHANGED_DOCTORS_INFO';
export const GET_IS_CHANGED_INFO = 'Clinic/GET_IS_CHANGED_INFO';
export const PRICES_LIST = 'Clinic/PRICES_LIST';
export const PRICES_LIST_PAGINATION_COUNT = 'Clinic/PRICES_LIST_PAGINATION_COUNT';
export const PRICES_LIST_LOADING = 'Clinic/PRICES_LIST_LOADING';

//  Appeal

// actions
// export const APPEAL_RESPONSIBLES_FETCH = 'Appeal/APPEAL_RESPONSIBLES_FETCH';
// export const APPEAL_INFORMATION_TYPES_FETCH = 'Appeal/APPEAL_INFORMATION_TYPES_FETCH';
// export const APPEAL_TREATMENT_TYPES_FETCH = 'Appeal/APPEAL_TREATMENT_TYPES_FETCH';
// export const APPEAL_GOSTELEMED_TYPES_FETCH = 'Appeal/APPEAL_GOSTELEMED_TYPES_FETCH';
export const APPEAL_CHECKUP_PROFILES_FETCH = 'Appeal/APPEAL_CHECKUP_PROFILES_FETCH';
// export const APPEAL_ADD = 'Appeal/APPEAL_ADD';
// export const APPEAL_UPDATE = 'Appeal/APPEAL_UPDATE';
// export const APPEALS_FETCH = 'Appeal/APPEALS_FETCH';
// export const APPEAL_TYPES_FETCH = 'Appeal/APPEAL_TYPES_FETCH';
// export const APPEALS_EXPORT = 'Appeal/APPEALS_EXPORT';
// export const APPEAL_HISTORY_FETCH = 'Appeal/APPEAL_HISTORY_FETCH';

// mutations
// export const APPEAL_RESPONSIBLES_ADD = 'Appeal/APPEAL_RESPONSIBLES_ADD';
// export const APPEAL_INFORMATION_TYPES_ADD = 'Appeal/APPEAL_INFORMATION_TYPES_ADD';
// export const APPEAL_TREATMENT_TYPES_ADD = 'Appeal/APPEAL_TREATMENT_TYPES_ADD';
// export const APPEAL_GOSTELEMED_TYPES_ADD = 'Appeal/APPEAL_GOSTELEMED_TYPES_ADD';
// export const APPEAL_CHECKUP_PROFILES_ADD = 'Appeal/APPEAL_CHECKUP_PROFILES_ADD';
// export const APPEALS_ADD = 'Appeal/APPEALS_ADD';
// export const APPEALS_SET = 'Appeal/APPEALS_SET';

// getters
// export const GET_APPEAL_RESPONSIBLES = 'Appeal/APPEAL_RESPONSIBLES';
export const GET_APPEAL_INFORMATION_TYPES = 'Appeal/APPEAL_INFORMATION_TYPES';
export const GET_APPEAL_TREATMENT_TYPES = 'Appeal/GET_APPEAL_TREATMENT_TYPES';
export const GET_APPEAL_GOSTELEMED_TYPES = 'Appeal/GET_APPEAL_GOSTELEMED_TYPES';
export const GET_APPEAL_CHECKUP_PROFILES = 'Appeal/GET_APPEAL_CHECKUP_PROFILES';

// ApealsNew

// getters
export const APPEAL_CREATED_TRIGGER = 'AppealsNew/APPEAL_CREATED_TRIGGER';

// actions
export const APPEALS_NEW_SEARCH = 'AppealsNew/APPEALS_NEW_SEARCH';
export const APPEALS_NEW_TYPES_FETCH = 'AppealsNew/APPEALS_NEW_TYPES_FETCH';
export const APPEALS_NEW_FETCH_APPEAL_BY_ID = 'AppealsNew/APPEALS_NEW_FETCH_APPEAL_BY_ID';
export const APPEALS_NEW_CREATE = 'AppealsNew/APPEALS_NEW_CREATE';
export const APPEALS_NEW_EDIT = 'AppealsNew/APPEALS_NEW_EDIT';
export const APPEALS_NEW_EXPORT = 'AppealsNew/APPEALS_NEW_EXPORT';

/// mutations
// export const TOGGLE_APPEAL_CREATED_TRIGGER = 'AppealsNew/TOGGLE_APPEAL_CREATED_TRIGGER';

// Appeals Templates

// getters
// export const APPEALS_TEMPLATES_FETCH_TRIGGER = 'AppealsTemplates/APPEALS_TEMPLATES_FETCH_TRIGGER';

// actions
export const APPEALS_TEMPLATES_FETCH = 'AppealsTemplates/APPEALS_TEMPLATES_FETCH';
export const APPEALS_TEMPLATES_CREATE = 'AppealsTemplates/APPEALS_TEMPLATES_CREATE';
export const APPEALS_TEMPLATES_EDIT = 'AppealsTemplates/APPEALS_TEMPLATES_EDIT';
export const APPEALS_TEMPLATES_REMOVE_ITEM = 'AppealsTemplates/APPEALS_TEMPLATES_REMOVE_ITEM';

// mutations
// export const TOGGLE_FETCH_APPEALS_TEMPLATES_TRIGGER = 'AppealsTemplates/TOGGLE_FETCH_APPEALS_TEMPLATES_TRIGGER';

// Registry

// actions
export const REGISTRY_EXPORT = 'Registry/REGISTRY_EXPORT';

//  Consultations

// actions
export const CONSULTATIONS_FETCH = 'Consultation/CONSULTATIONS_FETCH';
export const CONSULTATION_HISTORY_FETCH = 'Consultation/CONSULTATION_HISTORY_FETCH';
export const CONSULTATION_REOPEN_HISTORY_FETCH = 'Consultation/CONSULTATION_REOPEN_HISTORY_FETCH';
export const CONSULTATION_CHAT_FETCH = 'Consultation/CONSULTATION_CHAT_FETCH';
export const DOWNLOAD_RECORD = 'Consultation/DOWNLOAD_RECORD';
export const CONSULTATION_FILES_FETCH = 'Consultation/CONSULTATION_FILES_FETCH';
export const CONSULTATION_FILE_FETCH = 'Consultation/CONSULTATION_FILE_FETCH';
export const CONSULTATION_CHAT_MESSAGE_DELETE = 'Consultation/CONSULTATION_CHAT_MESSAGE_DELETE';
export const TRANSFER_REASONS_FETCH = 'Consultation/TRANSFER_REASONS_FETCH';
export const CONSULTATIONS_SEARCH = 'Consultation/CONSULTATIONS_SEARCH';
export const CONSULTATIONS_NEW_SEARCH = 'Consultation/CONSULTATIONS_NEW_SEARCH';
export const CONSULTATIONS_TRANSFER = 'Consultation/CONSULTATIONS_TRANSFER';
export const CONSULTATIONS_CANCEL = 'Consultation/CONSULTATIONS_CANCEL';
export const CONSULTATIONS_CONTROL_FETCH = 'Consultation/CONSULTATIONS_CONTROL_FETCH';
export const CONSULATION_CONTROL_PROBLEM_RESOLVE = 'Consultation/CONSULATION_CONTROL_PROBLEM_RESOLVE';
export const CONSULATION_CONTROL_EXPORT = 'Consultation/CONSULATION_CONTROL_EXPORT';
export const CONSULATION_REOPEN = 'Consultation/CONSULATION_REOPEN';
// mutations
export const CONSULTATIONS_SET = 'Consultation/CONSULTATIONS_SET';
export const CONSULTATIONS_ADD = 'Consultation/CONSULTATIONS_ADD';
export const CONSULTATIONS_CONTROL_SET = 'Consultation/CONSULTATIONS_CONTROL_SET';
export const CONSULTATIONS_CONTROL_LOADING_SET = 'Consultation/CONSULTATIONS_CONTROL_LOADING_SET';
export const TOGGLE_CONSULTATIONS_PROBLEMS_TRIGGER = 'Consultation/TOGGLE_CONSULTATIONS_PROBLEMS_TRIGGER';
// getters
export const CONSULTATIONS_PROBLEMS_TRIGGER = 'Consultation/CONSULTATIONS_PROBLEMS_TRIGGER';

// ClinicDocuments

// actions
export const CLINIC_DOCUMENTS_FETCH = 'ClinicDocuments/CLINIC_DOCUMENTS_FETCH';
export const CLINIC_DOCUMENTS_CREATE = 'ClinicDocuments/CLINIC_DOCUMENTS_CREATE';
export const CLINIC_DOCUMENTS_UPDATE = 'ClinicDocuments/CLINIC_DOCUMENTS_UPDATE';
export const CLINIC_DOCUMENTS_DELETE = 'ClinicDocuments/CLINIC_DOCUMENTS_DELETE';

// mutations
export const CLINIC_DOCUMENTS_SET = 'ClinicDocuments/CLINIC_DOCUMENTS_SET';
export const CLINIC_DOCUMENTS_CLINIC_ID_SET = 'ClinicDocuments/CLINIC_DOCUMENTS_CLINIC_ID_SET';
export const CLINIC_DOCUMENTS_LOADING_SET = 'ClinicDocuments/CLINIC_DOCUMENTS_LOADING_SET';
export const CLINIC_DOCUMENTS_QUERY_SET = 'ClinicDocuments/CLINIC_DOCUMENTS_QUERY_SET';

// getters
export const CLINIC_DOCUMENTS_GET_ALL = 'ClinicDocuments/CLINIC_DOCUMENTS_GET_ALL';
export const CLINIC_DOCUMENTS_GET_CONTRACTS = 'ClinicDocuments/CLINIC_DOCUMENTS_GET_CONTRACTS';
export const CLINIC_DOCUMENTS_GET_CONSTITUENT = 'ClinicDocuments/CLINIC_DOCUMENTS_GET_CONSTITUENT';

//  Doctors

// actions
export const DOCTOR_AUTH_TOKEN_FETCH = 'Doctor/DOCTOR_AUTH_TOKEN_FETCH';
export const DOCTOR_FETCH = 'Doctor/DOCTOR_FETCH';
export const GET_DOCTOR_SERVICES = 'Doctor/GET_DOCTOR_SERVICES';
export const DOCTOR_CREATE = 'Doctor/DOCTOR_CREATE';
export const DOCTOR_UPDATE = 'Doctor/DOCTOR_UPDATE';
export const DOCTOR_DELETE = 'Doctor/DOCTOR_DELETE';
export const DOCTORS_FETCH = 'Doctor/DOCTORS_FETCH';
export const DOCTORS_CONSULTATIONS_FETCH = 'Doctor/DOCTORS_CONSULTATIONS_FETCH';
export const SPECIALIZATIONS_SEARCH = 'Doctor/SPECIALIZATIONS_SEARCH';
export const DOCTOR_UNLOAD_FETCH = 'Doctor/DOCTOR_UNLOAD_FETCH';
export const DOCTOR_FETCH_REGIONS = 'Doctor/DOCTOR_FETCH_REGIONS';
export const DOCTOR_FETCH_SPECIALITIES = 'Doctor/DOCTOR_FETCH_SPECIALITIES';
export const DOCTOR_AVATAR_GET = 'Doctor/DOCTOR_AVATAR_GET';
export const DOCTOR_AVATAR_DELETE = 'Doctor/DOCTOR_AVATAR_DELETE';
export const DOCTOR_AVATAR_UPLOAD = 'Doctor/DOCTOR_AVATAR_UPLOAD';
export const DOCTOR_FILE_PUT = 'Doctor/DOCTOR_FILE_PUT';
export const DOCTOR_FILE_DOWNLOAD = 'Doctor/DOCTOR_FILE_DOWNLOAD';
export const DOCTORS_EVALUATION_FETCH = 'Doctor/DOCTORS_EVALUATION_FETCH';
export const DOCTORS_EVALUATION_MISTAKES_FETCH = 'Doctor/DOCTORS_EVALUATION_MISTAKES_FETCH';
export const DOCTORS_EVALUATION_EVALUATE = 'Doctor/DOCTORS_EVALUATION_EVALUATE';
export const DOCTORS_EVALUATION_DELETE = 'Doctor/DOCTORS_EVALUATION_DELETE';
export const DOCTORS_LIST_BY_SPECIALIZATION = 'Doctor/DOCTORS_LIST_BY_SPECIALIZATION';
export const DOCTOR_SCHEDULE_FETCH = 'Doctor/DOCTOR_SCHEDULE_FETCH';

// mutations
export const DOCTORS_SET = 'Doctor/DOCTORS_SET';
export const DOCTORS_ADD = 'Doctor/DOCTORS_ADD';
export const DOCTOR_REMOVE_BY_ID = 'Doctor/DOCTOR_REMOVE_BY_ID';
export const DOCTORS_CONSULTATIONS_SET = 'Doctor/DOCTORS_CONSULTATIONS_SET';
export const DOCTORS_CONSULTATIONS_ADD = 'Doctor/DOCTORS_CONSULTATIONS_ADD';
export const DOCTORS_EVALUATION_ITEMS_SET = 'Doctor/DOCTORS_EVALUATION_ITEMS_SET';
export const DOCTORS_EVALUATION_PAGE_SET = 'Doctor/DOCTORS_EVALUATION_PAGE_SET';
export const DOCTORS_EVALUATION_ITEMS_ADD = 'Doctor/DOCTORS_EVALUATION_ITEMS_ADD';
export const DOCTORS_EVALUATION_IS_LOADING_SET = 'Doctor/DOCTORS_EVALUATION_IS_LOADING_SET';
export const DOCTORS_EVALUATION_IS_ALL_ITEMS_LOADED_SET = 'Doctor/DOCTORS_EVALUATION_IS_ALL_ITEMS_LOADED_SET';
// getters

//  Legal Entities

// actions
export const LEGAL_ENTITIES_FETCH = 'LegalEntities/LEGAL_ENTITIES_FETCH';
export const LEGAL_ENTITY_FETCH = 'LegalEntities/LEGAL_ENTITY_FETCH';
export const LEGAL_ENTITIES_CREATE = 'LegalEntities/LEGAL_ENTITIES_CREATE';
export const LEGAL_ENTITIES_UPDATE = 'LegalEntities/LEGAL_ENTITIES_UPDATE';
export const LEGAL_DELETE = 'LegalEntities/LEGAL_DELETE';

// mutations
export const LEGAL_ENTITIES_SET = 'LegalEntities/LEGAL_ENTITIES_SET';
export const LEGAL_ENTITIES_ADD = 'LegalEntities/LEGAL_ENTITIES_ADD';
export const LEGAL_ENTITIES_REMOVE_BY_ID = 'LegalEntities/LEGAL_ENTITIES_REMOVE_BY_ID';
// getters

//  Schedule

// actions
export const SCHEDULE_FETCH = 'Schedule/SCHEDULE_FETCH';
export const FETCH_DOCTOR_ALL_SLOTS = 'Schedule/FETCH_DOCTOR_ALL_SLOTS';
export const SCHEDULE_TEMPLATES_FETCH = 'Schedule/SCHEDULE_TEMPLATES_FETCH';
export const SCHEDULE_SINGLE_TEMPLATE_FETCH = 'Schedule/SCHEDULE_SINGLE_TEMPLATE_FETCH';
export const SCHEDULE_TEMPLATE_UPDATE = 'Schedule/SCHEDULE_TEMPLATE_UPDATE';
export const SCHEDULE_TEMPLATE_CREATE = 'Schedule/SCHEDULE_TEMPLATE_CREATE';
export const SCHEDULE_TEMPLATE_DELETE = 'Schedule/SCHEDULE_TEMPLATE_DELETE';
export const SCHEDULE_TEMPLATE_TOGGLE_ACTIVATION = 'Schedule/SCHEDULE_TEMPLATE_TOGGLE_ACTIVATION';
export const SCHEDULE_TEMPLATES_DEACTIVATE_ALL = 'Schedule/SCHEDULE_TEMPLATES_DEACTIVATE_ALL';
export const SCHEDULE_CREATE_CHECKUP = 'Schedule/SCHEDULE_CREATE_CHECKUP';
export const BOOK_SLOT_FROM_CHAT = 'Schedule/BOOK_SLOT_FROM_CHAT';
export const SCHEDULE_FILETOKEN_FETCH = 'Schedule/SCHEDULE_FILETOKEN_FETCH';
export const SCHEDULE_CHECKUP_ADD_DOCUMENTS = 'Schedule/SCHEDULE_CHECKUP_ADD_DOCUMENTS';
export const SCHEDULE_PATIENTS_SEARCH = 'Schedule/SCHEDULE_PATIENTS_SEARCH';
export const DUTY_QUEUE_LIST_FETCH = 'Schedule/DUTY_QUEUE_LIST_FETCH';
export const DUTY_APPOINTMENT_CREATE = 'Schedule/DUTY_APPOINTMENT_CREATE';
// mutations
export const SCHEDULE_DATE_SET = 'Schedule/SCHEDULE_DATE_SET';
export const SCHEDULE_DOCTOR_SET = 'Schedule/SCHEDULE_DOCTOR_SET';
export const SCHEDULE_LOADING_SET = 'Schedule/SCHEDULE_LOADING_SET';
export const SCHEDULE_SET = 'Schedule/SCHEDULE_SET';
export const SCHEDULE_TEMPLATES_LOADING_SET = 'Schedule/SCHEDULE_TEMPLATES_LOADING_SET';
export const SCHEDULE_TEMPLATES_SET = 'Schedule/SCHEDULE_TEMPLATES_SET';
export const TOGGLE_SCHEDULE_TRIGGER = 'Schedule/TOGGLE_SCHEDULE_TRIGGER';
// getters
export const SCHEDULE_DATE_GET = 'Schedule/SCHEDULE_DATE_GET';
export const SCHEDULE_DOCTOR_GET = 'Schedule/SCHEDULE_DOCTOR_GET';
export const SCHEDULE_TRIGGER = 'Schedule/SCHEDULE_TRIGGER';

// SchedulePage

// actions
export const SCHEDULE_PAGE_FETCH = 'SchedulePage/SCHEDULE_PAGE_FETCH';
export const SCHEDULE_PAGE_SCHEDULE_FETCH = 'SchedulePage/SCHEDULE_PAGE_SCHEDULE_FETCH';
export const SCHEDULE_PAGE_SCHEDULE_ROW_FORM = 'SchedulePage/SCHEDULE_PAGE_SCHEDULE_ROW_FORM';
export const SCHEDULE_PAGE_SCHEDULE_ADD = 'SchedulePage/SCHEDULE_PAGE_SCHEDULE_ADD';
export const SCHEDULE_PAGE_DOCTORS_ADD = 'SchedulePage/SCHEDULE_PAGE_DOCTORS_ADD';
export const SCHEDULE_PAGE_SCHEDULE_FETCH_BY_DOCTORS = 'SchedulePage/SCHEDULE_PAGE_SCHEDULE_FETCH_BY_DOCTORS';
// mutations
export const SCHEDULE_PAGE_FILTER_CLINIC_SET = 'SchedulePage/SCHEDULE_PAGE_FILTER_CLINIC_SET';
export const SCHEDULE_PAGE_FILTER_REGION_SET = 'SchedulePage/SCHEDULE_PAGE_FILTER_REGION_SET';
export const SCHEDULE_PAGE_FILTER_SPECIALIZATIONS_SET = 'SchedulePage/SCHEDULE_PAGE_FILTER_SPECIALIZATIONS_SET';
export const SCHEDULE_PAGE_FILTER_DOCTORS_SET = 'SchedulePage/SCHEDULE_PAGE_FILTER_DOCTORS_SET';
export const SCHEDULE_PAGE_DATE_SET = 'SchedulePage/SCHEDULE_PAGE_DATE_SET';
export const SCHEDULE_PAGE_LOADING_SET = 'SchedulePage/SCHEDULE_PAGE_LOADING_SET';
export const SCHEDULE_PAGE_ALL_ITEMS_LOADED_SET = 'SchedulePage/SCHEDULE_PAGE_ALL_ITEMS_LOADED_SET';
export const SCHEDULE_PAGE_SCHEDULE_SET = 'SchedulePage/SCHEDULE_PAGE_SCHEDULE_SET';
export const SCHEDULE_PAGE_DOCTORS_SET = 'SchedulePage/SCHEDULE_PAGE_DOCTORS_SET';
export const SCHEDULE_PAGE_NULL_STATE = 'SchedulePage/SCHEDULE_PAGE_NULL_STATE';
export const SCHEDULE_PAGE_HOVERED_CELL_SET = 'SchedulePage/SCHEDULE_PAGE_HOVERED_CELL_SET';
export const SCHEDULE_PAGE_DRAGGABLE_CELL_SET = 'SchedulePage/SCHEDULE_PAGE_DRAGGABLE_CELL_SET';
// getters
export const SCHEDULE_PAGE_TIME_CELL_GET = 'SchedulePage/SCHEDULE_PAGE_TIME_CELL_GET';
export const SCHEDULE_PAGE_SCHEDULE_TOTAL_COUNT_GET = 'SchedulePage/SCHEDULE_PAGE_SCHEDULE_TOTAL_COUNT_GET';
// Statistics

// actions
export const STATISTICS_FETCH = 'Statistics/STATISTICS_FETCH';
export const STATISTICS_UNLOAD_FETCH = 'Statistics/STATISTICS_UNLOAD_FETCH';
export const STATISTICS_KTG_UNLOAD_FETCH = 'Statistics/STATISTICS_KTG_UNLOAD_FETCH';
export const CHECKUP_EXPORT = 'Statistics/CHECKUP_EXPORT';

// mutations
export const STATISTICS_CONSULTATIONS_SET = 'Statistics/STATISTICS_CONSULTATIONS_SET';
export const STATISTICS_CONSULTATIONS_COMMON_SET = 'Statistics/STATISTICS_CONSULTATIONS_COMMON_SET';
export const STATISTICS_SERVICE_SET = 'Statistics/STATISTICS_SERVICE_SET';
export const STATISTICS_COMMON_SERVICES_SET = 'Statistics/STATISTICS_COMMON_SERVICES_SET';
export const STATISTICS_CLEAR = 'Statistics/STATISTICS_CLEAR';

// getters
export const STATISTICS_CONSULTATIONS_LABELS = 'Statistics/STATISTICS_CONSULTATIONS_LABELS';
export const STATISTICS_CONSULTATIONS_COUNT = 'Statistics/STATISTICS_CONSULTATIONS_COUNT';
export const STATISTICS_ACTIVE_USERS_COUNT = 'Statistics/STATISTICS_ACTIVE_USERS_COUNT';

export const TOTAL_ACTIVE_USERS_COUNT = 'Statistics/TOTAL_ACTIVE_USERS_COUNT';
export const TOTAL_CONSULTATIONS_COUNT = 'Statistics/TOTAL_CONSULTATIONS_COUNT';

export const STATISTICS_SERVICES_LABELS = 'Statistics/STATISTICS_SERVICES_LABELS';
export const STATISTICS_SERVICES_THERAPIST_AVERAGE_TAKE_ON_TIME = 'Statistics/STATISTICS_SERVICES_THERAPIST_AVERAGE_TAKE_ON_TIME';
export const STATISTICS_SERVICES_PEDIATRICIAN_AVERAGE_TAKE_ON_TIME = 'Statistics/STATISTICS_SERVICES_PEDIATRICIAN_AVERAGE_TAKE_ON_TIME';
export const STATISTICS_SERVICES_SPECIALIZED_AVERAGE_TAKE_ON_TIME = 'Statistics/STATISTICS_SERVICES_SPECIALIZED_AVERAGE_TAKE_ON_TIME';

export const TOTAL_DUTY_AVERAGE_TAKE_ON_TIME = 'Statistics/TOTAL_DUTY_AVERAGE_TAKE_ON_TIME';
export const TOTAL_THERAPIST_AVERAGE_TAKE_ON_TIME = 'Statistics/TOTAL_THERAPIST_AVERAGE_TAKE_ON_TIME';
export const TOTAL_PEDIATRICIAN_AVERAGE_TAKE_ON_TIME = 'Statistics/TOTAL_PEDIATRICIAN_AVERAGE_TAKE_ON_TIME';
export const TOTAL_SPECIALIZED_AVERAGE_TAKE_ON_TIME = 'Statistics/TOTAL_SPECIALIZED_AVERAGE_TAKE_ON_TIME';

// Sms

// actions
export const MESSAGES_FETCH = 'Sms/MESSAGES_FETCH';
export const MESSAGES_SEND_MESSAGE = 'Sms/MESSAGES_SEND_MESSAGE';
export const SMS_SENDING = 'Sms/SMS_SENDING';
export const SMS_IMPORT_PHONES = 'Sms/SMS_IMPORT_PHONES';
export const SEND_CHECK_CODE = 'Sms/SEND_CHECK_CODE';
export const SENDED_CODE_CHECK = 'Sms/SENDED_CODE_CHECK';
export const SMS_TEMPLATES_FETCH = 'Sms/SMS_TEMPLATES_FETCH';
export const SMS_TEMPLATE_CREATE = 'Sms/SMS_TEMPLATE_CREATE';
export const SMS_TEMPLATE_UPDATE = 'Sms/SMS_TEMPLATE_UPDATE';
export const SMS_TEMPLATE_DELETE = 'Sms/SMS_TEMPLATE_DELETE';

// mutations
export const MESSAGES_SET = 'Sms/MESSAGES_SET';
export const MESSAGES_ADD = 'Sms/MESSAGES_ADD';
export const MESSAGES_TOTAL_COUNT_SET = 'Sms/MESSAGES_TOTAL_COUNT_SET';

// getters

// Promocodes

// actions
export const PROMOCODE_CREATE = 'Promocodes/PROMOCODE_CREATE';
export const PROMOCODE_UPDATE = 'Promocodes/PROMOCODE_UPDATE';
export const PROMOCODE_FETCH = 'Promocodes/PROMOCODE_FETCH';
export const PROMOCODES_FETCH = 'Promocodes/PROMOCODES_FETCH';
export const PROMOCODE_DELETE = 'Promocodes/PROMOCODE_DELETE';
export const PROMOCODE_EXPORT = 'Promocodes/PROMOCODE_EXPORT';
export const PROMOCODE_GENERATE = 'Promocodes/PROMOCODE_GENERATE';

// mutations
export const PROMOCODES_QUERY_SET = 'Promocodes/PROMOCODES_QUERY_SET';
export const PROMOCODES_FILTER_SET = 'Promocodes/PROMOCODES_FILTER_SET';
export const PROMOCODES_SET = 'Promocodes/PROMOCODES_SET';
export const PROMOCODES_ADD = 'Promocodes/PROMOCODES_ADD';
export const PROMOCODE_REMOVE_BY_ID = 'Promocodes/PROMOCODE_REMOVE_BY_ID';
export const PROMOCODES_IS_LOADING_SET = 'Promocodes/PROMOCODES_IS_LOADING_SET';
export const PROMOCODES_IS_ALL_ITEMS_LOADED_SET = 'Promocodes/PROMOCODES_IS_ALL_ITEMS_LOADED_SET';

// getters

export const PROMOCODES_GET = 'Promocodes/PROMOCODES_GET';
export const PROMOCODES_COUNT_GET = 'Promocodes/PROMOCODES_COUNT_GET';
export const PROMOCODES_FILTER_GET = 'Promocodes/PROMOCODES_FILTER_GET';
export const PROMOCODES_IS_LOADING_GET = 'Promocodes/PROMOCODES_IS_LOADING_GET';
export const PROMOCODES_IS_ALL_ITEMS_LOADED_GET = 'Promocodes/PROMOCODES_IS_ALL_ITEMS_LOADED_GET';

// actions
export const ACCOUNT_USERS_FETCH = 'Account/ACCOUNT_USERS_FETCH';

// mutations
export const ACCOUNT_USERS_SET = 'Account/ACCOUNT_USERS_SET';

// getters

// Users

// actions
export const GET_USERS = 'Users/GET_USERS';
export const GET_USER_BY_ID = 'Users/GET_USER_BY_ID';
export const GET_USER_DEVICE_ID = 'Users/GET_USER_DEVICE_ID';
export const DELETE_USER_DEVICE = 'Users/DELETE_USER_DEVICE';
export const USER_DELETE = 'Users/USER_DELETE';
export const USER_CREATE = 'Users/USER_CREATE';
export const USER_EDIT = 'Users/USER_EDIT';
export const USER_CREATE_FOR_ESIA = 'Users/USER_CREATE_FOR_ESIA';
export const USER_AVATAR_UPLOAD = 'Users/USER_AVATAR_UPLOAD';

// mutations
export const USERS_SET = 'Users/USERS_SET';
export const IS_LOADING_TRUE = 'Users/IS_LOADING_TRUE';
export const IS_LOADING_FALSE = 'Users/IS_LOADING_FALSE';
export const TOGGLE_FETCH_USERS_TRIGGER = 'Users/TOGGLE_FETCH_USERS_TRIGGER';

// getters
export const IS_LOADING_GET = 'Users/IS_LOADING_GET';
export const FETCH_USERS_TRIGGER = 'Users/FETCH_USERS_TRIGGER';

// PolicyList

// actions
export const GET_POLICY_BY_NUMBER = 'PolicyList/GET_POLICY_BY_NUMBER';
export const POLICY_ATTACH_LIST_FETCH = 'PolicyList/POLICY_ATTACH_LIST_FETCH';
export const POLICY_ATTACH = 'PolicyList/POLICY_ATTACH';
export const POLICY_DETACH = 'PolicyList/POLICY_DETACH';
export const POLICY_DETACH_BY_DATE = 'PolicyList/POLICY_DETACH_BY_DATE';
export const POLICY_PROLONGATION_FETCH = 'PolicyList/POLICY_PROLONGATION_FETCH';
export const PUT_POLICY_EDIT = 'PolicyList/PUT_POLICY_EDIT';
export const POLICY_IS_LOADING_BUTTON_GET = 'PolicyList/POLICY_IS_LOADING_BUTTON_GET';

// mutations
export const POLICY_SET = 'PolicyList/POLICY_SET';
export const POLICY_IS_LOADING = 'PolicyList/POLICY_IS_LOADING';
export const TOGGLE_POLICY_SEARCH_TRIGGER = 'PolicyList/TOGGLE_POLICY_SEARCH_TRIGGER';

// getters
export const POLICY_IS_LOADING_GET = 'PolicyList/POLICY_IS_LOADING_GET';
export const POLICY_SEARCH_TRIGGER = 'PolicyList/POLICY_SEARCH_TRIGGER';

// Patients

// actions
export const INSURANCE_COMPANIES_FETCH = 'Patients/INSURANCE_COMPANIES_FETCH';
export const INSURANCE_SUBPROGRAMS_FETCH = 'Patients/INSURANCE_SUBPROGRAMS_FETCH';
export const INSURANCE_PROGRAMMS_FETCH = 'Patients/INSURANCE_PROGRAMMS_FETCH';
export const PATIENTS_FETCH = 'Patients/PATIENTS_FETCH';
export const DOWNLOAD_SUBPROGRAM_DOCUMENTS = 'Patients/DOWNLOAD_SUBPROGRAM_DOCUMENTS';
export const FAMILY_PATIENTS_FETCH = 'Patients/FAMILY_PATIENTS_FETCH';
export const PATIENT_FROM_POLICY_FETCH = 'Patients/PATIENT_FROM_POLICY_FETCH';
export const PATIENTS_DELETED_FETCH = 'Patients/PATIENTS_DELETED_FETCH';
export const PATIENT_FETCH = 'Patients/PATIENT_FETCH';
export const PATIENT_CREATE = 'Patients/PATIENT_CREATE';
export const PATIENT_ONE_TIME_CREATE = 'Patients/PATIENT_ONE_TIME_CREATE';
export const PATIENT_CREATE_WITH_MODE = 'Patients/PATIENT_CREATE_WITH_MODE';
export const PATIENT_UPDATE = 'Patients/PATIENT_UPDATE';
export const PATIENT_DELETE = 'Patients/PATIENT_DELETE';
export const PATIENT_ADD_DOP = 'Patients/PATIENT_ADD_DOP';
export const PATIENT_DELETE_DOP = 'Patients/PATIENT_DELETE_DOP';
export const PATIENT_DEPERSONALIZATE = 'Patients/PATIENT_DEPERSONALIZATE';
export const PATIENT_TRANSFER = 'Patients/PATIENT_TRANSFER';
export const PATIENT_REESTABLISH = 'Patients/PATIENT_REESTABLISH';
export const PATIENT_POLICY_GET = 'Patients/PATIENT_POLICY_GET';
export const PATIENT_POLICY_CREATE = 'Patients/PATIENT_POLICY_CREATE';
export const PATIENT_POLICY_UPDATE = 'Patients/PATIENT_POLICY_UPDATE';
export const PATIENT_POLICY_ACTIVATE = 'Patients/PATIENT_POLICY_ACTIVATE';
export const PATIENT_POLICY_DELETE = 'Patients/PATIENT_POLICY_DELETE';
export const PATIENT_POLICY_DETACH = 'Patients/PATIENT_POLICY_DETACH';
export const PATIENT_POLICY_DETACH_DOP = 'Patients/PATIENT_POLICY_DETACH_DOP';
export const PATIENT_CONSULTATIONS_FETCH = 'Patients/PATIENT_CONSULTATIONS_FETCH';
export const PATIENT_APPEALS_FETCH = 'Patients/PATIENT_APPEALS_FETCH';
export const PATIENT_APPEALS_NEW_FETCH = 'Patients/PATIENT_APPEALS_NEW_FETCH';
export const PATIENT_APPEALS_RESPONSIBLES_FETCH = 'Patients/PATIENT_APPEALS_RESPONSIBLES_FETCH';
export const PATIENT_SERVICES_FETCH = 'Patients/PATIENT_SERVICES_FETCH';
export const LEGAL_LIST_FETCH = 'Patients/LEGAL_LIST_FETCH';
export const PATIENT_LIMITS_FETCH = 'Patients/PATIENT_LIMITS_FETCH';
export const PATIENT_LIMITS_SAVE = 'Patients/PATIENT_LIMITS_SAVE';
export const PATIENT_LIMITS_EXPORT = 'Patients/PATIENT_LIMITS_EXPORT';
export const PATIENT_ALL_LIMITS_EXPORT = 'Patients/PATIENT_ALL_LIMITS_EXPORT';
export const POLICY_HISTORY_FETCH = 'Patients/POLICY_HISTORY_FETCH';
export const PATIENT_HISTORY_FETCH = 'Patients/PATIENT_HISTORY_FETCH';
export const PATIENT_AUTH_TOKEN_FETCH = 'Patients/PATIENT_AUTH_TOKEN_FETCH';
export const PATIENT_PHONE_EXIST_CHECK = 'Patients/PATIENT_PHONE_EXIST_CHECK';
export const PATIENT_LIMITS_BY_PERSON_ID_FETCH = 'Patients/PATIENT_LIMITS_BY_PERSON_ID_FETCH';
export const POLICY_RELATE_ACTIVATE = 'Patients/POLICY_RELATE_ACTIVATE';

// mutations
export const PATIENTS_SET = 'Patients/PATIENTS_SET';
export const PATIENTS_DELETED_SET = 'Patients/PATIENTS_DELETED_SET';
export const PATIENTS_ADD = 'Patients/PATIENTS_ADD';
export const PATIENTS_DELETED_ADD = 'Patients/PATIENTS_DELETED_ADD';
export const PATIENT_CONSULTATIONS_SET = 'Patients/PATIENT_CONSULTATIONS_SET';
export const PATIENT_CONSULTATIONS_ADD = 'Patients/PATIENT_CONSULTATIONS_ADD';
export const PATIENT_DETAIL_CONSULATIONS_IS_LOADING_SET = 'Patients/PATIENT_DETAIL_CONSULATIONS_IS_LOADING_SET';
export const PATIENT_DETAIL_CONSULATIONS_IS_ALL_ITEMS_LOADED_SET = 'Patients/PATIENT_DETAIL_CONSULATIONS_IS_ALL_ITEMS_LOADED_SET';
export const PATIENT_DETAIL_ACTIVE_CONSULATION_SET = 'Patients/PATIENT_DETAIL_ACTIVE_CONSULATION_SET';
export const PATIENT_DETAIL_CONSULATIONS_RESET = 'Patients/PATIENT_DETAIL_CONSULATIONS_RESET';
export const PATIENT_APPEALS_SET = 'Patients/PATIENT_APPEALS_SET';
export const PATIENT_APPEALS_ADD = 'Patients/PATIENT_APPEALS_ADD';
export const PATIENT_APPEALS_RESPONSIBLES_SET = 'Patients/PATIENT_APPEALS_RESPONSIBLES_SET';
export const PATIENT_DETAIL_APPEALS_IS_LOADING_SET = 'Patients/PATIENT_DETAIL_APPEALS_IS_LOADING_SET';
export const PATIENT_DETAIL_APPEALS_IS_ALL_ITEMS_LOADED_SET = 'Patients/PATIENT_DETAIL_APPEALS_IS_ALL_ITEMS_LOADED_SET';
export const PATIENT_DETAIL_ACTIVE_APPEAL_SET = 'Patients/PATIENT_DETAIL_ACTIVE_APPEAL_SET';
export const PATIENT_DETAIL_APPEALS_RESET = 'Patients/PATIENT_DETAIL_APPEALS_RESET';
export const PATIENT_POLICY_SET = 'Patients/PATIENT_POLICY_SET';
export const PATIENT_ACTIVE_POLICY_ID_SET = 'Patients/PATIENT_ACTIVE_POLICY_ID_SET';
export const INSURANCE_COMPANIES_SET = 'Patients/INSURANCE_COMPANIES_SET';
export const INSURANCE_SUBPROGRAMS_SET = 'Patients/INSURANCE_SUBPROGRAMS_SET';
export const PATIENT_SERVICES_SET = 'Patients/PATIENT_SERVICES_SET';
export const PATIENT_SERVICES_DEL = 'Patients/PATIENT_SERVICES_DEL';
export const PATIENT_POLICY_ID_SET = 'Patients/PATIENT_POLICY_ID_SET';

// getters
export const GET_PATIENT_SERVICES = 'Patients/GET_PATIENT_SERVICES';
export const GET_PATIENT_POLICY_ID = 'Patients/GET_PATIENT_POLICY_ID';

// Indemnity

// actions

export const INDEMNITY_LETTER_NEW_GET = 'Indemnity/INDEMNITY_LETTER_NEW_GET';
export const INDEMNITY_LETTER_SAVE = 'Indemnity/INDEMNITY_LETTER_SAVE';
export const INDEMNITY_LETTERS_SAVE = 'Indemnity/INDEMNITY_LETTERS_SAVE';
export const INDEMNITY_LETTER_CREATE_DUBLES = 'Indemnity/INDEMNITY_LETTER_CREATE_DUBLES';
export const INDEMNITY_LETTER_FORM = 'Indemnity/INDEMNITY_LETTER_FORM';
export const INDEMNITY_ANNULMENT_LETTER_FORM = 'Indemnity/INDEMNITY_ANNULMENT_LETTER_FORM';
export const INDEMNITY_LETTER_PRINT = 'Indemnity/INDEMNITY_LETTER_PRINT';
export const INDEMNITY_LETTER_DOWNLOAD = 'Indemnity/INDEMNITY_LETTER_DOWNLOAD';
export const INDEMNITY_LETTER_MAKE_PDF = 'Indemnity/INDEMNITY_LETTER_MAKE_PDF';
export const INDEMNITY_LETTER_SEND = 'Indemnity/INDEMNITY_LETTER_SEND';
export const INDEMNITY_LETTER_DELETE = 'Indemnity/INDEMNITY_LETTER_DELETE';
export const INDEMNITY_LETTER_ANNULMENT = 'Indemnity/INDEMNITY_LETTER_ANNULMENT';
export const GET_LETTER_COLUMN_WIDTH = 'Indemnity/GET_LETTER_COLUMN_WIDTH';
export const INDEMNITY_LETTER_NUMBERS_UPDATE = 'Indemnity/INDEMNITY_LETTER_NUMBERS_UPDATE';
export const INDEMNITY_EDIT_TEMPLATE_SAVE = 'Indemnity/INDEMNITY_EDIT_TEMPLATE_SAVE';
export const INDEMNITY_EDIT_TEMPLATE_UPDATE = 'Indemnity/INDEMNITY_EDIT_TEMPLATE_UPDATE';
export const INDEMNITY_EDIT_TEMPLATE_NUMBERS_UPDATE = 'Indemnity/INDEMNITY_EDIT_TEMPLATE_NUMBERS_UPDATE';
export const INDEMNITY_GET_LETTER_BY_ID = 'Indemnity/INDEMNITY_GET_LETTER_BY_ID';
export const INDEMNITY_CREATE_EDIT_LETTER = 'Indemnity/INDEMNITY_CREATE_EDIT_LETTER';
export const INDEMNITY_LIST_NAMES_FETCH = 'Indemnity/INDEMNITY_LIST_NAMES_FETCH';
export const INDEMNITY_LIST_FETCH = 'Indemnity/INDEMNITY_LIST_FETCH';
export const INDEMNITY_TEMPLATES_FETCH = 'Indemnity/INDEMNITY_TEMPLATES_FETCH';
export const INDEMNITY_TEMPLATE_DELETE = 'Indemnity/INDEMNITY_TEMPLATE_DELETE';
export const NOSOLOGICAL_SERVICE_FETCH = 'Indemnity/NOSOLOGICAL_SERVICE_FETCH';
export const PRICE_LIST_FETCH = 'Indemnity/PRICE_LIST_FETCH';
export const CLINIC_PRICE_LIST_FETCH = 'Indemnity/CLINIC_PRICE_LIST_FETCH';
export const PRICE_TEMPLATE_CREATE = 'Indemnity/PRICE_TEMPLATE_CREATE';
export const PRICE_TEMPLATE_UPDATE = 'Indemnity/PRICE_TEMPLATE_UPDATE';
export const PRICE_TEMPLATE_DELETE = 'Indemnity/PRICE_TEMPLATE_DELETE';
export const PRICE_CLINICS_FETCH = 'Indemnity/PRICE_CLINICS_FETCH';
export const PRICE_TEMPLATES_LIST_FETCH = 'Indemnity/PRICE_TEMPLATES_LIST_FETCH';
export const PRICE_CLINIC_TEMPLATES_LIST_FETCH = 'Indemnity/PRICE_CLINIC_TEMPLATES_LIST_FETCH';
export const PRICE_CLINIC_TEMPLATE_CREATE = 'Indemnity/PRICE_CLINIC_TEMPLATE_CREATE';
export const PRICE_CLINIC_TEMPLATE_UPDATE = 'Indemnity/PRICE_CLINIC_TEMPLATE_UPDATE';
export const PRICE_CLINIC_TEMPLATE_DELETE = 'Indemnity/PRICE_CLINIC_TEMPLATE_DELETE';

// mutations
export const INDEMNITY_LETTER_SET = 'Indemnity/INDEMNITY_LETTER_SET';
export const INDEMNITY_TEMPLATE_SET = 'Indemnity/INDEMNITY_TEMPLATE_SET';
export const INDEMNITY_TEMPLATE_REMOVE_BY_ID = 'Indemnity/INDEMNITY_TEMPLATE_REMOVE_BY_ID';
export const INDEMNITY_IS_LETTER_SAVING_SET = 'Indemnity/INDEMNITY_IS_LETTER_SAVING_SET';
export const INDEMNITY_IS_LETTER_SENDING_SET = 'Indemnity/INDEMNITY_IS_LETTER_SENDING_SET';
export const INDEMNITY_LETTERS_SET = 'Indemnity/INDEMNITY_LETTERS_SET';
export const INDEMNITY_CHECKVALIDATION = 'Indemnity/INDEMNITY_CHECKVALIDATION';
export const INDEMNITY_IS_EDIT_TEMPLATE_TOGLE = 'Indemnity/INDEMNITY_IS_EDIT_TEMPLATE_TOGLE';
export const INDEMNITY_NEW_TEMPLATE_VALIDATION = 'Indemnity/INDEMNITY_NEW_TEMPLATE_VALIDATION';
export const INDEMNITY_ANALYSIS_LETTERS_SET = 'Indemnity/INDEMNITY_ANALYSIS_LETTERS_SET';
export const INDEMNITY_LETTERS_LOADING_SET = 'Indemnity/INDEMNITY_LETTERS_LOADING_SET';
export const INDEMNITY_IS_LETTER_EDITED_SET = 'Indemnity/INDEMNITY_IS_LETTER_EDITED_SET';
export const INDEMNITY_EDIT_TEMPLATE_SET = 'Indemnity/INDEMNITY_EDIT_TEMPLATE_SET';
export const INDEMNITY_IS_EDIT_TEMPLATE_SAVING_SET = 'Indemnity/INDEMNITY_IS_EDIT_TEMPLATE_SAVING_SET';
export const INDEMNITY_PERSON_ID_SET = 'Indemnity/INDEMNITY_PERSON_ID_SET';
export const INDEMNITY_HISTORY_WITHOUT_POLICY_SET = 'Indemnity/INDEMNITY_HISTORY_WITHOUT_POLICY_SET';
export const INDEMNITY_ANNULMENT_REASON_SET = 'Indemnity/INDEMNITY_ANNULMENT_REASON_SET';

// getters
export const GET_TEMPLATES = 'Indemnity/GET_TEMPLATES';

// TODO:
// export const INDEMNITY_LETTERS_GET_BY_POLICY_ID = 'Indemnity/INDEMNITY_LETTERS_GET_BY_POLICY_ID';
export const INDEMNITY_LETTERS_GET_BY_CLINIC = 'Indemnity/INDEMNITY_LETTERS_GET_BY_CLINIC';

// getters

// Specializations

// actions
export const SPECIALIZATIONS_FETCH = 'Specializations/SPECIALIZATIONS_FETCH';
export const SPECIALIZATION_FETCH_BY_ID = 'Specializations/SPECIALIZATION_FETCH_BY_ID';
export const SPECIALIZATION_CREATE = 'Specializations/SPECIALIZATION_CREATE';
export const SPECIALIZATION_UPDATE = 'Specializations/SPECIALIZATION_UPDATE';
export const SPECIALIZATION_RESTORE = 'Specializations/SPECIALIZATION_RESTORE';
export const SPECIALIZATION_DELETE = 'Specializations/SPECIALIZATION_DELETE';

// mutations
export const SPECIALIZATIONS_SET = 'Specializations/SPECIALIZATIONS_SET';

// Insurance

// actions

export const COMPANIES_FETCH = 'Insurance/COMPANIES_FETCH';
export const COMPANY_FETCH = 'Insurance/COMPANY_FETCH';
export const COMPANY_CREATE = 'Insurance/COMPANY_CREATE';
export const COMPANY_UPDATE = 'Insurance/COMPANY_UPDATE';
export const COMPANY_HIDE_OR_SHOW = 'Insurance/COMPANY_HIDE_OR_SHOW';
export const SUBPROGRAMS_FOR_RELATE_FETCH = 'Insurance/SUBPROGRAMS_FOR_RELATE_FETCH';

export const PROGRAM_FETCH = 'Insurance/PROGRAM_FETCH';
export const PROGRAM_CREATE = 'Insurance/PROGRAM_CREATE';
export const PROGRAM_UPDATE = 'Insurance/PROGRAM_UPDATE';
export const PROGRAM_HIDE_OR_SHOW = 'Insurance/PROGRAM_HIDE_OR_SHOW';
export const PROGRAMS_FETCH = 'Insurance/PROGRAMS_FETCH';
export const SUBPROGRAMS_FETCH = 'Insurance/SUBPROGRAMS_FETCH';
export const INSURANCE_SUBPROGRAM_UPLOAD_FILE = 'Insurance/INSURANCE_SUBPROGRAM_UPLOAD_FILE';

export const SUBPROGRAM_FETCH = 'Insurance/SUBPROGRAM_FETCH';
export const SUBPROGRAM_CREATE = 'Insurance/SUBPROGRAM_CREATE';
export const SUBPROGRAM_UPDATE = 'Insurance/SUBPROGRAM_UPDATE';
export const SUBPROGRAM_HIDE_OR_SHOW = 'Insurance/SUBPROGRAM_HIDE_OR_SHOW';

export const FILENAME_BY_ID_FETCH = 'Insurance/FILENAME_BY_ID_FETCH';
export const INSURANCE_POLICY_EXPORT = 'Insurance/INSURANCE_POLICY_EXPORT';
export const CALC_USER_COUNT_TODATE = 'Insurance/CALC_USER_COUNT_TODATE';
export const SUBPROGRAM_INFO_FETCH = 'Insurance/SUBPROGRAM_INFO_FETCH';
export const FETCH_MONITORING_QUESTIONNARIES = 'Insurance/FETCH_MONITORING_QUESTIONNARIES';
export const IMPORT_LIST_FETCH = 'Insurance/IMPORT_LIST_FETCH';
export const IMPORT_FILE_FETCH = 'Insurance/IMPORT_FILE_FETCH';

// mutations

export const COMPANIES_SET = 'Insurance/COMPANIES_SET';
export const COMPANIES_LOADING_SET = 'Insurance/COMPANIES_LOADING_SET';
export const COMPANY_SET = 'Insurance/COMPANY_SET';
export const COMPANY_LOADING_SET = 'Insurance/COMPANY_LOADING_SET';

export const PROGRAMS_SET = 'Insurance/PROGRAMS_SET';
export const PROGRAMS_LOADING_SET = 'Insurance/PROGRAMS_LOADING_SET';
export const PROGRAM_SET = 'Insurance/PROGRAM_SET';
export const PROGRAM_LOADING_SET = 'Insurance/PROGRAM_LOADING_SET';

export const SUBPROGRAMS_SET = 'Insurance/SUBPROGRAMS_SET';
export const SUBPROGRAMS_LOADING_SET = 'Insurance/SUBPROGRAM_LOADING_SET';
export const SUBPROGRAM_SET = 'Insurance/SUBPROGRAM_SET';
export const SUBPROGRAM_LOADING_SET = 'Insurance/SUBPROGRAM_LOADING_SET';
export const SUBPROGRAM_DOC_DATA_SET = 'Insurance/SUBPROGRAM_DOC_DATA_SET';
export const SUBPROGRAM_INFO_SET = 'Insurance/SUBPROGRAM_INFO_SET';
export const IS_LOADING_CALC = 'Insurance/IS_LOADING_CALC';

// getters

export const IS_LOADING_CALC_GET = 'Insurance/IS_LOADING_CALC_GET';

// TabPanel

// actions

// mutations

export const TAB_PANEL_ITEMS_FETCH_FROM_LOCALSTORAGE = 'TabPanel/TAB_PANEL_ITEMS_FETCH_FROM_LOCALSTORAGE';
export const TAB_PANEL_ITEMS_SAVE_TO_LOCALSTORAGE = 'TabPanel/TAB_PANEL_ITEMS_SAVE_TO_LOCALSTORAGE';
export const TAB_PANEL_ITEM_ADD = 'TabPanel/TAB_PANEL_ITEM_ADD';
export const TAB_PANEL_ITEM_REMOVE_BY_ID = 'TabPanel/TAB_PANEL_ITEM_REMOVE_BY_ID';
export const TAB_PANEL_ITEM_UPDATE_BY_ID = 'TabPanel/TAB_PANEL_ITEM_UPDATE_BY_ID';

// OTHER

// actions

export const CONFIGURATION_TEMPLATE_BY_CLINICS_FETCH = 'Other/CONFIGURATION_TEMPLATE_BY_CLINICS_FETCH';
export const CONFIGURATION_TEMPLATE_CREATE = 'Other/CONFIGURATION_TEMPLATE_CREATE';

// Roles

// actions
export const ROLES_FETCH = 'Roles/ROLES_FETCH';
export const ROLE_CREATE = 'Roles/ROLE_CREATE';
export const ROLE_UPDATE = 'Roles/ROLE_UPDATE';

// mutations
export const ROLES_SET = 'Roles/ROLES_SET';
export const ROLES_LOADING_SET = 'Roles/ROLES_LOADING_SET';

// Pages

// actions
export const PAGES_FETCH = 'Pages/PAGES_FETCH';
export const PAGE_CREATE = 'Pages/PAGE_CREATE';
export const PAGE_UPDATE = 'Pages/PAGE_UPDATE';

// mutations
export const PAGES_SET = 'Pages/PAGES_SET';
export const PAGES_LOADING_SET = 'Pages/PAGES_LOADING_SET';

// Queues
// actions
export const QUEUE_FETCH = 'Queues/QUEUE_FETCH';
export const QUEUES_FETCH = 'Queues/QUEUES_FETCH';
export const QUEUE_CREATE = 'Queues/QUEUE_CREATE';
export const QUEUE_UPDATE = 'Queues/U_TAG_UPDATE';
export const QUEUE_DELETE = 'Queues/QUEUE_DELETE';
export const QUEUES_TAGS_FETCH = 'Queues/QUEUES_TAGS_FETCH';
export const QUEUES_TAG_CREATE = 'Queues/QUEUES_TAG_CREATE';
export const QUEUES_TAG_UPDATE = 'Queues/QUEUES_TAG_UPDATE';
export const QUEUES_TAG_DELETE = 'Queues/QUEUES_TAG_DELETE';
export const QUEUE_CONSULTATIONS_FETCH = 'Queues/QUEUE_CONSULTATIONS_FETCH';
export const QUEUES_CONSULTATIONS_BY_PATIENT_FETCH = 'Queues/QUEUE_CONSULTATIONS_FETCHQUEUES_CONSULTATIONS_BY_PATIENT_FETCH';
export const QUEUE_CONSULTATIONS_CANCEL = 'Queues/QUEUE_CONSULTATIONS_CANCEL';

// mutations
export const QUEUES_SET = 'Queues/QUEUES_SET';
export const QUEUES_TAGS_SET = 'Queues/QUEUES_TAGS_SET';

// BannerManagment

// mutations
export const TOGGLE_BANNERS_TRIGGER = 'BannerManagement/TOGGLE_BANNERS_TRIGGER';

// getters
export const BANNERS_TRIGGER = 'BannerManagement/BANNERS_TRIGGER';

// Modals

export const OPEN_MODAL = 'Modals/OPEN_MODAL';
export const CLOSE_MODAL = 'Modals/CLOSE_MODAL';

// ConsultationReview

// actions
export const CONSULTATION_REVIEW_LIST_FETCH = 'ConsultationFeedback/CONSULTATION_REVIEW_LIST_FETCH';
export const GET_CONSULTATION_REVIEW_FOR_ID = 'ConsultationFeedback/GET_CONSULTATION_REVIEW_FOR_ID';
export const REASONS_REVIEW_REJECTION_FETCH = 'ConsultationFeedback/REASONS_REVIEW_REJECTION_FETCH';
export const REVIEW_MODERATION = 'ConsultationFeedback/REVIEW_MODERATION';
export const REVIEW_REJECT = 'ConsultationFeedback/REVIEW_REJECT';
// getters
export const CONSULTATION_REVIEW_LIST_GET = 'ConsultationFeedback/CONSULTATION_REVIEW_LIST_GET';
// mutations
export const REVIEW_ITEMS_SET = 'ConsultationFeedback/REVIEW_ITEMS_SET';
export const CURRENT_REVIEW_SET = 'ConsultationFeedback/CURRENT_REVIEW_SET';

// Stories

// Actions
export const GET_STORY_IMAGE = 'Stories/GET_STORY_IMAGE';
