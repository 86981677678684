import { APP_HOST } from '@/app.config';

export default class ChatApi {
  constructor($axios) {
    this.api = $axios.instance;
  }

  async markAsRead(chatId) {
    return this.api.post(`${APP_HOST}v3/chat-appeal/${chatId}/markAsRead`);
  }

  async getUnassigned() {
    const { data: { data } } = await this.api.get(`${APP_HOST}v3/chat-appeal/unassigned`);
    return data;
  }

  async putUnassign(id) {
    return this.api.put(`${APP_HOST}v3/chat-appeal/${id}/unassign`);
  }

  async getForRole(id) {
    const { data: { data } } = await this.api.get(`${APP_HOST}v3/chat-appeal/for-role/${id}`);
    return data;
  }

  async getInWork() {
    const { data: { data } } = await this.api.get(`${APP_HOST}v3/chat-appeal`);
    return data;
  }

  async takeOn(id) {
    return this.api.put(`${APP_HOST}v3/chat-appeal/${id}/takeOn`);
  }

  async close(id) {
    return this.api.put(`${APP_HOST}v3/chat-appeal/${id}/close`);
  }

  async move(params) {
    return this.api.put(`${APP_HOST}v3/chat-appeal/${params.chatAppeallId}/move?targetRoleId=${params.targetRoleId}`, { comment: params.comment });
  }

  async getMessage(params) {
    const { data: { data } } = await this.api.get(`${APP_HOST}v3/chat-appeal/${params.chatAppealId}/chat/messages`);
    return data;
  }

  async editMessage(params) {
    const { data: { data } } = await this.api.put(`${APP_HOST}v3/chat-appeal/${params.chatAppealId}/chat/messages/edit`, {
      messageId: params.messageId,
      text: params.text,
    });
    return data;
  }

  async getHistory(id) {
    const { data: { data } } = await this.api.get(`${APP_HOST}v3/chat-appeal/${id}/history`);
    return data;
  }

  async getOneHistoryMessage(params) {
    const { data: { data } } = await this.api.get(`${APP_HOST}v3/chat-appeal/${params.chatAppealId}/${params.patientId}/messages`);
    return data;
  }

  async getFile(params) {
    const { data } = await this.api.get(`${APP_HOST}v3/chat-appeal/${params.chatAppealId}/${params.fileName}`, { responseType: 'blob' });
    return data;
  }

  async sendSms(params) {
    const data = await this.api.post(`${APP_HOST}v3/chat-appeal/${params.id}/chat/send`, params.params);
    return data;
  }
}
