<template>
  <div class="bg-white border rounded">
    <div class="p-3">
      <div class="d-flex">
        <div class="w-50 pr-2">
          <div class="d-flex mb-1">
            <div class="font-weight-bold">
              ФИО
            </div>
            <div class="ml-2 text-break">
              {{ patientFullname }}
            </div>
          </div>
          <div class="d-flex mb-1">
            <div class="font-weight-bold">
              Статус
            </div>
            <div
              class="ml-2 text-break"
            >
              {{ getValue(reservation.status) }}
            </div>
          </div>
          <div class="d-flex mb-1">
            <div class="font-weight-bold">
              Телефон
            </div>
            <div
              class="ml-2 text-break"
            >
              {{ reservation.patient.phoneNumber || '-' }}
            </div>
          </div>
          <div class="d-flex mb-1">
            <div class="font-weight-bold">
              С оплатой
            </div>
            <div class="ml-2">
              {{ reservation.isPaymentRequired ? 'да' : 'нет' }}
            </div>
          </div>
          <div class="d-flex mb-1">
            <div class="font-weight-bold">
              ID пациента
            </div>
            <div
              class="ml-2 text-break"
            >
              {{ reservation.patient.id || '-' }}
            </div>
          </div>
          <div
            v-if="reservation.cancelReason"
            class="d-flex mb-1"
          >
            <div class="font-weight-bold">
              Причина отмены
            </div>
            <div
              class="ml-2 text-break"
            >
              {{ reservation.cancelReason }}
            </div>
          </div>
        </div>
        <div class="w-75">
          <div class="d-flex flex-wrap">
            <div class="font-weight-bold mb-1 mr-2">
              Клиника
            </div>
            <div
              class="text-break"
            >
              {{ reservation.doctor.clinic.title }}
            </div>
          </div>
          <div class="d-flex mb-1">
            <div class="font-weight-bold">
              ФИО врача
            </div>
            <div class="ml-2 text-break">
              {{ doctorFullname }}
            </div>
          </div>
          <div class="d-flex mb-1">
            <div class="font-weight-bold">
              Телефон врача
            </div>
            <a
              v-if="doctorPhoneNumber"
              :href="`tel:${doctorPhoneNumber}`"
              class="ml-2 text-break"
            >
              {{ doctorPhoneNumber }}
            </a>
            <div
              v-else
              class="ml-2 text-break"
            >
              -
            </div>
          </div>
          <div class="d-flex mb-1">
            <div class="font-weight-bold">
              Специальность врача
            </div>
            <div class="ml-2 text-break">
              {{ doctorSpecialization }}
            </div>
          </div>
          <div class="my-divider" />
          <div class="d-flex">
            <div class="w-50">
              <div class="d-flex">
                <div class="font-weight-bold">
                  Начало приема
                </div>
                <div class="ml-2 text-break">
                  {{ reservation.slot.startDate ? parseData(reservation.slot.startDate) : '—' }}
                </div>
              </div>
            </div>
            <div class="w-50">
              <div
                v-if="reservation.closeDate"
                class="d-flex mb-1"
              >
                <div class="font-weight-bold">
                  Закрыто
                </div>
                <div class="ml-2 text-break">
                  {{ parseData(reservation.closeDate) }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ml-3">
          <div class="d-flex flex-column">
            <b-button
              v-b-tooltip.hover.left
              variant="success"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="flex-shrink-0 mt-2 ml-auto p-1"
              title="История изменений"
              @click="openReservationHistoryModal"
            >
              <b-icon icon="book" />
            </b-button>
            <b-button
              v-if="reservation.status === 'Booked'"
              v-b-tooltip.hover.left
              variant="danger"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="mt-2 ml-auto p-1"
              title="Удаление бронирования"
              @click="openCancelModal"
            >
              <b-icon icon="trash" />
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { utc0ToDateWithUserTimezone } from '@/helpers/utils';
import { parseISO, format } from '@evd3v/date-fns';

import { mixinRoles } from '@/mixins';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

import { RESERVATION_STATUSES } from '@/helpers/consts';

export default {
  name: 'ReservationInfo',

  mixins: [mixinRoles],
  props: {
    reservation: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isInfoShow: false,
      RESERVATION_STATUSES,
      statuses: RESERVATION_STATUSES,
    };
  },
  computed: {
    doctorSpecialization() {
      return this.reservation.doctor.specialization.title;
    },
    patientFullname() {
      if (this.reservation.targetPatient?.lastName && this.reservation.targetPatient?.firstName) return this.targetPatientFullname;
      const { middleName, firstName, lastName } = this.reservation.patient;

      return `${lastName || ''} ${firstName || ''} ${middleName || ''}`;
    },
    targetPatientFullname() {
      const { middleName, firstName, lastName } = this.reservation.targetPatient;

      return `${lastName || ''} ${firstName || ''} ${middleName || ''}`;
    },
    doctorFullname() {
      try {
        const { middleName, firstName, lastName } = this.reservation.doctor;

        return `${lastName || ''} ${firstName || ''} ${middleName || ''}`;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    doctorPhoneNumber() {
      try {
        return this.reservation.doctor.phoneNumber;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  },
  methods: {
    checkFeature(key) {
      return this.checkFeatureAccess({ name: key?.name, url: key?.url });
    },
    openReservationHistoryModal() {
      uiService.showModal(MODALS.RESERVATION_HISTORY_MODAL, {
        name: 'ReservationHistoryModal',
        props: {
          reservationId: this.reservation.id,
        },
      });
    },
    parseData(ISODate) {
      if (!ISODate) {
        return null;
      }

      return format(parseISO(ISODate), 'dd.MM.yyyy HH:mm');
    },
    formatDateWithTimeZone(date) {
      const newDate = utc0ToDateWithUserTimezone(date);
      return format(new Date(newDate), 'dd.MM.yyyy HH:mm');
    },
    openCancelModal() {
      uiService.showModal(MODALS.RESERVATION_DELETE_MODAL, {
        name: 'ReservationDeletingModal',
        props: {
          reservation: this.reservation,
        },
      });
    },
    getValue(value) {
      const found = this.statuses.find((elem) => elem.value === value);
      return found ? found.title : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.my-divider {
  display: block;
  width: 100%;
  height: 1px;
  background-color: rgba(136, 136, 136, 0.5);
  margin: 10px 0;

  &_vertical {
    width: 1px;
    height: 50px;
    margin: 0 10px;
  }
}
</style>
