<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="md"
    :title="'Перевод чата'"
    hide-footer
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="policy-delete">
      <custom-scrollbar>
        <div
          class="mt-2 flex-column items-center"
        >
          <b-form-group
            label="Комментарий"
            :invalid-feedback="errorsValidation.comment[0]"
          >
            <b-form-textarea
              v-model="$v.comment.$model"
              :state="$v.$anyError ? !$v.comment.$error : null"
              placeholder="Введите текст"
              rows="3"
              size="sm"
            />
          </b-form-group>
          <div class="crm-wrapper-buttons">
            <b-button
              variant="outline-primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              @click="onClose"
            >
              Отменить
            </b-button>

            <b-button
              variant="danger"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="ml-3"
              @click="onClickDelete"
            >
              Перевести
            </b-button>
          </div>
        </div>
      </custom-scrollbar>
    </div>
  </b-modal>
</template>

<script>
import Bus from '@/eventBus';
import { required } from 'vuelidate/lib/validators';
import { showValidationErrorMessage } from '@/helpers/messages';

export default {
  name: 'ChatMoveAppealModal',
  data() {
    return {
      isOpen: true,
      comment: null,
      isDeleting: false,
    };
  },
  validations() {
    return {
      comment: { required },
    };
  },
  computed: {
    errorsValidation() {
      const errors = {};
      errors.comment = [];
      if (!this.$v.comment.required) {
        errors.comment.push('Поле "комментарий" обязательно для заполнения');
      }
      return errors;
    },
  },
  methods: {
    onClickClose() {
      this.onClose();
    },
    async onClickDelete() {
      this.$v.$touch();
      if (this.$v.$error) {
        showValidationErrorMessage();
        return;
      }
      Bus.$emit('chat-appeal:move', this.comment);
      this.onClose();
    },
    onClose() {
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-wrapper-buttons {
  margin-top: 35px;
}
.description {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}
</style>
