import * as SignalR from '@microsoft/signalr';
import * as Emitter from 'event-emitter';
import { APP_HUB_SOCKET_URL } from '@/app.config';

const allOff = require('event-emitter/all-off');

const MESSAGE_TYPE = 'message';
class HubClient {
  constructor() {
    this.hub = null;
    this.token = null;
    this.emitter = new Emitter();
  }

  async init(token) {
    if (!token) throw new Error('Token is undefined!');

    if (this.hub) {
      console.log('already initialized');
      return;
    }

    this.hub = new SignalR.HubConnectionBuilder()
      .withUrl(APP_HUB_SOCKET_URL, {
        accessTokenFactory() {
          return token;
        },
        skipNegotiation: true,
        transport: SignalR.HttpTransportType.WebSockets,
      })
      .configureLogging(SignalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    this.hub.on(MESSAGE_TYPE, this.onMessage.bind(this));

    await this.hub.start();
  }

  onMessage(message) {
    const { type, data } = message;
    this.emitter.emit(type, data);
  }

  on(type, cb) {
    this.emitter.on(type, cb);
  }

  off(type, cb) {
    this.emitter.off(type, cb);
  }

  async stop() {
    await this.hub.stop();
    allOff(this.emitter);
    this.hub = null;
  }
}

const HubClientService = new HubClient();

export default HubClientService;
