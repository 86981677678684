import { z } from 'zod';

// eslint-disable-next-line import/prefer-default-export
export const checkNotEmptyStringSchema = (fields) => {
  const schemaDefinition = {};
  const errorMsg = 'Поле не может быть пустым';
  fields.forEach((field) => {
    schemaDefinition[field] = z.string().min(1, errorMsg);
  });

  return z.object(schemaDefinition);
};
