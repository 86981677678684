export const dynamicValidateField = (options) => {
  const {
    errors, schema, field, value,
  } = options;

  const partialSchema = schema.pick({ [field]: true });
  const result = partialSchema.safeParse({ [field]: value });

  if (!result.success) {
    return { ...errors, [field]: result.error.errors.map((e) => e.message) };
  }
  delete errors[field];
  return errors;
};

export const validateSchema = (schema, fields) => {
  const result = schema.safeParse(fields);

  if (!result.success) {
    return {
      success: false,
      errors: Object.fromEntries(result.error.errors.map((error) => [error.path[0], error.message])),
    };
  }
  return {
    success: true,
    errors: [],
  };
};
