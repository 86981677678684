import mutations from './mutations';
import getters from './getters';
import actions from './actions';

export default {
  namespaced: true,
  state: {
    selectedDoctor: null,
    selectedDate: null,
    chatAppealId: null,

    isScheduleLoading: false,
    schedule: [],
    liveSchedule: [],
    scheduleTrigger: false,

    isScheduleTemplatesLoading: false,
    scheduleTemplates: [],

    isScheduleLiveTemplate: false,

    scheduleQuery: '',
    scheduleSpecializations: [],
    schedulePageDate: null,
  },
  actions,
  getters,
  mutations,
};
