<template>
  <div class="w-100 pt-4 containerLabsPage">
    <custom-scrollbar>
      <b-container
        fluid
      >
        <div class="h3 mb-4">
          Партнерские услуги
        </div>
        <div class="d-flex justify-content-between">
          <div class="d-flex checkBoxParams align-items-center">
            <b-form-checkbox
              v-model="isActive"
              size="sm"
              :disabled="isAllServices"
              @input="fetchPartnerServices()"
            >
              <span class="text-secondary">
                Активирована
              </span>
            </b-form-checkbox>
            <b-form-checkbox
              v-model="isMerged"
              size="sm"
              :disabled="isAllServices"
              @input="fetchPartnerServices()"
            >
              <span class="text-secondary">
                Привязана
              </span>
            </b-form-checkbox>
            <b-form-checkbox
              v-model="isMappable"
              size="sm"
              :disabled="isAllServices"
              @input="fetchPartnerServices()"
            >
              <span class="text-secondary">
                Склеиваемая
              </span>
            </b-form-checkbox>
          </div>
          <b-button
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            @click="$router.push('/import-partner-prices')"
          >
            <b-icon icon="file-earmark-excel" />
            Импортировать
          </b-button>
        </div>
        <b-form-checkbox
          v-model="isAllServices"
          name="check-button"
          size="sm"
          switch
          @input="fetchPartnerServices"
        >
          <span>
            Искать среди всех партнёрских услуг
          </span>
        </b-form-checkbox>
        <div class="mt-4 mb-4 d-flex">
          <b-input-group>
            <b-form-input
              v-model="partnerServiceName"
              placeholder="По названию  услуги"
              @keyup.native.enter="fetchPartnerServices"
            />
            <b-form-input
              v-model="partnerServiceCode"
              placeholder="По коду услуги"
              @keyup.native.enter="fetchPartnerServices"
            />
          </b-input-group>

          <b-button
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            class="ml-2"
            @click="fetchPartnerServices"
          >
            <b-icon icon="search" />
          </b-button>
        </div>
        <div class="legalPartnerSelect">
          <v-select
            v-model="selectedLegalCompanyId"
            :reduce="legal => legal.id"
            :options="legalCompanyOptions"
            placeholder="Выберите Юр.Лицо"
            label="legalName"
            @input="fetchPartnerServices"
          />
        </div>

        <b-overlay
          v-if="!partnerListStore || Object.keys(partnerListStore).length === 0"
          variant="transparent"
          :show="isLoading"
          no-wrap
        />
        <div v-if="partnerListStore">
          <div v-if="Object.keys(partnerListStore).length !== 0">
            <b-table
              v-if="partnerListStore"
              :fields="fields"
              :items="partnerListStore.data"
              :busy="isLoading"
              :per-page="0"
              :current-page="currentPage"
              :filter="filter"
              class="bg-white crm-table mt-4"
              bordered
              hover
              :small="true"
            >
              <template v-slot:cell(cost)="row">
                <b-button
                  class="mr-2 text-nowrap"
                  size="sm"
                  variant="primary"
                  :type="$const.PRIMARY_BUTTON"
                  @click="onOpenServicePrices(row.item)"
                >
                  Узнать цену
                </b-button>
              </template>
              <template v-slot:cell(isActive)="row">
                <b-badge
                  v-if="row.item.isActive"
                  class="p-2"
                  pill
                  variant="success"
                >
                  Активирована
                </b-badge>
                <b-badge
                  v-else
                  class="p-2"
                  pill
                  variant="danger"
                >
                  Не активирована
                </b-badge>
              </template>
              <template #cell(service)="row">
                <div v-if="row.item.service">
                  {{ row.item.service.name }}
                </div>
              </template>
              <template #cell(partner)="row">
                <div v-if="row.item.partner">
                  {{ row.item.partner.name }}
                </div>
              </template>
              <template v-slot:cell(isMapable)="row">
                <b-badge
                  v-if="row.item.isMapable"
                  class="p-2"
                  pill
                  variant="success"
                >
                  Требуется
                </b-badge>
                <b-badge
                  v-else
                  class="p-2"
                  pill
                  variant="danger"
                >
                  Не требуется
                </b-badge>
              </template>
              <template #cell(actions)="row">
                <div class="d-flex">
                  <b-button
                    v-b-tooltip.hover
                    class="mr-2"
                    size="sm"
                    title="Привязать к услуге"
                    variant="primary"
                    :type="$const.PRIMARY_BUTTON"
                    @click="onClickOpenPage(row)"
                  >
                    <b-icon icon="files" />
                  </b-button>
                  <b-button
                    v-b-tooltip.hover
                    size="sm"
                    title="Отвязать от услуг"
                    variant="info"
                    :type="$const.PRIMARY_BUTTON"
                    @click="onClickUnMap(row)"
                  >
                    <b-icon icon="clipboard-x" />
                  </b-button>
                  <b-button
                    v-if="row.item.isActive"
                    v-b-tooltip.hover
                    class="contact-legal-delete ml-2"
                    variant="danger"
                    :type="$const.PRIMARY_BUTTON"
                    size="sm"
                    title="Деактивировать"
                    @click="onClickDeActivatePartnerService(row)"
                  >
                    <b-icon icon="person-x-fill" />
                  </b-button>
                  <b-button
                    v-else
                    v-b-tooltip.hover
                    class="contact-legal-delete ml-2"
                    variant="success"
                    :type="$const.PRIMARY_BUTTON"
                    size="sm"
                    title="Активировать"
                    @click="onClickActivatePartnerService(row)"
                  >
                    <b-icon icon="person-check-fill" />
                  </b-button>
                  <b-button
                    v-if="!row.item.isMapable"
                    v-b-tooltip.hover
                    size="sm"
                    class="ml-2"
                    title="Включить склейку"
                    variant="success"
                    :type="$const.PRIMARY_BUTTON"
                    @click="onClickIsMappablePartnerService(row)"
                  >
                    <b-icon icon="layers-half" />
                  </b-button>
                  <b-button
                    v-else
                    v-b-tooltip.hover
                    size="sm"
                    class="ml-2"
                    title="Отключить склейку"
                    variant="danger"
                    :type="$const.PRIMARY_BUTTON"
                    @click="onClickIsMappablePartnerService(row)"
                  >
                    <b-icon icon="layers-half" />
                  </b-button>
                </div>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2" />
                  <strong>Загрузка...</strong>
                </div>
              </template>
            </b-table>
            <div
              class="mt-3"
            >
              <div
                v-show="partnerListStore"
                class="d-flex align-items-center"
              >
                <b-dropdown
                  id="dropdown-1"
                  :text="`${perPage}`"
                  split
                  class="m-md-2"
                  size="sm"
                  split-variant="outline-primary"
                  variant="primary"
                >
                  <b-dropdown-item @click="tableSizeHandler(10)">
                    10
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item @click="tableSizeHandler(25)">
                    25
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item @click="tableSizeHandler(50)">
                    50
                  </b-dropdown-item>
                </b-dropdown>
                <b-pagination
                  v-model="currentPage"
                  pills
                  :total-rows="+allCount"
                  :per-page="perPage"
                  style="padding-top: 16px"
                  @input="fetchPartnerServices"
                />
              </div>
            </div>
          </div>
          <span v-else>Ничего не найдено</span>
        </div>
      </b-container>
    </custom-scrollbar>
  </div>
</template>

<script>
export default {
  name: 'LaboratoryPartnerService',
  page: {
    title: 'CRM Doctis - Партнерские услуги',
  },
  components: {
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Название Услуги',
          sortable: true,
          tdClass: 'align-middle',
          class: ['nameClass', 'text-center'],
        },
        {
          key: 'code',
          label: 'Код услуги',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'partner',
          label: 'Партнер',
          sortable: true,
          thClass: ['align-middle', 'text-center'],
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'service',
          label: 'Привязанная к',
          sortable: true,
          thClass: ['align-middle', 'text-center'],
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'cost',
          label: 'Стоимость услуги',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'isActive',
          label: 'Статус услуги',
          tdClass: ['align-middle'],
          class: 'text-center',
        },
        {
          key: 'isMapable',
          label: 'Статус склейки',
          tdClass: ['align-middle'],
          class: 'text-center',
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'align-middle',
          class: ['actionsClass', 'text-center'],
        },
      ],
      selectedLegalCompanyId: null,
      legalCompanyOptions: [],
      userData: null,
      currentPage: 1,
      perPage: 10,
      filter: null,
      rows: 1,
      partnerList: [],
      isActive: true,
      isMerged: true,
      isMappable: true,
      isAllServices: false,
      partnerServiceName: null,
      partnerServiceCode: null,
      partnerServiceMinCost: null,
      partnerServiceMaxCost: null,
      allCount: null,
      limit: 10,
      isLoading: false,
    };
  },
  computed: {
    partnerListStore() {
      return this.$store.state.Laboratories.partnerServiceList;
    },
    paginationParams() {
      return {
        MinCost: this.partnerServiceMinCost,
        MaxCost: this.partnerServiceMaxCost,
        Code: this.partnerServiceCode,
        Name: this.partnerServiceName,
        limit: this.limit,
        offSet: this.offSet,
        isActive: this.isActive,
        isMerged: this.isMerged,
        isMappable: this.isMappable,
        LegalPartnerId: this.selectedLegalCompanyId,
        save: true,
      };
    },
    offSet() {
      return (this.currentPage - 1) * this.limit;
    },
  },
  async created() {
    await this.fetchPartnerServices();
    await this.fetchLegalPartners();
  },
  beforeDestroy() {
    this.$store.commit(this.$types.PARTNER_SERVICES_SET, null);
  },
  methods: {
    onOpenServicePrices(service) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ServicePricesModal',
        props: { service },
      });
    },
    async tableSizeHandler(mount) {
      this.perPage = mount;
      this.limit = this.perPage;
      await this.fetchPartnerServices();
    },
    async fetchPartnerServices() {
      this.isLoading = true;
      // eslint-disable-next-line no-undef
      const params = structuredClone(this.paginationParams);
      if (this.isAllServices) {
        params.isActive = undefined;
        params.isMappable = undefined;
        params.isMerged = undefined;
      }

      const data = await this.$store.dispatch(this.$types.GET_LABORATORY_PARTNER_SERVICES, params);

      this.allCount = data.xTotalCount;
      this.isLoading = false;
    },
    async fetchLegalPartners() {
      const { data: { data } } = await this.$store.dispatch(this.$types.GET_LABORATORY_LEGAL_PARTNERS, {});
      this.legalCompanyOptions = data;
    },
    async onClickActivatePartnerService(row) {
      await this.$store.dispatch(this.$types.LABORATORY_PARTNER_SERVICE_ACTIVATE, row.item.id);
      await this.fetchPartnerServices();
    },
    async onClickDeActivatePartnerService(row) {
      await this.$store.dispatch(this.$types.LABORATORY_PARTNER_SERVICE_DEACTIVATE, row.item.id);
      await this.fetchPartnerServices();
    },
    async onClickIsMappablePartnerService(row) {
      await this.$store.dispatch(this.$types.LABORATORY_PARTNER_SERVICE_SET_MAPPABLE, {
        partnerServiceId: row.item.id,
        value: !row.item.isMapable,
      });
      await this.fetchPartnerServices();
    },
    async onClickOpenPage(row) {
      await this.$router.push({
        path: 'laboratory-gluing-service',
        query: {
          id: row.item.id,
        },
      });
    },
    openAddPartnerModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ImportPartnerService',
        props: {
          params: this.paginationParams,
        },
      });
    },
    async onClickUnMap(row) {
      await this.$store.dispatch(this.$types.LABORATORY_PARTNER_SERVICE_UNMAP, row.item.id);
      await this.fetchPartnerServices();
    },
    openPartnerServiceMapModal(row) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'PartnerServiceMap',
        props: {
          partnerServiceGuid: row.item.id,
          serviceGuid: row.item.serviceId,
          params: this.paginationParams,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.policy-list-content {
  height: calc(100vh - #{$header-height});
  transition: height 0.3s linear;
}

::v-deep.crm-table {
  overflow: hidden;
  .nameClass{
    max-width: 30rem !important;
  }
  .actionsClass{
    min-width: 11rem !important;
  }

  .crm-input {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
}
.legalPartnerSelect{
  background-color: white;
}
.checkBoxParams {
  gap: 20px;
}
</style>
