<template>
  <b-modal
    visible
    scrollable
    centered
    size="xl"
    hide-footer
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="idemnity-modal">
      <Loading
        v-if="isLettersLoading"
        class="crm-loading-wrapper"
      >
        Загрузка данных
      </Loading>
      <div
        v-else
        class="step-wrapper"
      >
        <div class="modal-step">
          <div class="crm-flex-row">
            <p class="page-title">
              История {{ isReferral ? 'направлений' : 'гарантийных писем' }}
            </p>

            <span class="program-name">
              {{ programName }}
            </span>
          </div>

          <div class="letter-wrapper w-100 mt-4">
            <template v-for="(group, groupKey) in lettersGroups">
              <template v-for="(letter, letterKey) in group.letters">
                <transition
                  :key="letter.id"
                  name="fade"
                >
                  <div
                    v-if="letterKey === 0 || group.open"
                    class="d-flex w-100 border-bottom border-left border-bottom border-right"
                    :class="{ 'border-top': (groupKey === 0 && letterKey === 0) }"
                  >
                    <div
                      class="p-4 border-right"
                      :class="{
                        'main-letter' : (letterKey === 0 && group.letters.length > 1),
                        'sub-letter' : (letterKey > 0 && group.letters.length > 1),
                      }"
                      style="width: 300px; flex-shrink: 0;"
                      @click="() => letterKey === 0 ? lettersGroups[groupKey].open = !lettersGroups[groupKey].open : null"
                    >
                      <div v-if="letter.isSent && letter.isSigned">
                        <div class="d-flex mb-2 ">
                          <div
                            :class="`
                            font-weight-bold mr-auto
                            ${letterKey === 0 && !!group.letters.find((letter) => letter.isAnnulment) && 'text-danger'}
                          `"
                          >
                            Отправленное {{ isReferral ? 'направление' : 'письмо' }}
                          </div>

                          <template v-if="(letterKey === 0 && group.letters.length > 1)">
                            <b-icon
                              v-if="group.open"
                              icon="arrow-up"
                              aria-hidden="true"
                            />
                            <b-icon
                              v-else
                              icon="arrow-down"
                              aria-hidden="true"
                            />
                            <template />
                          </template>
                        </div>

                        <div
                          v-if="letter.isEdited"
                          class="mb-1"
                        >
                          Отредактировано
                        </div>

                        <div
                          v-if="letter.isAnnulment"
                          class="mb-1"
                        >
                          Аннулировано
                        </div>

                        <div class="font-weight-bold mt-2">
                          № {{ letter.documentNumber }}
                        </div>

                        <template v-if="letter.sendDate ">
                          <div class="mt-2">
                            Дата отправки:
                          </div>

                          <div class="mt-1">
                            {{ getFormatDate(letter.sendDate) }}
                          </div>
                        </template>

                        <template v-if="letter.creationDate">
                          <div class="mt-2">
                            Дата начала действия:
                          </div>

                          <div class="mt-1">
                            {{ getFormatDate(letter.creationDate) }}
                          </div>
                        </template>

                        <template v-if="letter.validityDate">
                          <div class="mt-2">
                            Срок действия до:
                          </div>

                          <div class="mt-1">
                            {{ getFormatDate(letter.validityDate) }}
                          </div>
                        </template>
                      </div>
                      <div v-else>
                        <div class="d-flex mb-2">
                          <div class="font-weight-bold mr-auto">
                            Черновик
                          </div>

                          <template v-if="(letterKey === 0 && group.letters.length > 1)">
                            <b-icon
                              v-if="group.open"
                              icon="arrow-up"
                              aria-hidden="true"
                            />
                            <b-icon
                              v-else
                              icon="arrow-down"
                              aria-hidden="true"
                            />
                            <template />
                          </template>
                        </div>

                        <div class="font-weight-bold mt-2">
                          № {{ letter.documentNumber }}
                        </div>

                        <template v-if="letter.sendDate ">
                          <div class="mt-2">
                            Дата отправки:
                          </div>

                          <div class="mt-1">
                            {{ getFormatDate(letter.sendDate) }}
                          </div>
                        </template>

                        <template v-if="letter.creationDate">
                          <div class="mt-2">
                            Дата начала действия:
                          </div>

                          <div class="mt-1">
                            {{ getFormatDate(letter.creationDate) }}
                          </div>
                        </template>

                        <template v-if="letter.validityDate">
                          <div class="mt-2">
                            Срок действия до:
                          </div>

                          <div class="mt-1">
                            {{ getFormatDate(letter.validityDate) }}
                          </div>
                        </template>
                      </div>
                    </div>
                    <div class="p-4 flex-grow-1">
                      <div class="d-flex align-items-center mb-2">
                        <div
                          v-if="letter.clinic"
                          class="font-weight-bold mr-auto"
                        >
                          Клиника {{ letter.clinic.title }}, {{ letter.clinic.addressString }}
                        </div>

                        <b-button
                          v-if="letter.isSent && letter.isSigned"
                          size="sm"
                          variant="primary"
                          :type="$const.PRIMARY_BUTTON"
                          class="ml-4"
                          title="Скачать"
                          @click="openIndemnityPdf(letter.id, letter.isAnnulment)"
                        >
                          <b-icon
                            icon="download"
                            aria-hidden="true"
                          />
                        </b-button>

                        <b-button
                          v-if="checkFeature(FEATURES_FOR_PATIENTS.abilityToEditIndemnuty)
                            && !group.letters.find((letter) => letter.isAnnulment)"
                          size="sm"
                          variant="primary"
                          :type="$const.PRIMARY_BUTTON"
                          class="ml-2"
                          title="Редактировать"
                          @click="openIndemnityModal({ indemnityLetterId: letter.id, isSent: letter.isSent })"
                        >
                          <b-icon
                            icon="pencil"
                            aria-hidden="true"
                          />
                        </b-button>

                        <b-button
                          v-if="!(letter.isSent && letter.isSigned)"
                          size="sm"
                          variant="danger"
                          :type="$const.PRIMARY_BUTTON"
                          class="ml-2"
                          title="Удалить"
                          @click="openIndemnityLettedDeleteModal(letter.id)"
                        >
                          <b-icon
                            icon="trash"
                            aria-hidden="true"
                          />
                        </b-button>

                        <b-button
                          v-else-if="!group.letters.find((letter) => letter.isAnnulment)"
                          size="sm"
                          variant="danger"
                          :type="$const.PRIMARY_BUTTON"
                          class="ml-2"
                          title="Аннулировать"
                          @click="openIndemnityAnnulmentModal({ indemnityLetterId: letter.id })"
                        >
                          <b-icon
                            icon="x"
                            aria-hidden="true"
                          />
                        </b-button>
                      </div>

                      <div
                        v-if="!(letter.fileKey && letter.fileName)"
                        class="ml-auto text-right mb-3"
                        style="font-size: 10px; line-height: 1.2; width: 200px;"
                      >
                        Данное ГП может отличаться от того, что было отправлено в клинику
                      </div>

                      <div
                        v-for="(row, rowKey) in getTableRows(letter, letter.priceType)"
                        :key="rowKey"
                      >
                        <template v-if="typeof row === 'string'">
                          {{ row }}
                        </template>

                        <template v-else-if="typeof row?.Name === 'string' || typeof row?.name === 'string'">
                          {{ row?.Name || row?.name }}

                          <template v-if="row.count">
                            | кол-во {{ row.count }}
                          </template>
                        </template>
                        <template v-else>
                          {{ row?.Name?.Value ?? row?.name?.value }}

                          <template v-if="row.count">
                            | кол-во {{ row.count }}
                          </template>
                        </template>
                      </div>

                      <div
                        v-if="letter.serviceString"
                        class="mt-3 text-break"
                      >
                        <div>
                          Прочие услуги:
                        </div>

                        <div>
                          {{ letter.serviceString }}
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mixinRoles } from '@/mixins';
import { parseISO, format } from '@evd3v/date-fns';
import { SIGNERS } from '@/helpers/constsIndemnity';
import Bus from '@/eventBus';
import * as types from '@/store/types';

import Loading from '@/components/Loading';

import { mapGetters } from 'vuex';

import { FEATURES_FOR_PATIENTS } from '@/helpers/consts';

export default {
  name: 'IndemnityHistoryModal',
  components: {
    Loading,
  },
  mixins: [mixinRoles],
  props: {
    personId: {
      type: [String, Number],
      default: null,
    },
    policyId: {
      type: Number,
      default: null,
    },
    subProgramId: {
      type: [String, Number],
      default: null,
    },
    isReferral: {
      type: Boolean,
      default: false,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      selectedLetter: null,
      selectedLetterUrl: null,
      currentStep: 1,
      SIGNERS,
      lettersGroups: [],
      FEATURES_FOR_PATIENTS,
    };
  },
  computed: {
    ...mapGetters({
      userInfo: types.USER_INFO_GET,
    }),
    policy() {
      return this.$store.state.Patients.policy;
    },
    programName() {
      return this.policy && this.policy.program ? this.policy.program.name : null;
    },
    letters() {
      const { letters } = this.$store.state.Indemnity;
      return letters.sort((a, b) => new Date(b.sendDate) - new Date(a.sendDate));
    },
    analysisLetters() {
      return this.$store.state.Indemnity.analysisLetters;
    },
    isLettersLoading() {
      return this.$store.state.Indemnity.isLettersLoading;
    },
    lettersSendDates() {
      return this.letters.map((letter) => (letter.sendDate ? format(parseISO(letter.sendDate), 'dd.MM.yyyy') : null));
    },
    analysisLettersSendDates() {
      return this.analysisLetters.map((letter) => (letter.created_at ? format(parseISO(letter.created_at), 'dd.MM.yyyy') : null));
    },
  },
  async created() {
    this.fetchLetters();

    Bus.$on('indemnity-history-modal:fetch-letters', this.fetchLetters);
  },
  beforeDestroy() {
    this.$store.commit(this.$types.INDEMNITY_LETTERS_SET, []);
    this.$store.commit(this.$types.INDEMNITY_ANALYSIS_LETTERS_SET, []);
    this.$store.commit(this.$types.PATIENT_POLICY_SET, null);
    this.$store.commit(this.$types.INDEMNITY_PERSON_ID_SET, null);

    if (this.$store.state.Indemnity.historyWithoutPolicy) {
      this.$store.commit(this.$types.INDEMNITY_HISTORY_WITHOUT_POLICY_SET, false);
    }

    Bus.$off('indemnity-history-modal:fetch-letters', this.fetchLetters);
  },
  methods: {
    checkFeature(key) {
      return this.checkFeatureAccess({ name: key?.name, url: key?.url });
    },
    async fetchLetters() {
      this.$store.commit(this.$types.INDEMNITY_LETTERS_LOADING_SET, true);
      this.lettersGroups = [];

      await this.$store.dispatch(this.$types.INDEMNITY_LIST_FETCH, { personId: this.personId, policyId: this.policyId, isReferral: this.isReferral });

      this.letters.forEach((letter) => {
        const { documentNumber } = letter;

        if (this.policyId !== letter.policyId) return;
        const isNeedGroupRows = [2, 3].includes(letter.priceType);
        const letterData = isNeedGroupRows ? {
          ...letter,
          tableRowsPrice: letter.tableRowsPrice.reduce((result, item) => {
            const itemInResult = result.find((i) => i.id === item.id);

            if (itemInResult) {
              itemInResult.count += 1;
            } else {
              result.push({ ...item, count: item.count || 1 });
            }
            return result;
          }, []),
        } : letter;

        const group = this.lettersGroups.find((g) => g.documentNumber === documentNumber);
        if (group) {
          group.letters.push(letterData);
          return;
        }

        this.lettersGroups.push({ documentNumber, open: false, letters: [letterData] });
      });
    },
    getTableRows(letter, value) {
      if (value === 3) {
        return letter.tableRowsPrice;
      }
      return letter.tableRows;
    },
    getFormatDate(date) {
      return format(parseISO(date), 'dd.MM.yyyy');
    },
    closeModal() {
      this.$emit('vuedals:close');
    },
    checkCurrentStepErrors() {
      this.$refs.step.checkHasValidateErrors();
    },
    onChangeStep(change) {
      this.currentStep += change;
    },
    openIndemnityModal({ indemnityLetterId, isSent }) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'IndemnityModal',
        props: {
          personId: this.personId,
          policyId: this.policyId,
          subProgramId: this.subProgramId,
          indemnityLetterId,
          isSent,
          isReferral: this.isReferral,
        },
      });
    },
    openIndemnityAnnulmentModal({ indemnityLetterId }) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'IndemnityLetterAnnulmentModal',
        props: {
          indemnityLetterId,
          policyId: this.policyId,
          isReferral: this.isReferral,
        },
      });
    },
    openIndemnityLettedDeleteModal(indemnityLetterId) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'IndemnityLetterDeleteModal',
        props: {
          indemnityLetterId,
          policyId: this.policyId,
          isReferral: this.isReferral,
        },
      });
    },
    async openIndemnityPdf(id) {
      const letter = await this.$store.dispatch(this.$types.INDEMNITY_GET_LETTER_BY_ID, { id, isReferral: this.isReferral });

      if (letter.fileKey && letter.fileName) {
        await this.$store.dispatch(this.$types.INDEMNITY_LETTER_DOWNLOAD, { key: letter.fileKey, name: letter.fileName });
      } else {
        this.$store.dispatch(this.$types.INDEMNITY_LETTER_PRINT, {
          ...letter,
          signerName: this.SIGNERS[0].name,
          signerPosition: this.SIGNERS[0].position,
        });
      }
    },
    async onSelectLetter(letterData) {
      if (this.selectedLetter?.id === letterData.id) {
        this.selectedLetter = null;
        this.selectedLetterUrl = null;

        return;
      }

      try {
        this.selectedLetter = JSON.parse(JSON.stringify(letterData));
        this.selectedLetterUrl = null;
        const letter = await this.$store.dispatch(this.$types.INDEMNITY_GET_LETTER_BY_ID, { id: letterData.id });

        const pdf = await this.$store.dispatch(this.$types.INDEMNITY_LETTER_MAKE_PDF, {
          ...letter,
          signerName: this.SIGNERS[0].name,
          signerPosition: this.SIGNERS[0].position,
        });

        this.selectedLetterUrl = URL.createObjectURL(pdf);
      } catch (error) {
        console.error(error);
      }
    },
    onClose() {
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.idemnity-modal {
  display: flex;
  flex-direction: column;
}

.letter-date {
  font-size: 12px;
}

::v-deep.step-wrapper {
  min-height: 300px;
  .crm-scrollbar-wrapper {
    height: calc(100vh - 202px);
    margin: 0 -10px 10px;
  }
  .page-title {
    margin: 0;
  }

  .indemtity-wrapper {
    flex-direction: column;
  }

  .crm-flex-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .program-name {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $blue;
    margin-left: 20px;
    text-align: right;
  }

  .page-description {
    margin-top: 30px;
    width: 100%;
  }
}

.crm-table {
  margin-top: 30px;
  width: 100%;
  border: 1px solid $gray;
  border-radius: 4px;
  overflow: hidden;
  font-size: 16px;
  line-height: 20px;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  &-row {
    display: flex;
    background-color: #fff;
    & + & {
      border-top: 1px solid $gray-shadow;
    }

    &--header {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      background-color: #f3f3f3;
    }
  }
  &-cell {
    padding: 10px;
    & + & {
      border-left: 1px solid $gray;
    }

    &--number {
      width: 35px;
      text-align: center;
      padding-left: 0;
      padding-right: 0;
    }

    &--sign, &--draft, &--sent {
      flex: 1;
    }

    &--control {
      width: 50px;
      justify-content: center;
      align-items: center;
      display: flex;
      padding-left: 0;
      padding-right: 0;
    }
    &-inner{
      display: flex;
      align-items: center;

      .letter-info {
        margin-right: auto;
      }

      .crm-icon-letter {
        margin-right: 10px;
        flex-shrink: 0;
        cursor: pointer;
      }

      .crm-icon-edit {
        margin-left: 10px;
        flex-shrink: 0;
        cursor: pointer;

        path {
          fill: $blue;
        }
        // rect {
        //     stroke: #6E88F3;
        // }
      }

      .crm-icon-close {
        margin-left: 10px;
        flex-shrink: 0;
        cursor: pointer;
      }
    }
  }
}

.crm-icon-delete {
  cursor: pointer;
}

.letter-wrapper {
  height: calc(100vh - 220px);
  overflow: auto;
}

.letter-list {
  height: 100%;
  width: 350px;
  flex-shrink: 0;
  overflow: auto;
}

.letter-item {
  border: 1px solid #E5E5E5;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &.active {
    color: #ffffff;
    background-color: #6E88F3;
  }

  & + & {
    border-top: none;
  }
}

.letter-detail {
  height: 100%;
  width: 100%;

  .crm-icon {
    cursor: pointer;
  }
}

.letter-pdf {
  height: calc(100% - 30px);
  width: 100%;
  overflow: auto;
}

.main-letter {
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.sub-letter {
  background: #efefef;
}
</style>
