import axiosClient from '@/services/core/axios';
import AuthService from '@/services/auth/auth.service';
import AccountService from '@/services/account/account.service';
import AppealsService from '@/services/appeals/appeals.service';
import ScheduleService from '@/services/schedule/schedule.service';
import UsersService from '@/services/users/users.service';
import AppealsTemplatesService from '@/services/appeals-templates/appeals-templates.service';
import AppealsTemplatesChatService from '@/services/appeals-templates-chat/appeals-templates-chat.service';

import ChatService from '@/services/chat/chat.service';
import SpecializationsService from '@/services/specializations/specializations.service';
import ClinicService from '@/services/clinic/clinic.service';

import PersonService from '@/services/person/person.service';
import StoriesService from '@/services/stories/stories.service';
import InsuranceService from '@/services/insurance/insurance.service';

import FileService from '@/services/file/file.service';
import DoctorsService from '@/services/doctors/doctors.service';
import ConsultationService from '@/services/consultation/consultation.service';
import ReservationService from '@/services/reservation/reservation.service';

import LocationService from '@/services/location/location.service';
import LaboratoryService from '@/services/laboratory/laboratory.service';

import RegistryService from '@/services/registry/registry.service';
import SipUserService from '@/services/sipUser/sipUser.service';

const authService = new AuthService(axiosClient);
const accountService = new AccountService(axiosClient);
const appealsService = new AppealsService(axiosClient);
const doctorsService = new DoctorsService(axiosClient);
const consultationService = new ConsultationService(axiosClient);
const reservationService = new ReservationService(axiosClient);

const scheduleService = new ScheduleService(axiosClient);
const sipUserService = new SipUserService(axiosClient);
const appealsTemplatesService = new AppealsTemplatesService(axiosClient);
const appealsTemplatesChatService = new AppealsTemplatesChatService(axiosClient);

const chatService = new ChatService(axiosClient);
const usersService = new UsersService(axiosClient);
const specializationsService = new SpecializationsService(axiosClient);
const clinicService = new ClinicService(axiosClient);
const personService = new PersonService(axiosClient);
const storiesService = new StoriesService(axiosClient);
const insuranceService = new InsuranceService(axiosClient);

const fileService = new FileService(axiosClient);

const locationService = new LocationService(axiosClient);
const laboratoryService = new LaboratoryService(axiosClient);

const registryService = new RegistryService(axiosClient);

// eslint-disable-next-line import/prefer-default-export
export {
  authService,
  accountService,
  appealsTemplatesService,
  appealsTemplatesChatService,
  appealsService,
  doctorsService,
  consultationService,
  scheduleService,
  sipUserService,
  usersService,
  chatService,
  specializationsService,
  clinicService,
  personService,
  storiesService,
  insuranceService,
  fileService,
  locationService,
  laboratoryService,
  registryService,
  reservationService,
};
