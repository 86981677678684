<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    :title="queue.id ? `Редактирование очереди ${queue.id}` : 'Добавление очереди'"
    :hide-footer="isLoading"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <b-form-group
        label="Название"
        label-class="required"
        invalid-feedback="Поле является обязательным"
        :state="!$v.queueData.name.$error"
      >
        <b-form-input
          v-model="$v.queueData.name.$model"
          placeholder="Введите название"
          required
        />
      </b-form-group>

      <b-form-group
        label="Описание"
        label-class="required"
        invalid-feedback="Поле является обязательным"
        :state="!$v.queueData.description.$error"
      >
        <b-form-input
          v-model="$v.queueData.description.$model"
          placeholder="Введите описание"
          required
        />
      </b-form-group>

      <div class="mt-3 mb-4">
        <div class="flex gap-2 justify-between flex-wrap">
          <b-form-checkbox
            v-model="isConsultationLimitForDoctor"
            size="sm"
          >
            <span class="text-secondary">
              Ограничения по пациентам
            </span>
          </b-form-checkbox>
          <b-form-checkbox
            v-model="isFreeConsultationsLimit"
            size="sm"
          >
            <span class="text-secondary">
              Количество бесплатных консультаций
            </span>
          </b-form-checkbox>
        </div>

        <div
          v-if="isConsultationLimitForDoctor"
          class="mb-4"
        >
          <b-form-input
            v-model="$v.queueData.consultationLimitForDoctor.$model"
            placeholder="Сколько пациентов врач может взять в работу одновременно"
            type="number"
            class="mt-2"
            :state="!$v.queueData.consultationLimitForDoctor.$error"
            min="0"
          />
          <p
            v-if="$v.queueData.consultationLimitForDoctor.$error"
            class="text-danger text-sm pt-1"
          >
            Значение должно быть больше 0
          </p>
        </div>
        <template v-if="isFreeConsultationsLimit">
          <div class="mb-4">
            <b-form-input
              v-model="$v.queueData.consultationLimitForPatientPerDay.$model"
              placeholder="Сколько бесплатных консультаций может быть у пациента в день"
              type="number"
              class="mt-2"
              :state="!$v.queueData.consultationLimitForPatientPerDay.$error"
              min="0"
            />
            <p
              v-if="$v.queueData.consultationLimitForPatientPerDay.$error"
              class="text-danger text-sm pt-1"
            >
              <template v-if="!$v.queueData.consultationLimitForPatientPerDay.required">
                Обязательно для заполнения при заполнении лимита консультаций на месяц
              </template>
              <template v-if="!$v.queueData.consultationLimitForPatientPerDay.maxValue">
                Не может быть больше лимита консультаций на месяц
              </template>
              <template v-if="!$v.queueData.consultationLimitForPatientPerDay.minValue">
                Значение должно быть больше 0
              </template>
            </p>
          </div>
          <div class="mb-4">
            <b-form-input
              v-model="$v.queueData.consultationLimitForPatientPerMonth.$model"
              placeholder="Сколько бесплатных консультаций может быть у пациента в месяц"
              type="number"
              class="mt-2"
              :state="!$v.queueData.consultationLimitForPatientPerMonth.$error"
              min="0"
            />
            <p
              v-if="$v.queueData.consultationLimitForPatientPerMonth.$error"
              class="text-danger text-sm pt-1"
            >
              <template v-if="!$v.queueData.consultationLimitForPatientPerMonth.required">
                Обязательно для заполнения при заполнении лимита консультаций на день
              </template>
              <template v-if="!$v.queueData.consultationLimitForPatientPerMonth.minValue">
                Значение должно быть больше {{ queueData.consultationLimitForPatientPerDay ?
                  `или равно ${ queueData.consultationLimitForPatientPerDay }`
                  : 0 }}
              </template>
            </p>
          </div>
        </template>
      </div>

      <b-form-group class="my-4">
        <div class="d-flex mb-2 position-relative">
          <label>
            Прикрепление врачей через клинику
          </label>

          <b-button
            :variant="clinicTableShow ? 'secondary' : 'primary'"
            :type="$const.PRIMARY_BUTTON"
            class="ml-auto"
            size="sm"
            style="width: 150px"
            @click="toggleClinicTableShow"
          >
            {{ clinicTableShow ? 'Скрыть' : 'Выбрать клинику' }}
          </b-button>
          <p class="error">
            {{ errorsValidation.clinic[0] }}
          </p>
        </div>

        <template v-if="clinicTableShow">
          <b-form-input
            v-model="clinicSearchString"
            type="text"
            debounce="500"
            placeholder="Поиск клиники по названию"
            size="sm"
            trim
            class="mb-2"
          />
          <div
            v-if="filteredClinicsSlice.length"
            style="max-height: 300px; overflow-y: hidden;"
            class="mb-4"
          >
            <b-table-simple
              small
              bordered
              class="mb-0"
            >
              <b-tbody>
                <b-tr
                  v-for="clinic in filteredClinicsSlice"
                  :key="clinic.id"
                >
                  <b-td>
                    {{ clinic.name }}
                  </b-td>
                  <b-td style="width: 40px">
                    <b-button
                      variant="success"
                      :type="$const.PRIMARY_BUTTON"
                      size="sm"
                      :disabled="isClinicLoading"
                      @click="onClinicSelect(clinic.id)"
                    >
                      <b-icon icon="plus" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>

          <div
            v-if="filteredClinics.length"
            class="mt-3"
          >
            <div
              class="crm-pagination"
            >
              <b-pagination
                v-model="clinicCurrentPage"
                :per-page="clinicPerPage"
                :total-rows="filteredClinics.length"
                style="padding-top: 16px"
              />
            </div>
          </div>

          <small
            v-if="clinicSearchString && !filteredClinics.length"
            class="ml-2 mb-4"
          >
            Клиника не найдена
          </small>
        </template>
      </b-form-group>

      <b-form-group>
        <div class="d-flex mb-2 position-relative">
          <label>
            Прикрепленные врачи
          </label>

          <b-button
            :variant="doctorTableShow ? 'secondary' : 'primary'"
            :type="$const.PRIMARY_BUTTON"
            class="ml-auto"
            size="sm"
            style="width: 150px"
            @click="toggleDoctorTableShow"
          >
            {{ doctorTableShow ? 'Скрыть' : 'Добавить врача' }}
          </b-button>
          <p class="error">
            {{ errorsValidation.doctor[0] }}
          </p>
        </div>

        <b-table-simple
          v-if="queueData.doctors.length"
          bordered
          small
        >
          <b-tbody>
            <b-tr
              v-for="doctor in queueData.doctors"
              :key="doctor.id"
            >
              <b-td>
                {{ doctor.name }}
              </b-td>
              <b-td style="width: 40px">
                <b-button
                  variant="danger"
                  :type="$const.PRIMARY_BUTTON"
                  size="sm"
                  @click="onDoctorRemove(doctor.id)"
                >
                  <b-icon icon="trash" />
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>

        <div
          v-else
          class="text-secondary"
        >
          Врачи не добавлены
        </div>

        <template v-if="doctorTableShow">
          <b-form-input
            v-model="doctorSearchString"
            type="text"
            debounce="500"
            placeholder="Поиск врача по фио"
            size="sm"
            trim
            class="mt-4 mb-2"
          />
          <div
            v-if="filteredDoctors.length"
            style="max-height: 210px; overflow-y: hidden;"
            class="mb-4"
          >
            <b-table-simple
              small
              bordered
              class="mb-0"
            >
              <b-tbody>
                <b-tr
                  v-for="doctor in filteredDoctorsSlice"
                  :key="doctor.id"
                >
                  <b-td>
                    {{ doctor.name }}
                  </b-td>
                  <b-td style="width: 40px">
                    <b-button
                      variant="success"
                      :type="$const.PRIMARY_BUTTON"
                      size="sm"
                      @click="onDoctorAdd(doctor)"
                    >
                      <b-icon icon="plus" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div
            v-if="filteredDoctors.length"
            class="mt-3"
          >
            <div
              class="crm-pagination"
            >
              <b-pagination
                v-model="doctorCurrentPage"
                :per-page="doctorPerPage"
                :total-rows="filteredDoctors.length"
                style="padding-top: 16px"
              />
            </div>
          </div>

          <small
            v-if="doctorSearchString && !filteredDoctors.length"
            class="ml-2 mb-4"
          >
            Врач не найден
          </small>
        </template>
      </b-form-group>

      <b-form-group>
        <div class="d-flex mb-2 position-relative">
          <label>
            Прикрепленные тэги
          </label>

          <b-button
            :variant="tagTableShow ? 'secondary' : 'primary'"
            :type="$const.PRIMARY_BUTTON"
            class="ml-auto"
            size="sm"
            style="width: 150px"
            @click="toggleTagTableShow"
          >
            {{ tagTableShow ? 'Скрыть' : 'Добавить тэг' }}
          </b-button>
          <p class="error">
            {{ errorsValidation.tag[0] }}
          </p>
        </div>

        <b-table-simple
          v-if="queueData.tags.length"
          bordered
          small
        >
          <b-tbody>
            <b-tr
              v-for="tag in queueData.tags"
              :key="tag.id"
            >
              <b-td>
                {{ tag.name }}
              </b-td>
              <b-td>
                {{ tag.comment || 'Нет комментария' }}
              </b-td>
              <b-td style="width: 40px">
                <b-button
                  variant="danger"
                  :type="$const.PRIMARY_BUTTON"
                  size="sm"
                  @click="onTagRemove(tag.id)"
                >
                  <b-icon icon="trash" />
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <div
          v-else
          class="text-secondary"
        >
          Тэги не добавлены
        </div>

        <template v-if="tagTableShow">
          <b-form-input
            v-model="tagSearchString"
            type="text"
            debounce="500"
            placeholder="Поиск тэга по названию"
            size="sm"
            trim
            class="mb-2 mt-4"
          />

          <div
            v-if="filteredTags.length"
            style="max-height: 200px; overflow-y: auto;"
          >
            <b-table-simple
              small
              bordered
              class="mb-0"
            >
              <b-tbody>
                <b-tr
                  v-for="tag in filteredTags"
                  :key="tag.id"
                >
                  <b-td>
                    {{ tag.name }}
                  </b-td>
                  <b-td>
                    {{ tag.comment || 'Нет комментария' }}
                  </b-td>
                  <b-td style="width: 40px">
                    <b-button
                      variant="success"
                      :type="$const.PRIMARY_BUTTON"
                      size="sm"
                      @click="onTagAdd(tag)"
                    >
                      <b-icon icon="plus" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>

          <small
            v-if="tagSearchString && !filteredTags.length"
            class="ml-2"
          >
            Тэг не найден
          </small>
        </template>
      </b-form-group>
    </template>

    <template #modal-footer>
      <b-button
        variant="success"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onSave"
      >
        Сохранить
      </b-button>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required, minLength, requiredIf, maxValue, minValue,
} from 'vuelidate/lib/validators';
import { showValidationErrorMessage, showErrorCustomMessage } from '@/helpers/messages';

import Preloader from '@/components/Preloader';

import { clinicService } from '@/services';

export default {
  name: 'QueueEditModal',
  components: {
    Preloader,
  },
  mixins: [validationMixin],
  props: {
    queue: {
      type: Object,
      default: () => ({}),
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    afterSave: {
      type: [Function],
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      isClinicLoading: false,
      clinicSearchString: '',
      doctorSearchString: '',
      tagSearchString: '',
      clinicTableShow: false,
      doctorTableShow: false,
      tagTableShow: false,
      clinicCurrentPage: 1,
      doctorCurrentPage: 1,
      clinicLimit: 5,
      doctorLimit: 5,
      clinicPerPage: 5,
      doctorPerPage: 5,
      clinics: [],
      doctors: [],
      tags: [],
      isConsultationLimitForDoctor: false,
      isFreeConsultationsLimit: false,
      queueData: {
        id: null,
        name: null,
        description: null,
        consultationLimitForDoctor: null,
        doctors: [],
        tags: [],
        consultationLimitForPatientPerDay: null,
        consultationLimitForPatientPerMonth: null,
      },
    };
  },
  validations() {
    return {
      queueData: {
        id: {},
        name: { required },
        description: { required },
        tags: {
          required,
          minLength: minLength(1),
        },
        doctors: {
          required,
          minLength: minLength(1),
        },
        consultationLimitForPatientPerDay: {
          required: requiredIf(() => this.patientPerMonthLimit),
          minValue: minValue(1),
          maxValue: maxValue(this.queueData.consultationLimitForPatientPerMonth),
        },
        consultationLimitForPatientPerMonth: {
          required: requiredIf(() => this.patientPerDayLimit),
          minValue: minValue(this.queueData.consultationLimitForPatientPerDay),
        },
        consultationLimitForDoctor: {
          minValue: minValue(1),
        },
      },
    };
  },
  computed: {
    errorsValidation() {
      const errors = {};

      errors.clinic = [];
      if (this.$v.$error && !this.queueData.doctors.length && !this.clinicTableShow) {
        errors.clinic.push('Необходимо выбрать клинику');
      }
      errors.doctor = [];
      if (this.$v.$error && !this.queueData.doctors.length && !this.doctorTableShow) {
        errors.doctor.push('Необходимо добавить врача');
      }
      errors.tag = [];
      if (this.$v.$error && !this.queueData.tags.length && !this.tagTableShow) {
        errors.tag.push('Необходимо добавить тэг');
      }

      return errors;
    },
    clinicOffSet() {
      return (this.clinicCurrentPage - 1) * this.clinicLimit;
    },
    doctorOffSet() {
      return (this.doctorCurrentPage - 1) * this.doctorLimit;
    },
    filteredClinics() {
      return this.clinics.filter((c) => {
        if (this.clinicSearchString && !c.name.toLowerCase().includes(this.clinicSearchString.toLowerCase())) return false; // search by clinic name from doctorSearchString
        return true;
      });
    },
    filteredClinicsSlice() {
      return this.filteredClinics.slice(this.clinicOffSet, this.clinicOffSet + this.clinicLimit);
    },
    filteredDoctors() {
      return this.doctors.filter((d) => {
        if (this.doctorSearchString && !d.name.toLowerCase().includes(this.doctorSearchString.toLowerCase())) return false; // search by doctor name from doctorSearchString
        return !this.queueData.doctors.find((dd) => dd.id === d.id); // return false if doctor added to userdata
      });
    },
    filteredDoctorsSlice() {
      return this.filteredDoctors.slice(this.doctorOffSet, this.doctorOffSet + this.doctorLimit);
    },
    filteredTags() {
      return this.tags.filter((t) => {
        if (this.tagSearchString && !t.name.toLowerCase().includes(this.tagSearchString.toLowerCase())) return false; // search by tag name from tagSearchString
        return !this.queueData.tags.find((tt) => tt.id === t.id); // return false if tag added to userdata
      });
    },
    patientPerDayLimit() {
      return !!this.queueData.consultationLimitForPatientPerDay;
    },
    patientPerMonthLimit() {
      return !!this.queueData.consultationLimitForPatientPerMonth;
    },
  },
  async created() {
    this.isLoading = true;
    try {
      if (this.queue.id) {
        this.queueData = await this.$store.dispatch(this.$types.QUEUE_FETCH, { id: this.queue.id });
        console.log('this.queueData', this.queueData);

        this.isConsultationLimitForDoctor = !!this.queueData.consultationLimitForDoctor;
        this.isFreeConsultationsLimit = !!this.queueData.consultationLimitForPatientPerDay || !!this.queueData.consultationLimitForPatientPerMonth;
        this.queueData.doctors = this.queueData.doctors.map((d) => ({ id: d.id, name: d.fullName }));
      }
      await this.tagsFetch();
      await this.clinicsNameFetch();
      await this.doctorsNameFetch();
    } catch (error) {
      console.error(error);
    }

    this.isLoading = false;
  },
  methods: {
    async clinicsNameFetch() {
      this.clinics = await clinicService.getListNames();
    },
    async tagsFetch() {
      this.tags = await this.$store.dispatch(this.$types.QUEUES_TAGS_FETCH);
    },
    async doctorsNameFetch() {
      this.doctors = (await this.$store.dispatch(this.$types.DOCTORS_FETCH))
        .map((doctor) => ({ id: doctor.id, name: doctor.fullName }));
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onClinicSelect(id) {
      const { doctors } = await this.fetchClinicById(id);
      doctors.forEach((d) => {
        if (this.queueData.doctors.find((dd) => dd.id === d.id)) return; // return if doctor already in array

        const doctor = {
          id: d.id,
          name: `${d.lastName} ${d.firstName} ${d.middleName}`,
        };

        this.queueData.doctors.push(doctor);
      });

      this.isClinicLoading = false;
    },
    onDoctorRemove(id) {
      this.queueData.doctors = this.queueData.doctors.filter((d) => d.id !== id);
    },
    onDoctorAdd(doctor) {
      if (this.queueData.doctors.find((d) => d.id === doctor.id)) return; // return if doctor already in array
      if (doctor) this.queueData.doctors.push(doctor);
    },
    onTagRemove(id) {
      this.queueData.tags = this.queueData.tags.filter((t) => t.id !== id);
    },
    onTagAdd(tag) {
      if (this.queueData.tags.find((t) => t.id === tag.id)) return; // return if tag already in array
      if (tag) this.queueData.tags.push(tag);
    },
    toggleClinicTableShow() {
      this.clinicTableShow = !this.clinicTableShow;
      this.clinicSearchString = '';
    },
    toggleDoctorTableShow() {
      this.doctorTableShow = !this.doctorTableShow;
      this.doctorSearchString = '';
    },
    toggleTagTableShow() {
      this.tagTableShow = !this.tagTableShow;
      this.tagSearchString = '';
    },
    async onSave() {
      this.$v.$touch();
      if (this.$v.$error) {
        showValidationErrorMessage();
        return;
      }

      try {
        this.isLoading = true;
        await this.$store.dispatch(this.$types[this.queue.id ? 'QUEUE_UPDATE' : 'QUEUE_CREATE'], {
          ...this.queueData,
          consultationLimitForDoctor: !this.isConsultationLimitForDoctor ? null : this.queueData.consultationLimitForDoctor,
          doctors: this.queueData.doctors.map((d) => d.id),
          tags: this.queueData.tags.map((t) => t.id),
          consultationLimitForPatientPerDay: !this.isFreeConsultationsLimit ? null : this.queueData.consultationLimitForPatientPerDay,
          consultationLimitForPatientPerMonth: !this.isFreeConsultationsLimit ? null : this.queueData.consultationLimitForPatientPerMonth,
        });

        this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
        this.afterSave();
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchClinicById(id) {
      try {
        return clinicService.getOne(id);
      } catch (err) {
        console.warn(err);
        showErrorCustomMessage('Не удалось получить информацию о клинике');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  font-size: 12px;
  color: red;
  position: absolute;
  top: 35px;
  right: 15px;
}
</style>
