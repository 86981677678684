import { createApi } from '@/helpers/api';
import { ROUTE_COMPONENTS } from '@/helpers/consts';
import router from '@/router';
import { authService } from '@/services';

export default {
  async login({ dispatch }, {
    login, password, saveAuth,
  }) {
    const { token, role, loginDecoded } = await authService.login({ login, password });

    dispatch('updateUserData', {
      saveAuth, token, role, loginDecoded,
    });
  },
  updateUserData({ commit, dispatch }, {
    saveAuth, token, role, loginDecoded,
  }) {
    if (saveAuth) {
      localStorage.setItem('token', token);
      localStorage.setItem('role', role);
      localStorage.setItem('login', loginDecoded);
    } else {
      sessionStorage.setItem('token', token);
      sessionStorage.setItem('role', role);
      sessionStorage.setItem('login', loginDecoded);
    }

    commit('SET_ROLE', role);
    commit('SET_TOKEN', token);
    commit('SET_LOGIN', loginDecoded);

    createApi(token);
    dispatch('userFetch');
  },
  async userLogOut({ commit }) {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('role');
    localStorage.clear();
    commit('SET_TOKEN', null);
    commit('SET_ROLE', null);
    createApi('');
    router.push('/login');
    // window.location.reload();
  },
  async userFetch({ commit, dispatch }) {
    commit('SET_USER_LOADING', true);

    try {
      const { user, status } = await authService.userFetch();

      // Разлогин для удаленных аккаунтов
      if (status === 204) return dispatch('userLogOut');
      // Разлогин для старой админки
      if (user.login === 'admin@crm.doctis.ru') return dispatch('userLogOut');
      // console.log('user: ', user);
      // Проверка для страницы направления на консультацию через импорт
      const filteredPages = user.roles[0].pages.filter((p) => {
        if (user.roles[0].name !== 'Administrator' && p.url === '/referral-duty-consultation-import') return user.canReferralToDuty; // Если у юзера разблокирована страница направления на консультацию через импорт
        return true;
      });

      const userData = { ...user };
      userData.roles[0].pages = [...filteredPages];

      commit('SET_USER', userData);

      // set routes
      const { roles } = user;
      const childrenPages = [];
      const addChildrenPages = (page) => {
        if (page.url && page.url !== '#') childrenPages.push(page);
        if (page?.childPages) page.childPages.forEach(addChildrenPages);
      };

      roles[0].pages.forEach(addChildrenPages);

      const redirectUrl = childrenPages.filter((p) => p.isMenuItem).sort((a, b) => a.index - b.index)[0].url; // first menu item
      const childrenRoutes = childrenPages.map(({ url }) => ROUTE_COMPONENTS[url]).filter((p) => p);
      const routes = [
        {
          path: '/',
          component: () => import('@/layouts/MainLayout'),
          redirect: () => redirectUrl,
          meta: { authRequired: true },
          children: [...childrenRoutes],
        },
      ];

      routes.push({ path: '*', redirect: '/' });
      routes.forEach((r) => router.addRoute(r));

      return user;
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      commit('SET_USER_LOADING', false);
    }
  },
};
