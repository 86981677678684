import ReservationApi from '@/services/reservation/reservation.api';

export default class ReservationService {
  constructor($axios) {
    this.api = new ReservationApi($axios);
  }

  search(params) {
    return this.api.search(params);
  }

  cancel(params) {
    return this.api.cancel(params);
  }

  getHistoryChanges(id) {
    return this.api.getHistoryChanges(id);
  }
}
