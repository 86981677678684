<template>
  <div
    ref="wrapper"
    :class="{ clickable: isOverflow }"
    class="p-4 bg-white rounded border"
    @click="toggleView"
  >
    <div
      :class="{ overflow: isOverflow, fullview: isFullView }"
      class="clinic-info--wrapper"
    >
      <div
        class="mr-4"
        style="width:350px;"
      >
        <p class="crm-info-block h5 mb-3">
          {{ clinic.appTitle || clinic.title }}
        </p>

        <!-- <div class="crm-info-block">
          <p class="crm-info-block--label text-primary">
            Юр. лицо:
          </p>

          <p class="crm-info-text">
            {{ clinic.title }}
          </p>
        </div> -->

        <div
          class="crm-info-block"
        >
          <p class="crm-info-block--label text-primary ">
            Телефон:
          </p>

          <div class="crm-info-text text-muted">
            <div
              v-for="(contact, idx) in clinic.contacts.filter(c => c.isMain)"
              :key="idx"
            >
              {{ contact.phoneNumber }}
              <!-- <span
                v-if="contact.comment"
                class="phone-comment"
              >
                {{ contact.comment }}
              </span> -->
            </div>
          </div>
        </div>

        <div
          class="crm-info-block"
        >
          <p class="crm-info-block--label text-primary">
            Почта для ГП:
          </p>

          <div class="crm-info-text text-muted">
            <div
              v-for="(email, idx) in clinic.contactsForIL"
              :key="idx"
            >
              {{ email }}
            </div>
          </div>
        </div>

        <div class="crm-info-block">
          <p class="crm-info-block--label text-primary">
            Город:
          </p>

          <p class="crm-info-text crm-info-text--address text-muted">
            {{ clinic?.address?.city?.name }}
          </p>
        </div>

        <div class="crm-info-block">
          <p class="crm-info-block--label text-primary">
            Адрес:
          </p>

          <p class="crm-info-text crm-info-text--address text-muted">
            {{ clinic.addressString }}
          </p>
        </div>

        <b-row>
          <b-col
            v-if="clinic.documents.length"
            class="mb-1"
          >
            <span class="text-primary"> Документы клиники: </span>
            <div
              class="d-flex flex-wrap mt-1"
            >
              <div
                v-for="(doc, index) in clinic.documents"
                :key="index"
                class="d-flex flex-column mr-1 mb-1"
                style="min-width: 45%"
              >
                <b-button
                  v-b-tooltip.hover
                  variant="primary"
                  :type="$const.PRIMARY_BUTTON"
                  size="sm"
                  class="d-flex align-items-center justify-content-start"
                  :title="doc.title"
                  @click="saveClinicDocument(doc.fileId, doc.title)"
                >
                  <b-icon icon="file-earmark-fill" />
                  <span class="ml-2">{{ documentType(doc) }}</span>
                </b-button>
              </div>

              <!-- <div class="d-flex flex-column">
                <template
                  v-for="(doc, index) in clinic.documents"
                >
                  <div
                    v-if="doc.type === 4 || doc.type === 5"
                    :key="index"
                    class="p-1"
                  >
                    <b-button
                      v-b-tooltip.hover
                      size="sm"
                      class="w-100 d-flex align-items-center justify-content-start"
                      :title="doc.title"
                      variant="primary"
                      @click="saveClinicDocument(doc.fileId, doc.title)"
                    >
                      <b-icon icon="file-earmark-fill" />
                      <span class="ml-2">{{ documentType(doc) }}</span>
                    </b-button>
                  </div>
                </template>
              </div> -->
            </div>
          </b-col>
          <b-col v-if="clinic.legalPerson && clinic.legalPerson.documents && clinic.legalPerson.documents.length">
            <span class="font-weight-bold"> Документы юр.лица </span>
            <div class="d-flex mt-2">
              <div class="d-flex flex-column">
                <div
                  v-for="doc in clinic.legalPerson.documents"
                  :key="doc.fileId"
                  class="p-1"
                >
                  <b-button
                    v-b-tooltip.hover
                    variant="primary"
                    :type="$const.PRIMARY_BUTTON"
                    size="sm"
                    class="w-100 d-flex align-items-center justify-content-start"
                    :title="doc.title"
                    @click="saveFile(doc.fileId, doc.name)"
                  >
                    <b-icon icon="file-earmark-fill" />
                    <span class="ml-2 doc-file-name">{{ doc.name }}</span>
                  </b-button>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-button
        v-if="clinic.isDeleted"
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="button-return-clinic"
        @click="openClinicRecoveryModal(clinic.id)"
      >
        Восстановить
      </b-button>

      <div class="extra-crm-info">
        <div class="extra-crm-info--wrapper">
          <div
            v-if="!(clinic.specialComment || clinic.services.length || clinic.priority )"
            class="extra-crm-info--block"
          >
            <p class="extra-crm-info--label text-primary">
              Нет дополнительной информации
            </p>
          </div>

          <div
            v-if="false"
            class="extra-crm-info--block"
          >
            <p class="extra-crm-info--label text-primary mb-2">
              Специализации
            </p>
            <p class="crm-info-text text-muted">
              <!-- TODO: специализации -->
            </p>
          </div>

          <div
            v-if="clinic.services.length"
            class="extra-crm-info--block"
          >
            <p class="extra-crm-info--label text-primary mb-2">
              Услуги
            </p>
            <div class="d-flex flex-wrap">
              <div
                v-for="service in clinic.services"
                :key="service"
                class="service-item py-1 px-2 m-1 rounded"
              >
                {{ clinicServices[service] }}
              </div>
            </div>
          </div>

          <div
            v-if="clinic.priority"
            class="extra-crm-info--block"
          >
            <p class="extra-crm-info--label text-primary mb-2">
              Приоритет клиники:
            </p>
            <p class="crm-info-text text-muted">
              {{ clinicPriority }}
            </p>
          </div>

          <div v-if="!checkFeature(FEATURES_FOR_CLINICS.hideDisplayOfClinicFeatures, FEATURES_FOR_CLINICS.hideDisplayOfClinicFeatures)">
            <div
              v-if="clinic.specialComment"
              class="extra-crm-info--block"
            >
              <p class="extra-crm-info--label text-primary mb-2">
                Особенности клиники
              </p>

              <div>
                <ClassicEditor
                  v-model="clinic.specialComment"
                  class="custom-ck-class"
                  :disabled="true"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="extra-crm-info--controls">
          <b-button
            v-if="checkFeatureAccess({ name: 'Список клиник - Просмотр клиники', url: '/clinics' })"
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            class="mb-2"
            size="sm"
            @click="handleButtonClick(clinic.id)"
          >
            <b-icon
              icon="eye"
            />
          </b-button>

          <b-button
            v-if="checkFeature(FEATURES_FOR_CHAT.historyClinic, FEATURES_FOR_CLINICS.historyClinic)"
            variant="info"
            :type="$const.PRIMARY_BUTTON"
            class="mb-2"
            size="sm"
            @click="openClinicHistoryModal"
          >
            <b-icon
              icon="clock-history"
            />
          </b-button>

          <b-button
            v-if="checkFeature(FEATURES_FOR_CHAT.deleteClinic, FEATURES_FOR_CLINICS.deleteClinic)"
            variant="danger"
            :type="$const.PRIMARY_BUTTON"
            class="mb-2"
            size="sm"
            @click="openClinicCardDeleteModal(clinic.id)"
          >
            <b-icon
              icon="trash"
            />
          </b-button>
        </div>
      </div>
      <div
        v-if="clinic.isDeleted"
        class="deleted-overlay"
      />
    </div>
  </div>
</template>

<script>
import { api } from '@/helpers/api';
import { mixinRoles } from '@/mixins';

import {
  CLINIC_SERVICES,
  CLINIC_PRIORITIES,
  FEATURES_FOR_CHAT,
  FEATURES_FOR_CLINICS,
} from '@/helpers/consts';
import { saveFile } from '@/helpers/utils';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

export default {
  name: 'ClinicInfo',
  components: {
    ClassicEditor: () => import('@/components/common/CKEditor/ClassicEditorComponent.vue'),
  },
  mixins: [mixinRoles],
  props: {
    clinic: {
      type: Object,
      default: () => ({}),
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      maxHeight: 520,
      fields: [
        { key: 'id', label: 'Документ', thClass: 'text-center' },
      ],
      isOverflow: false,
      isFullView: false,
      clinicServices: CLINIC_SERVICES,
      clinicPriorities: CLINIC_PRIORITIES,
      FEATURES_FOR_CHAT,
      FEATURES_FOR_CLINICS,
    };
  },
  computed: {
    clinicPriority() {
      const index = this.clinic.priority;
      const priority = this.clinicPriorities.find((p) => p.index === index);
      return priority ? priority.title : null;
    },
  },
  mounted() {
    if (this.$refs.wrapper.offsetHeight > this.maxHeight) {
      this.isOverflow = true;
    }
  },
  methods: {
    checkFeature(keyOne, keyTwo) {
      return this.modalName ? this.checkFeatureAccess({ name: keyOne.name, url: keyOne.url })
        : this.checkFeatureAccess({ name: keyTwo.name, url: keyTwo.url });
    },
    documentType(row) {
      if (row.type === 1) {
        return 'Договор';
      }
      if (row.type === 3) {
        return 'Лицензия';
      }
      if (row.type === 4) {
        return 'Прайс';
      }
      if (row.type === 5) {
        return 'Скан';
      }
      return 'Учредительные документы';
    },
    async saveClinicDocument(fileId, name) {
      try {
        const { data: file } = await api.get(`File/clinicdocument/${fileId}`, { responseType: 'blob' });

        saveFile(file, { name });
      } catch (e) {
        console.error(e);
      }
    },
    async saveFile(fileId, name) {
      try {
        const { data: file } = await api.get(`File/${fileId}`, { responseType: 'blob' });

        saveFile(file, { name });
      } catch (e) {
        console.error(e);
      }
    },
    toggleView(event) {
      if (!this.isOverflow
        || event.target.classList.contains('crm-icon-delete')
        || event.target.classList.contains('icon')
        || event.target.tagName === 'path') {
        return;
      }
      this.isFullView = !this.isFullView;
    },
    handleButtonClick(id) {
      if (this.modalName) {
        this.openClinicPageModal(id);
      } else {
        this.$router.push(`/clinics/${id}/`);
      }
    },
    openClinicRecoveryModal(id) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ClinicRecoveryModal',
        props: {
          clinicId: id,
        },
      });
    },
    onClickEditIcon() {
      this.$store.commit('Clinic/SET_CLINIC_INFO_IS_EDIT', true);
      this.$router.push(`/clinics/${this.clinic.id}/`);
    },
    openClinicHistoryModal() {
      uiService.showModal(MODALS.CLINIC_HISTORY_MODAL, {
        name: 'ClinicHistoryModal',
        props: {
          clinicId: this.clinic.id,
        },
      });
    },
    openClinicCardDeleteModal(id) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ClinicInfoCardDeleteModal',
        props: {
          clinicId: id,
        },
      });
    },
    openClinicPageModal(id) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ClinicPageModal',
        props: {
          clinicId: id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.clinic-info--wrapper {
  display: flex;
  align-items: stretch;
  flex-grow: 1;
  position: relative;

  &.overflow:not(.fullview) {
    max-height: 235px;
    overflow: hidden;

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      box-shadow: 0px 0px 10px 4px $white;
      bottom: 0;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 75px;
      background-image: url('~assets/images/select_arrow.svg?inline');
      width: 12px;
      height: 6px;
    }
  }
}
.clickable {
  cursor: pointer;
}
.crm-info--wrapper,
.extra-crm-info--wrapper{
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 634px;
}
.crm-info--wrapper {
  flex-basis: 456px;
  padding-right: 30px;
}
.extra-crm-info {
  // height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-basis: 724px;
}
.crm-info-block {
  display: flex;

  &:not(:last-child) {
    margin: 0 0 10px 0;
  }

  .crm-info-block--name {
    font-weight: bold;
    // color: $blue-magenta;
  }
  .crm-info-block--label {
    color: $blue;
    margin: 0 10px 0 0;
    min-width: 100px;
  }
  .phone-comment {
    font-weight: normal;
  }
}
.crm-info-text {
  font-weight: 500;
  margin: 0;
  color: $black-light;
  white-space: pre-line;
  word-break: break-word;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}
.extra-crm-info--wrapper {
  flex-grow: 1;
  border-right: 1px solid rgba(218, 218, 218, 0.7);
  border-left: 1px solid rgba(218, 218, 218, 0.7);
  padding-left: 30px;
  padding-right: 46px;

  .extra-crm-info--block {

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  .extra-crm-info--label {
    color: $blue;
    margin: 0 0 15px 1px;
  }
}
.extra-crm-info--controls {
  padding-left: 30px;
  display: flex;
  flex-direction: column;
}
::v-deep.crm-icon {

  &:not(last-child) {
    margin-bottom: 15px;
  }

  &:hover {
    cursor: pointer;

    rect {
      stroke: #6E88F3;
    }
    path {
      fill: $blue;
    }
  }
}

.service-item {
  background: #F3F3F3;
}

.crm-info-text--special {
  white-space: pre-line;
}

.crm-icon-delete {
  cursor: pointer;
  &:hover {
    cursor: pointer;

    rect {
      stroke: #6E88F3;
    }
  }
}

.button-return-clinic {
  width: unset;
  padding: 8px 20px 9px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-top: 3rem;
  position: relative;
  z-index: 2;
  align-self: center;
}
.deleted-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #FFFFFF;
  opacity: 0.7;
  border-radius: 4px;
  z-index: 1;
}
.headerTableClass{
  margin-top: 20rem !important;
}

.doc-file-name {
  max-width: 80px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
