<template>
  <b-modal
    id="ChatModal"
    visible
    centered
    size="xl"
    :header-class="[allowClosingModal ? 'p-4' : 'p-1']"
    body-class="p-0"
    :hide-header-close="allowClosingModal"
    no-close-on-backdrop
    :hide-footer="true"
    @hidden="onClose"
    @ok="onClose"
  >
    <template #modal-header>
      <div>
        <b-button
          size="sm"
          :disabled="isLoading || ishideLoading"
          variant="light"
          :type="$const.PRIMARY_BUTTON"
          class="mr-2"
          @click="onClickHide"
        >
          <b-icon icon="dash" />
        </b-button>

        <b-button
          size="sm"
          variant="danger"
          :type="$const.PRIMARY_BUTTON"
          class="mr-2"
          @click="onCloseModal"
        >
          <b-icon icon="x" />
        </b-button>
      </div>
    </template>
    <div class="chat-modal-container">
      <div class="chat-modal">
        <Loading
          v-if="isLoading"
          class="loading-wrapper"
        />
        <template v-else>
          <div class="d-flex chat-modal">
            <div class="info">
              <b-input
                v-model="queryInfo"
                placeholder="Поиск"
                class="info-input"
              />

              <div class="info-list">
                <div class="info-list-title">
                  <span class="patient-info-name">В работе</span>
                  <b-icon
                    class="icon"
                    :icon="filteredAppealListInWork.length ? 'chevron-up' : 'chevron-down'"
                  />
                </div>
                <div
                  ref="info"
                  class="info-list-in-work custom-scrollbar"
                >
                  <div
                    v-for="patient in filteredAppealListInWork"
                    :key="patient.id"
                  >
                    <ChatPatientInfoItem
                      :patient="patient"
                      :class="{ highlighted: selectedItems.includes(patient.id) }"
                      @click="clickedToPatient(patient)"
                    />
                  </div>
                </div>

                <div class="info-list-title">
                  <span class="patient-info-name">Новые</span>
                  <b-icon
                    class="icon"
                    :icon="filteredAppealListNew.length ? 'chevron-up' : 'chevron-down'"
                  />
                </div>
                <div class="info-list-new-appeal custom-scrollbar">
                  <div
                    v-for="patient in filteredAppealListNew"
                    :key="patient.id"
                  >
                    <ChatPatientInfoItem
                      :patient="patient"
                      :take-of-work="true"
                      @take-on="clickTakeOfWork(patient)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="patient-chat"
            >
              <b-input-group
                class="patient-input-group"
              >
                <b-input
                  v-model="queryChat"
                  placeholder="Чат с пациентом"
                  class="patient-input"
                  size="sm"
                  @keyup.native.enter="getFilteredMessage"
                />
                <b-button
                  variant="light"
                  :type="$const.PRIMARY_BUTTON"
                  class="rounded-0 border-0"
                  :disabled="!patientInfo"
                  @click="getFilteredMessage"
                >
                  <b-icon icon="search" />
                </b-button>
              </b-input-group>

              <div
                ref="chatInner"
                class="chat-inner custom-scrollbar"
              >
                <template v-if="isLoadingMessages">
                  <preloader style="margin: 20px auto" />
                </template>
                <template v-else>
                  <b-button
                    v-if="patientDetailInfo?.id && isAllMessages"
                    variant="light"
                    :type="$const.PRIMARY_BUTTON"
                    class="w-100"
                    @click="getAllHistoryMessages"
                  >
                    Загрузить историю
                  </b-button>
                  <div v-if="isAllMessages">
                    <ChatMessageContainer
                      v-for="(message, index) in filteredMessages"
                      :key="message?.id"
                      :index="index"
                      :message="message"
                      :appeal-id="patientInfo?.id"
                      :filtered-messages="filteredMessages"
                    />
                  </div>
                  <div v-else>
                    <transition-group name="fade">
                      <div
                        v-for="(appeal) in visibleAppeals"
                        :ref="`appeal-${appeal.id}`"
                        :key="appeal.id"
                        class="appeal-item"
                      >
                        <ChatMessageContainer
                          v-for="(message, index) in appeal.messages"
                          :key="message.id"
                          :index="index"
                          :message="message"
                          :appeal-id="appeal.id"
                          :filtered-messages="appeal.messages"
                        />
                      </div>
                    </transition-group>
                  </div>
                </template>
              </div>

              <div class="chat-controls">
                <div class="chat-controls-upload">
                  <input
                    ref="fileInput"
                    type="file"
                    multiple
                    :disabled="!patientInfo"
                    accept="application"
                    @change="changeInputFiles"
                  >
                  <b-icon
                    class="icon"
                    icon="paperclip"
                  />
                </div>
                <ResizableTextarea
                  ref="resize"
                  :allow-editing="!!patientDetailInfo"
                  :editable-text="editableText.text"
                  @messageSend="sendTextMessage"
                  @inputText="getMessageText"
                />

                <transition name="slide-fade-text">
                  <div
                    v-if="countOfTemplates"
                    class="custom-popover"
                  >
                    <div
                      v-for="(value, key) in templates"
                      :key="key"
                      class="mt-1"
                    >
                      <div
                        class="cursor-pointer"
                        @click="chooseValue(value)"
                      >
                        <p class="style-text-value">
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                </transition>

                <div
                  class="chat-controls-items"
                  :class="{'with-template-btn': isTemplateBtn && patientDetailInfo?.id}"
                >
                  <b-icon
                    class="icon-cursor"
                    icon="cursor"
                    @click="sendTextMessage"
                  />
                </div>
                <div
                  v-if="checkFeature(FEATURES_FOR_CHAT.templateBtn) && patientDetailInfo?.id"
                  class="add-template-icon"
                  @click="openAppealTemplateModal"
                >
                  <b-icon
                    class="icon-cursor"
                    icon="file-earmark-text"
                    title="Добавить шаблон"
                  />
                </div>
              </div>
            </div>
            <div class="patient-detail-info">
              <custom-scrollbar>
                <div v-if="checkFeature(FEATURES_FOR_CHAT.mainWindow)">
                  <span class="patient-detail-info-title">
                    Детальная информация
                  </span>
                  <div
                    class="patient-fio-wrapper"
                  >
                    <ChatPatientFio :patient-detail-info="patientDetailInfo" />

                    <ChatPatientInfoButtons
                      :roles="filteredRoles"
                      :patient-detail-info="patientDetailInfo"
                      @chatAppealMove="chatAppealMove"
                      @moveToTheSameDepartment="moveToTheSameDepartment"
                    />
                  </div>
                </div>
                <ChatPatientDetailTabs
                  :patient-detail-info="patientDetailInfo"
                  :chat-appeal-id="patientInfo?.id"
                  :patient-info="patientInfo"
                />
              </custom-scrollbar>
              <b-button
                variant="danger"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                :disabled="!patientDetailInfo?.id"
                @click="clickCloseAppeal"
              >
                <span>Закрыть обращение</span>
              </b-button>
            </div>
          </div>
        </template>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Loading from '@/components/Loading';
import { v4 as uuidv4 } from 'uuid';
import * as types from '@/store/types';
import { mapGetters } from 'vuex';
import ChatMessageContainer from '@/components/Chat/ChatMessageContainer';
import ChatPatientInfoButtons from '@/components/Chat/ChatPatientInfoButtons.vue';
import ResizableTextarea from '@/components/base/ResizableTextarea';
import { format } from '@evd3v/date-fns';
import ChatPatientFio from '@/components/Chat/ChatPatientFio';
import ChatPatientInfoItem from '@/components/Chat/ChatPatientInfoItem';
import ChatPatientDetailTabs from '@/components/Chat/ChatPatientDetailTabs.vue';
import HubClientService from '@/services/core/hub';
import { mixinRoles } from '@/mixins';
import { FEATURES_FOR_CHAT, FORMATS_FOR_PREVIEW, ALLOWED_FORMATS } from '@/helpers/consts';
import Preloader from '@/components/Preloader';
import newChatMessageSound from '@/assets/audio_tracks/new-chat-message.mp3';

import {
  chatService,
  fileService,
  appealsService,
  appealsTemplatesChatService,
} from '@/services';

import {
  showErrorCustomMessage,
  showCustomMessage,
  showSuccessMessage,
} from '@/helpers/messages';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

import { debounce } from '@/helpers/debounce';

export default {
  name: 'ChatModal',
  components: {
    Loading,
    ChatMessageContainer,
    ChatPatientInfoItem,
    ChatPatientFio,
    ChatPatientInfoButtons,
    ChatPatientDetailTabs,
    ResizableTextarea,
    Preloader,
  },
  mixins: [mixinRoles],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      isLoadingMessages: false,
      ishideLoading: false,
      transfer: '',
      queryInfo: '',
      queryChat: null,
      text: '',
      editableText: '',
      roles: [],
      userRole: null,
      patientInfo: null,
      activePatientId: null,
      patientDetailInfo: null,
      appealListNew: [],
      appealListInWork: [],
      messages: [],
      filteredMessages: [],
      selectedItems: [],
      FEATURES_FOR_CHAT,
      chatDataObj: null,
      historyAppeals: [],
      allAppeals: [],
      visibleAppeals: [],
      messagesForAppeal: [],
      isAllMessages: false,
      timeout: null,
      words: [],
      templates: {},
    };
  },
  computed: {
    ...mapGetters({
      appealCreatedTrigger: 'Appeal/GET_APPEAL_CREATED_TRIGGER',
      chatData: types.GET_CHAT_DATA,
    }),
    activePatient() {
      try {
        return this.filteredAppealListInWork.find((item) => item.id === this.activePatientId);
      } catch (e) {
        console.error(e);
      }
      return true;
    },
    filteredRoles() {
      return this.roles.filter((r) => r.isRoleForChatAppeal && r.id !== this.userRole.id && r.titleName);
    },
    filteredAppealListInWork() {
      const search = this.queryInfo.toLowerCase();
      return this.appealListInWork.filter((m) => m.personInfo.lastName.toLowerCase().includes(search));
    },
    filteredAppealListNew() {
      const search = this.queryInfo.toLowerCase();
      return this.appealListNew.filter((m) => m.personInfo.lastName.toLowerCase().includes(search));
    },
    allowClosingModal() {
      return this.checkFeature(FEATURES_FOR_CHAT.chatWindow);
    },
    isTemplateBtn() {
      return this.checkFeature(FEATURES_FOR_CHAT.templateBtn);
    },
    searchWord() {
      const word = this.words.at(-1);
      return word !== undefined ? word : '';
    },
    countOfTemplates() {
      return Object.keys(this.templates).length;
    },
  },
  watch: {
    async patientInfo(event) {
      await this.getPatient(event);
      this.queryChat = null;
    },
    filteredMessages: {
      handler() {
        if (this.isAllMessages) {
          this.scrollToBottom(this.$refs.chatInner);
        }
      },
    },
    async appealCreatedTrigger() {
      await this.getChatAppealInWork();
      await this.getPatient(this.patientInfo.id);
    },
  },
  async created() {
    this.isLoading = true;
    try {
      const userRoleName = this.$store.state.Auth.role;
      this.roles = await this.$store.dispatch(this.$types.ROLES_FETCH);
      this.userRole = this.roles.find((r) => r.name === userRoleName);
      await this.getChatAppealList();
      await this.getChatAppealInWork();
    } catch (e) {
      console.log(e);
    }

    this.isLoading = false;
  },
  async mounted() {
    this.initializeSocket();
    if (this.chatDataObj) {
      this.initChatByChatData();
    }
  },
  beforeDestroy() {
    HubClientService.off('chat.message', this.onGetMessage);
    HubClientService.off('chatAppeal.created', this.onGetAppealList);
    HubClientService.off('chatAppeal.moved', this.onGetAppealList);
    HubClientService.off('chatAppeal.takeOn', this.onGetAppealList);
    HubClientService.off('chat.messageEdited', this.onEditMessage);
  },
  methods: {
    checkFeature(key) {
      return this.checkFeatureAccess({ name: `${key?.name}`, url: `${key?.url}` });
    },
    async initializeSocket() {
      HubClientService.on('chat.message', this.onGetMessage);
      HubClientService.on('chatAppeal.created', this.onGetAppealList);
      HubClientService.on('chatAppeal.moved', this.onGetAppealList);
      HubClientService.on('chatAppeal.takeOn', this.onGetAppealList);
      HubClientService.on('chat.messageEdited', this.onEditMessage);
    },
    async onGetMessage(message) {
      if (this.isInfoMessage(message)) {
        this.filteredMessages.push(message);
      } else if (this.isPatientMessage(message)) {
        this.playAudioOnNewMessageChat();
        const modifiedMessage = await this.getMessageWithUrl(message);
        this.filteredMessages.push(modifiedMessage);
        if (this.visibleAppeals.length) {
          const lastObj = this.visibleAppeals[this.visibleAppeals.length - 1];
          if (lastObj.id === modifiedMessage.params.chatAppealId) {
            lastObj.messages.push(modifiedMessage);
          }
        }
        await chatService.markAsRead(message.chatId);
        if (this.isAllMessages) {
          this.scrollToBottom(this.$refs.chatInner);
        }
      } else {
        const modifiedMessage = await this.getMessageWithUrl(message);
        if (this.visibleAppeals.length) {
          const lastObj = this.visibleAppeals[this.visibleAppeals.length - 1];
          if (lastObj.id === modifiedMessage.params.chatAppealId) {
            lastObj.messages.push(modifiedMessage);
          }
        }
        this.scrollToBottom(this.$refs.scrollContainer);
      }
    },

    async onEditMessage(editedMessage) {
      if (editedMessage) {
        this.filteredMessages = this.filteredMessages.map((message) => {
          if (message.id === editedMessage.id) {
            return {
              ...message,
              text: editedMessage.text,
            };
          }
          return message;
        });
      }
    },

    isPatientMessage(message) {
      return message?.member?.memberType === 'Patient' && message.chatId === this.activePatient?.chatId;
    },
    isInfoMessage(message) {
      return message?.messageType === 'Info' && message.chatId === this.activePatient?.chatId;
    },
    async onGetAppealList() {
      await this.getChatAppealList();
    },
    formatDate(date) {
      return format(new Date(date), 'dd.MM.yyyy');
    },
    onClose() {
      this.$emit('input', false);
    },
    onCloseModal() {
      this.onClose();
      this.chatDataObj = null;
    },
    getMessageText(text) {
      this.text = text;
      this.words = [this.text];
      // this.words = this.words.reduce((acc, word) => acc.concat(word.split(' ').filter((part) => part.length > 0)), []);
      const debouncedSearch = debounce((fullText) => {
        const searchPhrase = fullText.trim();
        const counterWords = searchPhrase.length;
        if (counterWords && counterWords !== 0) {
          this.searchTemplates(searchPhrase);
        } else {
          this.templates = {};
        }
      }, 500);

      debouncedSearch(this.searchWord);
    },
    async searchTemplates(searchPhrase) {
      try {
        const { templates } = await appealsTemplatesChatService.getWithSearch(searchPhrase, true);
        if (Object.keys(templates).length) {
          this.templates = this.transformValues(templates);
        } else {
          this.templates = '';
        }
      } catch (e) {
        showCustomMessage('error', 'Ошибка', '');
        console.log(e);
      }
    },
    chooseValue(value) {
      const { textarea } = this.$refs.resize.$refs;
      const currentText = textarea.innerText;
      const words = currentText.split(' '); // разбиваем текст на слова
      words[words.length - 1] = value; // заменяем последнее слово на выбранное значение
      textarea.innerText = words.join(' '); // объединяем слова обратно в строку и обновляем текстовое поле
      this.text = textarea.innerText;
      this.templates = {};
    },
    clearTextArea() {
      this.$refs.resize.$refs.textarea.textContent = '';
      this.getMessageText('');
    },
    async getChatAppealInWork() {
      const data = await this.chatAppealsInWork();
      this.appealListInWork = data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    },
    async getChatAppealList() {
      try {
        const data = this.userRole.chatAppealLine === 1
          ? await this.getChatAppealUnassigned()
          : await this.getChatAppealForRole(this.userRole.id);
        this.appealListNew = data
          .filter((p) => p.personInfo.lastName !== null || p.personInfo.firstName !== null)
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      } catch (error) {
        console.error(error);
      }
    },
    async getChatAppealUnassigned() {
      try {
        const data = await chatService.getUnassigned();
        return data;
      } catch (err) {
        showErrorCustomMessage('Не удалось получить список обращений');
        console.warn(err);
        throw err;
      }
    },
    async getChatAppealForRole(id) {
      try {
        const data = await chatService.getForRole(id);
        return data;
      } catch (err) {
        showErrorCustomMessage('Не удалось получить список обращений');
        console.warn(err);
        throw err;
      }
    },
    async chatAppealsInWork() {
      try {
        const data = await chatService.getInWork();
        return data;
      } catch (err) {
        showErrorCustomMessage('Не удалось получить список обращений');
        console.warn(err);
        throw err;
      }
    },
    async getAllMessages(patient) {
      try {
        const data = await chatService.getMessage({ chatAppealId: patient.id });
        return data;
      } catch (err) {
        showErrorCustomMessage('Не удалось получить чат с пациентом');
        console.warn(err);
        throw err;
      }
    },
    async getImageForMessage(fileName) {
      try {
        const previewData = await this.fetchFileBlob(this.patientInfo?.id, fileName);
        return URL.createObjectURL(previewData);
      } catch (e) {
        console.error(e);
        return null;
      }
    },
    async fetchFileBlob(chatAppealId, fileName) {
      const data = await chatService.getFile({ chatAppealId, fileName });
      return data;
    },
    async getMessageWithUrl(message) {
      if (message.messageType === 'File') {
        const parserdPayload = JSON.parse(message.payload);
        const { filesInformation } = parserdPayload;
        if (FORMATS_FOR_PREVIEW.includes(filesInformation[0].extension)) {
          const messageImage = {
            ...message,
            imageUrl: await this.getImageForMessage(filesInformation[0].name),
          };

          return messageImage;
        }
      }
      return message;
    },
    async getChatAppealMessage(patient) {
      this.patientInfo = patient;
      try {
        const data = await this.getAllMessages(patient);

        const messagePromises = data.map(async (item) => {
          try {
            return await this.getMessageWithUrl(item);
          } catch (error) {
            console.error(error);
          }
        });
        this.messages = await Promise.all(messagePromises);
        this.filteredMessages = this.messages;
        this.isAllMessages = true;
      } catch (e) {
        console.error(e);
      }
    },
    getFilteredMessage() {
      try {
        const search = this.queryChat?.toLowerCase();
        if (!search) {
          this.filteredMessages = this.messages;
          return;
        }

        this.filteredMessages = this.messages.filter((m) => {
          const messageText = m.text?.toLowerCase() || '';
          return messageText.includes(search);
        });
      } catch (error) {
        console.error(error);
      }
    },
    async sendTextMessage() {
      if (!this.patientInfo) return;
      if (this.text.trim().length > 0) {
        try {
          await chatService.sendSms({
            id: this.patientInfo.id,
            params: {
              text: this.text,
              messageType: 'Text',
            },
          });
        } catch (err) {
          showErrorCustomMessage('Не удалось отправить сообщение');
          console.warn(err);
          throw err;
        }
        this.clearTextArea();
        await this.getChatAppealMessage(this.patientInfo);
        this.scrollToBottom(this.$refs.chatInner);
      }
    },
    async changeInputFiles(e) {
      this.scrollToBottom(this.$refs.chatInner);
      try {
        const files = [...e.target.files];

        const validFiles = this.filterValidFiles(files, ALLOWED_FORMATS);

        if (validFiles.length === 0) {
          showCustomMessage('warning', 'Не правильный формат файла', 'Допустимые для загрузки в чат форматы: JPG JPEG PNG HEIC PDF MP4 HEVC');
          return;
        }

        this.$refs.fileInput.value = null;
        console.log('files', files);
        const fileNames = await fileService.send(files);

        const newfilenames = fileNames.map((f) => f.data);
        newfilenames.map(async (filename) => {
          try {
            await chatService.sendSms({
              id: this.patientInfo.id,
              params: {
                messageType: 'File',
                payload: JSON.stringify({ fileNames: [filename] }),
              },
            });
          } catch (err) {
            showErrorCustomMessage('Не удалось отправить сообщение');
            console.warn(err);
            throw err;
          }
          await this.getChatAppealMessage(this.patientInfo);
        });
      } catch (error) {
        console.error(error);
      }
    },
    filterValidFiles(files, allowedFormats) {
      return files.filter((file) => {
        const fileExtension = file.name.split('.').pop().toLowerCase();

        return allowedFormats.some((allowedFormat) => {
          const allowedExtension = allowedFormat.split('/').pop().toLowerCase();

          return file.type === allowedFormat || fileExtension === allowedExtension;
        });
      });
    },
    toggleItemSelection(itemId) {
      if (this.selectedItems.includes(itemId)) {
        this.selectedItems = this.selectedItems.filter((id) => id === itemId);
      } else {
        this.selectedItems = [];
        this.selectedItems.push(itemId);
      }
    },
    async clickTakeOfWork(patient) {
      try {
        this.activePatientId = patient.id;
        await this.chatAppealTakeOn(this.activePatientId);
        await Promise.all([
          this.getChatAppealList(),
          this.getChatAppealInWork(),
        ]);
        await this.getChatAppealMessage(patient);
        this.toggleItemSelection(patient.id);
      } catch (e) {
        console.log(e);
      }
    },
    async chatAppealTakeOn(id) {
      try {
        await chatService.takeOn(id);
      } catch (err) {
        showErrorCustomMessage('Не удалось взять обращение в работу');
        console.warn(err);
        throw err;
      }
    },
    async chatAppealMove(appeal) {
      try {
        await chatService.move({ chatAppeallId: this.patientInfo.id, targetRoleId: appeal.role, comment: appeal.comment });
      } catch (err) {
        showErrorCustomMessage('Не удалось переместить обращение в другую группу');
        console.warn(err);
        throw err;
      }
      await this.getChatAppealInWork();
      this.patientInfo = null;
      this.patientDetailInfo = null;
      this.filteredMessages = null;
    },
    async moveToTheSameDepartment() {
      try {
        await chatService.putUnassign(this.activePatientId);
      } catch (err) {
        showErrorCustomMessage('Не удалось перевести обращение обратно в отдел');
        console.warn(err);
      }
      await this.getChatAppealInWork();
      this.patientDetailInfo = null;
      this.filteredMessages = null;
    },
    async clickCloseAppeal() {
      return this.activePatient?.createdAppeals?.length
        ? this.openChatAppealDeleteModal()
        : this.openCreatingAppealBeforeClosingModal();
    },
    async getPatient(obj) {
      if (!obj?.personId) return;

      try {
        const [patient, deviceId] = await Promise.all([
          this.$store.dispatch(this.$types.PATIENT_FETCH, obj?.personId),
          this.$store.dispatch(this.$types.GET_USER_DEVICE_ID, obj?.personId),
        ]);

        this.patientDetailInfo = { ...patient, deviceId };
      } catch (error) {
        console.error(error);
      }
    },
    scrollToBottom(id) {
      this.$nextTick(() => {
        if (id) {
          setTimeout(() => {
            id.scrollTo({
              top: id.scrollHeight,
              behavior: 'smooth',
            });
          }, 300);
        }
      });
    },
    async clickedToPatient(patient) {
      try {
        if (this.patientInfo?.id === patient?.id) return;

        this.isLoadingMessages = true;
        this.activePatientId = patient.id;

        await this.getChatAppealMessage(patient);
        await chatService.markAsRead(patient.chatId);

        this.toggleItemSelection(patient.id);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoadingMessages = false;
      }
    },
    openChatAppealDeleteModal() {
      uiService.showModal(MODALS.CHAT_APPEAL_DELETE_MODAL, {
        name: 'ChatAppealDeleteModal',
        props: {
          patient: this.patientInfo,
        },
        actions: {
          afterDelete: async (id) => {
            try {
              await this.closeChatAppeal(id);
              this.getChatAppealInWork();
              this.filteredMessages = null;
              this.patientDetailInfo = null;
              this.clearTextArea();
            } catch (err) {
              console.log(err);
            }
          },
        },
      });
    },
    openAppealTemplateModal() {
      uiService.showModal(MODALS.APPEAL_TEMPLATE_CHAT_MODAL, {
        name: 'AppealTemplatesChatModal',
        props: {
          title: 'Выберите шаблон для вставки его в чат',
          // onSelectAppealTemplate: this.onSelectAppealRejectTemplate,
        },
        actions: {
          onSelectAppealTemplate: (value) => {
            this.$refs.resize.$refs.textarea.textContent = value;
            this.getMessageText(value);
          },
        },
      });
    },
    async closeChatAppeal(id) {
      try {
        await chatService.close(id);
      } catch (err) {
        showErrorCustomMessage('Не удалось закрыть обращение');
        console.warn(err);
        throw err;
      }
    },
    openCreatingAppealBeforeClosingModal() {
      uiService.showModal(MODALS.APPEAL_BEFORE_CLOSING_MODAL, {
        name: 'CreatingAppealBeforeClosingModal',
        props: {
          patient: this.patientInfo,
        },
        actions: {
          afterDelete: async (id, data) => {
            try {
              await this.createNewAppeals(data);
              await this.closeChatAppeal(id);
              this.getChatAppealInWork();
              this.clearTextArea();
              this.filteredMessages = null;
              this.patientDetailInfo = null;
            } catch (err) {
              console.log(err);
            }
          },
        },
      });
    },
    async createNewAppeals(data) {
      try {
        const response = await appealsService.create(data);
        if (response?.status === 'success') {
          showSuccessMessage(response.data.id, 'создано');
        }
        if (response?.id) {
          showSuccessMessage(response.id, 'создано');
        }
        return response;
      } catch (err) {
        console.log(err);
        showErrorCustomMessage('При создании обращения что-то пошло не так');
      }
    },
    playAudioOnNewMessageChat() {
      const audio = new Audio(newChatMessageSound);
      audio.play();
    },
    onClickHide() {
      if (this.ishideLoading) return;
      this.ishideLoading = true;
      try {
        const chatData = {
          id: uuidv4(),
          data: {
            text: this.text,
            patient: this.patientInfo,
          },
        };
        this.chatDataObj = chatData;
      } catch (e) {
        console.error(e);
        this.ishideLoading = false;
      } finally {
        this.onClose();
      }
    },
    async initChatByChatData() {
      const {
        data: {
          text,
          patient,
        },
      } = this.chatDataObj;

      await this.clickedToPatient(patient);

      await this.$nextTick();

      setTimeout(() => {
        if (this.$refs.resize && this.$refs.resize.$refs.textarea) {
          this.$refs.resize.$refs.textarea.textContent = text;
          this.text = text;
        }
      }, 300);
    },
    async getAllHistoryMessages() {
      this.isAllMessages = false;
      this.visibleAppeals = [];
      this.isLoadingMessages = true;

      try {
        this.historyAppeals = await chatService.getHistory(this.patientDetailInfo.id);

        if (this.historyAppeals && this.historyAppeals.length > 0) {
          const allAppealsData = await Promise.all(this.historyAppeals.map(async (appeal) => {
            try {
              const data = await chatService.getOneHistoryMessage({
                chatAppealId: appeal.id,
                patientId: this.patientDetailInfo.id,
              });

              const messages = await Promise.all(data.map((item) => this.getMessageWithUrl(item)));

              return {
                id: appeal.id,
                created: appeal.createdAt,
                messages,
              };
            } catch (err) {
              showErrorCustomMessage('Не удалось получить историю сообщений обращения');
              console.warn(err);
              throw err;
            }
          }));

          // this.allAppeals = allAppealsData.sort((a, b) => new Date(b.created) - new Date(a.created));
          // const systemText = 'Так как в течение длительного времени';
          // this.allAppeals = allAppealsData.filter((elem) => elem.messages.every((message) => message.text && !message.text.includes(systemText)));
          this.allAppeals = allAppealsData;
          this.visibleAppeals = this.allAppeals.sort((a, b) => new Date(a.created) - new Date(b.created));
        }
      } catch (err) {
        showErrorCustomMessage('Ошибка при получении истории сообщений');
        console.error(err);
      } finally {
        this.isLoadingMessages = false;
      }
    },
    transformValues(obj) {
      Object.keys(obj).forEach((key) => {
        obj[key] = obj[key]
          .slice(1, -1)
          .replace(/\\n/g, '\n')
          .replace(/\\/g, '');
      });
      return obj;
    },
  },
};
</script>

<style lang="scss">
#ChatModal___BV_modal_header_ div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>

<style lang="scss" scoped>
::v-deep#ChatModal___BV_modal_outer_ {
  z-index: 999!important;
}

.chat-modal-container {
  width: 100%;
  height: 100%;
  padding: 0;
  box-sizing: border-box;
  margin-bottom: 1px;
}

.chat-modal {
  width: 100%;
  height: calc(100vh - 150px);
}
.info {
  width: 30%;
  height: calc(100vh - 150px);
  padding: 10px 0;

  &-input {
    background-color: #4747470a;
    box-shadow: 0 0 2px rgba(0, 0, 0, .2) inset;
    margin-bottom: 10px;
    width: 95%;
    margin: 0 auto;
  }

  &-list {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 210px);
      &-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #dbdbdb54;
        padding: 5px 10px;
        margin: 10px 0;

        span {
          color: #858282
        }
        .icon {
          color: #858282;
          stroke: #858282;
          stroke-width: 15%;
        }
      }
      &-in-work {
        height: 100%;
        overflow-y: auto;
      }
      &-new-appeal{
        height: 100%;
        overflow-y: auto;
      }
  }
}

.patient-chat {
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 40%;
  height: 100%;
  border-left: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
}

.patient-input-group {
  border: 1px solid #e5f0f8;
  margin-top: 5px;
}

.patient-input {
  border: none;
}

.patient-detail-info {
  width: 30%;
  display: flex;
  flex-direction: column;
  padding: 10px;

  &-title {
    display: block;
    padding-top: 10px;
    margin: 0 auto;
    text-align: center;
  }
  .patient-fio-wrapper {
    display: flex;
    flex-direction: column;
    margin: 10px 15px;
  }
  .appeal-btn {
    background-color: #ebebeb;
    border: none;
    .icon{
      color:rgb(97, 93, 93);
    }
  }
}
.chat-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding-top: 10px;
  overflow-y: auto;
}
.chat-controls {
  display: flex;
  align-items: flex-end;
  max-height: 120px;
  min-height: 40px;
  position: relative;

  &-upload {
    display: flex;
    align-items: center;
    width: 30px;
    flex: 0 0 30px;
    position: absolute;
    left: 5px;
    bottom: 5px;
    cursor: pointer;

    input {
      position: absolute;
      left: 0;
      bottom: 3px;

      font-size: 0;
      opacity: 0;
      width: 15px;
      height: 29px;
      cursor: pointer;
    }

    .icon {
      height: 20px;
      left: 0;
      position: absolute;
      pointer-events: none;
      bottom: 5px;
      width: 20px;
    }
  }
}
.chat-controls-items {
  display: flex;
  gap: 7px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.icon-list-ol {
  cursor: pointer;
}
.icon-cursor {
  cursor: pointer;
}

.add-template-icon {
  margin: 0 10px 0 10px;
  position: relative;
  bottom: 7px;
  right: 0px;
}

.with-template-btn {
  right: 45px;
}

.patient-info:hover {
  background-color: rgb(107, 175, 253);
  border-radius: 3px;
  cursor: pointer;
}
.highlighted {
  background: rgb(245, 243, 243);
  box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
}
.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 0;
  }

  scrollbar-width: none;
}
.custom-scrollbar {
  scrollbar-color: #a8a9aa;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 17px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 17px;
    background: #e4f3ff;
  }

  &::-webkit-scrollbar-thumb {
    background: #a8a9aa;
    border-radius: 17px;
  }
}
.chat-modal-header{
  padding: 50px;
}
.scroll-container {
  overflow-y: auto;
}
.fade-enter-active, .fade-leave-active {
  transition: all 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(40px);
}

.cursor-pointer{
  cursor: pointer;
  transition: all .5s;

  &:hover {
    transform: translateX(7px);
  }
}

.custom-popover {
  position: absolute;
  max-width: 425px;
  max-height: 456px;
  overflow-y: auto;
  scrollbar-width: thin;
  bottom: 50px;
  padding: 12px;
  color: black;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2) inset;
  border-radius: 8px;
  background-color: #e7e9e9;
}

.style-text-value {
  font-size: 12px;
  word-break: break-word;
  white-space: pre-wrap;
}

.slide-fade-text-enter-active,
.slide-fade-text-leave-active {
  transition: all .7s ease;
}

.slide-fade-text-leave {
  opacity: 0;
  transform: translateY(30px);
}

.slide-fade-text-enter,
.slide-fade-text-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
