<template>
  <div class="w-100 p-4">
    <b-container fluid="xl">
      <b-row style="gap: 12px;">
        <b-col class="bg-white p-3 rounded border">
          <div class="h5 font-weight-bold">
            Статистика
          </div>

          <div>
            <div class="font-weight-bold mb-1 mt-3">
              Период
            </div>

            <b-row class="d-flex pb-5">
              <b-col>
                <base-date-picker
                  v-model="unloadingFrom"
                  :error="!!errors.unloadingFrom"
                  :errors="errors.unloadingFrom"
                  :max-date="maxUnloadingDateFrom"
                  @change="validateField('unloadingFrom', unloadingFrom)"
                />
              </b-col>

              <b-col>
                <base-date-picker
                  v-model="unloadingTo"
                  :min-date="minUnloadingDateTo"
                  :max-date="maxDateTo"
                  :error="!!errors.unloadingTo"
                  :errors="errors.unloadingTo"
                  @change="validateField('unloadingTo', unloadingTo)"
                />
              </b-col>
            </b-row>
          </div>

          <div class="d-flex justify-content-end">
            <b-button
              size="sm"
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              class="ml-2"
              @click="openUnloadingModal"
            >
              Скачать Excel
            </b-button>
          </div>
        </b-col>
        <b-col
          v-if="checkFeatureAccess({ name: 'Отчет для КТГ', url: '/reports' })"
          class="bg-white p-3 rounded border"
        >
          <div class="h5 font-weight-bold">
            Отчет для КТГ
          </div>

          <div>
            <div class="font-weight-bold mb-1 mt-3">
              Период
            </div>

            <b-row class="d-flex pb-5">
              <b-col>
                <base-date-picker
                  v-model="unloadingKtgFrom"
                  :max-date="maxUnloadingKtgDateFrom"
                  :error="!!errors.unloadingKtgFrom"
                  :errors="errors.unloadingKtgFrom"
                  @change="validateField('unloadingKtgFrom', unloadingKtgFrom)"
                />
              </b-col>

              <b-col>
                <base-date-picker
                  v-model="unloadingKtgTo"
                  :min-date="minUnloadingKtgDateTo"
                  :max-date="maxDateTo"
                  :error="!!errors.unloadingKtgTo"
                  :errors="errors.unloadingKtgTo"
                  @change="validateField('unloadingKtgTo', unloadingKtgTo)"
                />
              </b-col>
            </b-row>
          </div>

          <div class="d-flex justify-content-end">
            <b-button
              size="sm"
              variant="light"
              :type="$const.PRIMARY_BUTTON"
              class="border"
              @click="resetKtgFilters"
            >
              Сбросить даты
            </b-button>

            <b-button
              size="sm"
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              :disabled="ktgLoading"
              class="ml-2"
              @click="fetchKtg"
            >
              Скачать

              <b-spinner
                v-if="ktgLoading"
                variant="light"
                small
              />
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row
        v-if="checkFeatureAccess({ name: 'Чекап', url: '/reports' })"
        class="mt-3"
      >
        <b-col class="d-flex justify-content-between bg-white p-3 rounded border">
          <div class="h5 font-weight-bold">
            Чекап
          </div>

          <b-button
            size="sm"
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            @click="openCheckupExportModal"
          >
            Экспорт
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  subMonths,
  formatISO,
} from '@evd3v/date-fns';

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mixinRoles } from '@/mixins';
import { dateWithoutTime } from '@/helpers/utils';

import { BaseDatePicker } from '@/components/base';
import { reportsSchema } from '@/validation/zod/reports/reports.validation';

export default {
  name: 'Reports',

  page: {
    title: 'CRM Doctis - Статистика',
  },
  components: {
    BaseDatePicker,
  },

  mixins: [validationMixin, mixinRoles],
  data() {
    return {
      unloadingFrom: subMonths(new Date(), 1),
      unloadingTo: new Date(),
      statisticsFrom: subMonths(new Date(), 1),
      statisticsTo: new Date(),
      statisticsPeriod: 2,
      unloadingKtgFrom: subMonths(new Date(), 1),
      unloadingKtgTo: new Date(),
      ops: {
        scrollPanel: {
          scrollingX: true,
        },
      },
      ktgLoading: false,
      errors: {},
    };
  },
  validations: {
    unloadingFrom: {
      required,
    },
    unloadingTo: {
      required,
    },
    statisticsFrom: {
      required,
    },
    statisticsTo: {
      required,
    },
    unloadingKtgFrom: {
      required,
    },
    unloadingKtgTo: {
      required,
    },
    unloadingGroup: ['unloadingFrom', 'unloadingTo'],
    statisticsGroup: ['statisticsFrom', 'statisticsTo'],
    ktgGroup: ['unloadingKtgFrom', 'unloadingKtgTo'],
  },
  computed: {
    maxUnloadingDateFrom() {
      return this.unloadingTo || new Date();
    },
    minUnloadingDateTo() {
      return this.unloadingFrom || null;
    },
    maxDateTo() {
      return new Date();
    },
    maxStatisticsDateFrom() {
      return this.statisticsTo || new Date();
    },
    minStatisticsDateTo() {
      return this.statisticsFrom || null;
    },
    maxUnloadingKtgDateFrom() {
      return this.unloadingKtgTo || new Date();
    },
    minUnloadingKtgDateTo() {
      return this.unloadingKtgFrom || null;
    },
  },
  created() {
    // this.fetchStatistics();
  },
  beforeDestroy() {
    this.$store.commit(this.$types.STATISTICS_CLEAR);
  },
  methods: {
    validateField(field, value) {
      const partialSchema = reportsSchema.pick({ [field]: true });
      const result = partialSchema.safeParse({ [field]: value });

      if (!result.success) {
        this.$set(this.errors, field, result.error.errors.map((e) => e.message));
      } else {
        this.$delete(this.errors, field);
      }
    },
    async fetchStatistics() {
      if (this.$v.statisticsGroup.$error) return;

      try {
        this.$store.dispatch(this.$types.STATISTICS_FETCH, {
          to: this.statisticsTo && formatISO(this.statisticsTo),
          from: this.statisticsFrom && formatISO(this.statisticsFrom),
          dateRangeKind: this.statisticsPeriod,
        });
      } catch (e) {
        console.warn(e);
      }
    },
    resetStatisticsFilters() {
      this.statisticsFrom = null;
      this.statisticsTo = null;
      this.statisticsPeriod = 2;
    },
    async fetchKtg() {
      if (this.errors?.unloadingKtgFrom || this.errors?.unloadingKtgTo) return;

      this.ktgLoading = true;

      try {
        await this.$store.dispatch(this.$types.STATISTICS_KTG_UNLOAD_FETCH, {
          startDate: this.unloadingKtgFrom ? dateWithoutTime(formatISO(this.unloadingKtgFrom)) : null,
          endDate: this.unloadingKtgTo ? dateWithoutTime(formatISO(this.unloadingKtgTo)) : null,
        });
      } catch (e) {
        console.warn(e);
      } finally {
        this.ktgLoading = false;
      }
    },
    resetKtgFilters() {
      this.unloadingKtgFrom = null;
      this.unloadingKtgTo = null;
      const result = reportsSchema.safeParse({ unloadingKtgFrom: this.unloadingKtgFrom, unloadingKtgTo: this.unloadingKtgTo });
      this.$delete(this.errors, 'unloadingKtgFrom');
      this.$delete(this.errors, 'unloadingKtgTo');

      if (!result.success) {
        result.error.errors.forEach((error) => {
          if (error.path[0] === 'unloadingKtgFrom' || error.path[0] === 'unloadingKtgTo') {
            this.$set(this.errors, error.path[0], [error.message]);
          }
        });
      }
    },
    openUnloadingModal() {
      if (this.errors?.unloadingFrom || this.errors?.unloadingTo) return;
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'DownloadExcelModal',
        props: {
          dateFrom: this.unloadingFrom ? dateWithoutTime(formatISO(this.unloadingFrom)) : null,
          dateTo: this.unloadingTo ? dateWithoutTime(formatISO(this.unloadingTo)) : null,
        },
      });
    },
    openCheckupExportModal() {
      this.$store.commit(this.$types.OPEN_MODAL, { name: 'CheckupExportModal' });
    },
    dateForInput(date) {
      return new Date(date).toISOString().split('T')[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics-scrollbar {
  height: 100vh;
  margin: 0 auto;
}
.statistics {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 800px;
}
.crm-container {
  &.crm-container--title {
    margin-bottom: 50px;
    justify-content: space-between;
  }

  &.crm-container--title,
  .crm-container--unloading {
    justify-content: space-between;
  }

  &.crm-container-ktg {
    margin-top: 40px;
  }
}

.unloading-menu {
  display: flex;
  flex-basis: 870px;
  align-items: center;
  justify-content: space-between;
}
.chartWrapper {
  display: flex;
  width: 90%;
  flex-flow: column;
}
.unloading-datepickers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 605px;
}
.statistics-datepickers {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.statistics-radiobuttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.statistics-radiobuttons--title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-right: 20px;
}
.statistics-radiobuttons {
  margin-left: 20px;
}
.crm-radiobutton {
  &:not(:last-child) {
    margin-right: 20px;
  }
}
.title-unloading {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: $black-light;
  margin-right: 20px;
}
.crm-datepicker {
  flex-basis: 168px;
}
.crm-buttons-wrapper {
  display: flex;
  width: 269px;
}
.button_transparent {
  background-color: transparent;
  color: $blue;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  padding: 8px 0;
  flex-basis: 127px;

  &.without-border {
    border: none;
  }

  &:not(:last-child) {
    margin-right: 15px;
  }

  &.unloading-button {
    flex-basis: 168px;
    //margin-left: 220px;
  }

  .exel-icon{
    margin-left: 6px;
  }
}
.crm-container--unloading {
  margin-bottom: 50px;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-radius: 12px;
  border-top: 2px solid rgba(0, 0, 0, 0.58);
}
</style>
