<template>
  <div
    class="company border rounded p-3"
    :class="{'company_hidden': companyInfo.isHidden}"
  >
    <div
      class="company-detail"
      :class="allowClicked"
    >
      <router-link
        class="company-image"
        :to="`/insurance-companies/${companyInfo.id}/programs`"
      >
        <img
          :src="img"
          class="rounded"
        >
      </router-link>

      <div class="company-info w-100">
        <div class="h5">
          {{ companyInfo.title }}
        </div>

        <div class="text-primary mt-3">
          {{ companyInfo.phoneNumber }}
        </div>
      </div>

      <div
        v-if="!checkFeature(FEATURES_FOR_INSURANCE_COMPANIES.prohibitionOfActionsWithSubroutines)"
        class="d-flex flex-column"
      >
        <router-link
          v-if="checkFeatureAccess({ name: 'Редактирование компании', url: '/insurance-companies' })"
          :to="`/insurance-companies/${companyInfo.id}/edit`"
        >
          <b-button
            v-b-tooltip.hover.left
            variant="link"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            title="Редактировать"
          >
            <b-icon icon="pencil" />
          </b-button>
        <!-- class="company-control" -->
        <!-- <icon-edit class="crm-icon" /> -->
        </router-link>

        <b-button
          v-if="checkFeatureAccess({ name: 'Создание подпрограммы', url: '/insurance-companies' })"
          v-b-tooltip.hover.left
          variant="link"
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          title="Добавить подпрограмму"
          @click="$router.push(`/insurance-companies/${companyInfo.id}/subprograms/new`)"
        >
          <b-icon icon="plus-lg" />
        </b-button>
        <b-button
          v-if="checkFeatureAccess({ name: 'Возможность видеть скрытые подпрограммы', url: '/insurance-companies' })"
          v-b-tooltip.hover.left
          variant="link"
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          :title="showHiddens ? 'Показать не скрытые' : 'Показать скрытые'"
          :disabled="searchHiddens"
          @click="getSubprograms"
        >
          <b-icon
            v-if="showHiddens"
            icon="eye"
          />
          <b-icon
            v-else
            icon="eye-slash"
          />
        </b-button>
      </div>
    </div>

    <div class="company-subprograms">
      <template v-if="sortedSubprogram.length">
        <div class="text-muted mb-2">
          Подпрограммы
        </div>

        <div
          class="company-subprograms-list"
          :class="allowClicked"
        >
          <router-link
            v-for="subprogram in isHideSubprograms ? sortedSubprogram.slice(0, 4): sortedSubprogram"
            :key="subprogram.id"
            :class="['company-subprogram text-primary', { 'company-subprogram_deactivated' : !subprogram.isActive }]"
            :to="`/insurance-companies/${companyInfo.id}/programs/${subprogram.programId}/subprograms/${subprogram.id}`"
          >
            {{ subprogram.name }}
          </router-link>
        </div>
      </template>

      <div
        v-else
        class="text-muted"
      >
        Подпрограмм нет
      </div>

      <b-button
        v-if="subprograms.length > 4"
        variant="light"
        :type="$const.PRIMARY_BUTTON"
        class="mt-1 py-0 w-100"
        size="sm"
        @click="() => isHideSubprograms = !isHideSubprograms"
      >
        <template v-if="isHideSubprograms">
          Показать все
        </template>
        <template v-else>
          Скрыть
        </template>
        <b-icon
          :icon="isHideSubprograms ? 'chevron-down' : 'chevron-up'"
          class="ml-2"
        />
      </b-button>
    </div>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';
// import IconLetter from 'assets/images/letter_icon.svg';
// import IconEdit from 'assets/images/edit_icon.svg';
// import IconArrowDropdown from 'assets/images/arrow-dropdown.svg';
import { concat, partition, sortBy } from 'lodash';
import { APP_HOST } from '@/app.config';
import { api } from '@/helpers/api';
import { FEATURES_FOR_INSURANCE_COMPANIES } from '@/helpers/consts';

export default {
  name: 'InsuranceCompany',
  components: {
    // IconLetter,
    // IconEdit,
    // IconArrowDropdown,
  },
  mixins: [mixinRoles],
  props: {
    company: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isHideSubprograms: true,
      showHiddens: false,
      searchHiddens: false,
      searchedSubprograms: null,
      FEATURES_FOR_INSURANCE_COMPANIES,
    };
  },
  computed: {
    companyInfo() {
      return this.company.company;
    },

    sortedSubprogram() {
      const groupedArrays = partition(this.subprograms, 'isActive');
      const sortedActive = sortBy(groupedArrays[0], 'name');
      const sortedNotActive = sortBy(groupedArrays[1], 'name');
      return concat(sortedActive, sortedNotActive);
    },

    subprograms() {
      if (this.searchedSubprograms) return this.searchedSubprograms;
      // eslint-disable-next-line max-len
      return this.company.programsWithSubprograms.reduce((acc, program) => [...acc, ...program.subPrograms.map((subprogram) => ({ ...subprogram, programId: program.program.id, id: subprogram.subprogramId }))], []);
    },
    img() {
      if (this.companyInfo.logo !== '00000000-0000-0000-0000-000000000000') {
        // eslint-disable-next-line max-len
        return `${APP_HOST === '/api/' ? 'https://crm.doctis.ru:6004/api/' : APP_HOST}File/${this.companyInfo.logo}`;
      }

      // eslint-disable-next-line global-require
      return require('assets/images/avatar-insurance-empty.svg?inline');
    },
    allowClicked() {
      let classes = '';
      if (this.checkFeature(FEATURES_FOR_INSURANCE_COMPANIES.prohibitionOfActionsWithSubroutines)) {
        classes = 'disabled';
      } return classes;
    },
  },
  created() {
    if (this.subprograms.length <= 3) this.isHideSubprograms = false;
    if (this.company && this.company.company) this.showHiddens = this.company.company.isHidden;
  },
  methods: {
    async getSubprograms() {
      this.searchHiddens = true;

      try {
        const { data: subprograms } = await api.get('v2/insuranceCompanies/getWithCompany', {
          params: {
            companyId: this.company.company.id,
            getHidden: !this.showHiddens,
          },
        });
        this.searchedSubprograms = subprograms;

        this.showHiddens = !this.showHiddens;
      } catch (e) {
        console.error(e);
      } finally {
        this.searchHiddens = false;
      }
    },
    checkFeature(feature) {
      return this.checkFeatureAccess({ name: feature.name, url: feature.url });
    },
  },
};
</script>

<style lang="scss" scoped>
.company {
  background: #fff;
  // box-shadow: 0px 1px 2px #C4C4C4;
  // border-radius: 10px;
  // padding: 20px;
  // display: flex;
  box-sizing: border-box;
  // flex-wrap: wrap;
  // flex-direction: column;

  // @media screen and (max-width: 991px) {
  // }

  &_hidden {
    opacity: 0.5;
  }
}

.company-detail {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.company-image {
  height: 80px;
  width: 80px;
  margin-right: 15px;
  flex-shrink: 0;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}
.company-name {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  width: 100%;
  overflow-wrap: break-word;
  word-break: break-all;
}

.company-phone {
  margin-top: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: $blue;
  white-space: normal;
}

.company-subprograms-list{
  box-sizing: border-box;
  margin: 0 -10px;
  display: flex;
  flex-wrap: wrap;
}

.company-subprograms-title {
  font-size: 14px;
  line-height: 18px;
  color: #707070;
  margin-bottom: 5px;
}

::v-deep.company-subprogram {
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  width: calc(50% - 20px);
  margin: 5px 10px;

  &_deactivated {
    color: #C7C7C7!important;
  }
}
.subprogram-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #4B62C1;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-top: 10px;

  .crm-icon-cross {
    flex-shrink: 0;
    path {
      fill: #4B62C1;
    }
    margin-right: 10px;
  }
  .reverse{
    transform: rotate(180deg);
  }
}
.disabled {
  color: #4B62C1;
  pointer-events: none;
}
</style>
