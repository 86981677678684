<template>
  <b-modal
    visible
    scrollable
    centered
    :title="title"
    size="md"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <div style="min-height: 350px">
      <div class="mb-3">
        <div class="mb-2">
          Инициатор
        </div>
        <v-select
          v-model="selectedInitiators"
          :options="INITIATORS"
          :error="!!errors.selectedInitiators"
          :errors="errors.selectedInitiators"
          placeholder="Выберите инициатора"
          fluid
          label="name"
        />
        <div
          v-if="errors.selectedInitiators"
          class="text-danger mb-2 small"
        >
          {{ errors.selectedInitiators[0] }}
        </div>
      </div>

      <div>
        <div class="mb-2">
          Причина отмены консультации
        </div>
        <v-select
          v-model="selectedTransferReason"
          label="reason"
          :options="reasons"
          :error="!!errors.selectedTransferReason"
          :errors="errors.selectedTransferReason"
          placeholder="Выберите причину"
          loading-title="Загрузка"
          :disabled="selectedInitiators === null"
          fluid
        />
        <div
          v-if="errors.selectedTransferReason"
          class="text-danger mb-2 small"
        >
          {{ errors.selectedTransferReason[0] }}
        </div>
      </div>
    </div>
    <template #modal-footer>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isCanceling"
        class="float-right"
        @click="cancelConsultation"
      >
        Отмена консультации
        <b-spinner
          v-if="isCanceling"
          variant="light"
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Bus from '@/eventBus';
import { z } from 'zod';
import {
  errorTypes,
  defaultErrorField,
  selectedInitiatorSchema,
  selectedTransferReasonSchema,
} from '@/validation/zod/consultation/consultation.validation.js';
import { validateSchema } from '@/validation/zod/main/main.validation.js';
import { showValidationErrorMessage } from '@/helpers/messages';
import { INITIATORS } from '@/helpers/consts';

export default {
  name: 'ConsultationCancelModal',
  props: {
    consultation: {
      type: Object,
      default: () => ({}),
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Отмена консультации',
    },
    cb: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: true,
      isCanceling: false,
      selectedTransferReason: null,
      selectedInitiators: null,
      reasons: [],
      INITIATORS,
      errorTypes,
      errors: {},
    };
  },
  watch: {
    async selectedInitiators() {
      this.selectedTransferReason = null;
      await this.fetchTransferReasons();
    },
  },
  methods: {
    onClose() {
      this.$emit('input', false);
    },
    async fetchTransferReasons() {
      const type = this.selectedInitiators.id;
      this.reasons = await this.$store.dispatch(this.$types.TRANSFER_REASONS_FETCH, type);
    },
    async cancelConsultation() {
      this.errors = {};

      const fieldsToValidate = {
        selectedInitiators: this.selectedInitiators,
        selectedTransferReason: this.selectedTransferReason,
      };

      const schema = z.object({
        selectedInitiators: selectedInitiatorSchema,
        selectedTransferReason: selectedTransferReasonSchema,
      });

      const validationResult = validateSchema(schema, fieldsToValidate);

      if (!validationResult.success) {
        Object.entries(validationResult.errors).forEach(([field, errorMsg]) => {
          if (errorMsg === errorTypes.obj_null) {
            errorMsg = defaultErrorField;
          }
          this.$set(this.errors, field, [errorMsg]);
        });
        showValidationErrorMessage();
        return;
      }

      this.isCanceling = true;

      try {
        await this.$store.dispatch(this.$types.CONSULTATIONS_CANCEL, {
          consultationId: this.consultation.id,
          cancelReason: this.selectedTransferReason.id,
        });

        Bus.$emit('consultations:update', this.consultation.id);
        this.$store.commit(this.$types.PATIENT_DETAIL_CONSULATIONS_RESET);
        this.$store.commit(this.$types.TOGGLE_SCHEDULE_TRIGGER); // сделает тогл триггера и обновит расписание
        this.onClose();
      } finally {
        this.isCanceling = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
