<template>
  <div class="rounded p-3 border">
    <div class="d-flex justify-content-between">
      <span class="font-weight-bold">{{ template.name }}</span>

      <div class="d-flex">
        <base-switcher
          v-model="isActive"
          :disabled="isLoading"
          class="switcher mt-2"
          @change="changeTemplateAvailableDebounced"
        />

        <div class="ml-3 d-flex flex-column">
          <b-button
            v-b-tooltip.hover
            variant="light"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            :disabled="isLoading"
            class="border border-primary p-1"
            title="Редактировать"
            @click="openEditModal"
          >
            <b-icon icon="pencil-square" />
          </b-button>

          <b-button
            v-b-tooltip.hover
            variant="light"
            :type="$const.PRIMARY_BUTTON"
            :disabled="isLoading"
            size="sm"
            class="border border-danger mt-2 p-1"
            title="Удалить"
            @click="openDeleteAgreeModal"
          >
            <b-icon icon="trash" />
          </b-button>
          <b-button
            v-if="checkFeature(FEATURES_FOR_SCHEDULE.scheduleHistory)"
            v-b-tooltip.hover
            variant="light"
            :type="$const.PRIMARY_BUTTON"
            :disabled="isLoading"
            size="sm"
            class="border border-danger mt-2 p-1"
            title="История изменения"
            @click="openHistoryModal"
          >
            <b-icon icon="book" />
          </b-button>
        </div>
      </div>
    </div>

    <div class="crm-item-row">
      <span class="text-secondary">
        Шаблон активен с {{ formatDate(template.startDate) }}
      </span>
    </div>
    <div
      v-if="!isActive"
      class="crm-item-row"
    >
      <span class="text-secondary">
        Шаблон деактивирован с {{ formatDate(template?.lastChangeActivityStatus?.date) }}
      </span>
    </div>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';
import debounce from 'lodash/debounce';
import { parseISO, format } from '@evd3v/date-fns';
import { FEATURES_FOR_SCHEDULE } from '@/helpers/consts';
import { BaseSwitcher } from '@/components/base';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

const FETCH_DELAY = 700;

export default {
  name: 'ScheduleTemplateItem',
  components: {
    BaseSwitcher,
  },
  mixins: [mixinRoles],
  props: {
    doctor: {
      type: Object,
      default: () => ({}),
    },
    template: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isLoading: false,

      isActive: this.template.isActive,
      FEATURES_FOR_SCHEDULE,
    };
  },
  watch: {
    template: {
      handler(newVal) {
        this.isActive = newVal.isActive;
      },
      deep: true,
    },
  },
  created() {
    this.changeTemplateAvailableDebounced = debounce(this.changeTemplateAvailable, FETCH_DELAY);
  },
  methods: {
    checkFeature(key) {
      return this.checkFeatureAccess({ name: `${key?.name}`, url: `${key?.url}` });
    },
    formatDate(date) {
      return format(parseISO(date), 'dd.LL.yy');
    },
    async changeTemplateAvailable() {
      if (this.isActive === this.template.isActive) return;

      this.isLoading = true;

      const isActiveTemp = this.template.isActive;

      try {
        await this.$store.dispatch(this.$types.SCHEDULE_TEMPLATE_TOGGLE_ACTIVATION,
          { templateId: this.template.id, activationState: !isActiveTemp });
      } catch (e) {
        this.isActive = isActiveTemp;
      } finally {
        this.isLoading = false;
        if (this.$store.state.SchedulePage.schedule.length) {
          this.$store.dispatch(this.$types.SCHEDULE_PAGE_FETCH, true);
        }
      }
    },
    async deleteTemplate() {
      if (this.isLoading) return;

      this.isLoading = true;

      try {
        await this.$store.dispatch(this.$types.SCHEDULE_TEMPLATE_DELETE, this.template.id);
        this.$store.commit(this.$types.TOGGLE_SCHEDULE_TRIGGER); // сделает тогл триггера и обновит расписание
      } finally {
        this.isLoading = false;
      }
    },
    openDeleteAgreeModal() {
      this.$store.commit(
        this.$types.OPEN_MODAL,
        {
          name: 'AgreeModal',
          props: { cb: this.deleteTemplate },
        },
      );
    },
    openEditModal() {
      uiService.showModal(MODALS.SCHEDULE_TEMPLATE_EDIT_MODAL, {
        name: 'ScheduleTemplateEditModal',
        props: {
          templateProp: this.template,
          minuteInterval: this.doctor?.settings?.consultationDurationInMinutes || 15,
        },
      });
      // Bus.$emit('open-modal:schedule-edit', this.template);
    },
    openHistoryModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ScheduleHistoryModal',
        props: {
          templateId: this.template.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.template-item {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  box-shadow: 0px 1px 2px #C4C4C4;
  border-radius: 10px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
.crm-item-row {
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}
.template-title {
  color: #1411B3;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}
.template-start {
  font-weight: 600;
  color: $gray-dark;
}
.crm-buttons {
  display: flex;
  align-items: center;

  .switcher {
    margin-right: 30px;
  }
}
::v-deep.crm-icon {
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 15px;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.progress {
    cursor: wait;
  }

  &.icon-delete:hover {
    rect {
      stroke: $red;
    }
  }

  &:not(.icon-delete):hover {
    rect {
      stroke: $blue;
    }
    path {
      fill: $blue;
    }
  }
}
</style>
