import ChatApi from '@/services/chat/chat.api';

export default class ChatService {
  constructor($axios) {
    this.api = new ChatApi($axios);
  }

  async markAsRead(chatId) {
    return this.api.markAsRead(chatId);
  }

  async getUnassigned() {
    return this.api.getUnassigned();
  }

  async putUnassign(id) {
    return this.api.putUnassign(id);
  }

  async getForRole(id) {
    return this.api.getForRole(id);
  }

  async getInWork() {
    return this.api.getInWork();
  }

  async takeOn(id) {
    return this.api.takeOn(id);
  }

  async close(id) {
    return this.api.close(id);
  }

  async move(obj) {
    return this.api.move(obj);
  }

  async getMessage(obj) {
    return this.api.getMessage(obj);
  }

  async editMessage(obj) {
    return this.api.editMessage(obj);
  }

  async getHistory(id) {
    return this.api.getHistory(id);
  }

  async getOneHistoryMessage(params) {
    return this.api.getOneHistoryMessage(params);
  }

  async getFile(obj) {
    return this.api.getFile(obj);
  }

  async sendSms(obj) {
    return this.api.sendSms(obj);
  }
}
