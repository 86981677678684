<template>
  <b-modal
    visible
    scrollable
    centered
    :title="title"
    size="lg"
    @hidden="onClose"
    @ok="onClose"
  >
    <div
      style="min-height: 300px;"
      class="pb-5"
    >
      <div class="mb-2">
        <div class="small font-weight-bold mb-1">
          Инициатор
        </div>
        <b-select
          v-model="selectedInitiators"
          value-field="id"
          text-field="name"
          :options="INITIATORS"
        />
        <div
          v-if="errors.selectedInitiators"
          class="mt-2 text-danger small"
        >
          {{ errors.selectedInitiators[0] }}
        </div>
      </div>
      <div class="mb-2">
        <div class="small font-weight-bold mb-1">
          Причина отмены консультации
        </div>
        <v-select
          v-model="selectedTransferReason"
          label="reason"
          :options="reasons"
          :clearable="true"
          class="crm-select"
          placeholder="Выберите причину"
          loading-title="Загрузка"
          :disabled="selectedInitiators === null"
        />
        <div
          v-if="errors.selectedTransferReason"
          class="mt-2 text-danger small"
        >
          {{ errors.selectedTransferReason[0] }}
        </div>
      </div>
    </div>
    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isCanceling"
        @click="onClose"
      >
        Отменить
      </b-button>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isCanceling"
        class="ml-2"
        @click="onClickCancel"
      >
        Удалить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Bus from '@/eventBus';
import { z } from 'zod';
import {
  errorTypes,
  numberSchema,
  defaultErrorField,
  selectedTransferReasonSchema,
} from '@/validation/zod/consultation/consultation.validation.js';
import { validateSchema } from '@/validation/zod/main/main.validation.js';
import { showValidationErrorMessage } from '@/helpers/messages';
import { INITIATORS } from '@/helpers/consts';

import { reservationService } from '@/services';

export default {
  name: 'ReservationDeletingModal',
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Вы уверены, что хотите отменить бронирование?',
    },
    reservation: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isOpen: true,
      isCanceling: false,
      selectedTransferReason: null,
      selectedInitiators: null,
      reasons: [],
      errors: {},
      INITIATORS,
      errorTypes,
    };
  },
  watch: {
    async selectedInitiators() {
      await this.fetchTransferReasons();
      this.selectedTransferReason = '';
    },
  },
  methods: {
    onClose() {
      this.$emit('input', false);
    },
    async fetchTransferReasons() {
      this.reasons = await this.$store.dispatch(this.$types.TRANSFER_REASONS_FETCH, this.selectedInitiators);
    },
    async onClickCancel() {
      this.errors = {};

      const fieldsToValidate = {
        selectedInitiators: this.selectedInitiators,
        selectedTransferReason: this.selectedTransferReason,
      };

      const schema = z.object({
        selectedInitiators: numberSchema,
        selectedTransferReason: selectedTransferReasonSchema,
      });

      const validationResult = validateSchema(schema, fieldsToValidate);

      if (!validationResult.success) {
        Object.entries(validationResult.errors).forEach(([field, errorMsg]) => {
          if (
            errorMsg === errorTypes.num_null
            || errorMsg === errorTypes.obj_null
            || errorMsg === errorTypes.obj_str
          ) {
            errorMsg = defaultErrorField;
          }
          this.$set(this.errors, field, [errorMsg]);
        });
        showValidationErrorMessage();
        return;
      }

      this.isCanceling = true;

      try {
        await reservationService.cancel({
          bookingId: this.reservation.id,
          cancelReason: this.selectedTransferReason.id,
        });

        Bus.$emit('consultations-reservation:update', this.reservation.id);
      } finally {
        this.isCanceling = false;
        this.onClose();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
