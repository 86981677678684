import { render, staticRenderFns } from "./ConsultationDeletingModal.vue?vue&type=template&id=722c9a38&scoped=true"
import script from "./ConsultationDeletingModal.vue?vue&type=script&lang=js"
export * from "./ConsultationDeletingModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "722c9a38",
  null
  
)

export default component.exports