<template>
  <div class="containerLabsPage w-100">
    <custom-scrollbar>
      <b-container fluid>
        <b-overlay
          v-if="isLoading"
          :show="isLoading"
          no-wrap
          variant="transparent"
        />
        <transition
          mode="out-in"
          name="component-fade"
        >
          <div
            v-if="!isLoading"
            class="pt-4"
          >
            <div class="d-flex mb-4">
              <router-link
                class="mr-4"
                to="/consultation-feedback"
              >
                <ArrowIcon />
              </router-link>
              <h4 class="font-weight-bold">
                Информация об отзыве<span style="color: #0D6EFD"> № {{ currentReviewData.consultationId }}</span>
              </h4>
            </div>
            <b-row>
              <b-col>
                <b-form-group
                  label="Дата создания и время"
                  label-class="font-weight-bold pt-0"
                >
                  <span>
                    {{ uploadDateFormat(currentReviewData.moderatedAt) }}
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="ID консультации"
                  label-class="font-weight-bold pt-0"
                >
                  <span>
                    {{ currentReviewData.consultationId }}
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="ФИО пациента"
                  label-class="font-weight-bold pt-0"
                >
                  <span>
                    {{ currentReviewData.patientFio }}
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="ФИО врача"
                  label-class="font-weight-bold pt-0"
                >
                  <span>
                    {{ currentReviewData.doctorFio }}
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Отзыв"
                  label-class="font-weight-bold pt-0"
                >
                  <span>
                    {{ currentReviewData.reviewText }}
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Статус модерации"
                  label-class="font-weight-bold pt-0"
                >
                  <b-form-select
                    v-model="selectedStatusModeration"
                    :options="statusOptionsModeration"
                    :disabled="isEdit"
                    class="w-25"
                    size="sm"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Причина отклонения"
                  label-class="font-weight-bold pt-0"
                >
                  <b-form-select
                    v-model="selectedReasonOfRejection"
                    :options="reasonsRejectionList"
                    class="w-75"
                    size="sm"
                    :disabled="!isDisabledModeration || isEdit"
                    @change="validateField('selectedReasonOfRejection', selectedReasonOfRejection)"
                  />
                  <div
                    v-if="errors.selectedReasonOfRejection"
                    class="mt-1 text-danger small"
                  >
                    {{ errors.selectedReasonOfRejection[0] }}
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Комментарий/другое"
                  label-class="font-weight-bold pt-0"
                >
                  <b-form-textarea
                    id="comments"
                    v-model.trim="review.comment"
                    placeholder="Введите комментарий"
                    :disabled="isEdit"
                    class="w-75"
                    rows="1"
                    max-rows="7"
                    @input="validateField('review.comment', review.comment)"
                  />
                  <div
                    v-if="errors['review.comment']"
                    class="mt-1 text-danger small"
                  >
                    {{ errors['review.comment'][0] }}
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group>
                  <b-button
                    class="mr-2 mt-2"
                    size="sm"
                    variant="primary"
                    :type="$const.PRIMARY_BUTTON"
                    :disabled="!isDisabledButton"
                    @click="onClickSave"
                  >
                    Сохранить
                    <b-spinner
                      v-if="false"
                      variant="light"
                      small
                    />
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </transition>
      </b-container>
    </custom-scrollbar>
  </div>
</template>

<script>
import ArrowIcon from 'assets/images/indemnity-arrow-icon.svg';
import { format, parseISO } from '@evd3v/date-fns';
import { validationMixin } from 'vuelidate';
import { showValidationErrorMessage } from '@/helpers/messages';
import { laboratoryOrderInfoSchema } from '@/validation/zod/laboratory-order-info/laboratoryOrderInfo.validation';
import { validateData, makeValidate } from '@/validation/zod/main/helper.validation';

const ANOTHER_REASON_FOR_REJECTOIN = 'Другое';

export default {
  name: 'ConsultationFeedbackInfo',
  components: { ArrowIcon },
  mixins: [validationMixin],
  props: {
    reviewId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      disabledModeration: false,
      disabledRejection: false,
      disabledButton: false,
      selectedStatusModeration: null,
      selectedReasonOfRejection: null,
      comment: '',
      reasonsRejection: [],
      review: {
        rejectReasonId: '',
        comment: '',
      },
      statusOptionsModeration: [
        {
          value: null,
          text: 'Выберете статус модерации',
          key: 'Moderation',
        },
        {
          value: 0,
          text: 'Опубликовать',
          key: 'Published',
        },
        {
          value: 1,
          text: 'Отклонить',
          key: 'Rejected',
        },
      ],
      errors: {},
    };
  },
  computed: {
    isEdit() {
      return this.currentReviewData.moderationStatus !== 'Moderation';
    },
    isPublished() {
      return this.currentReviewData.moderationStatus === 'Published';
    },
    isRejected() {
      return this.currentReviewData.moderationStatus === 'Rejected';
    },
    selectedStatusRejected() {
      return this.selectedStatusModeration === 1;
    },
    isDisabledModeration() {
      return this.selectedStatusModeration === 0 || this.selectedStatusModeration === null ? this.disabledModeration : !this.disabledModeration;
    },
    isAnotherReason() {
      return this.selectedReasonOfRejection === ANOTHER_REASON_FOR_REJECTOIN;
    },
    isDisabledRejection() {
      return this.isAnotherReason ? this.disabledRejection : !this.disabledRejection;
    },
    isDisabledButton() {
      return this.isEdit || this.selectedStatusModeration === null ? this.disabledButton : !this.disabledButton;
    },
    currentReviewData() {
      return this.$store.state.ConsultationFeedback.currentReview.data;
    },
    reasonsRejectionList() {
      return this.reasonsRejection.map((item) => item.reason);
    },
  },
  watch: {
    selectedStatusModeration() {
      if (!this.isEdit && (this.selectedStatusModeration === null || this.selectedStatusModeration === 0)) {
        this.review.comment = '';
        this.selectedReasonOfRejection = null;
      }
    },
  },
  async created() {
    this.isLoading = true;
    try {
      await this.currentReviewrDataFetch();
      await this.fetchReasonsRejection();

      this.initEditedValues();
      const actualStatus = this.statusOptionsModeration.find((item) => item.key === this.currentReviewData.moderationStatus);
      this.selectedStatusModeration = actualStatus.value;
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  },
  beforeDestroy() {
    this.$store.commit(this.$types.CURRENT_REVIEW_SET, []);
  },
  methods: {
    makeValidate(fields) {
      const errors = makeValidate(fields, this, laboratoryOrderInfoSchema);
      Object.keys(errors).forEach((field) => {
        this.$set(this.errors, field, errors[field]);
      });
    },
    validateField(field, value) {
      const error = validateData(field, value, laboratoryOrderInfoSchema);
      if (error) {
        this.$set(this.errors, field, error);
      } else {
        this.$delete(this.errors, field);
      }
    },
    initEditedValues() {
      if (this.currentReviewData) {
        const { comment, rejectReason } = this.currentReviewData;

        if (this.isPublished) {
          this.review.comment = comment;
        }
        if (this.isRejected) {
          const availableReason = this.reasonsRejection.find((item) => item.reason === rejectReason);

          if (availableReason) {
            this.selectedReasonOfRejection = rejectReason;
            this.review.comment = comment;
          } else {
            this.selectedReasonOfRejection = ANOTHER_REASON_FOR_REJECTOIN;
            this.review.comment = rejectReason;
          }
        }
      }
    },
    uploadDateFormat(obj) {
      return obj ? format(parseISO(obj), 'dd.MM.yyyy HH:mm') : null;
    },
    async currentReviewrDataFetch() {
      await this.$store.dispatch(this.$types.GET_CONSULTATION_REVIEW_FOR_ID, {
        reviewId: this.reviewId,
      });
    },
    async fetchReasonsRejection() {
      this.reasonsRejection = await this.$store.dispatch(this.$types.REASONS_REVIEW_REJECTION_FETCH);
    },
    async onClickSave() {
      if (this.selectedStatusModeration === 0) {
        const data = await this.$store.dispatch(this.$types.REVIEW_MODERATION,
          {
            id: this.currentReviewData.id,
            comment: this.review.comment,
          });
        if (data?.status === 'success') {
          await this.$router.push('/consultation-feedback');
        }
      }
      if (this.selectedStatusRejected) {
        const fieldsToValidate = [
          'selectedReasonOfRejection',
          'review.comment',
        ];
        this.makeValidate(fieldsToValidate);
        if (Object.keys(this.errors).length > 0) {
          showValidationErrorMessage();
          return;
        }
        try {
          const reasonId = this.reasonsRejection.find((item) => item.reason === this.selectedReasonOfRejection);
          const data = await this.$store.dispatch(this.$types.REVIEW_REJECT, {
            id: this.currentReviewData.id,
            body: {
              rejectReasonId: reasonId?.id,
              comment: this.review.comment,
            },
          });

          if (data?.status === 'success') {
            await this.$router.push('/consultation-feedback');
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
};
</script>
