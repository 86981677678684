<template>
  <b-modal
    visible
    scrollable
    centered
    :title="title"
    :size="modalSize"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <div
      v-if="isLoading"
      class="d-flex justify-content-center w-100"
    >
      <b-spinner variant="success" />
    </div>

    <template v-else>
      <div class="d-flex align-items-center">
        <div class="h6 mt-2 mr-3">
          Тип страхования
        </div>

        <b-form-checkbox-group
          v-model="insuranceTypeArray"
          :options="INSURANCE_TYPES"
          value-field="id"
          text-field="title"
          disabled
        />
      </div>

      <div
        class="w-100 mt-3"
      >
        <div>
          <div class="mb-2 h6">
            Информация о враче (ФИО, пол и дата рождения)
          </div>

          <div>
            <b-input
              :value="$v.doctor.email.$model"
              placeholder="Введите email"
              title="Введите email"
              :state="$v.$anyError ? !$v.doctor.email.$error : null"
              :disabled="checkFeature(FEATURES_FOR_DOCTORS.blockEmailField) && !!doctorId"
              @input="$v.doctor.email.$model = $event.toLowerCase()"
            />

            <div
              v-if="$v.doctor.email.$error"
              class="mt-1 text-danger small"
            >
              {{ errorsValidation.email[0] }}
            </div>
          </div>

          <b-row class="mt-2">
            <b-col md="6">
              <b-input
                :value="$v.doctor.login.$model"
                placeholder="Введите логин"
                title="Введите логин"
                class="mt-2"
                :state="$v.$anyError ? !$v.doctor.login.$error : null"
                :disabled="checkFeature(FEATURES_FOR_DOCTORS.blockLoginField) && !!doctorId"
                @input="$v.doctor.login.$model = $event.toLowerCase()"
              />

              <div
                v-if="$v.doctor.login.$error"
                class="mt-1 text-danger small"
              >
                {{ errorsValidation.login[0] }}
              </div>
            </b-col>
            <b-col md="6">
              <b-input
                v-model="$v.doctor.password.$model"
                placeholder="Введите пароль"
                title="Введите пароль"
                :state="$v.$anyError ? !$v.doctor.password.$error : null"
                :disabled="checkFeature(FEATURES_FOR_DOCTORS.blockPasswordField) && !!doctorId"
                class="mt-2"
              />

              <div
                v-if="$v.doctor.password.$error"
                class="mt-1 text-danger small"
              >
                {{ errorsValidation.password[0] }}
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col md="6">
              <b-input
                v-model="$v.doctor.lastName.$model"
                placeholder="Введите фамилию"
                title="Введите фамилию"
                :state="$v.$anyError ? !$v.doctor.lastName.$error : null"
                class="mt-2"
              />

              <div
                v-if="$v.doctor.lastName.$error"
                class="mt-1 text-danger small"
              >
                {{ errorsValidation.lastName[0] }}
              </div>
            </b-col>
            <b-col md="6">
              <b-input
                v-model="$v.doctor.firstName.$model"
                placeholder="Введите имя"
                title="Введите имя"
                :state="$v.$anyError ? !$v.doctor.firstName.$error : null"
                class="mt-2"
              />

              <div
                v-if="$v.doctor.firstName.$error"
                class="mt-1 text-danger small"
              >
                {{ errorsValidation.firstName[0] }}
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col md="6">
              <b-input
                v-model="$v.doctor.middleName.$model"
                placeholder="Введите отчество"
                title="Введите отчество"
                class="mt-2"
              />

              <div
                v-if="$v.doctor.middleName.$error"
                class="mt-2 text-danger small"
              >
                {{ errorsValidation.middleName[0] }}
              </div>
            </b-col>
            <b-col md="6">
              <!-- v-mask="'+7 (###) ### ##-##'" -->
              <b-input
                v-model="$v.doctor.phoneNumber.$model"
                v-mask="phoneMaskString"
                placeholder="Введите телефон"
                title="Введите телефон"
                :state="$v.$anyError ? !$v.doctor.phoneNumber.$error : null"
                class="mt-2"
              />

              <div
                v-if="$v.doctor.phoneNumber.$error"
                class="mt-2 text-danger small"
              >
                {{ errorsValidation.phoneNumber[0] }}
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col md="6">
              <b-form-radio-group
                v-model="$v.doctor.sex.$model"
                :options="sexTabs"
                value-field="value"
                text-field="title"
              />

              <div
                v-if="$v.doctor.sex.$error"
                class="mt-2 text-danger small"
              >
                {{ errorsValidation.sex[0] }}
              </div>
            </b-col>
            <b-col md="6">
              <base-date-picker
                v-model="$v.doctor.birthDate.$model"
                v-maska="'##.##.####'"
                :max-date="maxDate"
                class="crm-datepicker"
                :error="$v.doctor.birthDate.$error"
              />

              <div
                v-if="$v.doctor.birthDate.$error"
                class="mt-2 text-danger small"
              >
                {{ errorsValidation.birthDate[0] }}
              </div>
            </b-col>
          </b-row>
        </div>

        <div class="block-divider w-100 my-4" />

        <b-form-checkbox
          v-model="doctor.settings.isActive"
          value="true"
          :disabled="!checkFeatureAccess({ name: 'Возможность ставить галочку - Учетная запись врача является активной', url: '/doctors' })"
          @change="() => {
            doctor.settings.deactivationReason = null;
            doctor.settings.deactivationComment = null;
          }"
        >
          Учетная запись врача является активной
        </b-form-checkbox>

        <template v-if="!doctor.settings.isActive">
          <b-form-group
            :state="!$v.doctor.settings.deactivationReason.$error"
            :invalid-feedback="errorsValidation.deactivationReason[0]"
            class="mt-2"
          >
            <v-select
              id="v-select"
              v-model="doctor.settings.deactivationReason"
              :reduce="reason => reason.id"
              :options="inactiveReasons"
              placeholder="Выберите причину"
              label="title"
              :clearable="true"
            />
          </b-form-group>

          <b-form-group
            :state="!$v.doctor.settings.deactivationComment.$error"
            :invalid-feedback="errorsValidation.deactivationComment[0]"
            class="mb-3"
          >
            <b-form-textarea
              v-model="doctor.settings.deactivationComment"
              placeholder="Введите комментарий"
              rows="3"
              size="sm"
            />
          </b-form-group>
        </template>

        <b-form-checkbox
          v-if="checkFeatureAccess({ name: 'Видеть галочку Дистанционный мониторинг', url: '/doctors' })"
          v-model="doctor.settings.remoteMonitoringAllowed"
          value="true"
          class="mt-1"
        >
          Дистанционный мониторинг
        </b-form-checkbox>

        <b-form-checkbox
          v-if="checkFeature(FEATURES_FOR_DOCTORS.doctorSeesThePolicy)"
          v-model="doctor.settings.isShowPolicy"
          class="mt-1"
        >
          Врач видит полис
        </b-form-checkbox>
        <b-form-checkbox
          v-if="checkFeature(FEATURES_FOR_DOCTORS.isTrainedForReferralCreation)"
          v-model="doctor.settings.isTrainedForReferralCreation"
          class="mt-1"
        >
          Обучен на создание направлений
        </b-form-checkbox>
        <b-form-checkbox
          v-if="checkFeature(FEATURES_FOR_DOCTORS.isRecommendedDoctor)"
          v-model="doctor.settings.isRecommended"
          class="mt-1"
        >
          Рекомендованный врач
        </b-form-checkbox>

        <div v-if="!checkFeature(FEATURES_FOR_DOCTORS.hideCheckboxTestAndCommissionFee)">
          <div class="block-divider w-100 my-4" />

          <div class="flex justify-between items-end gap-4">
            <div>
              <div class="mb-2 h6">
                Статус врача
              </div>

              <div
                class="flex gap-3"
                :class="seeCheckboxTestAndCommissionFee"
              >
                <b-form-checkbox
                  v-model="doctor.isTest"
                  class="mt-1"
                >
                  TEST
                </b-form-checkbox>
              </div>
            </div>
            <div
              class="flex gap-3"
              :class="seeCheckboxTestAndCommissionFee"
            >
              <b-form-checkbox
                v-model="doctor.settings.isRewardEnabled"
                class="mt-1"
              >
                Комиссионное вознаграждение
              </b-form-checkbox>
            </div>
          </div>
        </div>

        <div class="block-divider w-100 my-4" />

        <b-row v-if="checkFeatureAccess({ name: 'Возможность изменять среднее время консультации', url: '/doctors' })">
          <b-col>
            <div class="mb-2 h6">
              Среднее время консультации
            </div>

            <b-form-radio-group
              v-model="$v.doctor.settings.consultationDurationInMinutes.$model"
              :options="TYPE_OF_CONSULTATION_TIME"
              value-field="id"
              text-field="value"
              class="d-flex"
            />
            <div
              v-if="$v.doctor.settings.consultationDurationInMinutes.$error"
              class="mt-2 text-danger small"
            >
              {{ errorsValidation.consultationDurationInMinutes[0] }}
            </div>
          </b-col>
        </b-row>

        <div class="block-divider w-100 my-4" />

        <div
          v-if="checkFeatureAccess({ name: 'Видеть раздел - Признак врача', url: '/doctors' }) && isDoctisDomain"
        >
          <div
            class="mb-2 h6"
          >
            Признак врача
          </div>
          <b-form-radio-group
            v-model="doctor.settings.signType"
            :options="filteredSignTypes"
            :disabled="!checkFeatureAccess({ name: 'Возможность изменять признак врача', url: '/doctors' })"
            value-field="id"
            text-field="title"
          />
        </div>
        <template v-if="doctor.settings.signType === 1">
          <div class="d-flex justify-content-between mt-3">
            <div class="d-flex align-items-end">
              <div class="h6 text-nowrap mr-3">
                Идентификатор МиД <span class="small">(только для врачей МиД)</span>
              </div>

              <b-button
                v-if="!showMidClinicStatus"
                variant="outline-primary"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                class="border-0 ml-3"
                @click="showMidClinic"
              >
                Заполнить автоматически
              </b-button>
            </div>
            <div class="d-flex flex-column">
              <b-input
                v-model="$v.doctor.settings.midServiceId.$model"
                :disabled="!checkFeatureAccess({ name: 'Возможность изменять идентификатор МИД', url: '/doctors' })"
                placeholder="0"
                type="number"
                min="0"
              />

              <div
                v-if="$v.doctor.settings.midServiceId.$error"
                class="mt-2 text-danger small"
              >
                {{ errorsValidation.midServiceId[0] }}
              </div>
            </div>
          </div>

          <b-row class="mt-3">
            <b-col
              v-if="showMidClinicStatus"
              md="12"
              class="d-flex flex-column justify-content-between"
            >
              <div class="h6 mb-2">
                Выберите клинику для автоматического заполнения МиД
                <span
                  v-b-tooltip.hover
                  title="Поиск производится по ФИО. В случае ошибки - проверьте правильность введенного ФИО"
                >
                  <b-icon
                    icon="exclamation-circle-fill"
                    variant="secondary"
                    class="ml-2"
                  />
                </span>
              </div>

              <div
                class="d-flex align-items-center"
                :style="{height:'38px'}"
              >
                <b-skeleton
                  v-if="midClinicsSearch"
                  id="skeleton"
                  type="input"
                  class="w-100"
                />
                <v-select
                  v-else
                  v-model="midServiceId"
                  :options="midClinics"
                  :disabled="midClinicsSearch"
                  :reduce="clinic => clinic.serviceId"
                  :clearable="false"
                  placeholder="Выберите клинику"
                  label="clinicTitle"
                  class="w-100 crm-doctor-select"
                />

                <b-button
                  variant="primary"
                  :type="$const.PRIMARY_BUTTON"
                  size="sm"
                  class="ml-2"
                  :disabled="midClinicsSearch"
                  @click="searchMidClinics"
                >
                  <b-icon
                    v-if="midClinicSearched"
                    icon="arrow-counterclockwise"
                  />
                  <b-icon
                    v-else
                    icon="search"
                  />
                </b-button>
              </div>
            </b-col>
          </b-row>
          <div
            v-if="doctor.settings.signType === 1 || doctor.id"
            class="d-flex justify-content-between mt-3"
          >
            <b-form-checkbox
              v-if="doctor.settings.signType === 1"
              v-model="doctor.settings.useExternalSchedule"
            >
              Использовать расписание МиД
            </b-form-checkbox>

            <b-button
              v-if="doctor.id"
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              @click="openScheduleModal"
            >
              Просмотреть расписание
            </b-button>
          </div>
        </template>

        <div class="block-divider w-100 my-4" />

        <div>
          <div class="mb-2 h6">
            Тип видимости
          </div>

          <b-form-radio-group
            v-model="doctor.settings.accessType"
            :options="filteredAccessTypesKeys"
            value-field="id"
            text-field="title"
            class="radio-group-grid"
            @change="onChangeAccessType"
          />
        </div>
        <template v-if="doctor.settings.accessType == 3">
          <div
            v-for="(subprogramItemData, index) in $v.subprogramsIdsData.$each.$iter"
            :key="subprogramItemData.id.$model"
          >
            <div class="doctor-edit-modal__subprogram-header d-flex mb-2 bg-white">
              <div
                class="d-flex justify-content-between align-items-center bg-primary rounded p-2 w-100"
                :class="{
                  'border border-danger bg-white': subprogramItemData.$anyError,
                }"
                @click="subprogramItemData.showContent.$model = !subprogramItemData.showContent.$model"
              >
                <div
                  :class="[
                    subprogramItemData.$anyError ? 'text-dark' : 'text-white'
                  ]"
                >
                  Подпрограмма {{ Number(index) + 1 }}
                </div>

                <b-icon
                  icon="caret-down-fill"
                  :variant="subprogramItemData.$anyError ? 'danger' : 'light'"
                  :rotate="subprogramItemData.showContent.$model ? 180 : 0"
                />
              </div>

              <b-button
                v-if="subprogramsIdsData && subprogramsIdsData.length > 1"
                variant="danger"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                class="ml-2"
                @click="agreeRemoveSubprogram(subprogramItemData.id.$model)"
              >
                <b-icon icon="x" />
              </b-button>
            </div>

            <div
              v-if="subprogramItemData.showContent.$model"
              class="px-3"
            >
              <b-row class="mt-3">
                <b-col>
                  <b-form-group
                    label="Страховая компания"
                  >
                    <v-select
                      id="v-select"
                      v-model="subprogramItemData.companyId.$model"
                      :reduce="company => company.id"
                      :options="insuranceCompanies"
                      placeholder="Выберите компанию"
                      label="title"
                      @input="changeCompany(subprogramItemData)"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group
                    label="Страховая программа"
                  >
                    <b-form-select
                      v-model="subprogramItemData.programId.$model"
                      :options="subprogramItemData.insurancePrograms.$model"
                      value-field="id"
                      text-field="name"
                      @input="changeProgram(subprogramItemData)"
                      @keyup.native.backspace="programId = null"
                    >
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        Выберите страховую программу
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group
                    label="Страховая подпрограмма"
                    :state="$v.$anyError ? !subprogramItemData.subprogramId.$error : null"
                  >
                    <b-form-select
                      v-model="subprogramItemData.subprogramId.$model"
                      :options="subprogramItemData.insuranceSubprograms.$model"
                      value-field="id"
                      text-field="name"
                      @keyup.native.backspace="subprogramItemData.subprogramId.$model = null"
                    >
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        Выберите страховую подпрограмму
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>

          <b-button
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="mt-2"
            @click="addNewSubprogram"
          >
            Добавить подпрограмму
          </b-button>
        </template>

        <div class="block-divider w-100 my-4" />

        <div class="mb-3 h6">
          Место работы
        </div>
        <div class="new-specialization mb-3">
          <div class="mt-2">
            <div class="mb-2 h6">
              Название клиники
            </div>

            <base-async-select
              id="v-select"
              v-model="clinicSelect"
              :disabled="!checkFeatureAccess({ name: 'Возможность изменять название клиники', url: '/doctors' })"
              :error="$v.doctor.clinicId.$error"
              :fetch-function="fetchClinic"
              required
              server-paginated
            />

            <div
              v-if="$v.doctor.clinicId.$error"
              class="mt-1 text-danger small"
            >
              {{ errorsValidation.clinicId[0] }}
            </div>
          </div>
          <div class="mt-2">
            <div class="mb-2 h6">
              Дата начала
            </div>
            <base-date-picker
              v-model="$v.doctor.settings.currentPlaceOfWorkStartDate.$model"
              v-maska="'##.##.####'"
              :min-date="minDateForInput"
              :max-date="maxDateForInput"
              class="field-input"
              :error="$v.doctor.settings.currentPlaceOfWorkStartDate.$error"
            />
          </div>
        </div>
        <div
          v-if="doctor.placesOfWork.length"
          class="block-divider w-100 my-4 "
        />
        <b-row v-if="doctor.placesOfWork.length">
          <b-col>
            <b-form-group
              label="Место работы"
              label-class="font-weight-bold pt-0"
            >
              <div
                v-for="(item, idx) in $v.doctor.placesOfWork.$each.$iter"
                :key="idx"
                class="d-flex flex-column w-100 mb-2"
                style="gap: 10px"
              >
                <div class="w-100 d-flex justify-content-between mt-2">
                  <b-form-checkbox
                    v-model="item.endDateChecked.$model"
                    @change="updateEndDate(idx)"
                  >
                    По настоящее время
                  </b-form-checkbox>
                  <b-button
                    v-if="doctor.doctorSpecializations.length"
                    size="sm"
                    style="width: max-content"
                    variant="danger"
                    :type="$const.PRIMARY_BUTTON"
                    @click="onClickDeletePlacesOfWork(idx)"
                  >
                    Удалить
                  </b-button>
                </div>

                <b-form-group
                  invalid-feedback="Поле не может быть пустым"
                  :state="!item.organizationName.$error"
                >
                  <b-form-input
                    v-model="item.organizationName.$model"
                    placeholder="Название места работы"
                  />
                </b-form-group>

                <b-form-group
                  invalid-feedback="Поле не может быть пустым"
                  :state="!item.specializations.$error"
                >
                  <multiselect
                    v-model="item.specializations.$model"
                    :multiple="true"
                    placeholder="Выберите специализацию"
                    :close-on-select="false"
                    label="name"
                    track-by="id"
                    :options="specializationsOption"
                    @search-change="fetchSpecializationsDoctorWorkPlace"
                  />
                </b-form-group>

                <b-row>
                  <b-col>
                    <b-form-group
                      label="Дата начала"
                      invalid-feedback="Поле не может быть пустым"
                      :state="!item.startDate.$error"
                    >
                      <base-date-picker
                        v-model="item.startDate.$model"
                        v-maska="'##.##.####'"
                        :max-date="maxDate"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Дата окончания"
                      invalid-feedback="Поле не может быть пустым"
                      :state="!item.endDate.$error"
                    >
                      <base-date-picker
                        v-model="item.endDate.$model"
                        v-maska="'##.##.####'"
                        :disabled="item.endDateChecked.$model"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Город"
                    >
                      <v-select
                        id="v-select"
                        v-model="item.cityId.$model"
                        :reduce="city => city.id"
                        :options="cities"
                        placeholder="Выберите город"
                        label="name"
                        autocomplete="new-cities"
                        :clearable="true"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <div
                  class="block-divider w-100 mt-3"
                />
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          @click="onAddWorkPlace"
        >
          Добавить место работы
        </b-button>

        <div class="block-divider w-100 my-4" />

        <b-row class="mt-3 d-flex">
          <b-col>
            <div class="mb-2 h6">
              Снилс
            </div>

            <b-input
              v-model="doctor.snils"
              v-mask="'###-###-### ##'"
              placeholder="Снилс"
            />
          </b-col>

          <b-col>
            <div class="mb-2 h6">
              Стоимость приема
            </div>

            <b-input
              v-model="$v.doctor.settings.consultationPrice.$model"
              :disabled="!checkFeatureAccess({ name: 'Возможность изменять стоимость приема', url: '/doctors' })"
              type="number"
              placeholder="Стоимость приема"
            />

            <div
              v-if="$v.doctor.settings.consultationPrice.$error"
              class="mt-2 text-danger small"
            >
              {{ errorsValidation.consultationPrice[0] }}
            </div>
          </b-col>

          <!-- <b-col> -->
          <!-- <div class="mb-2 h6">
              Депозит
            </div> -->

          <!-- <b-input
              v-model="doctor.settings.depositPrice"
              :disabled="!checkFeatureAccess({ name: 'Возможность изменять депозит', url: '/doctors' })"
              type="number"
              placeholder="Депозит"
            /> -->

          <!--            <div-->
          <!--              v-if="$v.doctor.settings.depositPrice.$error"-->
          <!--              class="mt-2 text-danger small"-->
          <!--            >-->
          <!--              {{ errorsValidation.depositPrice[0] }}-->
          <!--            </div>-->
          <!-- </b-col> -->
        </b-row>
        <div
          v-if="checkFeatureAccess({ name: 'Видеть раздел - Покрытие полиса', url: '/doctors' })"
          class="mt-3"
        >
          <b-form-radio-group
            v-model="doctor.settings.priceBehavior"
            :options="PRICE_BEHAVIOR_TYPES"
            value-field="id"
            text-field="title"
            class="d-flex flex-column"
          />
        </div>

        <div class="mt-3">
          <div
            v-for="(v, index) in $v.doctor.doctorSpecializations.$each.$iter"
            :key="v.$model.id"
          >
            <div class="">
              <div class="mb-2 ">
                <div class="d-flex justify-content-between">
                  <div class="h6">
                    ({{ Number(index) + 1 }}) Специализация
                  </div>

                  <b-button
                    v-if="doctor.doctorSpecializations.length > 1"
                    size="sm"
                    variant="danger"
                    :type="$const.PRIMARY_BUTTON"
                    @click="onClickDeleteSpecialization(index)"
                  >
                    Удалить
                  </b-button>
                </div>
              </div>
              <div class="new-specialization">
                <div class="mt-2">
                  <div
                    class="mb-2 h6"
                  >
                    Название специализации
                  </div>
                  <base-async-select
                    :value="specializations[index]"
                    :fetch-function="fetchSpecializations"
                    class="select specialization-select"
                    placeholder="Начните вводить текст"
                    required
                    @change="changeSpecialization($event, index)"
                  />

                  <div
                    v-if="v.specializationId.$error"
                    class="mt-2 text-danger small"
                  >
                    {{ errorsValidation.doctorSpecializations.specializationId[index][0] }}
                  </div>
                </div>
                <div class="mt-2">
                  <div class="mb-2 h6">
                    Опыт работы <span class="small">(дата начала работы)</span>
                  </div>

                  <base-date-picker
                    v-model="v.experience.$model"
                    :max-date="maxDate"
                    :error="!v.experience.$model"
                    class="w-100 d-block"
                  />

                  <div
                    v-if="v.isForAdults.$error"
                    class="mt-2 text-danger small"
                  >
                    {{ errorsValidation.doctorSpecializations.experience[index][0] }}
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div>
                <div class="d-flex mt-3">
                  <b-form-checkbox
                    v-model="v.isForAdults.$model"
                    value="true"
                  >
                    Взрослый
                  </b-form-checkbox>

                  <b-form-checkbox
                    v-model="v.isForChildren.$model"
                    value="true"
                    class="ml-3"
                  >
                    Детский
                  </b-form-checkbox>
                </div>
                <div
                  v-if="v.isForAdults.$error"
                  class="mt-2 text-danger small"
                >
                  {{ errorsValidation.doctorSpecializations.age[index][0] }}
                </div>
              </div>
              <div class="mt-3">
                <b-form-checkbox
                  v-model="v.isMain.$model"
                  :disabled="checkFeatureAccess({ name: 'заблокировать Основная специализация', url: '/doctors' })"
                  value="true"
                >
                  Основная специализация
                </b-form-checkbox>

                <div
                  v-if="v.isMain.$error"
                  class="mt-2 text-danger small"
                >
                  {{ errorsValidation.isMain[0] }}
                </div>
              </div>
            </div>

            <div class="block-divider w-100 my-4" />
          </div>

          <div class="mt-3">
            <b-button
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              @click="onClickAddSpecialization"
            >
              Добавить специализацию
            </b-button>
          </div>
        </div>

        <div class="block-divider w-100 my-4" />

        <div class="communication">
          <div class="h6">
            Тип связи
          </div>

          <b-form-checkbox
            v-model="doctor.settings.isTextEnabled"
          >
            Текст
          </b-form-checkbox>

          <b-form-checkbox
            v-model="doctor.settings.isAudioEnabled"
          >
            Аудио
          </b-form-checkbox>

          <b-form-checkbox
            v-model="doctor.settings.isVideoEnabled"
          >
            Видео
          </b-form-checkbox>
        </div>

        <div class="block-divider w-100 my-4" />

        <div class="mt-3 w-100">
          <div
            v-if="checkFeatureAccess({ name: 'Видеть раздел - Прикрепленные очереди', url: '/doctors' })"
          >
            <div

              class="d-flex align-items-center mb-4"
            >
              <div class="mb-2 h6">
                Прикрепленные очереди
              </div>

              <b-button
                :variant="queueTableShow ? 'secondary' : 'primary'"
                :type="$const.PRIMARY_BUTTON"
                class="ml-auto"
                size="sm"
                @click="toggleQueueTableShow"
              >
                {{ queueTableShow ? 'Скрыть' : 'Добавить очередь' }}
              </b-button>
            </div>

            <b-table-simple
              v-if="doctor.queues.length"
              bordered
              small
            >
              <b-tbody>
                <b-tr
                  v-for="queue in doctor.queues"
                  :key="queue.id"
                >
                  <b-td class="table-text">
                    {{ queue.name }}
                  </b-td>
                  <b-td class="table-text">
                    {{ queue.description || 'Нет описания' }}
                  </b-td>
                  <b-td style="width: 40px">
                    <b-button
                      variant="danger"
                      :type="$const.PRIMARY_BUTTON"
                      size="sm"
                      @click="onQueueRemove(queue.id)"
                    >
                      <b-icon icon="trash" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <div
              v-else
              class="text-secondary"
            >
              Очереди не добавлены
            </div>

            <template v-if="queueTableShow">
              <b-form-input
                v-model="queueSearchString"
                type="text"
                debounce="500"
                placeholder="Поиск очереди по названию"
                size="sm"
                trim
                class="mb-2 mt-4"
                style="height: 38px"
              />

              <div
                v-if="filteredQueues.length"
                style="max-height: 200px; overflow-y: auto;"
              >
                <b-table-simple
                  small
                  bordered
                  class="mb-0"
                >
                  <b-tbody>
                    <b-tr
                      v-for="queue in filteredQueues"
                      :key="queue.id"
                    >
                      <b-td class="table-text">
                        {{ queue.name }}
                      </b-td>
                      <b-td class="table-text">
                        {{ queue.description || 'Нет описания' }}
                      </b-td>
                      <b-td style="width: 40px">
                        <b-button
                          :type="$const.PRIMARY_BUTTON"
                          size="sm"
                          @click="onQueueAdd(queue)"
                        >
                          <b-icon icon="plus" />
                        </b-button>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>

              <small
                v-if="queueSearchString && !filteredQueues.length"
                class="ml-2"
              >
                Очередь не найден
              </small>
            </template>
            <div
              class="block-divider w-100 my-4 "
            />
          </div>

          <b-row
            v-for="(education, index) in $v.doctor.doctorUniversityEducationBlanks.$each.$iter"
            :key="`education-${index}`"
          >
            <b-col class="mt-3">
              <div class="d-flex">
                <div class="h6 font-weight-bold pb-3">
                  ({{ Number(index) + 1 }}) Образование
                </div>
                <div class="ml-auto">
                  <b-button
                    v-if="doctor.doctorUniversityEducationBlanks.length > 0"
                    variant="danger"
                    :type="$const.PRIMARY_BUTTON"
                    size="sm"
                    @click="onEducationRemove(index)"
                  >
                    Удалить
                  </b-button>
                </div>
              </div>
              <b-form-group>
                <div
                  class="d-flex flex-column w-100"
                  style="gap: 10px"
                >
                  <b-form-select
                    v-model="education.diplomaType.$model"
                    :state="education.diplomaType.$model !== null"
                    :options="educationOptions"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        Выберите тип диплома
                      </b-form-select-option>
                    </template>
                  </b-form-select>

                  <v-select
                    v-model="education.directionOfStudyId.$model"
                    v-b-tooltip
                    :title="!!education.fileDiploma.$model ? SPECIALIZATION_DISABLED_TEXT : null"
                    :reduce="speciality => speciality.id"
                    :options="specialities"
                    placeholder="Выберите специальность"
                    :disabled="!!education.fileDiploma.$model"
                    label="name"
                  />
                  <div
                    v-if="education.directionOfStudyId.$error"
                    class="error-text"
                  >
                    <span>
                      Выберите специальность
                    </span>
                  </div>

                  <b-form-group
                    invalid-feedback="Поле не может быть пустым"
                    :state="!education.universityName.$error"
                    class="mb-0"
                  >
                    <b-form-input
                      v-model="education.universityName.$model"
                      placeholder="Организация, которая выдала документ"
                    />
                  </b-form-group>
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        invalid-feedback="Поле не может быть пустым"
                        :state="!education.yearOfGraduation.$error"
                        class="mb-0"
                      >
                        <b-form-input
                          v-model="education.yearOfGraduation.$model"
                          placeholder="Год получения документа"
                          type="number"
                          trim
                          min="minValue"
                          :formatter="formatYearReceiptDocument"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-file
                        v-model="education.$model.tempFiles"
                        accept=".pdf"
                        drop-placeholder="Перетащите файл сюда..."
                        placeholder="Выберите файлы для добавления..."
                        :multiple="true"
                      />
                      <div
                        v-if="$v.anyError && !education.fileDiploma.$model"
                        class="error-text"
                      >
                        <span>
                          Необходимо добавить файл
                        </span>
                      </div>
                    </b-col>
                  </b-row>
                  <b-button
                    v-if="education.fileDiploma.$model"
                    variant="primary"
                    :type="$const.PRIMARY_BUTTON"
                    size="sm"
                    class="ml-auto"
                    @click="() => onDoctorFileDownload(education.fileDiploma.$model)"
                  >
                    Скачать файл
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <div class="mt-3">
            <b-button
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="w-220px"
              @click="onEducationAdd"
            >
              Добавить образование
            </b-button>
          </div>

          <div
            class="block-divider w-100 my-4"
          />

          <b-row
            v-for="(additional, index) in $v.doctor.doctorAdditionalEducationBlanks.$each.$iter"
            :key="`additional-${index}`"
          >
            <b-col class="mt-3">
              <div class="d-flex justify-content-between">
                <div class="h6 font-weight-bold pb-3">
                  ({{ Number(index) + 1 }}) Допобразование
                </div>
                <div class="ml-auto">
                  <b-button
                    v-if="doctor.doctorAdditionalEducationBlanks.length > 0"
                    variant="danger"
                    :type="$const.PRIMARY_BUTTON"
                    size="sm"
                    @click="() => onAdditionalRemove(index)"
                  >
                    Удалить
                  </b-button>
                </div>
              </div>
              <b-form-group>
                <div
                  class="d-flex flex-column w-100"
                  style="gap: 10px"
                >
                  <b-form-select
                    v-model="additional.educationType.$model"
                    :state="additional.educationType.$model !== null"
                    :options="typesAdditionalEducation"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        Выберите тип диплома о доп образовании
                      </b-form-select-option>
                    </template>
                  </b-form-select>

                  <b-form-input
                    v-if="additional.educationType.$model === 'Custom'"
                    v-model="additional.educationCustomTypeName"
                    placeholder="Введите тип диплома о доп образовании"
                    trim
                  />

                  <v-select
                    v-model="additional.directionOfStudyId.$model"
                    v-b-tooltip
                    :title="!!additional.fileDiploma.$model ? SPECIALIZATION_DISABLED_TEXT : null"
                    :reduce="speciality => speciality.id"
                    :options="specialities"
                    placeholder="Выберите специальность"
                    label="name"
                    :disabled="!!additional.fileDiploma.$model"
                    @input="changeAdditional(additional.directionOfStudyId.$model)"
                  />

                  <div
                    v-if="additional.directionOfStudyId.$error"
                    class="error-text"
                  >
                    <span>
                      Выберите специальность
                    </span>
                  </div>

                  <b-form-group
                    invalid-feedback="Поле не может быть пустым"
                    :state="!additional.issueOrganization.$error"
                    class="mb-0"
                  >
                    <b-form-input
                      v-model="additional.issueOrganization.$model"
                      placeholder="Организация выдавшая диплом"
                    />
                  </b-form-group>

                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        label="Дата выдачи диплома"
                        invalid-feedback="Поле не может быть пустым"
                        :state="!additional.issueDate.$error"
                        class="mb-0"
                      >
                        <base-date-picker
                          v-model="additional.issueDate.$model"
                          :max-date="maxDate"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Добавление файла"
                      >
                        <b-form-file
                          v-model="additional.$model.tempFiles"
                          accept=".pdf"
                          drop-placeholder="Перетащите файл сюда..."
                          placeholder="Выберите файлы для добавления..."
                          :multiple="true"
                        />
                        <div
                          v-if="$v.anyError && !additional.fileDiploma.$model"
                          class="error-text"
                        >
                          <span>
                            Необходимо добавить файл
                          </span>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-button
                    v-if="additional.fileDiploma.$model"
                    variant="primary"
                    :type="$const.PRIMARY_BUTTON"
                    size="sm"
                    class="ml-auto"
                    @click="() => onDoctorFileDownload(additional.fileDiploma.$model)"
                  >
                    Скачать файл
                  </b-button>

                  <div class="d-flex justify-content-between align-center">
                    <!-- <div class="d-flex mt-2">
                      <b-form-checkbox
                        v-model="additional.isActive.$model"
                        switch
                      />
                      <span>
                        Отображать специальность
                      </span>
                    </div> -->
                    <div />
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <div class="mt-3">
            <b-button
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="w-220px"
              @click="onAdditionalAdd"
            >
              Добавить допобразование
            </b-button>
          </div>

          <div
            class="block-divider w-100 my-4"
          />

          <div
            v-for="(accreditation, index) in $v.doctor.doctorAccreditationBlanks.$each.$iter"
            :key="`accreditation-${index}`"
            class="mt-4"
          >
            <div class="d-flex justify-content-between mb-2">
              <div class="h6 font-weight-bold">
                ({{ Number(index) + 1 }}) Аккредитация
              </div>

              <b-button
                v-if="doctor.doctorAccreditationBlanks.length > 0"
                variant="danger"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                class="ml-auto"
                @click="onAccreditationRemove(index)"
              >
                Удалить
              </b-button>
            </div>
            <b-row>
              <b-col>
                <b-form-group label="Тип аккредитации">
                  <b-form-radio-group
                    v-model="accreditation.accreditationType.$model"
                    :options="ACCREDITATION_TYPES"
                    :state="$v.$anyError ? !accreditation.accreditationType.$error : null"
                    value-field="key"
                    text-field="title"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Файл аккредитации">
                  <b-form-file
                    v-model="accreditation.$model.tempFiles"
                    :state="$v.$anyError ? !accreditation.fileDiploma.$error : null"
                    accept=".pdf"
                    drop-placeholder="Перетащите файл сюда..."
                    :disabled="accreditationFilesLoading"
                    placeholder="Выберите файлы для добавления..."
                    :multiple="true"
                  />
                  <div
                    v-if="$v.anyError && !accreditation.fileDiploma.$model"
                    class="error-text"
                  >
                    <span>
                      Необходимо добавить файл
                    </span>
                  </div>

                  <b-button
                    v-if="accreditation.fileDiploma.$model"
                    variant="primary"
                    :type="$const.PRIMARY_BUTTON"
                    size="sm"
                    class="ml-auto mt-2"
                    @click="() => onDoctorFileDownload(accreditation.fileDiploma.$model)"
                  >
                    Скачать файл
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  label="Дата окончания срока"
                  invalid-feedback="Поле не может быть пустым"
                >
                  <base-date-picker
                    v-model="accreditation.expirationDate.$model"
                    :error="$v.anyError ? accreditation.fileDiploma.$error : false"
                  />
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group
                  label="Специальность"
                  invalid-feedback="Поле не может быть пустым"
                >
                  <v-select
                    v-model="accreditation.directionOfStudyId.$model"
                    v-b-tooltip
                    :title="!!accreditation.fileDiploma.$model ? SPECIALIZATION_DISABLED_TEXT : null"
                    :reduce="speciality => speciality.id"
                    :options="specialities"
                    placeholder="Выберите специальность"
                    :disabled="!!accreditation.fileDiploma.$model"
                    label="name"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <b-button
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="w-220px"
            @click="onAccreditationAdd"
          >
            Добавить аккредитацию
          </b-button>

          <div
            class="block-divider w-100 my-4 "
          />
          <div class="mb-3 h6">
            Регалии
          </div>

          <b-row
            v-for="(сategory, index) in $v.doctor.doctorCategoryBlanks.$each.$iter"
            :key="`category-${index}`"
          >
            <b-col class="mt-3">
              <div class="d-flex justify-content-between mb-2">
                <div class="h6 font-weight-bold pb-3">
                  ({{ Number(index) + 1 }}) Категория
                </div>
                <div>
                  <b-button
                    v-if="doctor.doctorCategoryBlanks.length > 0"
                    variant="danger"
                    :type="$const.PRIMARY_BUTTON"
                    size="sm"
                    @click="onCategoryBlanksRemove(index)"
                  >
                    Удалить
                  </b-button>
                </div>
              </div>
              <b-form-group>
                <div
                  class="d-flex flex-column w-100"
                  style="gap: 10px"
                >
                  <b-form-select
                    v-model="сategory.categoryType.$model"
                    :state="сategory.categoryType.$model !== null"
                    :options="doctorCategories"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        Выберите категорию
                      </b-form-select-option>
                    </template>
                  </b-form-select>

                  <v-select
                    v-model="сategory.directionOfStudyId.$model"
                    v-b-tooltip
                    :title="!!сategory.fileDiploma.$model ? SPECIALIZATION_DISABLED_TEXT : null"
                    :reduce="speciality => speciality.id"
                    :options="specialities"
                    placeholder="Выберите специальность"
                    label="name"
                    :disabled="!!сategory.fileDiploma.$model"
                  />
                  <div
                    v-if="сategory.directionOfStudyId.$error"
                    class="error-text"
                  >
                    <span>
                      Выберите специальность
                    </span>
                  </div>

                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        label="Дата выдачи диплома"
                        invalid-feedback="Поле не может быть пустым"
                        :state="!сategory.issueDate.$error"
                      >
                        <base-date-picker
                          v-model="сategory.issueDate.$model"
                          :max-date="maxDate"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Добавление файла"
                      >
                        <b-form-file
                          v-model="сategory.$model.tempFiles"
                          accept=".pdf"
                          drop-placeholder="Перетащите файл сюда..."
                          placeholder="Выберите файлы для добавления..."
                          :multiple="true"
                        />
                        <div
                          v-if="$v.anyError && !сategory.fileDiploma.$model"
                          class="error-text"
                        >
                          <span>
                            Необходимо добавить файл
                          </span>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-button
                    v-if="сategory.fileDiploma.$model"
                    variant="primary"
                    :type="$const.PRIMARY_BUTTON"
                    size="sm"
                    class="ml-auto"
                    @click="() => onDoctorFileDownload(сategory.fileDiploma.$model)"
                  >
                    Скачать файл
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="mt-3">
            <b-button
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              :disabled="!disabledRegaliesButtons"
              class="w-220px"
              @click="onCategoryAdd"
            >
              Добавить категорию
            </b-button>

            <span
              v-if="!disabledRegaliesButtons"
              class="text small ml-3"
            >
              *доступно после добавления образования или допобразования
            </span>
          </div>

          <div
            class="block-divider w-100 my-4 "
          />

          <div v-if="showScienceDegrees">
            <div class="d-flex justify-content-between align-items-baseline mb-2">
              <div
                class="mb-2 h6"
              >
                Научная степень
              </div>
              <b-button
                v-if="showScienceDegrees"
                variant="danger"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                class="ml-auto"
                @click="removeScienceDegree"
              >
                Удалить
              </b-button>
            </div>
            <div
              class="d-flex flex-column w-100"
              style="gap: 10px"
            >
              <b-row>
                <b-col md="6">
                  <b-form-select
                    v-model="$v.doctor.doctorScienceDegreeBlank.scienceDegreeType.$model"
                    :state="$v.doctor.doctorScienceDegreeBlank.scienceDegreeType.$model !== null"
                    :options="doctorScienceDegrees"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                      >
                        Выберите категорию
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-col>
                <b-col md="6">
                  <b-form-file
                    v-model="doctor.doctorScienceDegreeBlank.tempFiles"
                    accept=".pdf"
                    drop-placeholder="Перетащите файл сюда..."
                    placeholder="Выберите файлы для добавления..."
                    :multiple="true"
                    @input="() => onScienceDegreeBlankAdd(doctor.doctorScienceDegreeBlank.tempFiles)"
                  />
                  <div
                    v-if="!$v.doctor.doctorScienceDegreeBlank.fileDiploma.$model"
                    class="error-text mt-1"
                  >
                    <span>
                      Необходимо добавить файл
                    </span>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>

          <div
            class="d-flex justify-content-between mt-2 mb-2"
          >
            <b-button
              v-if="$v.doctor.doctorScienceDegreeBlank.fileDiploma.$model"
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              @click="() => onDoctorFileDownload($v.doctor.doctorScienceDegreeBlank.fileDiploma.$model)"
            >
              Скачать файл
            </b-button>
            <b-button
              v-if="!showScienceDegrees"
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="w-220px"
              @click="onScienceDegreeAdd"
            >
              Добавить научную степень
            </b-button>
          </div>

          <div
            class="block-divider w-100 my-4 "
          />

          <div v-if="showDoctorRank">
            <div class="d-flex justify-content-between align-items-baseline mb-2">
              <div
                class="mb-2 h6"
              >
                Звание врача
              </div>
              <b-button
                v-if="showDoctorRank"
                variant="danger"
                :type="$const.PRIMARY_BUTTON"
                size="sm"
                class="ml-auto"
                @click="removeDoctorRank"
              >
                Удалить
              </b-button>
            </div>
            <div
              class="d-flex flex-column w-100"
              style="gap: 10px"
            >
              <b-row>
                <b-col md="6">
                  <b-form-select
                    v-model="$v.doctor.doctorRankBlank.rankType.$model"
                    :state="$v.doctor.doctorRankBlank.rankType.$model !== null"
                    :options="doctorRank"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        Выберите категорию
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-col>
                <b-col md="6">
                  <b-form-file
                    v-model="doctor.doctorRankBlank.tempFiles"
                    accept=".pdf"
                    drop-placeholder="Перетащите файл сюда..."
                    placeholder="Выберите файлы для добавления..."
                    :multiple="true"
                    @input="() => onRankBlankAdd(doctor.doctorRankBlank.tempFiles)"
                  />
                  <div
                    v-if="!$v.doctor.doctorRankBlank.fileDiploma.$model"
                    class="error-text mt-1"
                  >
                    <span>
                      Необходимо добавить файл
                    </span>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>

          <div class="d-flex justify-content-between mt-2 mb-2">
            <b-button
              v-if="$v.doctor.doctorRankBlank.fileDiploma.$model"
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"

              @click="() => onDoctorFileDownload($v.doctor.doctorRankBlank.fileDiploma.$model)"
            >
              Скачать файл
            </b-button>
            <b-button
              v-if="!showDoctorRank"
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="w-220px"
              @click="onDoctorRankAdd"
            >
              Добавить звание
            </b-button>
          </div>

          <div
            class="block-divider w-100 my-4 "
          />

          <div>
            <div class="mb-2">
              О себе
            </div>
            <b-form-textarea
              v-model="$v.doctor.about.$model"
              :custom-toolbar="['']"
            />
          </div>

          <div>
            <div class="mb-2">
              Комментарий
            </div>
            <b-form-textarea
              v-model="$v.doctor.comment.$model"
              :custom-toolbar="['']"
            />
          </div>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClickSave"
      >
        Сохранить
        <b-spinner
          v-if="isSaving"
          variant="light"
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { formatISO } from '@evd3v/date-fns';
import { validationMixin } from 'vuelidate';
import { mixinRoles } from '@/mixins';
import { phoneMixins } from '@/mixins/phoneMixins';
import { api } from '@/helpers/api';
import {
  SIGN_TYPES,
  INSURANCE_TYPES,
  DOCTOR_ACCESS_TYPE,
  ACCREDITATION_TYPES,
  FEATURES_FOR_DOCTORS,
  PRICE_BEHAVIOR_TYPES,
  DOCTOR_INACTIVE_REASONS,
  TYPE_OF_CONSULTATION_TIME,
} from '@/helpers/consts';

import { minLength, required } from 'vuelidate/lib/validators';
import { conformToMask } from 'vue-text-mask';
import { APP_SETTINGS, APP_URL } from '@/app.config';
import Bus from '@/eventBus';
import { dateWithoutTime, hasRussianName } from '@/helpers/utils';
import {
  showValidationErrorMessage,
  showCustomMessage,
  showErrorCustomMessage,
} from '@/helpers/messages';

import { locationService } from '@/services';

import {
  BaseAsyncSelect,
  BaseDatePicker,
  // BaseFile,
} from '@/components/base';
import debounce from 'lodash.debounce';

const FETCH_DELAY = 700;

const SPECIALIZATION_DISABLED_TEXT = 'Не редактируется. Для изменения пересоздайте регалию';

export default {
  name: 'DoctorEditModal',
  components: {
    BaseAsyncSelect,
    BaseDatePicker,
  },
  mixins: [validationMixin, mixinRoles, phoneMixins],
  props: {
    doctorId: {
      type: [String, Number],
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Добавление врача',
    },
  },
  data() {
    return {
      isDoctorActive: false,
      isOpen: true,
      cities: [],
      phoneMaskString: '+7 (###) ### ##-##',
      selectedEducation: null,
      endDateChecked: false,
      phoneMask: ['+', /\d/, ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, '-', /\d/, /\d/],
      snilsMask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, ' ', /\d/, /\d/],
      DOCTOR_ACCESS_TYPE,
      isLoading: false,
      inactiveReasons: DOCTOR_INACTIVE_REASONS,
      maxDate: new Date(),
      minValue: 1950,
      clinic: null,
      specializations: [null],
      isSaving: false,
      checkErrors: false,
      hadMidId: false,
      midDisabled: false,
      regaly: null,
      showScienceDegrees: false,
      showDoctorRank: false,
      sexTabs: [
        {
          title: 'Мужской',
          value: 1,
        },
        {
          title: 'Женский',
          value: 2,
        },
      ],
      INSURANCE_TYPES,
      insuranceTypeArray: [],
      SIGN_TYPES,
      PRICE_BEHAVIOR_TYPES,
      TYPE_OF_CONSULTATION_TIME,
      ACCREDITATION_TYPES,
      SPECIALIZATION_DISABLED_TEXT,
      companyId: null,
      programId: null,
      showMidClinicStatus: true,
      midClinicsSearch: false,
      midClinicSearched: false,
      midClinics: [],
      insuranceData: [],
      insuranceCompanies: [],
      insurancePrograms: [],
      insuranceSubprograms: [],
      educationOptions: [
        { value: 'University', text: 'Диплом ВУЗа' },
        { value: 'Internship', text: 'Диплом (удостоверение) интернатура' },
        { value: 'Residency', text: 'Диплом (удостоверение) ординатура' },
        { value: 'GraduateSchool', text: 'Диплом (удостоверение) аспирантура' },
      ],
      typesAdditionalEducation: [
        { value: 'PrimaryRetraining', text: 'Диплом первичной переподготовки' },
        { value: 'AdvancedTraining', text: 'Диплом повышения квалификации' },
        { value: 'Accreditation', text: 'Диплом об аккредитации' },
        { value: 'Custom', text: 'Прочее' },
      ],
      doctorCategories: [
        { value: 'First', text: 'Первая категория' },
        { value: 'Second', text: 'Вторая категория' },
        { value: 'Highest', text: 'Высшая категория' },
      ],
      doctorScienceDegrees: [
        { value: 'CMS', text: 'Кандидат медицинских наук' },
        { value: 'DMS', text: 'Доктор медицинских наук' },
      ],
      doctorRank: [
        { value: 'Docent', text: 'Доцент' },
        { value: 'Professor', text: 'Профессор' },
        { value: 'ProfessorRas', text: 'Профессор РАН' },
        { value: 'CorrespondingMember', text: 'Член-корреспондент' },
        { value: 'Academician', text: 'Академик РАН' },
      ],
      doctor: {
        id: 0,
        userId: 0,
        lastName: '',
        middleName: '',
        firstName: '',
        about: '',
        comment: '',
        phoneNumber: '+7 (',
        birthDate: null,
        sex: null,
        email: '',
        login: '',
        password: '',
        clinicId: null,
        regions: [],
        queues: [],
        isTest: false,
        settings: {
          id: 0,
          isTextEnabled: true,
          isAudioEnabled: false,
          isVideoEnabled: false,
          consultationPrice: 0,
          depositPrice: 0,
          useExternalSchedule: false,
          midServiceId: 0,
          signType: 0,
          medicalInsuranceType: null,
          accessType: 0,
          isActive: true,
          priceBehavior: 0,
          remoteMonitoringAllowed: false,
          subprogramsIds: [],
          deactivationComment: null,
          deactivationReason: null,
          currentPlaceOfWorkStartDate: null,
          consultationDurationInMinutes: 15,
          isShowPolicy: false,
          isTrainedForReferralCreation: false,
          isRecommended: false,
          isRewardEnabled: false,
        },
        doctorScienceDegreeBlank: {
          scienceDegreeType: null,
          fileDiploma: null,
        },
        doctorRankBlank: {
          rankType: null,
          fileDiploma: null,
        },
        doctorSpecializations: [
          {
            specializationId: null,
            experience: null,
            isForAdults: true,
            isForChildren: true,
            isMain: false,
          },
        ],
        doctorUniversityEducationBlanks: [],
        doctorAdditionalEducationBlanks: [],
        doctorCategoryBlanks: [],
        doctorAccreditationBlanks: [],
        placesOfWork: [],
      },
      subprogramsIdsData: [

      ],
      queueTableShow: false,
      queueSearchString: '',
      queues: [],
      placeOfWork: {
        organizationName: '',
        specializations: [],
        startDate: null,
        endDate: null,
        cityId: '',
        cityName: '',
        endDateChecked: false,
      },
      doctorUniversityEducationBlank: {
        directionOfStudyId: null,
        yearOfGraduation: null,
        universityName: null,
        diplomaType: null,
        fileDiploma: null,
      },
      doctorAdditionalEducationBlank: {
        directionOfStudyId: null,
        issueOrganization: null,
        issueDate: null,
        educationType: null,
        educationCustomTypeName: null,
        isActive: true,
        fileDiploma: null,
      },
      doctorCategoryBlanks: {
        directionOfStudyId: null,
        issueDate: null,
        categoryType: null,
        fileDiploma: null,
      },
      doctorAccreditationBlanks: {
        directionOfStudyId: null,
        expirationDate: null,
        accreditationType: null,
        fileDiploma: null,
        file: null,
      },
      accreditationFilesLoading: false,
      specializationsOption: [],
      specialities: [],
      speciality: null,
      typeAdditionalEducation: null,
      doctorScienceDegree: null,
      doctorCategory: null,
      doctorAccreditation: null,
      FEATURES_FOR_DOCTORS,
    };
  },
  validations() {
    return {
      doctor: {
        email: {
          required,
          mailValidation: (val) => !(/[ ]/.test(val)),
        },
        login: {
          required,
          correctValue: (val) => !(/[а-яёА-ЯЁ ]/.test(val)),
        },
        password: {
          required: !this.doctorId ? required : () => true,
          minLength: !this.doctorId ? minLength(6) : () => true,
        },
        firstName: {
          required,
          onlyRussian: this.isDoctisNet ? () => true : (value) => hasRussianName(value),
        },
        lastName: {
          required,
          onlyRussian: this.isDoctisNet ? () => true : (value) => hasRussianName(value),
        },
        middleName: {
          onlyRussian: this.isDoctisNet || !this.doctor.middleName ? () => true : (value) => hasRussianName(value),
        },
        about: {},
        comment: {},
        phoneNumber: {
          required,
          isValid: (value) => /^\+\d \(\d{3}\) \d{3} \d{2}-\d{2}$/i.test(value),
        },
        birthDate: {
          required,
        },
        sex: {
          required,
        },
        clinicId: {
          required,
        },
        settings: {
          deactivationReason: {
            required: this.doctor.settings.isActive ? () => true : required,
          },
          deactivationComment: {
            required: this.doctor.settings.isActive ? () => true : required,
          },
          signType: {
            required: this.isDoctisDomain ? required : () => true,
          },
          consultationPrice: {
            required,
            isValid: (value) => value >= 0,
          },
          midServiceId: {
            required: this.doctor.settings.signType === 1 ? required : () => true,
            isValid: this.doctor.settings.signType === 1 ? (value) => value >= 0 : () => true,
          },
          medicalInsuranceType: {},
          subprogramId: {},
          // subprogramId: {
          //   required: Number(this.doctor.settings.accessType) === 3 ? required : () => true,
          // },
          // subprogramsIds: {
          //   isValid: Number(this.doctor.settings.accessType) === 3 ? (value) => value.length > 0 : () => true,
          // },
          currentPlaceOfWorkStartDate: {
            required,
          },
          consultationDurationInMinutes: {
            required: this.doctor.settings.consultationDurationInMinutes === null ? required : () => true,
          },
        },
        doctorSpecializations: {
          isValid: (value) => value.length > 0,
          $each: {
            experience: {
              required,
            },
            specializationId: {
              required,
            },
            isMain: {
              isAlone: () => this.doctor.doctorSpecializations.reduce((acc, spec) => (spec.isMain ? acc + 1 : acc), 0) === 1,
            },
            isForChildren: {
              isSelectedOne: (value, spec) => value || spec.isForAdults,
            },
            isForAdults: {
              isSelectedOne: (value, spec) => value || spec.isForChildren,
            },
          },
        },
        placesOfWork: {
          $each: {
            organizationName: {
              required,
            },
            specializations: {
              required,
            },
            startDate: {
              ...({ required }),
            },
            endDate: {},
            cityId: {},
            endDateChecked: {},
          },
        },
        doctorUniversityEducationBlanks: {
          $each: {
            diplomaType: {
              required,
            },
            directionOfStudyId: {
              required,
            },
            universityName: {
              required,
            },
            yearOfGraduation: {
              required,
            },
            fileDiploma: {
              required,
            },
            tempFiles: {},
          },
        },
        doctorAdditionalEducationBlanks: {
          $each: {
            educationType: {
              required,
            },
            directionOfStudyId: {
              required,
            },
            issueOrganization: {
              required,
            },
            issueDate: {
              required,
            },
            isActive: {},
            fileDiploma: {
              required,
            },
          },
        },

        doctorCategoryBlanks: {
          $each: {
            categoryType: {
              required,
            },
            directionOfStudyId: {
              required,
            },
            issueDate: {
              required,
            },
            fileDiploma: {
              required,
            },
          },
        },
        doctorAccreditationBlanks: {
          $each: {
            accreditationType: { required },
            directionOfStudyId: { required },
            expirationDate: { required },
            fileDiploma: { required },
          },
        },

        doctorScienceDegreeBlank: {
          scienceDegreeType: {
            required: this.showScienceDegrees ? required : () => true,
          },
          fileDiploma: {
            required: this.showScienceDegrees ? required : () => true,
          },
        },

        doctorRankBlank: {
          rankType: {
            required: this.showDoctorRank ? required : () => true,
          },
          fileDiploma: {
            required: this.showDoctorRank ? required : () => true,
          },
        },
      },
      subprogramsIdsData: {
        isValid: Number(this.doctor.settings.accessType) === 3 ? (value) => value.length > 0 : () => true,
        $each: {
          id: {},
          companyId: {},
          programId: {},
          subprogramId: {
            required: Number(this.doctor.settings.accessType) === 3 ? required : () => true,
          },
          showContent: {},
          insurancePrograms: {},
          insuranceSubprograms: {},
        },
      },
    };
  },
  computed: {
    seeCheckboxTestAndCommissionFee() {
      let classes = '';

      if (!this.checkFeature(FEATURES_FOR_DOCTORS.seeCheckboxTestAndCommissionFee)) {
        classes = 'disabled';
      }

      return classes;
    },
    minDateForInput() {
      return new Date(1950, 0, 1);
    },
    maxDateForInput() {
      return new Date();
    },
    modalSize() {
      if (this.selectedMidClinic?.clinicTitle.length > 65) {
        return 'xl';
      }

      return 'lg';
    },
    disabledRegaliesButtons() {
      return this.doctor.doctorUniversityEducationBlanks.length || this.doctor.doctorAdditionalEducationBlanks.length;
    },
    selectedMidClinic() {
      return this.midClinics?.find((item) => item.serviceId === this.midServiceId);
    },
    midServiceId: {
      get() {
        return Number(this.$v.doctor.settings.midServiceId.$model);
      },
      set(newVal) {
        this.$v.doctor.settings.midServiceId.$model = String(newVal);
      },
    },
    filteredSignTypes() {
      if (this.doctorId) {
        return this.SIGN_TYPES;
      }

      if (this.checkFeatureAccess({ name: 'Признак врача - только "Нет признака"', url: '/doctors' })) {
        return [this.SIGN_TYPES[0]];
      }

      return this.SIGN_TYPES;
    },
    fullName() {
      return `${this.doctor.lastName} ${this.doctor.firstName} ${this.doctor.middleName}`;
    },
    isDoctisNet() {
      return APP_URL === 'admin.doctis.net';
    },
    isDoctisDomain() {
      return !APP_SETTINGS || APP_SETTINGS === 'default';
    },
    errorsValidation() {
      const errors = {};

      errors.email = [];
      if (!this.$v.doctor.email.required) {
        errors.email.push('Поле не может быть пустым');
      }
      if (!this.$v.doctor.email.mailValidation) {
        errors.email.push('Не должно быть пробелов');
      }

      errors.login = [];
      if (!this.$v.doctor.login.required) {
        errors.login.push('Поле не может быть пустым');
      }
      if (!this.$v.doctor.login.correctValue) {
        errors.login.push('Значение некорректно');
      }

      errors.password = [];
      if (!this.$v.doctor.password.required) {
        errors.password.push('Поле не может быть пустым');
      }
      if (!this.$v.doctor.password.minLength) {
        errors.password.push('Поле должно содержать не менее 6 символов');
      }

      errors.firstName = [];
      if (!this.$v.doctor.firstName.required) {
        errors.firstName.push('Поле не может быть пустым');
      }
      if (!this.$v.doctor.firstName.onlyRussian) {
        errors.firstName.push('Доступно: русский язык и дефис');
      }

      errors.lastName = [];
      if (!this.$v.doctor.lastName.required) {
        errors.lastName.push('Поле не может быть пустым');
      }
      if (!this.$v.doctor.lastName.onlyRussian) {
        errors.lastName.push('Доступно: русский язык и дефис');
      }

      errors.middleName = [];
      if (!this.$v.doctor.middleName.onlyRussian) {
        errors.middleName.push('Доступно: русский язык и дефис');
      }

      errors.phoneNumber = [];
      if (!this.$v.doctor.phoneNumber.required) {
        errors.phoneNumber.push('Поле не может быть пустым');
      }
      if (!this.$v.doctor.phoneNumber.isValid) {
        errors.phoneNumber.push('Некорректное значение поля');
      }

      errors.birthDate = [];
      if (!this.$v.doctor.birthDate.required) {
        errors.birthDate.push('Некорректное значение поля');
      }

      errors.sex = [];
      if (!this.$v.doctor.sex.required) {
        errors.sex.push('Поле не может быть пустым');
      }

      errors.clinicId = [];
      if (!this.$v.doctor.clinicId.required) {
        errors.clinicId.push('Поле не может быть пустым');
      }

      errors.signType = [];
      if (!this.$v.doctor.settings.signType.required) {
        errors.signType.push('Поле не может быть пустым');
      }

      errors.deactivationReason = [];
      if (!this.$v.doctor.settings.deactivationReason.required) {
        errors.deactivationReason.push('Поле не может быть пустым');
      }

      errors.deactivationComment = [];
      if (!this.$v.doctor.settings.deactivationComment.required) {
        errors.deactivationComment.push('Поле не может быть пустым');
      }

      // errors.depositPrice = [];
      // if (!this.$v.doctor.settings.depositPrice.required) {
      //   errors.depositPrice.push('Поле не может быть пустым');
      // }
      // if (!this.$v.doctor.settings.depositPrice.isValid) {
      //   errors.depositPrice.push('Некорректное значение поля');
      // }

      errors.consultationPrice = [];
      if (!this.$v.doctor.settings.consultationPrice.required) {
        errors.consultationPrice.push('Поле не может быть пустым');
      }
      if (!this.$v.doctor.settings.consultationPrice.isValid) {
        errors.consultationPrice.push('Некорректное значение поля');
      }

      errors.midServiceId = [];
      if (!this.$v.doctor.settings.midServiceId.required) {
        errors.midServiceId.push('Поле не может быть пустым');
      }
      if (!this.$v.doctor.settings.midServiceId.isValid) {
        errors.midServiceId.push('Некорректное значение поля');
      }
      // errors.subprogramId = [];
      // if (!this.$v.doctor.settings.subprogramId.required) {
      //   errors.subprogramId.push('Поле не может быть пустым');
      // }

      // errors.subprogramsIds = [];
      // if (!this.$v.doctor.settings.subprogramsIds.isValid) {
      //   errors.subprogramsIds.push('Нужно выбрать хотя-бы одну подпрограмму');
      // }

      errors.isMain = [];
      if (!this.$v.doctor.doctorSpecializations.$each[0]?.isMain.isAlone) {
        errors.isMain.push('Необходимо пометить одну специальность как основную');
      }

      errors.consultationDurationInMinutes = [];
      if (!this.$v.doctor.settings.consultationDurationInMinutes.required) {
        errors.consultationDurationInMinutes.push('Необходимо выбрать среднее время консультации');
      }

      errors.doctorSpecializations = {};

      errors.doctorSpecializations.experience = this.doctor.doctorSpecializations.map((item, index) => {
        const localErrors = [];
        if (!this.$v.doctor.doctorSpecializations.$each[index].experience.required) {
          localErrors.push('Поле не может быть пустым');
        }
        return localErrors;
      });

      errors.doctorSpecializations.age = this.doctor.doctorSpecializations.map((item, index) => {
        const localErrors = [];
        if (!this.$v.doctor.doctorSpecializations.$each[index].isForChildren.isSelectedOne) {
          localErrors.push('Необходимо выбрать хотя бы один вариант');
        }
        return localErrors;
      });

      errors.doctorSpecializations.specializationId = this.doctor.doctorSpecializations.map((item, index) => {
        const localErrors = [];
        if (!this.$v.doctor.doctorSpecializations.$each[index].specializationId.required) {
          localErrors.push('Поле не может быть пустым');
        }
        return localErrors;
      });
      return errors;
    },
    clinicSelect: {
      get() {
        return this.clinic;
      },
      set(newVal) {
        this.clinic = newVal;

        this.doctor.clinicId = newVal && newVal.value;
      },
    },
    accessTypesKeys() {
      return Object.entries(DOCTOR_ACCESS_TYPE).map(([id, title]) => ({
        id, // можно сделать Number(id), но я решил оставить так
        title,
      }));
    },
    filteredAccessTypesKeys() {
      if (!this.checkFeatureAccess({ name: 'Видеть радиокнопку - Тип видимости - Только в определенных подпрограммах', url: '/doctors' })) {
        return this.accessTypesKeys.filter((item) => item.title !== 'Только в определенных подпрограммах');
      }

      return this.accessTypesKeys;
    },
    filteredQueues() {
      return this.queues.filter((q) => {
        if (this.queueSearchString && !q.name?.toLowerCase().includes(this.queueSearchString.toLowerCase())) return false; // search by queue name from queueSearchString
        return !this.doctor.queues.find((qq) => qq.id === q.id); // return false if queue added to userdata
      });
    },
  },
  async created() {
    this.isLoading = true;

    this.cities = (await locationService.getCities({ query: '' })).sort((a, b) => a.name.localeCompare(b.name));

    if (this.checkFeatureAccess({ name: 'При открытии - тип страхования заблокирован', url: '/doctors' })) {
      this.INSURANCE_TYPES = INSURANCE_TYPES.map((item) => {
        item.disabled = true;

        return item;
      });
    }
    this.specialities = await this.$store.dispatch(this.$types.DOCTOR_FETCH_SPECIALITIES);
    this.queues = await this.$store.dispatch(this.$types.QUEUES_FETCH);
    this.insuranceData = await this.$store.dispatch(this.$types.COMPANIES_FETCH);
    this.insuranceCompanies = this.insuranceData
      .filter((i) => i.company?.isHidden === false)
      .map(({ company: { id, title } }) => ({ id, title }));

    this.insuranceTypeArray.push(0);

    if (this.doctorId) {
      try {
        const doctor = await this.$store.dispatch(this.$types.DOCTOR_FETCH, this.doctorId);
        const emptyClinicId = '00000000-0000-0000-0000-000000000000';
        this.insuranceTypeArray.push(0);

        this.doctor = {
          ...doctor,
          email: doctor.email || doctor.userInfo?.email,
          login: doctor.login || doctor.userInfo?.userName,
          phoneNumber: doctor.phoneNumber ? conformToMask(doctor.phoneNumber, this.phoneMask).conformedValue : '+7 (',
          birthDate: new Date(doctor.birthDate),
          clinicId: doctor.clinicId === emptyClinicId ? null : doctor.clinicId,
          regions: doctor.regions.map((region) => ({ ...region, title: region.name })),
          doctorSpecializations: doctor.doctorSpecializations.map((specialization) => ({
            ...specialization,
            experience: new Date(specialization.experience),
          })),
          doctorCategoryBlanks: doctor.doctorCategoryBlanks || [],
          doctorScienceDegreeBlank: doctor.doctorScienceDegreeBlank ? doctor.doctorScienceDegreeBlank : this.doctor.doctorScienceDegreeBlank,
          doctorRankBlank: doctor.doctorRankBlank ? doctor.doctorRankBlank : this.doctor.doctorRankBlank,
        };

        if (this.doctor.settings.currentPlaceOfWorkStartDate) {
          this.doctor.settings.currentPlaceOfWorkStartDate = new Date(this.doctor.settings.currentPlaceOfWorkStartDate);
        }

        if (doctor.settings.isRewardEnabled) {
          this.doctor.settings.isRewardEnabled = doctor.settings.isRewardEnabled;
        }

        if (this.doctor.doctorScienceDegreeBlank.scienceDegreeType !== null) {
          this.showScienceDegrees = true;
        }

        if (this.doctor.doctorRankBlank.rankType !== null) {
          this.showDoctorRank = true;
        }

        this.doctor.placesOfWork = doctor.placesOfWork.map((place) => ({
          ...place,
          endDateChecked: false,
          startDate: new Date(place.startDate),
          endDate: place.endDate ? new Date(place.endDate) : null,
          specializations: place.specializations.map((item) => ({
            ...item,
            name: item.title,
          })),
        }));

        this.doctor.doctorAccreditationBlanks = this.doctor.doctorAccreditationBlanks.map((item) => ({
          ...item,
          expirationDate: item.expirationDate ? new Date(item.expirationDate) : null,
        }));

        this.doctor.doctorAdditionalEducationBlanks = this.doctor.doctorAdditionalEducationBlanks.map((additional) => ({
          ...additional,
          issueDate: new Date(additional.issueDate),
          expirationDate: null,
        }));

        this.doctor.doctorCategoryBlanks = this.doctor.doctorCategoryBlanks.map((category) => ({
          ...category,
          issueDate: new Date(category.issueDate),
        }));

        if (this.doctor.mainDoctorSpecialization) {
          this.doctor.mainDoctorSpecialization = {
            ...this.doctor.mainDoctorSpecialization,
            experience: new Date(doctor.mainDoctorSpecialization.experience),
          };
        }

        this.clinic = doctor.clinicId === emptyClinicId ? null : {
          name: doctor.clinicName,
          value: doctor.clinicId,
        };

        this.specializations = this.doctor.doctorSpecializations.map((specialization) => ({
          name: this.doctor.specializations.find((spec) => spec.id === specialization.specializationId).title,
          value: specialization.specializationId,
        }));

        if (
          this.doctor.settings.subprogramsIds
          && this.doctor.settings.subprogramsIds.length
        ) {
          /* eslint-disable-next-line */
          for (const subprogramId of doctor.settings.subprogramsIds) {
            /* eslint-disable no-await-in-loop */
            const { data } = await api.get(`v2/insuranceCompanies/programs/subprogram/${subprogramId}`);
            const companyId = data.company.id;
            const programId = data.program.id;
            this.subprogramsIdsData.push({
              id: uuidv4(),
              companyId,
              programId,
              subprogramId,
              insurancePrograms: [],
              insuranceSubprograms: [],
              showContent: true,
            });
          }
          /* eslint-disable-next-line */
          for(const subprogramData of this.subprogramsIdsData) {
            const { programs, subprograms } = this.setInsuranceEntities(subprogramData.companyId, subprogramData.programId);
            subprogramData.insurancePrograms = programs || [];
            subprogramData.insuranceSubprograms = subprograms || [];
          }
        }

        if (this.doctor.settings.signType === 1) {
          this.searchMidClinics();
        }

        if (this.doctor.settings?.subprogramsIds && !this.doctor.settings?.subprogramsIds.length) {
          this.addNewSubprogram();
        }

        this.hadMidId = doctor.settings.signType === 1;
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    }

    if (!this.doctorId && this.checkFeatureAccess({
      name: 'При открытии произвести поиск клиники, выбрать первую в поле "Название клиники"',
      url: '/doctors',
    })) {
      const clinic = (await this.fetchClinic({ take: 10 }))[0];
      this.clinicSelect = clinic;
      // this.computedInsuranceTypeArray = ['OMS']; условие стоит выше
    }

    this.midDisabled = !!(this.doctor.settings.useExternalSchedule && this.doctor.settings.midServiceId);

    if (!this.doctor.placesOfWork?.length || !this.doctor.placesOfWork) {
      this.doctor.placesOfWork = [];
      // this.doctor.placesOfWork.push({ ...this.placeOfWork });
    }

    this.isLoading = false;
  },
  methods: {
    checkFeature(feature) {
      return this.checkFeatureAccess({ name: feature.name, url: feature.url });
    },
    async showMidClinic() {
      this.showMidClinicStatus = true;
      await this.searchMidClinics();
    },
    updateEndDate(idx) {
      this.doctor.placesOfWork[idx].endDate = null;
    },
    onInputDate() {
      const isValidDate = new Date(this.doctor.settings.currentPlaceOfWorkStartDate) >= new Date(this.minDateForInput)
      && new Date(this.doctor.settings.currentPlaceOfWorkStartDate) < new Date(this.maxDateForInput);
      if (!isValidDate) {
        this.doctor.settings.currentPlaceOfWorkStartDate = this.minDateForInput;
      }
    },
    onAddWorkPlace() {
      this.doctor.placesOfWork.push({ ...this.placeOfWork });
    },
    addNewSubprogram() {
      this.$v.subprogramsIdsData.$model.push({
        id: uuidv4(),
        companyId: null,
        programId: null,
        subprogramId: null,
        insurancePrograms: [],
        insuranceSubprograms: [],
        showContent: true,
      });
    },
    agreeRemoveSubprogram(id) {
      this.$store.commit(
        this.$types.OPEN_MODAL,
        {
          name: 'AgreeModal',
          props: { cb: this.removeSubprogram, cbArgs: [id] },
        },
      );
    },
    removeSubprogram(id) {
      this.$v.subprogramsIdsData.$model = this.$v.subprogramsIdsData.$model.filter((item) => item.id !== id);
    },
    formatYearReceiptDocument(e) {
      return String(e).substring(0, 4);
    },
    async searchMidClinics() {
      this.midClinicsSearch = true;

      try {
        const clinics = await this.$store.dispatch(this.$types.GET_DOCTOR_SERVICES, this.fullName);
        if (Array.isArray(clinics)) {
          this.midClinics = clinics;
        } else {
          this.midClinics = [];
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.midClinicsSearch = false;
        this.midClinicSearched = true;
      }
    },
    openScheduleModal() {
      if (!this.hadMidId && this.doctor.settings.signType === 1) {
        // eslint-disable-next-line no-alert
        alert('Будет показано предыдущее расписание врача. Сохраните изменения, чтобы посмотреть расписание по МиД.');
      }
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ScheduleModal',
        props: {
          doctorId: this.doctor.id,
        },
      });
      // Bus.$emit('open-modal:schedule', this.doctor.id);
    },
    onClose() {
      this.$emit('input', false);
    },
    setInsuranceEntities(companyId, programId) {
      try {
        if (companyId === null) {
          return {
            programs: [],
            subprograms: [],
          };
        }
        const companyObject = this.insuranceData
          .filter(({ company: { id } }) => id === companyId)[0];

        const programs = companyObject.programsWithSubprograms
          .filter(({ program }) => program.isHidden === false)
          .map(({ program: { id, name } }) => ({ id, name }));
        if (!programId) {
          return {
            programs,
            subprograms: [],
          };
        }

        const programObject = companyObject.programsWithSubprograms.filter(({ program: { id } }) => id === programId)[0];
        const subprograms = programObject.subPrograms
          .filter((i) => i.isHidden === false)
          .map(({ subprogramId, name }) => ({ id: subprogramId, name }));

        return {
          programs,
          subprograms,
        };
      } catch (e) {
        console.error(e);
      }
    },
    changeAdditional(item) {
      console.log(item);
    },
    changeCompany(subprogramData) {
      subprogramData.programId.$model = null;
      subprogramData.subprogramId.$model = null;
      subprogramData.insurancePrograms.$model = [];
      subprogramData.insuranceSubprograms.$model = [];
      const { programs, subprograms } = this.setInsuranceEntities(subprogramData.companyId.$model, subprogramData.programId.$model);
      subprogramData.insurancePrograms.$model = programs;
      subprogramData.insuranceSubprograms.$model = subprograms;
    },
    changeProgram(subprogramData) {
      subprogramData.subprogramId.$model = null;
      subprogramData.insuranceSubprograms.$model = [];
      const { programs, subprograms } = this.setInsuranceEntities(subprogramData.companyId.$model, subprogramData.programId.$model);
      subprogramData.insurancePrograms.$model = programs || [];
      subprogramData.insuranceSubprograms.$model = subprograms || [];
    },
    onChangeAccessType(accessType) {
      if (accessType !== 3) {
        this.$v.doctor.settings.subprogramId.$model = null;
      }
    },
    async addAllFiles() {
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const index in this.doctor.doctorUniversityEducationBlanks) {
        await this.onEducationFileAdd(index);
      }
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const index in this.doctor.doctorAdditionalEducationBlanks) {
        await this.onAdditionalFileAdd(index);
      }
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const index in this.doctor.doctorCategoryBlanks) {
        await this.onCategoryFileAdd(index);
      }
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const index in this.doctor.doctorAccreditationBlanks) {
        await this.onAccreditationFileAdd(index);
      }
    },
    async onClickSave() {
      this.isSaving = true;
      await this.addAllFiles();

      this.$v.$touch();
      this.checkErrors = true;

      if (this.$v.$error) {
        showValidationErrorMessage();

        if (this.$v.doctor.placesOfWork.$error) {
          showCustomMessage('error', 'Ошибка валидации', 'Необходимо заполнить все поля в разделе "Места работы"');
        }

        this.isSaving = false;
        return;
      }

      this.isSaving = true;
      try {
        const doctorSpecializations = this.doctor.doctorSpecializations.map((item) => {
          const newItem = { ...item };

          return newItem;
        });

        const doctor = {
          ...JSON.parse(JSON.stringify(this.doctor)),
          phoneNumber: `+${this.doctor.phoneNumber.replace(/\D/g, '')}`,
          birthDate: dateWithoutTime(formatISO(this.doctor.birthDate)),

          regions: this.doctor.regions.map((region) => ({
            id: region.id,
            name: region.name,
            code: region.code,
          })),
          doctorSpecializations: doctorSpecializations.map((specialization) => ({
            ...specialization,
            experience: dateWithoutTime(formatISO(specialization.experience)),
          })),
          mainDoctorSpecialization: doctorSpecializations.map((specialization) => ({
            ...specialization,
            experience: dateWithoutTime(formatISO(specialization.experience)),
          })).find((specializaion) => !!specializaion.isMain),
          userInfo: {
            ...this.doctor.userInfo,
            email: this.doctor.email,
            userName: this.doctor.login,
            phoneNumber: `+${this.doctor.phoneNumber.replace(/\D/g, '')}`,
          },
          doctorAccreditationBlanks: this.doctor.doctorAccreditationBlanks.map(({ tempFiles, ...item }) => ({
            ...item,
            expirationDate: dateWithoutTime(formatISO(item.expirationDate)),
          })),
          doctorScienceDegreeBlank: this.showScienceDegrees ? this.doctor.doctorScienceDegreeBlank : null,
          doctorRankBlank: this.showDoctorRank ? this.doctor.doctorRankBlank : null,
          doctorAdditionalEducationBlanks: this.doctor.doctorAdditionalEducationBlanks.map((item) => ({
            ...item,
            issueDate: item.issueDate ? dateWithoutTime(formatISO(item.issueDate)) : null,
          })),
        };

        // что-бы через html-ку не меняли значение
        doctor.settings.isRewardEnabled = this.doctor.settings.isRewardEnabled;
        doctor.settingsuseExternalSchedule = this.midDisabled ? true : this.doctor.settings.useExternalSchedule;
        doctor.settings.currentPlaceOfWorkStartDate = dateWithoutTime(formatISO(this.doctor.settings.currentPlaceOfWorkStartDate));
        doctor.settings.subprogramsIds = this.subprogramsIdsData.map((item) => item.subprogramId).filter((item) => item !== null);
        [doctor.settings.medicalInsuranceType] = this.insuranceTypeArray;
        console.log(doctor.settings.medicalInsuranceType);

        if (this.doctorId) {
          await this.$store.dispatch(this.$types.DOCTOR_UPDATE, doctor);

          Bus.$emit('doctors:update', this.doctorId);
        } else {
          await this.$store.dispatch(this.$types.DOCTOR_CREATE, doctor);
        }

        this.onClose();
      } catch (e) {
        console.warn(e);
      } finally {
        this.isSaving = false;
      }
    },
    onClickAddSpecialization() {
      const newSpecialization = {
        specializationId: null,
        experience: null,
        isForAdults: true,
        isForChildren: true,
        isMain: false,
      };

      this.specializations.push({
        name: '',
        value: null,
      });
      this.$v.doctor.doctorSpecializations.$model.push(newSpecialization);
    },
    onClickDeleteSpecialization(index) {
      this.doctor.doctorSpecializations.splice(index, 1);
      this.doctor.specializations.splice(index, 1);
      this.specializations.splice(index, 1);
    },
    onClickDeletePlacesOfWork(idx) {
      this.doctor.placesOfWork.splice(idx, 1);
      if (this.doctor.specializations?.length) {
        this.doctor.specializations.splice(idx, 1);
      }
    },
    changeSpecialization(spec, index) {
      // При удалении
      if (!spec) {
        this.specializations.splice(index, 1, spec);
        this.$v.doctor.doctorSpecializations.$model[index].specializationId = null;

        return;
      }

      // Проверка есть ли уже такая специальность. Если есть - выводим ошибку
      if (this.specializations.some((item) => {
        if (item) {
          return item.id === spec.id
            || item.value === spec.id;
        }

        return false;
      })) {
        showErrorCustomMessage('Такая специальность уже выбрана');
        return;
      }

      // Добавелние специальности
      this.specializations.splice(index, 1, spec);
      this.$v.doctor.doctorSpecializations.$model[index].specializationId = spec && spec.id;
    },
    changeAccreditationSpecialization(spec, index) {
      console.log(spec, index);
      this.$v.doctor.doctorAccreditationBlanks.$model[index].directionOfStudyId = spec;
    },
    toggleQueueTableShow() {
      this.queueTableShow = !this.queueTableShow;
    },
    onQueueRemove(id) {
      this.doctor.queues = this.doctor.queues.filter((q) => q.id !== id);
    },
    onQueueAdd(queue) {
      if (this.doctor.queues.find((q) => q.id === queue.id)) return; // return if queue already in array
      if (queue) this.doctor.queues.push(queue);
    },
    async fetchClinic({ query, skip, take }) {
      const clinics = await this.$store.dispatch(this.$types.CLINICS_FETCH, {
        skip,
        take,
        filter: {
          query,
          network: null,
          city: null,
        },
      });

      return clinics.map((clinic) => ({ value: clinic.id, name: clinic.title }));
    },
    async fetchSpecializations(query) {
      const data = await this.$store.dispatch(this.$types.SPECIALIZATIONS_SEARCH, query);

      return data;
    },
    fetchSpecializationsDoctorWorkPlace: debounce(async function fetchSpecializationsDoctorWorkPlace(query) {
      const data = await this.$store.dispatch(this.$types.SPECIALIZATIONS_SEARCH, query);

      this.specializationsOption = data;
    }, FETCH_DELAY),
    deleteRegion(region) {
      this.doctor.regions = this.doctor.regions.filter((item) => item.id !== region.id);
    },
    onEducationAdd() {
      const newEducation = {
        directionOfStudyId: null,
        yearOfGraduation: null,
        universityName: null,
        diplomaType: null,
        fileDiploma: null,
        tempFiles: null,
      };

      this.doctor.doctorUniversityEducationBlanks.push(newEducation);
    },
    onAdditionalAdd() {
      const newAdditional = {
        directionOfStudyId: null,
        issueOrganization: null,
        issueDate: null,
        educationType: null,
        educationCustomTypeName: null,
        isActive: true,
        fileDiploma: null,
        tempFiles: null,
      };

      this.doctor.doctorAdditionalEducationBlanks.push(newAdditional);
    },
    onAccreditationAdd() {
      const newAccreditation = {
        directionOfStudyId: null,
        expirationDate: null,
        accreditationType: null,
        fileDiploma: null,
        tempFiles: null,
      };

      this.doctor.doctorAccreditationBlanks.push(newAccreditation);
    },

    onCategoryAdd() {
      const newCategory = {
        directionOfStudyId: null,
        issueDate: null,
        categoryType: null,
        fileDiploma: null,
        tempFiles: null,
      };

      this.doctor.doctorCategoryBlanks.push(newCategory);
    },
    onScienceDegreeAdd() {
      this.showScienceDegrees = true;
    },
    onDoctorRankAdd() {
      this.showDoctorRank = true;
    },
    onEducationRemove(index) {
      this.doctor.doctorUniversityEducationBlanks.splice(index, 1);

      if (!(this.doctor.doctorUniversityEducationBlanks?.length || this.doctor.doctorAdditionalEducationBlanks?.length)) {
        this.doctor.doctorCategoryBlanks = [];
      }
    },
    onAccreditationRemove(index) {
      this.doctor.doctorAccreditationBlanks.splice(index, 1);
    },
    onAdditionalRemove(index) {
      this.doctor.doctorAdditionalEducationBlanks.splice(index, 1);

      if (!(this.doctor.doctorUniversityEducationBlanks?.length || this.doctor.doctorAdditionalEducationBlanks?.length)) {
        this.doctor.doctorCategoryBlanks = [];
      }
    },
    onCategoryBlanksRemove(index) {
      this.doctor.doctorCategoryBlanks.splice(index, 1);
    },
    removeScienceDegree() {
      this.showScienceDegrees = false;
      this.doctor.doctorScienceDegreeBlank.scienceDegreeType = null;
      this.doctor.doctorScienceDegreeBlank.fileDiploma = null;
    },
    removeDoctorRank() {
      this.showDoctorRank = false;
      this.doctor.doctorRankBlank.rankType = null;
      this.doctor.doctorRankBlank.fileDiploma = null;
    },

    async onEducationFileAdd(index) {
      const education = this.doctor.doctorUniversityEducationBlanks[index];
      if (education.tempFiles) {
        const fileId = await this.$store.dispatch(this.$types.DOCTOR_FILE_PUT, education.tempFiles);
        education.fileDiploma = fileId;
        education.tempFiles = null;
      }
    },

    async onAdditionalFileAdd(index) {
      const additional = this.doctor.doctorAdditionalEducationBlanks[index];
      if (additional.tempFiles) {
        const fileId = await this.$store.dispatch(this.$types.DOCTOR_FILE_PUT, additional.tempFiles);
        additional.fileDiploma = fileId;
        additional.tempFiles = null;
      }
    },

    async onCategoryFileAdd(index) {
      const category = this.doctor.doctorCategoryBlanks[index];
      if (category.tempFiles) {
        const fileId = await this.$store.dispatch(this.$types.DOCTOR_FILE_PUT, category.tempFiles);
        category.fileDiploma = fileId;
        category.tempFiles = null;
      }
    },

    async onAccreditationFileAdd(index) {
      this.accreditationFilesLoading = true;

      try {
        const accreditation = this.doctor.doctorAccreditationBlanks[index];
        if (accreditation.tempFiles) {
          const fileId = await this.$store.dispatch(this.$types.DOCTOR_FILE_PUT, accreditation.tempFiles);
          accreditation.fileDiploma = fileId;
          accreditation.tempFiles = fileId;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.accreditationFilesLoading = false;
      }
    },

    async onScienceDegreeBlankAdd(file) {
      const fileId = await this.$store.dispatch(this.$types.DOCTOR_FILE_PUT, file);
      this.doctor.doctorScienceDegreeBlank.fileDiploma = fileId;
    },

    async onRankBlankAdd(file) {
      const fileId = await this.$store.dispatch(this.$types.DOCTOR_FILE_PUT, file);
      this.doctor.doctorRankBlank.fileDiploma = fileId;
    },

    async onDoctorFileDownload(key) {
      await this.$store.dispatch(this.$types.DOCTOR_FILE_DOWNLOAD, { fileKey: key });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep#v-select .vs__dropdown-toggle {
    height: 38px;
}
.doctor-edit-modal {
  &__subprogram-header {
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 10;
    margin-top: 17px;

    &::before {
      position: absolute;
      content: '';
      transform: translate(0, -100%);
      left: 0;
      top: 0;
      height: 17px;
      width: 100%;
      background-color: #fff;
      z-index: -1;
    }
  }
}
:deep(.multiselect__tag) {
  color: #323232;
  background: #f3f3f3;
}
:deep(.multiselect__tag-icon) {
  background: #f3f3f3 ;
}
:deep(.multiselect__tag-icon:hover) {
  background: #c7c7c7 ;
}
:deep(.multiselect__tag-icon::after) {
  color: #323232;
}

.block-divider {
  display: block;
  height: 1px;
  background: rgb(199, 199, 199);
}
.crm-doctor-select{
  height: 38px;
}
.radio-group-grid{
  display: grid;
  grid-template-columns: 50% 50%;
}
.new-specialization {
  display: grid;
  grid-template-columns: 60% 270px;
  column-gap: 30px;
}
.communication{
  display: flex;
  align-items: baseline;
  gap: 20px;
}
.table-text{
  vertical-align: middle;
}
.w-220px {
  width: 220px !important;
}
.error-text {
  font-size: 13px;
  color: red;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
