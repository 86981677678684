<template>
  <div class="w-100 pt-4">
    <AsyncList
      :is-all-items-loaded="isAllItemsLoaded"
      without-loader
      class="h-100"
      @load-more-items="loadMoreItems"
    >
      <b-container>
        <div class="mb-3">
          <div class="h3 mb-0">
            Бронирования
          </div>
        </div>

        <ReservationSearchPanel
          v-model="filter"
          @search="reservationFetch(true)"
        />

        <div class="mt-4">
          <div
            v-if="!totalCount && searchDone && !isLoading"
            class="w-100 bg-white border rounded p-4 font-weight-bold"
          >
            Ничего не найдено по выбранным фильтрам
            <span
              style="cursor: pointer;"
              class="text-primary underlineAnnotation"
              @click="clearAndFetch"
            >
              Сбросить фильтры
            </span>
          </div>

          <template v-if="totalCount">
            <transition-group name="fade">
              <div
                v-for="slot in reservationSlots"
                :key="slot.id"
              >
                <ReservationInfo
                  :reservation="slot"
                  class="mt-3"
                />
              </div>
            </transition-group>
          </template>

          <div v-if="isLoading">
            <b-col
              v-for="item in 20"
              :key="item"
              cols="12"
              class="mt-3 p-0 rounded"
            >
              <b-skeleton-img
                no-aspect
                height="183px"
              />
            </b-col>
          </div>
        </div>
      </b-container>
    </AsyncList>
  </div>
</template>

<script>
import Bus from '@/eventBus';
import debounce from 'lodash/debounce';
import AsyncList from '@/components/AsyncList';

import ReservationSearchPanel from '@/components/Consultations/ReservationSearchPanel';
import ReservationInfo from '@/components/Consultations/ReservationInfo';

import { reservationService } from '@/services';
import { showErrorCustomMessage } from '@/helpers/messages';

const LIMIT = 15;
const FETCH_DELAY = 700;
export default {
  name: 'ConsultationsReservation',
  page: {
    title: 'CRM Doctis - Бронирования',
  },
  components: {
    AsyncList,
    ReservationSearchPanel,
    ReservationInfo,
  },
  data() {
    return {
      LIMIT,
      limit: LIMIT,
      isAllItemsLoaded: false,
      isLoading: false,
      isError: false,
      searchDone: false,
      filter: {
        doctorName: null,
        patientName: null,
        phoneNumber: null,
      },
      reservationSlots: [],
    };
  },
  computed: {
    totalCount() {
      return this.reservationSlots.length;
    },
  },
  watch: {
    filter: {
      handler() {
        localStorage.setItem('reservation-filter', JSON.stringify({ ...this.filter }));
      },
      deep: true,
    },
  },
  created() {
    Bus.$on('consultations-reservation:update', this.updateReservations);
    Bus.$on('consultations-reservation:reset-all-filters', this.resetAllFilters);
    this.consultationsFetchDebounced = debounce(this.reservationFetch, FETCH_DELAY);

    if (localStorage.getItem('reservation-filter')) {
      try {
        const filter = JSON.parse(localStorage.getItem('consultation-filter'));
        this.filter = { ...filter };
      } catch (e) {
        console.error(e);
        localStorage.removeItem('reservation-filter');
      }
    }
  },
  beforeDestroy() {
    Bus.$off('consultations-reservation:update', this.updateReservations);
    Bus.$off('consultations-reservation:reset-all-filters', this.resetAllFilters);
  },
  methods: {
    loadMoreItems() {
      if (this.isLoading) return;
      this.reservationFetch(false);
    },
    async reservationFetch(clear) {
      if ((this.isLoading || this.isError) && !clear) return;
      if (clear) this.reservationSlots = [];

      this.isAllItemsLoaded = false;
      this.isLoading = true;

      const prevTotal = clear ? 0 : this.totalCount;

      const params = {
        skip: prevTotal,
        take: this.limit,
        ...this.filter,
      };

      try {
        const result = await reservationService.search(params);
        this.reservationSlots = [...this.reservationSlots, ...result];

        if (prevTotal + this.limit > this.totalCount) {
          this.isAllItemsLoaded = true;
        }
      } catch (err) {
        this.isAllItemsLoaded = true;
        showErrorCustomMessage('Не удалось загрузить список слотов бронирования');
        console.log(err);
        if (err?.response?.status === 500) {
          this.isError = true;
        }
      } finally {
        this.isLoading = false;
        this.searchDone = true;
      }
    },
    resetAllFilters() {
      localStorage.removeItem('reservation-filter');
      this.filter = {
        doctorName: null,
        patientName: null,
        phoneNumber: null,
        bookingStatus: null,
      };

      this.reservationSlots = [];
      this.reservationFetch(false);
    },
    async clearAndFetch() {
      this.resetAllFilters();
      await this.reservationFetch(true);
    },
    updateReservations(id) {
      if (this.reservationSlots.find((e) => e.id === id)) {
        this.reservationFetch(true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: all 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateX(50px);
}
</style>
