<template>
  <div class="login">
    <form
      class="login-form"
      @submit.prevent="handleSignIn"
    >
      <div
        :class="{ 'login-form__hellow-gashu-text_show': helloGashuShow }"
        class="login-form__hellow-gashu-text"
      >
        Привет от <span>Гашу:)</span>
      </div>
      <img
        class="logo"
        :src="require(`assets/images/${logo}.svg?inline`)"
        @click="onClickLogo"
      >

      <div class="crm-input-block">
        <base-input
          v-model="login"
          :error="!!errors.username"
          :errors="errors.username"
          :input-style="{
            padding: '21px 20px',
          }"
          placeholder="Логин"
          class="login-form__input"
          fluid
          @blur="validateField('username', login)"
          @keydown.meta.enter="submit"
        />

        <base-input
          ref="password"
          v-model="password"
          :error="!!errors.password"
          :errors="errors.password"
          :input-style="{
            padding: '21px 20px',
          }"
          :type="showPassword ? 'text' : 'password'"
          placeholder="Пароль"
          class="login-form__input"
          :class="{ 'mb-4': !!errors.password }"
          fluid
          v-on="{
            blur: onblurPassword,
          }"
          @blur="validateField('password', password)"
          @keydown.meta.enter="submit"
        >
          <template
            v-slot:suffix
          >
            <icon-eye
              :class="[ 'icon-eye', { active: showPassword }]"
              @click="toggleShowPassword"
            />
          </template>
        </base-input>
      </div>

      <base-checkbox
        v-model="saveAuth"
        class="login-form__checkbox"
        align-top
      >
        Сохранить логин и пароль для входа в профиль
      </base-checkbox>

      <b-button
        type="submit"
        class="login-form__button"
        variant="outline-primary"
        pill
      >
        ВОЙТИ
      </b-button>
    </form>
  </div>
</template>

<script>
import {
  // BaseButton,
  BaseInput,
  BaseCheckbox,
} from '@/components/base';

import { validationMixin } from 'vuelidate';
import { DOMAIN_SETTINGS } from '@/helpers/consts';

import { showErrorCustomMessage } from '@/helpers/messages';

import IconEye from 'assets/images/eye_1.svg';
import { APP_SETTINGS } from '@/app.config';
import HubClientService from '@/services/core/hub';
import { authService } from '@/services';
import { authorizationSchema } from '@/validation/zod/auth/auth.validation.js';

export default {
  name: 'Login',
  page: {
    title: 'CRM Doctis - Вход',
  },
  components: {
    BaseInput,
    // BaseButton,
    BaseCheckbox,
    IconEye,
  },
  mixins: [validationMixin],
  props: {
    redirectFrom: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      login: '',
      password: '',
      saveAuth: true,
      showPassword: false,
      passwordSelection: {
        start: 0,
        end: 0,
      },
      countLogoClicks: 0,
      helloGashuShow: false,
      errors: {},
    };
  },
  computed: {
    logo() {
      return DOMAIN_SETTINGS[APP_SETTINGS || 'default'].logo;
    },
  },
  async created() {
    if (this.$route.query?.token) this.signInWithToken();
    if (this.$route.query?.error) {
      showErrorCustomMessage(this.$route.query?.error);
    }
  },
  methods: {
    onClickLogo() {
      this.countLogoClicks += 1;
      if (this.countLogoClicks === 3) {
        this.helloGashuShow = true;

        setTimeout(() => {
          this.countLogoClicks = 0;
          this.helloGashuShow = false;
        }, 3500);
      }
    },
    async focusPassword() {
      this.$refs.password.$refs.input.focus();
      await this.$nextTick();
      this.$refs.password.$refs.input.setSelectionRange(this.passwordSelection.start, this.passwordSelection.end);
    },
    async toggleShowPassword() {
      this.showPassword = !this.showPassword;
      await this.$nextTick();
      this.focusPassword();
    },
    onblurPassword(event) {
      this.passwordSelection.start = event.target.selectionStart;
      this.passwordSelection.end = event.target.selectionEnd;
    },
    // for dynamic validation
    validateField(field, value) {
      const partialSchema = authorizationSchema.pick({ [field]: true });
      const result = partialSchema.safeParse({ [field]: value });

      if (!result.success) {
        this.$set(this.errors, field, result.error.errors.map((e) => e.message));
      } else {
        this.$delete(this.errors, field);
      }
    },
    async handleSignIn() {
      this.errors = {};
      const result = authorizationSchema.safeParse({ username: this.login, password: this.password });

      if (!result.success) {
        result.error.errors.forEach((error) => {
          this.$set(this.errors, error.path[0], [error.message]);
        });
        return;
      }

      try {
        await this.$store.dispatch('Auth/login', {
          login: this.login,
          password: this.password,
          saveAuth: this.saveAuth,
        });
      } catch (err) {
        console.log(err);

        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const error = err?.response?.data?.error || '';
        const errorWithLink = error.replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`);

        showErrorCustomMessage(errorWithLink);
      }

      try {
        const { token } = this.$store.state.Auth;
        if (token) {
          await HubClientService.init(this.$store.state.Auth.token);
        }
      } catch (e) {
        console.log(e);
      }

      await this.$router.push('/');

      if (localStorage.getItem('internal')) {
        window.location.reload();
      }
    },
    async signInWithToken() {
      try {
        const jwtToken = this.$route.query?.token;

        const { role, loginDecoded } = authService.decodeToken(jwtToken);

        await this.$store.dispatch('Auth/updateUserData', {
          saveAuth: this.saveAuth, token: jwtToken, role, loginDecoded,
        });
        this.$router.push('/');
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  @include default-flex-prop;
  width: 100%;
  height: 100%;
}
.login-form {
  position: relative;
  color: $black-light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 70px;
  width: 430px;
  background: $white;
  box-shadow: 0px 1px 2px $gray-shadow;
  box-sizing: border-box;
  border-radius: 4px;

  &__hellow-gashu-text {
    position: absolute;
    opacity: 0;
    font-weight: 500;
    font-size: 17px;
    transition: .5s;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 80px;
    pointer-events: none;

    & span {
      color: black;
      transition: 1s;
    }

    &_show {
      opacity: 1;
      top: 53px;

      & span {
        color: orange;
      }
    }
  }
}
.logo {
  width: 100%;
  max-height: 70px;
  margin-bottom: 50px;
}
.crm-input-block {
  width: 100%;
}
.login-form__input:first-child {
  margin-bottom: 25px;
}
.login-form__button {
  margin-top: 30px;
  font-weight: 500;
  font-size: 16px;
}
.login-form__checkbox {
  margin-top: 20px;
  width: 100%;
}
::v-deep.icon-eye {
  cursor: pointer;

  &:hover,
  &.active {
    path {
      fill: $blue;
    }
  }
}
</style>
