<template>
  <div>
    <div class="crm-form-grid">
      <b-form-input
        v-model="filter.doctorName"
        type="search"
        size="sm"
        placeholder="Введите ФИО врача"
        class="border"
        @keyup.enter="$emit('search')"
      />
      <b-form-input
        v-model="filter.patientName"
        type="search"
        size="sm"
        placeholder="Введите ФИО пациента (или ФИО допа)"
        class="border"
        @keyup.enter="$emit('search')"
      />
      <b-button
        v-b-tooltip.hover
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        title="Поиск"
        @click="$emit('search')"
      >
        <b-icon icon="search" />
      </b-button>
    </div>

    <div class="mt-2 second-line-inputs">
      <b-form-input
        v-model="filter.patientPhoneNumber"
        type="search"
        size="sm"
        placeholder="Номер телефона"
        class="border"
        @keyup.enter="$emit('search')"
      />
      <v-select
        v-model="filter.bookingStatus"
        :options="RESERVATION_STATUSES"
        :reduce="title => title.value"
        placeholder="Cтатус бронирования"
        label="title"
        :clearable="false"
        class="crm-select"
      />

      <b-button
        variant="outline-primary"
        :type="$const.PRIMARY_BUTTON"
        class="ml-auto border-0"
        size="sm"
        @click="resetAllFilters"
      >
        Очистить введенные данные
      </b-button>
    </div>
  </div>
</template>

<script>
import Bus from '@/eventBus';
import { mixinRoles } from '@/mixins';

import { RESERVATION_STATUSES } from '@/helpers/consts';

export default {
  name: 'ReservationSearchPanel',
  mixins: [mixinRoles],
  model: {
    prop: 'filter',
    event: 'change',
  },
  props: {
    filter: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      RESERVATION_STATUSES,
    };
  },
  computed: {
  },
  methods: {
    resetAllFilters() {
      Bus.$emit('consultations-reservation:reset-all-filters');
    },
  },
};
</script>

<style lang="scss" scoped>

.crm-form-grid{
  display: grid;
  justify-content: space-between;
  grid-template-columns: 47% 46% 4% 1fr;
  gap: 10px;
}

::v-deep.crm-select {
  .vs__dropdown-toggle {
    height: 31px;
    background: #fff;
  }

  .vs__selected {
    line-height: 1.3;
  }
}

.second-line-inputs {
  display: grid;
  grid-template-columns: 22% 24% 52% 1fr;
  gap: 10px;
}
</style>
