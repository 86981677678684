import { APP_HOST } from '@/app.config';

export default class ReservationApi {
  constructor($axios) {
    this.api = $axios.instance;
  }

  // поиск забронированных слотов
  async search(params) {
    const { data } = await this.api.get(`${APP_HOST}v2/booking/search`, { params });
    return data;
  }

  // отменить бронь
  async cancel(params) {
    const { data } = await this.api.post(`${APP_HOST}v2/booking/cancel`, params);
    return data;
  }

  // получение конкретного шаблона
  async getHistoryChanges(bookingId) {
    const { data } = await this.api.get(`${APP_HOST}v2/booking/${bookingId}/history`);
    return data;
  }
}
